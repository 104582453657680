import { Box, Typography } from '@mui/material';

import NextPublicWrapper from '../components/entry/NextPublicWrapper';

function MaintenanceModeView() {
  return (
    <NextPublicWrapper>
      <Box mt={1}>
        <Typography className="tertia-prime-text">
          Nice to see you again,
        </Typography>
        <Typography className="canon-text" gutterBottom>
          We're in Maintenance Mode
        </Typography>

        <Typography paragraph className="tertia-prime-text">
          Your favorite Adaptive Cyber Insurance Platform is undergoing
          maintenance at this time. We do not anticipate this to take long.
        </Typography>

        <Typography paragraph className="tertia-prime-text">
          Please check back within the next 30 minutes
        </Typography>
      </Box>
    </NextPublicWrapper>
  );
}

export default MaintenanceModeView;
