/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useMemo } from 'react';
import { TableRow as MuiTableRow } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { TableContext } from './context.table';
import { TableCell } from './cell.table';
import { withExpandableRow } from '../hocs/withExpandableRow';

const TableRowBase = withExpandableRow(({ cellStyle, row, ...props }) => {
  const defaultStyles = useRowStyles();
  const { columns, rowHover, styles, ...ctx } = useContext(TableContext);
  const { isHovered, onHover } = useRowHover(rowHover);

  const classes = useMemo(() => {
    if (styles) {
      const ctxStyles =
        typeof styles.row === 'function'
          ? styles.row(row, props)
          : styles.row || {};
      return { ...defaultStyles, ...ctxStyles };
    }

    return defaultStyles;
  }, [styles, defaultStyles, row, props]);

  return (
    <MuiTableRow
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      {...props}
      classes={classes}
      className={props.expanded ? classes.open : classes.root}
      data-qa={`TABLE_ROW:${row?.id}`}
    >
      {columns.map((column, idx) => {
        return (
          <TableCell
            column={column}
            ctx={ctx}
            row={row}
            key={`td-${idx}`}
            isHovered={isHovered}
            style={cellStyle}
            data-qa={`TABLE_CELL:${column.name}`}
          />
        );
      })}
    </MuiTableRow>
  );
});

export const TableRow = React.memo(TableRowBase, (prev, next) => {
  return JSON.stringify(prev.row) === JSON.stringify(next.row);
});

const useRowStyles = makeStyles(({ palette }) => ({
  root: {
    background: palette.background.lighter,

    '&:hover': {
      background: palette.background.tableRowHover,
    },
  },
  open: {
    background: palette.background.tableRowHover,
    cursor: 'pointer',
  },
}));

function useRowHover(rowHover) {
  const [isHovered, setRowHover] = useState(false);

  if (!rowHover) {
    return { isHovered, onHover: null };
  }

  function onHover() {
    setRowHover(!isHovered);
  }

  return { isHovered, onHover };
}

TableRow.displayName = 'TableRow';
