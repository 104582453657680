import { PERSONA_TYPE_TO_BASE_PATH_MAPPING } from '../components/hooks/useAuth';
import { RedirectToMfaConfig } from '../components/modals/v2/RedirectToMfa';
import { Modal } from '../components/modals/v2/helpers/v2.modal.helpers';
import { MfaType } from '../console/_statics/mfa.statics';
import type { Persona } from '../console/_statics/persona.statics';

interface UserDataMfaProps {
  mfaType: MfaType;
  account: {
    accountType: Persona;
    enforceMfa: boolean;
  };
}

const MANAGE_PROFILE_VIEW_URL = 'manage/me' as const;
const MANAGE_MFA_VIEW_URL = 'manage/me?tab=mfa' as const;

export function getProfileMfaUrl(accountType: Persona) {
  const personaUrl = PERSONA_TYPE_TO_BASE_PATH_MAPPING[accountType];

  return `/${personaUrl}/${MANAGE_MFA_VIEW_URL}`;
}

export function isMfaEnforced(userData: UserDataMfaProps) {
  if (!userData) {
    return false;
  }

  const { mfaType } = userData;
  const enforceMfa = userData.account?.enforceMfa;

  return enforceMfa && mfaType === MfaType.NONE;
}

export function checkIfMfaEnforced(userData: UserDataMfaProps) {
  // check is so you can go to the other tab to change contact number
  if (window.location.href.includes(MANAGE_PROFILE_VIEW_URL)) {
    return;
  }

  if (isMfaEnforced(userData)) {
    const profileMfaUrl = getProfileMfaUrl(userData.account?.accountType);

    Modal.show(RedirectToMfaConfig, {
      data: { profileMfaUrl },
    });
  }
}
