import React from 'react';

import { useQuery } from '@tanstack/react-query';

// other
import { getPasswordConstraints } from '../../../api/auth/auth.api';
import type { ValidationErrorDto } from '../../../api/auth/auth.dto';

export function usePasswordConstraints() {
  const [errors, setPasswordErrors] = React.useState<
    ValidationErrorDto[] | null
  >(null);

  const constraints = useQuery(['password', 'field', 'constraints'], () =>
    getPasswordConstraints().then((resp) =>
      resp.data.map((constraint) => ({
        ...constraint,
        code: constraint.errorCode,
        detail: constraint.displayText,
      }))
    )
  );

  const enrichedContraints = React.useMemo(() => {
    const error_map: Record<string, string> = keyByErrorCode(errors);

    if (!constraints?.data) {
      return [];
    }

    return constraints.data.map((constraint) => {
      const errorDetail = error_map[`${constraint.code}`];

      return {
        ...constraint,
        detail: errorDetail || constraint.detail,
        isValid: !errorDetail,
      };
    });
  }, [errors, constraints.data]);

  return {
    initial: errors === null,
    errors,
    data: enrichedContraints,
    setPasswordErrors,
  };
}

// helpers
function keyByErrorCode(errors: ValidationErrorDto[] | null) {
  if (!errors) {
    return {};
  }

  return errors.reduce(
    (acc, error) => ({
      ...acc,
      [error.code]: error.detail,
    }),
    {}
  );
}
