/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import type { AxiosError, AxiosResponse } from 'axios';

// components
import ReactCodeInput from 'react-code-input';
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox as CheckboxBase,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { InputLabelBase } from '../inputs/InputLabelBase';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { PasswordField as PasswordFieldBase } from '../inputs/text-fields/passwords/PasswordField';

import Showable from '../Showable';
import { withFormController } from '../hocs/forms';

import NextPublicWrapper from './NextPublicWrapper';

// other
import { login } from '../../api/auth/auth.api';
import {
  cacheAuthTokens,
  setIsDeviceRemembered,
} from '../../utils/next/auth.utils';
import { manageAPIError } from '../../utils';
import { LoadingButton } from '../Buttons/LoadingButton';
import {
  useRememberDevice,
  useRememberDeviceFeature,
} from '../hooks/useRememberDevice';

const TextField = withFormController(TextFieldBase);
const PasswordField = withFormController(PasswordFieldBase);
const Checkbox = withFormController(CheckboxBase);

interface FormValues {
  username: string;
  password: string;
  rememberDevice: boolean;
  mfaEnabled?: boolean;
  mfaType?: string;
  mfaCode?: string;
}

interface ResponseData {
  accessToken: string;
  refreshToken: string;
}

const NewLogin = () => {
  const history = useHistory();
  const rememberDeviceFeature = useRememberDeviceFeature();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation<{ redirectUrl?: string; from?: Location }>();

  const rememberDevice = useRememberDevice({
    onSuccess: () => history.replace('/loading'),
    onFailure: () => {
      setTimeout(() => {
        enqueueSnackbar(
          'Your current session has expired. Please log back into the platform to refresh your session.',
          { variant: 'info' }
        );
      }, 1000);
    },
  });

  const [errorMessage, setErrorMessage] = React.useState<string | null>('');
  const [resendFlag, setResendFlag] = React.useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      mfaEnabled: false,
      rememberDevice: rememberDevice.isRemembered,
    },
  });
  const { handleSubmit, formState } = methods;

  const mfaEnabled = methods.watch('mfaEnabled');
  const mfaCode = methods.watch('mfaCode');

  function onSubmit({ rememberDevice, mfaEnabled, ...values }: FormValues) {
    return login({ data: values })
      .then((resp: AxiosResponse<ResponseData>) => {
        setIsDeviceRemembered(rememberDevice);
        cacheAuthTokens(resp.data.accessToken, resp.data.refreshToken);
        const nextLocation =
          location.state?.redirectUrl ?? location.state?.from ?? 'loading';

        history.replace(nextLocation);
      })
      .catch((error: AxiosError<{ mfaType?: string }>) => {
        if (error.response?.status === 418) {
          history.push('/password-expired', { email: values.username });
          return;
        }

        if (error.response?.status === 307) {
          methods.setValue('mfaEnabled', true);
          methods.setValue('mfaType', error.response?.data.mfaType);
          setErrorMessage('');
          return;
        }

        if (error.response?.status === 412) {
          setErrorMessage(manageAPIError(error));
          setResendFlag(true);
          return;
        }

        if (error.response?.status === 500) {
          setErrorMessage('Service Down or Under Maintenance');
          return;
        }

        // Error handling for disabled user and previously used password
        if (
          (error.response?.status === 401 || error.response?.status === 409) &&
          error.response?.data
        ) {
          setErrorMessage(manageAPIError(error));
          return;
        }

        setErrorMessage('Invalid credentials. Please try again.');
      });
  }

  const handleMfaCodeChange = (nextValue: string) => {
    methods.setValue('mfaCode', nextValue);
  };

  if (rememberDevice.isRemembered && rememberDevice.isLoading) {
    return null;
  }

  return (
    <NextPublicWrapper>
      <Box mt={1}>
        <Typography className="tertia-prime-text">
          Nice to see you again,
        </Typography>
        <Typography className="canon-text">Welcome back.</Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputLabelBase style={{ color: '#fff' }}>Email</InputLabelBase>
          {/* @ts-ignore */}
          <TextField
            name="username"
            placeholder="Please enter email"
            inputProps={{ type: 'email', required: 'true' }}
          />

          <InputLabelBase style={{ paddingTop: 0, color: '#fff' }}>
            Password
          </InputLabelBase>
          <PasswordField
            name="password"
            /* @ts-ignore */
            placeholder="Please enter password"
            required
          />

          <Showable show={mfaEnabled}>
            <InputLabelBase style={{ color: '#fff' }}>
              Multi-Factor Code
            </InputLabelBase>
            {/* @ts-ignore */}
            <ReactCodeInput
              type="text"
              name="mfa"
              fields={6}
              onChange={handleMfaCodeChange}
            />
          </Showable>

          <Showable show={rememberDeviceFeature.on}>
            <Box mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="rememberDevice"
                    defaultChecked={rememberDevice.isRemembered}
                    color="secondary"
                  />
                }
                label={
                  <Typography variant="caption" style={{ color: '#fff' }}>
                    Remember this device
                  </Typography>
                }
              />
            </Box>
          </Showable>

          <Box>
            {/* @ts-ignore */}
            <LoadingButton
              fullWidth
              size="small"
              type="submit"
              styleName="ctaButton"
              loading={formState.isSubmitting}
              disabled={mfaEnabled && mfaCode?.length !== 6}
              style={{ marginTop: '2rem' }}
              whileLoading={() => 'Loading...'}
            >
              Login
            </LoadingButton>
          </Box>

          <Showable show={Boolean(errorMessage)}>
            <p style={{ color: '#fa8072' }}>{errorMessage}</p>
            <Showable show={resendFlag}>
              <Link to="/resend-validation" className="link-underlined">
                Resend Validation Email
              </Link>
            </Showable>
          </Showable>

          <Box mt={6}>
            <Link
              className="link-underlined"
              to="/forgot-password"
              style={{ color: '#fff' }}
            >
              Forgot Password
            </Link>
            <Showable show={mfaEnabled}>
              <Link
                to={{
                  pathname: '/forgot-password',
                  state: {
                    username: methods.getValues('username'),
                    password: methods.getValues('password'),
                  },
                }}
                style={{ color: '#fff', marginLeft: 16 }}
                className="link-underlined"
              >
                Reset MFA
              </Link>
            </Showable>
          </Box>
        </form>
      </FormProvider>
    </NextPublicWrapper>
  );
};

export default NewLogin;
