import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

// components
import { Grid, DialogContent, DialogActions } from '@mui/material';
import { withStyles } from '@mui/styles';

import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import CBButton from '../../components/Buttons/CbButton';

// utils
import { withFormController } from '../../components/hocs/forms';
import { toggleModalDirect } from '../../utils/storeUtils';
import { PubSub } from '../../utils/eventUtils';

// apis
import { wizerConnect } from './ConnectorService';

const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  apiKey: Yup.string().required('API Key is required'),
});

const WizerTokenForm = ({ classes, data, ...props }) => {
  const { cbid } = data;
  const defaultValues = {
    apiKey: '',
  };
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    getValues,
    formState: { isDirty, isSubmitting },
  } = methods;

  const onSubmit = (formData) => {
    const payload = { apiKey: formData.apiKey };
    return wizerConnect({ cbid }, payload)
      .then(() => {
        toggleModalDirect('WizerTokenForm', false);
        PubSub.publish('connector-page-refetch', true);
        enqueueSnackbar('Connector added successfully!', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Failed to add the connector', { variant: 'error' });
      });
  };

  const handleCancel = () => {
    toggleModalDirect('WizerTokenForm', false);
  };

  return (
    <section className={classes.cardContentContainer}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={classes.description}>
            Get the API Key for your account from
            https://admin.wizer-training.com/
          </p>
          <DialogContent classes={{ root: classes.container }}>
            <Grid
              container
              justifyContent="left"
              className={classes.inputFields}
            >
              <Grid item md={10}>
                <TextField
                  name="apiKey"
                  label="Enter API Key"
                  type="password"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Connect"
            />
          </DialogActions>
        </form>
      </FormProvider>
    </section>
  );
};

const styles = ({ palette }) => {
  return {
    description: {
      fontSize: '1.167rem',
      color: palette.primary.main,
      lineHeight: 1.5,
      fontStyle: 'italic',
      textAlign: 'center',
      margin: '0 0 2rem 0 !important',
    },
    container: {
      flex: '1 1 auto',
      padding: '0 5rem 0.5rem 5rem',
      'overflow-y': 'visible',
    },
    inputFields: {
      justifyContent: 'space-around',
    },
  };
};

export default withStyles(styles)(WizerTokenForm);
