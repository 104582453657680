import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Typography } from '@mui/material';
import { CreatePasswordField } from '../../inputs/text-fields/passwords/CreatePasswordField';
import { PasswordField } from '../../inputs/text-fields/passwords/PasswordField';
import { TextField } from '../../inputs';
import CBButton from '../../Buttons/CbButton';
import { validatePassword } from '../../../api/SignupService';

class SignupEmailExisting extends Component {
  constructor(props) {
    super(props);
    const email = props.data ? props.data.email : '';
    const firstName = props.data ? props.data.firstName : '';
    const lastName = props.data ? props.data.lastName : '';
    const tokenType = props.data ? props.data.tokenType : '';

    this.state = {
      email,
      firstName,
      lastName,
      tokenType,
      deepLink: false,
      redirect: false,
      password1: '',
      password2: '',
      errors: {
        password1: [],
        password2: undefined,
        generalNetwork: undefined,
      },
      disabled: false,
    };

    this.handleValidatePassword = this.handleValidatePassword.bind(this);
  }

  checkPasswords = () => {
    const { password1, password2 } = this.state;

    if (password1 !== password2) {
      this.setState({
        errors: {
          password2: 'Passwords do not match',
        },
      });
    } else {
      this.setState({
        errors: {
          password2: undefined,
        },
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { password1, password2 } = this.state;

    // validate passwords before triggering request
    this.checkPasswords();
    if (password1 !== password2) {
      return;
    }

    const { token } = this.props;
    const payload = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      password: this.state.password1,
    };

    this.setState({
      disabled: true,
    });

    this.props
      .submitJoinExistingAccount(payload, token)
      .then(() => this.success())
      .catch((error) => this.error(error));
  };

  success = () => {
    const { redirectUrl } = this.props;

    if (_.isUndefined(redirectUrl)) {
      this.setState({ redirect: true, disabled: false, deepLink: false });
    } else {
      this.setState({ deepLink: true, redirect: false });
    }
  };

  error = (error) => {
    if (error.response.status === 400) {
      this.setState({
        errors: {
          password1: Object.values(error.response.data.errors),
        },
        disabled: false,
      });
    } else if (error.response.status === 404) {
      this.setState({
        errors: {
          generalNetwork: 'Email Setup Link Expired/Not Found!',
        },
        disabled: false,
      });
    } else if (error.response.status === 412) {
      const errorMessage =
        typeof error.response.data == 'string'
          ? error.response.data
          : 'Authorization Failed for Token';

      this.setState({
        errors: {
          generalNetwork: errorMessage,
        },
        disabled: false,
      });
    } else {
      this.setState({
        errors: {
          generalNetwork: 'Service Unavailable. Please try again.',
        },
        disabled: false,
      });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target?.value }, this.checkPasswords);
  };

  handlePasswordOnChange = (field) => {
    return (value) => {
      this.setState(
        {
          [field]: value,
        },
        this.checkPasswords
      );
    };
  };

  handleError = (BEValidationErrors) => {
    this.setState((currState) => ({
      errors: {
        password1: BEValidationErrors,
        password2: currState.errors.password2,
      },
    }));
  };

  render() {
    const { redirect, tokenType, email, deepLink, disabled } = this.state;

    const { redirectUrl } = this.props;

    if (deepLink) {
      return <Redirect to={redirectUrl} />;
    }
    if (redirect) {
      return <Redirect to="/loading" />;
    }
    return (
      <div style={{ textAlign: 'left' }}>
        <form onSubmit={this.handleSubmit}>
          {_.eq(tokenType, 'QUOTE_SENT_NEW_USER') && (
            <Typography variant="body1" color="default" align="center">
              Please create a password for {email}
            </Typography>
          )}

          {_.eq(tokenType, 'QUOTE_LINK_NEW_AGENT') && (
            <Typography variant="body1" color="default" align="center">
              Please create a password for {email}
            </Typography>
          )}

          {!_.eq(tokenType, 'QUOTE_SENT_NEW_USER') &&
            !_.eq(tokenType, 'QUOTE_LINK_NEW_AGENT') && (
              <span>
                <Typography variant="body1" color="default" align="center">
                  Please enter below information to complete your{' '}
                  {this.props.data.accountType === 'AGENCY'
                    ? 'agency'
                    : 'account'}{' '}
                  setup!
                </Typography>

                <TextField
                  id="firstName"
                  label="First Name"
                  required
                  value={this.state.firstName}
                  onChange={this.handleChange('firstName')}
                />

                <TextField
                  id="lastName"
                  label="Last Name"
                  required
                  value={this.state.lastName}
                  onChange={this.handleChange('lastName')}
                />
              </span>
            )}
          <CreatePasswordField
            id="password1"
            label="Password"
            required
            errors={this.state.errors.password1}
            onChange={this.handlePasswordOnChange('password1')}
            onError={({ errors }) => this.handleError(errors)}
            onValidate={this.handleValidatePassword}
          />
          <div style={{ paddingTop: '.8rem' }}>
            <PasswordField
              id="password2"
              name="password2"
              label="Confirm Password"
              value={this.state.password2}
              required
              onChange={this.handleChange('password2')}
              error={this.state.errors.password2}
            />
            <span className="error-text">{this.state.errors.password2}</span>
          </div>
          <span className="error-text">{this.state.errors.generalNetwork}</span>

          <div style={{ textAlign: 'center', marginTop: '15px' }}>
            <CBButton
              loading={disabled}
              disabled={this.state.disabled}
              type="submit"
              styleName="ctaButton"
              buttonText="Create account"
              size="large"
            />
          </div>
        </form>

        <Typography type="body1" align="center" style={{ marginTop: 15 }}>
          Have an account? <Link to="/login">Sign in</Link>
        </Typography>

        {this.state.errors.generalNetwork && (
          <Typography
            type="body1"
            align="center"
            style={{ paddingTop: '10px', color: 'red' }}
          >
            {this.state.errors.generalNetwork}
          </Typography>
        )}
      </div>
    );
  }

  handleValidatePassword(password) {
    const { match } = this.props;

    return validatePassword({ token: match.params.token }, { password });
  }
}

export default withRouter(SignupEmailExisting);
