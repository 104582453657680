import React from 'react';
import TextFieldBase from './text-fields/base/TextFieldBase';
import { CleaveBase } from './CleaveBase';

const UkPhoneField = (props: React.ComponentProps<typeof TextFieldBase>) => {
  return (
    <>
      <TextFieldBase inputComponent={CleaveInput} {...props} />
    </>
  );
};

export default UkPhoneField;

type CleaveInputProps = {
  inputRef: React.Ref<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

const CleaveInput: React.FC<CleaveInputProps> = ({ inputRef, ...props }) => {
  return (
    <CleaveBase
      ref={inputRef}
      options={{
        delimiters: [' ', ' ext. '],
        blocks: [5, 6, 10], // Define block sizes for the phone number format
        delimiterLazyShow: true,
      }}
      {...props}
    />
  );
};
