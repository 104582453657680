import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { delayedEvent } from '../../../../utils/eventUtils';
import {
  address1,
  city,
  zipCode,
} from '../../../../utils/validationSchemas/accounts.schema';
import { LoadingButton } from '../../../../components/Buttons/LoadingButton';

import {
  createClaim,
  getClaim,
  updateClaim,
} from '../../_services/claims.service';

import { UsStatesFull } from '../../../../utils/USState';
import { CreateClaimReporter } from './CreateClaimReporter';
import { CreateClaimLocationLoss } from './CreateClaimLocationLoss';
import { CreateClaimOther } from './CreateClaimOther';
import { fetchAccountDetailsById } from '../../../../accounts/AccountService';
import withRouter from '../../../../components/hocs/withRouter';
import CBButton from '../../../../components/Buttons/CbButton';
import { useToggleModal } from '../../../../utils/modal.utils';

const Schema = Yup.object({
  name: Yup.object({
    label: Yup.string(),
    value: Yup.string().required('Please select a option '),
  }),
  cellPhone: Yup.number().label('Cell Phone').fromPhone().phone(),
  workPhone: Yup.number().label('Work Phone').fromPhone().phone(),
  isRightContact: Yup.string().label('Contact').required(),
  address: address1,
  city,
  state: Yup.string().label('State').required(),
  zipCode,
  lossDescription: Yup.string().label('Loss Description').required(),
});

export const ClaimCreateEdit = withRouter(({ data, location, updateUrl }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const disclaimerStyles = useDisclaimerStyles();
  const [claimId, setClaimId] = useState(data.id || null);
  const [editing, setEditing] = useState(Boolean(data.id));
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [ignoreme, setIgnoreme] = useState('');
  const form = useForm({
    defaultValues: {
      name: data.name ? { label: data.name } : {},
      email: data.email || '',
      role: data.role || '',
      companyName: data.companyName || '',
      workPhone: data.workPhone || '',
      cellPhone: data.cellPhone || '',
      isRightContact:
        (_.has(data, 'isRightContact') && boolToString(data.isRightContact)) ||
        'yes',
      contactName: data.contactName || '',
      contactEmail: data.contactEmail || '',
      contactPhone: data.contactPhone || '',
      address: data.address || '',
      city: data.city || '',
      state: _.find(UsStatesFull, { value: data.state }) || {},
      zipCode: data.zipCode || '',
      lossDate: data.lossDate ? moment(data.lossDate).format('YYYY-MM-DD') : '',
      lossDescription: data.lossDescription || '',
      impactedDevicesOs: data.impactedDevicesOs || '',
      impactedDevicesType: data.impactedDevicesType || '',
      incidentNoticed: data.incidentNoticed || '',
      lossInformationExplanation: data.lossInformationExplanation || '',
      isAuthoritiesInvolved:
        (_.has(data, 'isAuthoritiesInvolved') &&
          boolToString(data.isAuthoritiesInvolved)) ||
        'no',
      authorityName: data.authorityName || '',
      authorityPhoneNumber: data.authorityPhoneNumber || '',
      isReportFiled:
        (_.has(data, 'isReportFiled') && boolToString(data.isReportFiled)) ||
        'no',
      reportNumber: data.reportNumber || '',
      isBusinessImpacted:
        (_.has(data, 'isBusinessImpacted') &&
          boolToString(data.isBusinessImpacted)) ||
        'yes',
      isCyberIncident:
        (_.has(data, 'isCyberIncident') &&
          boolToString(data.isCyberIncident)) ||
        'yes',
      isRemediationServiceBegun:
        (_.has(data, 'isRemediationServiceBegun') &&
          boolToString(data.isRemediationServiceBegun)) ||
        'no',
      otherInformationExplanation: data.otherInformationExplanation || '',
      isFiled: data.isFiled || false,
    },
    resolver: yupResolver(Schema),
  });

  form.watch([
    'name',
    'isRightContact',
    'isAuthoritiesInvolved',
    'isReportFiled',
    'state',
  ]);
  const values = form.getValues();

  useOrgDetails(data.accountId, form.setValue, editing);
  useConitionalFields(form, values);

  function onSelect({ target }) {
    form.setValue(target.name, target.value);
  }

  function onMultiSelect(name, vals) {
    form.setValue(name, vals);

    if (name === 'name') {
      form.setValue('role', vals.role);
      form.setValue('email', vals.email);
      form.setValue('companyName', vals.organizationName);
    }
  }

  const onSubmit = useOnSubmit(
    claimId,
    editing,
    setClaimId,
    setEditing,
    setLoading,
    enqueueSnackbar
  );

  const onFileClaim = useCallback(
    () => {
      form.setValue('isFiled', true);
      form.handleSubmit(onSubmit)();
    },
    // eslint-disable-next-line
    [claimId, editing]
  );

  const handleCancel = useCallback(() => {
    const pathoverride = `${location.pathname.replace(`/${data.id}`, '')}`;
    toggleModal.direct('ClaimCreateEdit', false);
    updateUrl({}, 'replace', pathoverride);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    // this is a hack to get the form values to rerender on mount
    setIgnoreme(new Date().toISOString());

    return () => {
      const pathoverride = `${location.pathname.replace(`/${data.id}`, '')}`;
      updateUrl({}, 'replace', pathoverride);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <DialogContent
        style={{
          paddingLeft: '1.6666666667rem',
          paddingRight: '1.6666666667rem',
        }}
      >
        <FormProvider
          {...form}
          onSelect={onSelect}
          onMultiSelect={onMultiSelect}
          loading={loading}
        >
          <CreateClaimReporter editing={editing} />
          <CreateClaimLocationLoss />
          <CreateClaimOther />
        </FormProvider>
      </DialogContent>

      <div style={{ margin: '0 0 1rem', textAlign: 'center' }}>
        <CBButton
          styleName="cancel"
          color="primary"
          buttonText="Cancel"
          onClick={handleCancel}
          style={{ marginRight: 0 }}
        />
        <span style={{ marginRight: '1rem', marginLeft: '1rem' }}>
          <LoadingButton
            disabled={!form.formState.isDirty}
            loading={loading}
            loadingText="Saving..."
            styleName="ctaButton"
            type="submit"
          >
            Save
          </LoadingButton>
        </span>

        <LoadingButton
          {...form.register('isFiled')}
          disabled={!form.formState.isDirty}
          loading={loading}
          loadingText="Saving..."
          styleName="ctaButton"
          color="primary"
          onClick={onFileClaim}
        >
          File Claim
        </LoadingButton>

        <p className={disclaimerStyles.root}>
          If you have any further questions, please call 1 (833) MEET MOO.{' '}
          <br />
          Your Examiner will promptly contact you to further discuss your claim.
        </p>
      </div>
    </form>
  );
});

const useDisclaimerStyles = makeStyles(({ palette }) => ({
  root: {
    margin: '2.25rem 0',
    color: palette.common.cowbellBlue,
    fontSize: '1.25rem',
  },
}));

const useConitionalFields = (form, values) => {
  useEffect(() => {
    form.register('name', { required: true });
    form.register('role', { required: true });
    form.register('email', { required: true });
    form.register('companyName', { required: true });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values.isRightContact === 'yes') {
      form.unregister('contactName', { required: true });
      form.unregister('contactEmail', { required: true });
      form.unregister('contactPhone', { required: true });
    } else {
      form.register('contactName');
      form.register('contactEmail');
      form.register('contactPhone');
    }
    // eslint-disable-next-line
  }, [values.isRightContact]);

  useEffect(() => {
    if (values.isAuthoritiesInvolved === 'yes') {
      form.register('authorityName');
      form.register('authorityPhoneNumber');
      form.register('isReportFiled');
    } else {
      form.unregister('authorityName', { required: true });
      form.unregister('authorityPhoneNumber', { required: true });
      form.unregister('isReportFiled', { required: true });
    }
    // eslint-disable-next-line
  }, [values.isAuthoritiesInvolved]);

  useEffect(() => {
    if (values.isReportFiled === 'yes') {
      form.register('reportNumber');
    } else {
      form.unregister('reportNumber', { required: true });
    }
    // eslint-disable-next-line
  }, [values.isReportFiled]);
};

const useOnSubmit = (
  claimId,
  editing,
  setClaimId,
  setEditing,
  setLoading,
  enqueueSnackbar
) => {
  const toggleModal = useToggleModal();

  return useCallback(
    (formData) => {
      setLoading(true);

      if (!editing) {
        return createClaim({}, formData)
          .then((resp) => {
            if (formData.isFiled) {
              enqueueSnackbar('Claim successfully filed', {
                variant: 'success',
              });
              delayedEvent('table-refetch', 500, 'claims');
              toggleModal.direct('ClaimCreateEdit', false);
            } else {
              setClaimId(resp.data.id);
              setEditing(true);
              enqueueSnackbar('Claim progress saved', { variant: 'success' });
            }

            setLoading(false);
          })
          .catch(() => {
            enqueueSnackbar('Error saving claim', { variant: 'error' });
            setLoading(false);
          });
      }

      return updateClaim({ claimId }, formData)
        .then((resp) => getClaim({ claimId: resp.data.id }))
        .then((resp) => {
          if (resp.data.status === 'UN_FILED') {
            enqueueSnackbar('Claim progress saved', { variant: 'success' });
            setLoading(false);
          }

          if (resp.data.status === 'FILED') {
            enqueueSnackbar('Claim successfully filed', { variant: 'success' });
            toggleModal.direct('ClaimCreateEdit', false);
          }
        })
        .catch(() => {
          setLoading(false);
          enqueueSnackbar('Error saving claim', { variant: 'error' });
        });
    },
    // eslint-disable-next-line
    [claimId, editing]
  );
};

const useOrgDetails = (accountId, setValue, editing) => {
  useEffect(() => {
    if (!editing) {
      fetchAccountDetailsById(accountId)
        .then(({ data: accDetailsData }) => {
          const state = _.find(UsStatesFull, { value: accDetailsData.state });

          setValue('address', accDetailsData.address1);
          setValue('city', accDetailsData.city);
          setValue('state', state);
          setValue('zipCode', accDetailsData.zipCode);
        })
        .catch(console.error.bind(console));
    }
    // eslint-disable-next-line
  }, []);
};

function boolToString(bool) {
  return bool ? 'yes' : 'no';
}
