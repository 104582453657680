import { InputLabelBase } from '../../../../../components/inputs/InputLabelBase';
import type { Question } from './securityQuestions.statics';
import {
  QuestionTypes,
  commonAnswerGroupStyles,
  commonQuestionGroupStyles,
  encryptionQuestionGroupStyles,
  mfaQuestionGroupStyles,
  planningQuestionGroupStyles,
} from './securityQuestions.statics';

/* SCHEMAS */
export const primeOneBaseQuestions = [
  /* Training  */
  {
    questionHeading: '1. Training',
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0 }}
        htmlFor="training"
        {...inputLabelProps}
      >
        Is cybersecurity training required for all employees with Computer
        System access at least annually?
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    name: 'training',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /*  Multifactor Authentication - mfaEmail*/
  {
    questionHeading: '2. Multifactor Authentication',
    topLevelQuestion: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        className="tertia-text"
        style={{ paddingTop: '0.75rem', paddingLeft: '1.5rem' }}
        {...inputLabelProps}
      >
        Is MFA deployed uniformly across all accounts and logins for access to
        the following?
      </InputLabelBase>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaEmail"
        {...inputLabelProps}
      >
        Email:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'mfaEmail',
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
  },

  /* Multifactor Authentication - mfaRemoteSystemAccess */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaRemoteSystemAccess"
        {...inputLabelProps}
      >
        Remote Computer System:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaRemoteSystemAccess',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
  },

  /* Multifactor Authentication - mfaAdminAccounts */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaAdminAccounts"
        {...inputLabelProps}
      >
        Admin/Privileged Accounts:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaAdminAccounts',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
  },

  /* Multifactor Authentication - mfaCloud */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaCloud"
        {...inputLabelProps}
      >
        Cloud:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaCloud',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
  },

  /* Multifactor Authentication - mfaVendor */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaVendor"
        {...inputLabelProps}
      >
        Vendor Access to Computer System:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaVendor',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
  },

  /* Multifactor Authentication - mfaComments */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="mfaComments"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        Comments:
      </InputLabelBase>
    ),
    questionGroupStyle: {
      paddingLeft: '1.5rem',
      paddingBottom: '0.5rem',
    },
    type: QuestionTypes.text,
    placeholder: 'MFA comments',
    name: 'mfaComments',
    show: {
      fields: [
        'mfaEmail',
        'mfaRemoteSystemAccess',
        'mfaAdminAccounts',
        'mfaCloud',
        'mfaVendor',
      ],
      shouldShow: (answers: string[]) => {
        return answers.filter((ans) => ans != null && ans !== 'YES').length;
      },
    },
  },

  /* Backups - backupFrequency */
  {
    questionHeading: '3. Backups',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0 }}
        htmlFor="backupFrequency"
        {...inputLabelProps}
      >
        How often does the Organisation perform backups of business-critical
        data?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: {
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'backupFrequency',
    required: true,
    dynamic: true,
    answers: [
      { label: 'Daily or More Often', value: 'DAILY' },
      { label: 'Weekly', value: 'WEEKLY' },
      { label: 'Monthly', value: 'MONTHLY' },
      { label: 'Quarterly', value: 'QUARTERLY' },
      { label: 'Biannually', value: 'SIX_MONTHS' },
      { label: 'Never', value: 'NEVER' },
    ],
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            style={{ paddingTop: 0 }}
            htmlFor="backupTypes"
            {...inputLabelProps}
          >
            3a. Backups are (select all that apply):
          </InputLabelBase>
        ),
        type: QuestionTypes.multiSelect,
        questionGroupStyle: {
          paddingLeft: '1.5rem',
        },
        answerGroupStyle: commonAnswerGroupStyles,
        name: 'backupTypes',
        required: true,
        dynamic: true,
        comments: (ans: string[]) => {
          return ans != null && ans.includes('BACKUP_FREQUENCY_OTHER');
        },
        show: {
          fieldName: 'backupFrequency',
          isNot: 'NEVER',
        },
        answers: [
          {
            label: 'Segmented (either offline or air-gapped)',
            value: 'BACKUP_FREQUENCY_SEGMENTED',
          },
          {
            label: `In a cloud service that is secured by login credentials separate from the Computer System credentials and/or requires MFA`,
            value: `BACKUP_FREQUENCY_CLOUD`,
            tooltip:
              'In a cloud service that is secured by login credentials separate from the Computer System credentials and/or requires MFA',
          },
          {
            label: 'Tested at least annually',
            value: 'BACKUP_FREQUENCY_TESTED_ANNUALLY',
          },
          { label: 'Other (please specify)', value: 'BACKUP_FREQUENCY_OTHER' },
        ],
      },
    ],
  },

  /* Backups - patchingFrequency */
  {
    questionHeading: '4. Patching',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0 }}
        htmlFor="patchingFrequency"
        {...inputLabelProps}
      >
        How often does the Organisation apply updates to Computer Systems and
        applications when a patch is released?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'patchingFrequency',
    questionGroupStyle: {
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Daily or More Often', value: 'DAILY' },
      { label: 'Weekly', value: 'WEEKLY' },
      { label: 'Monthly', value: 'MONTHLY' },
      { label: 'Quarterly', value: 'QUARTERLY' },

      { label: 'Biannually', value: 'SIX_MONTHS' },
      { label: 'Never', value: 'NEVER' },
    ],
  },

  /* Backups - vendorManagement */
  {
    questionHeading: '5. Vendors',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '65%' }}
        htmlFor="vendorManagement"
        {...inputLabelProps}
      >
        Does the Organisation have a vendor management programme to ensure third
        parties' levels of security are commensurate with the Organisation's
        cybersecurity standard?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'vendorManagement',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Encryption - encryptionAtRest */
  {
    questionHeading: '6. Encryption',
    topLevelQuestion: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        className="tertia-text"
        style={{ paddingTop: '0.5rem', paddingLeft: '1.5rem' }}
        {...inputLabelProps}
      >
        Is encryption of sensitive information in place for the following?
      </InputLabelBase>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="encryptionAtRest"
        {...inputLabelProps}
      >
        At Rest:
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    questionGroupStyle: encryptionQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'encryptionAtRest',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Encryption - encryptionInTransit */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="encryptionInTransit"
        {...inputLabelProps}
      >
        In transit:
      </InputLabelBase>
    ),
    questionGroupStyle: encryptionQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'encryptionInTransit',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Encryption - encryptionOnDevices */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="encryptionOnDevices"
        {...inputLabelProps}
      >
        On mobile devices:
      </InputLabelBase>
    ),
    questionGroupStyle: encryptionQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'encryptionOnDevices',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Contingency Planning - planIncidentResponse */
  {
    questionHeading: '7. Contingency Planning',
    topLevelQuestion: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        className="tertia-text"
        style={{ paddingTop: '0.75rem', paddingLeft: '1.5rem' }}
        {...inputLabelProps}
      >
        Does the Organisation have the following written plans, in place and
        tested at least annually, related to a potential interruption of their
        Computer Systems?
      </InputLabelBase>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="planIncidentResponse"
        {...inputLabelProps}
      >
        Incident Response:
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    questionGroupStyle: planningQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'planIncidentResponse',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Contingency Planning - planDisasterRecovery */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="planDisasterRecovery"
        {...inputLabelProps}
      >
        Disaster Recovery:
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    questionGroupStyle: planningQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'planDisasterRecovery',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Contingency Planning - planBusinessContinuity */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="planBusinessContinuity"
        {...inputLabelProps}
      >
        Business Continuity:
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    questionGroupStyle: planningQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'planBusinessContinuity',
    required: true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Contingency Planning - plansComments */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="plansComments"
        {...inputLabelProps}
      >
        Comments:
      </InputLabelBase>
    ),
    questionGroupStyle: {
      paddingLeft: '1.5rem',
      paddingBottom: '0.5rem',
    },
    show: {
      fields: [
        'planIncidentResponse',
        'planDisasterRecovery',
        'planBusinessContinuity',
      ],
      shouldShow: (answers: string[]) => {
        return answers.filter((ans) => ans != null && ans === 'false').length;
      },
    },
    type: QuestionTypes.text,
    name: 'plansComments',
  },

  /* EOL Products */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="eolProducts"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        <strong>8. End-of-Life</strong> - Are any EOL products in use?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    required: true,
    name: 'eolProducts',
    dynamic: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'N/A', value: 'NA' },
    ],
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="eolProducts-comments"
            style={{ paddingTop: 0 }}
            {...inputLabelProps}
          >
            8a. If yes, please comment on usage with supplemental control
            information:
          </InputLabelBase>
        ),
        questionGroupStyle: {
          paddingBottom: '0.5rem',
        },
        type: QuestionTypes.text,
        name: 'eolProducts-comments',
        show: {
          fieldName: 'eolProducts',
          is: 'YES',
        },
        placeholder:
          'Please comment on usage with supplemental control information',
      },
    ],
  },

  /* Endpoint Detection and Response */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="edr"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        <strong>9. Endpoint Detection & Response</strong> - Is an EDR tool in
        place across the Organisation's Computer System?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    required: true,
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'edr',
    dynamic: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'Uncertain', value: 'UNCERTAIN' },
    ],
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="edr-comments"
            style={{ paddingTop: 0 }}
            {...inputLabelProps}
          >
            9a. If yes, who is the vendor providing the EDR product?
          </InputLabelBase>
        ),

        type: QuestionTypes.text,
        placeholder: `Who is the vendor providing the EDR product?`,
        name: 'edr-comments',
        show: {
          fieldName: 'edr',
          is: 'YES',
        },
      },
    ],
  },

  /* Next generation Antivirus */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="ngav"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        <strong>10. Next Generation Antivirus</strong> - Is NGAV in place across
        the Organisation's Computer System?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    required: true,
    name: 'ngav',
    dynamic: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'Uncertain', value: 'UNCERTAIN' },
    ],
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="ngav-comments"
            style={{ paddingTop: 0 }}
            {...inputLabelProps}
          >
            10a. Who is the vendor providing the NGAV product?
          </InputLabelBase>
        ),
        type: QuestionTypes.text,
        placeholder: `Who is the vendor providing the NGAV product?`,
        name: 'ngav-comments',
        show: {
          fieldName: 'ngav',
          is: 'YES',
        },
      },
    ],
  },

  /* Cyber Crime - isVerifyingBankAccounts */
  {
    questionHeading: (
      <>
        11. Cyber Crime{' '}
        <i style={{ fontWeight: 500 }}>
          (Required if purchased Cyber Crime, otherwise Optional)
        </i>
      </>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="isVerifyingBankAccounts"
        {...inputLabelProps}
      >
        11a. Does the Organisation verify vendor/supplier bank accounts before
        adding them to their accounts payable systems?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'isVerifyingBankAccounts',
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Cyber Crime - isAuthenticatingFundTransferRequests */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="isAuthenticatingFundTransferRequests"
        {...inputLabelProps}
      >
        11b. Is there an established procedure to verify changes to funds
        transfer instructions using a predetermined phone number of the vendor?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'isAuthenticatingFundTransferRequests',
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* Cyber Crime - isPreventingUnauthorizedWireTransfers */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="isPreventingUnauthorizedWireTransfers"
        {...inputLabelProps}
      >
        11c. Does the Organisation prevent unauthorised employees from
        initiating wire transfers?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'isPreventingUnauthorizedWireTransfers',
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="cyberCrimeComments"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        Comments:
      </InputLabelBase>
    ),
    questionGroupStyle: {
      paddingLeft: '1.5rem',
      paddingBottom: '0.5rem',
    },
    show: {
      fields: [
        'isAuthenticatingFundTransferRequests',
        'isPreventingUnauthorizedWireTransfers',
        'isVerifyingBankAccounts',
      ],
      shouldShow: (answers: string[]) => {
        return answers.filter((ans) => ans != null && ans === 'false').length;
      },
    },
    type: QuestionTypes.text,
    placeholder: 'Cyber crime comments',
    name: 'cyberCrimeComments',
  },
] as Readonly<Question[]>;

export const primeOneMediaQuestions = [
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="contentReviewProcedure"
        {...inputLabelProps}
      >
        1. Are content review procedures conducted by a qualified legal adviser
        (e.g. solicitor) before information is published and/or disseminated to
        third parties by the Organisation?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'contentReviewProcedure',
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="copyrightClearanceSearch"
        {...inputLabelProps}
      >
        2. Has the Organisation conducted copyright and trademark clearance
        searches for all trade and service marks that are or have been used by
        the Organisation?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'copyrightClearanceSearch',
    comments: () => true,
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },
] as unknown as Readonly<Question[]>;

export const primeOnePastActivitiesQuestions = [
  /* priorCoverage */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="priorCoverage"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        1. Does the Organisation have a current in-force Cyber insurance policy?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'priorCoverage',
    required: true,
    answers: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'Uncertain', value: 'UNCERTAIN' },
    ],
  },

  /* priorOrPendingClaimCircumstances */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="priorOrPendingClaimCircumstances"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        2. Is the Organisation or any person aware of any prior or pending
        circumstances that could lead to a claim under the proposed policy?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'priorOrPendingClaimCircumstances',
    required: true,
    comments: (ans: string) => {
      return ans === 'true';
    },
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* cyberEvent */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="cyberEvent"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        3. Has the Organisation sustained a Cyber Incident in the last five
        years?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'cyberEvent',
    required: true,
    dynamic: true,
    answers: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="cyberEvent-comments"
            style={{ paddingTop: 0 }}
            {...inputLabelProps}
          >
            3a. If yes, provide incident details including losses paid.
          </InputLabelBase>
        ),
        type: QuestionTypes.text,
        name: 'cyberEvent-comments',
        required: true,
        show: {
          fieldName: 'cyberEvent',
          is: 'true',
        },
      },
    ],
  },

  /* privacyLitigation */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="privacyLitigation"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        4. In the past five years, has the Organisation been subject to
        litigation resulting from a privacy incident? This includes regulatory
        and civil action.
      </InputLabelBase>
    ),
    type: QuestionTypes.boolAnswer,
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'privacyLitigation',
    required: true,
    comments: (ans: string) => {
      return ans === 'true';
    },
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  /* unscheduledSystemDowntime */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="unscheduledSystemDowntime"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        5. During the last three years, has the Organisation suffered loss of
        business income as a result of unscheduled system downtime?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.boolAnswer,
    name: 'unscheduledSystemDowntime',
    required: true,
    comments: (ans: string) => {
      return ans === 'true';
    },
    answers: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },
] as unknown as Readonly<Question[]>;
