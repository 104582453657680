import type { MenuItemProps, MenuProps } from '@mui/material';
import { IconButton, Menu as MuiMenu } from '@mui/material';

import _ from 'lodash';
import type {
  PropsWithChildren,
  ReactElement,
  MouseEventHandler,
  MouseEvent,
} from 'react';
import React, { cloneElement, Children, useCallback } from 'react';

import type { DefaultTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import TableMenuIcon from '../../_assets/svg/TableMenuIcon.svg';

interface SimpleMenu extends MenuProps {
  anchor?: JSX.Element;
  minWidth?: string;
}

export const SimpleMenu = ({
  anchor = (
    <IconButton size="small" aria-haspopup="true">
      <TableMenuIcon />
    </IconButton>
  ),
  children,
  minWidth,
  ...props
}: Omit<SimpleMenu, 'open'>) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useClasses({ minWidth });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createClickHandler = useCallback(
    (childOnClick: MouseEventHandler<HTMLLIElement> | undefined) => {
      return (event: MouseEvent<HTMLLIElement>) => {
        if (_.isFunction(childOnClick)) {
          childOnClick(event);
          handleClose();
        }
      };
    },
    []
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div onClick={(e) => e.stopPropagation()} aria-hidden>
      {cloneElement(anchor, {
        'aria-describedby': id,
        onClick: handleClick,
      })}
      <MuiMenu
        id={id}
        open={open}
        autoFocus={false}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        {...props}
      >
        {Children.map(children, (c) => {
          const child = c as ReactElement<
            PropsWithChildren<MenuItemProps & { closeMenu: () => void }>
          >;

          if (child?.props) {
            return cloneElement(child, {
              classes: { root: classes.menuItem, ...child.props.classes },
              onClick: createClickHandler(child.props.onClick),
              closeMenu: handleClose,
            });
          }
          return <span />;
        })}
      </MuiMenu>
    </div>
  );
};

const useClasses = makeStyles<DefaultTheme, { minWidth?: string }>(
  ({ config, palette }) => ({
    paper: {
      minWidth: ({ minWidth }) => minWidth ?? 'auto',
      background: palette.common.darkBlue,
      border: `1px solid ${palette.common.almostWhite}`,
      color: palette.common.almostWhite,
    },

    menuItem: {
      padding: '0.4166666667rem 0.8333333333rem',
      color: config.colors.white,

      '&:hover, &:focus': {
        color: config.colors.white,
        fontWeight: 600,
        background: '#113E68',
      },
    },
  })
);
