import React from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
// components
import { makeStyles } from '@mui/styles';
import { TypeAheadBase } from '../../components/inputs/autocomplete';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import { withShowable } from '../../console/_global/lib/withShowable';
// services
import { getMyTeams } from '../../teams/TeamsService';

const defaultLabelProps = {
  style: { padding: 0, fontSize: '1rem' },
};

export const AgencyTeamsLookupField = withShowable(
  ({
    show = true,
    defaultOptions,
    label,
    labelProps = defaultLabelProps,
    ...props
  }) => {
    if (!show) {
      return null;
    }
    const classes = useStyles();
    const inputClasses = classnames({
      [classes.inline]: labelProps.inline,
    });

    const { setValue } = useFormContext();
    const [options, setOptions] = React.useState(defaultOptions);

    function handleChange(newValue) {
      setValue(props.name, newValue);
    }

    const onFetch = React.useCallback(({ input }, callback) => {
      getMyTeams({ search: input }).then(({ data }) => {
        callback(
          data.content.map((team) => ({
            label: `${team.name}`,
            value: team.id,
            meta: {
              ...team,
            },
          }))
        );
      });
    }, []);

    function onOptionsChange(newOptions) {
      setOptions(newOptions);
    }

    return (
      <>
        <InputLabelBase
          indent
          htmlFor={props.name}
          required={props.required}
          {...labelProps}
        >
          {label || 'Team'}
        </InputLabelBase>
        <TypeAheadBase
          id="teams"
          options={options}
          onChange={handleChange}
          onOptionsChange={onOptionsChange}
          onFetch={onFetch}
          className={inputClasses}
          fetchOnMount
          {...props}
        />
      </>
    );
  }
);

const useStyles = makeStyles(() => ({
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));
