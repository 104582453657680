import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import reducers from '../reducers/reducers';

import migrations from '../migrations';

import { SideEffectTransformations } from '../store/side-effect.store.transformer';

const persistConfig = {
  key: 'root',
  storage,
  debug: false,
  whitelist: [
    'auth',
    'account',
    'uiSettings',
    'team',
    'teams',
    'prime100',
    'coverage250',
    'tabs',
  ],
  migrate: createMigrate(migrations, { debug: false }),
  stateReconciler: autoMergeLevel2,
  version: 10,
  transforms: [SideEffectTransformations],
};

const reducer = persistReducer(persistConfig, reducers);
const enhancers = composeWithDevTools({ trace: true });
const store = createStore(reducer, {}, enhancers(applyMiddleware(thunk)));

export const persistor = persistStore(store);
export default store;
