import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import {
  Divider,
  Grid,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid as MuiGrid,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// components
import { withFormController } from '../hocs/forms';
import { TextFieldBase } from '../inputs/TextFieldBase';

import { delayedEvent } from '../../utils/eventUtils';
import { addAgencyDa } from '../../admin/digital/da.service';
import CBAutocomplete from '../autocomplete/CBAutocomplete';
import CbButton from '../Buttons/CbButton';
import { PhoneField as PhoneFieldBase } from '../inputs';
import { getAutocompleteAccounts } from '../../accounts/AccountService';

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);

const daAgencySchema = Yup.object().shape({
  npn: Yup.string()
    .test(
      'len',
      'NPN must be less than or equal to 10 digits',
      (val) => val.length <= 10
    )
    .required()
    .label('NPN'),
  contactFirstName: Yup.string().required().label('First Name'),
  contactLastName: Yup.string().required().label('Last Name'),
  contactEmail: Yup.string().email().label('Email').required(),
  contactPhone: Yup.number().label('Phone').fromPhone('').phone('').required(),
});

export const AgencyCreateProfile = withStyles(({ config, palette }) => ({
  headings: {
    marginTop: '1rem',
    color: palette.primary.contrastText,
    fontWeight: config.weights.normal,
    fontSize: config.textSizes.normal,
  },
  nameContainer: {
    direction: 'row',
    justify: 'center',
    alignItems: 'center',
  },
  divider: {
    marginBottom: '1rem',
    backgroundColor: palette.primary.contrastText,
  },
}))(({ classes, close, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  // State utilized for handling backendErrors
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [agencyNames, setNames] = useState([]);
  let searching = false;

  const defaultValues = {
    name: '',
    description: data.description,
    hostname: data.hostname,
    npn: data.npn || '',
    agencyId: data.id,
  };

  const {
    handleSubmit,
    setValue,
    register,
    formState: { isSubmitting },
    ...methods
  } = useForm({
    defaultValues,
    resolver: yupResolver(daAgencySchema),
  });

  const handleSearch = (event) => {
    const { value } = event.target;
    searching = true;
    setName(value);
    setValue('name', value);
    getAutocompleteAccounts({
      search: value,
      page: 0,
      pageSize: 100,
    })
      .then((resp) => {
        setNames(resp.data.content);

        searching = false;
      })
      .catch(console.error.bind(console));
  };
  const handleAccountNameChange = (event, value) => {
    if (value) {
      setName(value.name);
      setValue('name', value.name);
    }
  };
  const onSubmit = ({ ...formData }) => {
    const payload = {
      ...formData,
    };

    return addAgencyDa({}, payload)
      .then(() => {
        enqueueSnackbar('Agency successfully added!', { variant: 'success' });
        close();
        delayedEvent('table-refetch', 2000, 'agencies');
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(error.response, 'data', 'Not able to add agency at this time')
          )
        );
      });
  };

  const renderAccountOption = (optionProps, options) => {
    // eslint-disable-next-line no-shadow
    const { npn, name, city, zipCode } = options;
    const stateVal = options.state;

    return (
      <li {...optionProps}>
        <div
          style={{ width: '100%', position: 'relative', paddingBottom: '10' }}
        >
          <span>{npn}</span>
          <p style={{ margin: '0', fontWeight: 'bold' }}>{name}</p>
          <p style={{ margin: 0 }}>
            {city}, {stateVal}, {zipCode}
          </p>
        </div>
      </li>
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div style={{ marginBottom: '1rem' }}>
            <CBAutocomplete
              id="name"
              label="Name"
              required
              placeholder="enter agency name"
              options={agencyNames}
              getOptionLabel={(o) => o?.name ?? ''}
              renderOption={renderAccountOption}
              onChangeHandler={handleAccountNameChange}
              onTextChangeHandler={handleSearch}
              inputValue={name || ''}
              data-qa="name"
              loading={searching}
            />
          </div>
          <TextField label="Description" name="description" />

          <TextField required type="number" label="NPN" name="npn" />

          <div className={classes.headings}>AGENT INFORMATION</div>
          <Divider className={classes.divider} />

          <MuiGrid container spacing={6}>
            <MuiGrid item md={6}>
              <TextField label=" First Name" name="contactFirstName" required />
            </MuiGrid>
            <MuiGrid item md={6}>
              <TextField label="Last Name" name="contactLastName" required />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={6}>
            <MuiGrid item md={6}>
              <TextField label="Email" name="contactEmail" required />
            </MuiGrid>
            <Grid item md={6}>
              <PhoneField label="Phone Number" name="contactPhone" required />
            </Grid>
          </MuiGrid>
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText=" Save Agency"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
});
