import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import clsx from 'classnames';
import Moment from 'moment';
import { withSnackbar, useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { Box, Card, Grid, InputAdornment } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { useQueryClient } from '@tanstack/react-query';
import { toggleModalDirect } from '../../../utils/storeUtils';
import { UsStatesFull } from '../../../utils/USState.js';
import { SimpleSelect } from '../../../components/inputs/selects/SimpleSelect';
import { InputLabelBase } from '../../../components/inputs/InputLabelBase';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import { PhoneField as PhoneFieldBase } from '../../../components/inputs/PhoneField';
import { RegisteredNaicsCodeAutoComplete } from '../../../naics/NaicsCodeAutoComplete';

import '../../../console/_global/lib/validations/CurrencySchemaType';
import '../../../console/_global/lib/validations/NumeralSchemaType';
import { INDICATION_STATUS } from '../../../console/_statics/quote.statics';
import {
  updateOrgProfile,
  verifyOrgProfile,
} from '../../../accounts/AccountService';
import { PubSub } from '../../../utils/eventUtils';
import { stringsToBools } from '../../../utils/data.utils';
import { fetchQuoteDetails } from '../../../api/apis';
import withRouter from '../../../components/hocs/withRouter';
import { withFormController } from '../../../components/hocs/forms';
import {
  noOfEmployees,
  revenue,
} from '../../../utils/validationSchemas/accounts.schema';
import { EditSection } from './EditSection';

import { RegisteredDomainField as Domain } from '../../../components/inputs/DomainField';
import { NumeralFieldBase } from '../../../components/inputs/NumeralField';
import Showable from '../../../components/Showable';
import { ProductTypes } from '../../../types';
import LanguageCurrencyFieldBase from '../../../components/inputs/LanguageCurrencyField';
import { manageAPIError } from '../../../utils';
import { FirmoAndSecurityHeader } from '../../../console/agencies/quotes/PrimeX/AccountDetails/components/SecurityAssessment';
import { getPhoneValue } from '../../../i18n/forms/LanguageForm';

const DomainField = withFormController(Domain);
const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);
const MuiSelect = withFormController(SimpleSelect);
const NumeralField = withFormController(NumeralFieldBase);

/**
 * 👻 ==================================
 *
 * THIS FILE IS CURSED STAY AWAY!!!
 *
 * 👻 ===================================
 * */

const MAX_INTERVALS = 10;
const INTERVAL_DELAY = 3000;

const ownershipTypeOptions = [
  { value: 'Public', label: 'Public' },
  { value: 'Private', label: 'Private' },
  { value: 'Non-Profit', label: 'Non-Profit' },
  { value: 'Public Sector', label: 'Public Sector' },
  { value: 'Partnership', label: 'Partnership' },
  { value: 'Non-Corporates', label: 'Non-Corporates' },
];

const entityTypeOptions = [
  { value: 'Independent', label: 'Independent' },
  { value: 'Holding', label: 'Holding' },
  { value: 'Subsidiary', label: 'Subsidiary' },
  { value: 'Branch', label: 'Branch' },
  { value: 'Parent', label: 'Parent' },
];

const isFranchiseOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

const getIsFranchise = (isFranchise) => {
  if (isFranchise === true || isFranchise === 'Yes') {
    return 'Yes';
  }
  if (isFranchise === false || isFranchise === 'No') {
    return 'No';
  }
  return null;
};

const getYesNoValue = (value) => {
  if (value === true) {
    return 'yes';
  }

  if (value === false) {
    return 'no';
  }

  return undefined;
};

const formatIsFranchise = (isFranchise) => {
  if (isFranchise === 'Yes') {
    return true;
  }
  if (isFranchise === 'No') {
    return false;
  }
  return null;
};

export const inputProps = {
  style: {
    border: 'none',
  },
};

const autoCompleteLabelProps = {
  style: { paddingTop: 0 },
  indent: false,
};

export const AccountDetails = withSnackbar(
  withRouter(
    ({
      parentClasses,
      account,
      accountId,
      quote,
      route,
      match,
      $cardRef,
      readOnly = false,
      from,
      live = false,
      togglePrompt,
      productType = null,
      refetchCall,
      onSectionSubmit,
      ...props
    }) => {
      const wholesale = route === 'quote';
      const agency = route === 'agency';
      const admin = route === 'admin';
      const UW = route === 'underwriter';

      const { enqueueSnackbar } = useSnackbar();
      const classes = useClasses({ from, live });
      const { palette } = useTheme();
      const [editing, setEditing] = useState(false);
      const [error, setError] = useState('');
      const gridItem = clsx(classes.gridItem, {
        editing,
      });
      const initialRender = useRef(true);

      const queryClient = useQueryClient();

      const defaultValues = useMemo(
        () => ({
          ...account,
          phoneNumber: getPhoneValue(account.phoneNumber ?? ''),
          changeInScope: getYesNoValue(account.changeInScope),
          collectingPersonalInformation: getYesNoValue(
            account.collectingPersonalInformation
          ),
          franchiseSystemConnectivity: getYesNoValue(
            account.franchiseSystemConnectivity
          ),
          totalRevenue:
            account.totalRevenue ??
            (account.revenue || 0 + account.nonUsRevenue || 0),
          domains: _.get(account, 'domains', [])
            .map((item) => item.domain)
            .join(', '),
          isFranchise: getIsFranchise(account.isFranchise),
        }),
        [account]
      );

      const { handleSubmit, ...methods } = useForm({
        defaultValues,
        resolver: yupResolver(determineSchema(productType)),
      });

      React.useEffect(() => {
        methods.register('primaryIndustry');
        methods.register('secondaryIndustry');
        methods.register('secondPrimaryIndustry');
        methods.register('secondSecondaryIndustry');
        // eslint-disable-next-line
      }, [methods]);

      const values = methods.getValues();

      const isGlobalFranchise = methods.watch('isFranchise');

      React.useEffect(() => {
        if (
          isGlobalFranchise !== 'Yes' &&
          productType === ProductTypes.p100_pro
        ) {
          methods.setValue('franchiseSystemConnectivity', undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isGlobalFranchise]);

      const collectPersonalInfo = methods.watch(
        'collectingPersonalInformation'
      );

      React.useEffect(() => {
        if (
          collectPersonalInfo !== 'yes' &&
          productType === ProductTypes.p100_pro
        ) {
          methods.setValue('numberOfPersonalRecords', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [collectPersonalInfo]);

      const chScope = methods.watch('changeInScope');

      React.useEffect(() => {
        if (chScope !== 'yes' && productType === ProductTypes.p100_pro) {
          methods.setValue('changeInScopeDetail', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [chScope]);

      const primaryNaicsPercentage = methods.watch('primaryNaicsPercentage');

      methods.watch([
        'secondaryNaicsPercentage',
        'primaryIndustry',
        'secondaryIndustry',
        'secondPrimaryIndustry',
        'secondSecondaryIndustry',
        'secondNaicsCode',
      ]);

      useEffect(() => {
        if (initialRender.current && primaryNaicsPercentage) {
          methods.reset();
          initialRender.current = false;
        }

        if (!initialRender.current && primaryNaicsPercentage) {
          methods.setValue(
            'secondaryNaicsPercentage',
            100 - Number(primaryNaicsPercentage)
          );
        }
        // eslint-disable-next-line
      }, [primaryNaicsPercentage]);

      useEffect(() => {
        const sub = PubSub.subscribe('cancel-detailChanges', (res) => {
          if (res) {
            methods.reset(defaultValues);
          }
        });

        return () => {
          sub.remove();
        };
        // eslint-disable-next-line
      }, []);

      const updateProfile = (payload, isFranchise) => {
        updateOrgProfile(
          {},
          {
            accountId,
            isFranchise: formatIsFranchise(isFranchise),
            ...payload,
          }
        )
          .then(() => {
            setEditing(false);
            refetchCall?.();
            PubSub.publish('disable-Submit', false);
            PubSub.publish('fetch-details', true);
            PubSub.publish('org:agent-attested', {
              isVerifiedByAgent: false,
            });
            enqueueSnackbar('Account Details Updated Successfully.', {
              variant: 'success',
            });

            methods.reset({
              ...payload,
              changeInScope: getYesNoValue(payload.changeInScope),
              secondaryNaicsPercentage: 100 - payload.primaryNaicsPercentage,
              collectingPersonalInformation: getYesNoValue(
                payload.collectingPersonalInformation
              ),
              franchiseSystemConnectivity: getYesNoValue(
                payload.franchiseSystemConnectivity
              ),
              isFranchise: getIsFranchise(isFranchise),
            });

            if (typeof onSectionSubmit === 'function') {
              onSectionSubmit();
            }
          })
          .catch((error) => {
            methods.reset(defaultValues);
            const errorMsg = manageAPIError(
              error,
              'Not able to save details at this time. Please try again later.'
            );

            setError(errorMsg);
          });
      };

      const handlePrimaryNaics = (naicsData) => {
        methods.setValue('naicsCode', naicsData.value, { shouldDirty: true });
        methods.setValue('primaryIndustry', naicsData?.meta?.primaryIndustry, {
          shouldDirty: true,
        });
        methods.setValue(
          'secondaryIndustry',
          naicsData?.meta?.secondaryIndustry,
          { shouldDirty: true }
        );
      };

      const handleSecondaryNaics = (naicsData) => {
        methods.setValue('secondNaicsCode', naicsData?.value ?? undefined);
        methods.setValue(
          'secondPrimaryIndustry',
          naicsData?.meta?.primaryIndustry ?? undefined
        );
        methods.setValue(
          'secondSecondaryIndustry',
          naicsData?.meta?.secondaryIndustry ?? undefined
        );
      };

      const onSubmit = async ({ isFranchise, ...formValues }) => {
        let payload = stringsToBools({
          ...formValues,
        });

        if (productType === ProductTypes.p100_pro) {
          await queryClient.invalidateQueries({
            queryKey: ['account-details', accountId],
            exact: true,
          });
        }

        if (!formValues.secondNaicsCode) {
          payload = _.omit(payload, [
            'secondNaicsCode',
            'secondaryNaicsPercentage',
            'secondPrimaryIndustry',
            'secondSecondaryIndustry',
          ]);
        }

        if (wholesale || agency || admin || UW) {
          if (agency || admin || UW) {
            updateProfile(payload, isFranchise);
          } else {
            updateOrgProfile(
              {},
              {
                accountId,
                quoteId: quote.id,
                isFranchise: formatIsFranchise(isFranchise),
                ...payload,
              }
            )
              .then((response) => {
                setEditing(false);
                PubSub.publish('quote:patching', { isPatching: true });
                $cardRef.scrollIntoView({ behavior: 'smooth' });
                return onAccountUpdate(response, match, quote.lastPatched);
              })
              .then((updatedQuote) => {
                PubSub.publish('quote:patched', updatedQuote);
                PubSub.publish('quote:patching', { isPatching: false });
                props.enqueueSnackbar(
                  'Quote succesfully updated. Please review coverages.',
                  {}
                );
                methods.reset({
                  ...payload,
                  changeInScope: getYesNoValue(payload.changeInScope),
                  collectingPersonalInformation: getYesNoValue(
                    payload.collectingPersonalInformation
                  ),
                  franchiseSystemConnectivity: getYesNoValue(
                    payload.franchiseSystemConnectivity
                  ),
                  isFranchise: getIsFranchise(isFranchise),
                  secondaryNaicsPercentage:
                    100 - payload.primaryNaicsPercentage,
                });

                if (typeof onSectionSubmit === 'function') {
                  onSectionSubmit();
                }
              })
              .catch((error) => {
                methods.reset(defaultValues);
                PubSub.publish('quote:patching', { isPatching: false });

                if (error.message === 'max retries reached') {
                  props.enqueueSnackbar(
                    'An error occurred, please try updating the account details again.',
                    {
                      variant: 'error',
                    }
                  );
                } else {
                  props.enqueueSnackbar(
                    'Failed to save account details, please try again.',
                    {
                      variant: 'error',
                    }
                  );
                }
              });
          }
        } else {
          verifyOrgProfile(
            {},
            {
              accountId,
              isFranchise: formatIsFranchise(isFranchise),
              ...payload,
            }
          ).then((resp) => {
            const { WillInvalidateQuotes, WillReferQuotes } = resp.data;

            methods.reset();

            if (WillInvalidateQuotes || WillReferQuotes) {
              setEditing(false);
              toggleModalDirect(
                'InvalidateQuotes',
                {
                  accountId,
                  isFranchise: formatIsFranchise(isFranchise),
                  update: 'firmo',
                  reset: methods.reset,
                  ...payload,
                },
                {
                  title: `Account Changes for ${account.name}`,
                  maxWidth: 'md',
                }
              );
            } else {
              updateProfile(payload, isFranchise);
            }
          });
        }
      };

      useEffect(() => {
        if (togglePrompt)
          togglePrompt('ACCOUNT_DETAILS', methods.formState.isDirty);
        // eslint-disable-next-line
      }, [methods.formState.isDirty]);

      const usRevenue = methods.watch('revenue');
      const nonUsRevenue = methods.watch('nonUsRevenue');

      useEffect(() => {
        if (productType === ProductTypes.p100_pro) {
          const usRevenueNumber =
            typeof usRevenue === 'string'
              ? Number(usRevenue.replace(/[^0-9.-]+/g, ''))
              : usRevenue || 0;
          const nonUsRevenueNumber =
            typeof nonUsRevenue === 'string'
              ? Number(nonUsRevenue?.replace(/[^0-9.-]+/g, ''))
              : nonUsRevenue || 0;
          methods.setValue(
            'totalRevenue',
            usRevenueNumber + nonUsRevenueNumber
          );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [usRevenue, nonUsRevenue]);

      const disabled = wholesale
        ? quote.agencyStatus !== INDICATION_STATUS
        : readOnly;

      return (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.header}>
              <FirmoAndSecurityHeader>
                {productType === ProductTypes.p100_pro
                  ? 'GENERAL INFORMATION'
                  : 'ACCOUNT DETAILS'}
              </FirmoAndSecurityHeader>
            </div>
            <Card className={parentClasses?.card}>
              <Grid container spacing={8}>
                <Grid className={gridItem} item md={6}>
                  <div>
                    <InputLabelBase required>Name</InputLabelBase>
                    <TextField
                      name="name"
                      inputProps={inputProps}
                      fullWidth
                      placeholder="Company name"
                      disabled={disabled}
                    />
                  </div>
                  <div>
                    <InputLabelBase className={classes.inputLabelCustom}>
                      DBA
                    </InputLabelBase>
                    <Box>
                      <TextField
                        name="dbaOrTradestyle"
                        inputProps={inputProps}
                        fullWidth
                        placeholder="DBA"
                        disabled={disabled}
                      />
                    </Box>
                  </div>
                  <div>
                    <InputLabelBase
                      className={classes.inputLabelCustom}
                      required
                    >
                      Primary Industry Code
                    </InputLabelBase>
                    <Box display="flex" justifyContent="space-between">
                      <NaicsCodeField
                        name="naicsCode"
                        labelProps={autoCompleteLabelProps}
                        placeholder="Primary Industry code"
                        defaultValue={values.naicsCode}
                        required
                        textFieldProps={inputProps}
                        disabled={disabled}
                        ref={methods.register('naicsCode', { type: 'custom' })}
                        onChange={handlePrimaryNaics}
                      />
                      <div className={classes.percentageFieldContainer}>
                        <TextField
                          name="primaryNaicsPercentage"
                          defaultValue={primaryNaicsPercentage}
                          inputProps={{
                            min: 51,
                            max: 100,
                            ...inputProps,
                          }}
                          type="number"
                          placeholder="132"
                          disabled={disabled}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Box>
                  </div>
                  <div>
                    <InputLabelBase
                      className={classes.inputLabelCustom}
                      required={primaryNaicsPercentage != 100}
                    >
                      Secondary Industry Code
                    </InputLabelBase>
                    <Box display="flex" justifyContent="space-between">
                      <NaicsCodeField
                        name="secondNaicsCode"
                        required
                        labelProps={autoCompleteLabelProps}
                        placeholder="Secondary Industry code"
                        defaultValue={values.secondNaicsCode}
                        readOnly={false}
                        textFieldProps={inputProps}
                        disabled={disabled}
                        disableClearable={false}
                        ref={methods.register('secondNaicsCode', {
                          type: 'custom',
                        })}
                        onChange={handleSecondaryNaics}
                      />
                      <div className={classes.percentageFieldContainer}>
                        <TextField
                          name="secondaryNaicsPercentage"
                          defaultValue={100 - Number(primaryNaicsPercentage)}
                          inputProps={{
                            min: 0,
                            max: 49,
                            ...inputProps,
                          }}
                          type="number"
                          placeholder="132"
                          disabled
                          fullWidth
                          value={100 - Number(primaryNaicsPercentage)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Box>
                  </div>
                  <div>
                    <InputLabelBase className={classes.inputLabelCustom}>
                      EIN Number
                    </InputLabelBase>
                    <Box>
                      <TextField
                        name="ein"
                        inputProps={inputProps}
                        type="number"
                        fullWidth
                        placeholder="123456789"
                        disabled={disabled}
                      />
                    </Box>
                  </div>
                  <div>
                    <InputLabelBase
                      className={classes.inputLabelCustom}
                      required
                    >
                      {productType === ProductTypes.p100_pro
                        ? 'US Revenue'
                        : 'Projected Revenue'}
                    </InputLabelBase>
                    <Box mb={2}>
                      <LanguageCurrencyField
                        name="revenue"
                        defaultValue={values.revenue}
                        inputProps={inputProps}
                        fullWidth
                        disabled={disabled}
                      />
                    </Box>
                  </div>

                  <Showable show={productType === ProductTypes.p100_pro}>
                    <div>
                      <InputLabelBase
                        className={classes.inputLabelCustom}
                        required
                      >
                        Non-US Revenue
                      </InputLabelBase>
                      <Box>
                        <LanguageCurrencyField
                          name="nonUsRevenue"
                          defaultValue={values.nonUsRevenue}
                          inputProps={inputProps}
                          fullWidth
                          disabled={disabled}
                        />
                      </Box>
                    </div>
                    <div>
                      <InputLabelBase
                        className={classes.inputLabelCustom}
                        required
                      >
                        Total Revenue
                      </InputLabelBase>
                      <Box>
                        <LanguageCurrencyField
                          name="totalRevenue"
                          defaultValue={values.totalRevenue}
                          inputProps={inputProps}
                          fullWidth
                          disabled
                        />
                      </Box>
                    </div>
                  </Showable>

                  <div>
                    <InputLabelBase className={classes.inputLabelCustom}>
                      DUNS
                    </InputLabelBase>
                    <Box>
                      <TextField
                        name="dunsNumber"
                        defaultValue={values.dunsNumber}
                        inputProps={inputProps}
                        type="number"
                        fullWidth
                        placeholder="1234567"
                        disabled={disabled}
                      />
                    </Box>
                  </div>
                  <div>
                    <InputLabelBase
                      className={classes.inputLabelCustom}
                      required
                    >
                      Number of Employees
                    </InputLabelBase>
                    <TextField
                      name="noOfEmployeesAll"
                      defaultValue={values.noOfEmployeesAll}
                      inputProps={inputProps}
                      type="number"
                      fullWidth
                      placeholder="132"
                      disabled={disabled}
                    />
                  </div>
                  <div>
                    <InputLabelBase
                      className={classes.inputLabelCustom}
                      required
                    >
                      Year Established
                    </InputLabelBase>
                    <Box>
                      <TextField
                        name="yearEstablished"
                        defaultValue={values.yearEstablished}
                        inputProps={{ ...inputProps, maxLength: 4 }}
                        fullWidth
                        placeholder="1985"
                        disabled={disabled}
                      />
                    </Box>
                  </div>
                  <div>
                    <InputLabelBase style={{ paddingTop: 0 }} required>
                      Ownership Type
                    </InputLabelBase>
                    <MuiSelect
                      name="ownershipType"
                      controllerProps={{ control: methods.control }}
                      defaultValue={values.ownershipType}
                      options={ownershipTypeOptions}
                      fullWidth
                      className={classes.selectInputBase}
                      classes={{
                        select: classes.select,
                        disabled: classes.selectDisabled,
                      }}
                      disabled={disabled}
                    />
                  </div>

                  <Showable show={productType === ProductTypes.p100_pro}>
                    <div style={{ alignItems: 'flex-start' }}>
                      <div>
                        <Box display="flex" pt={2}>
                          <Box width="50%">
                            <InputLabelBase
                              className={classes.inputLabelCustom}
                              required
                            >
                              Address 1
                            </InputLabelBase>
                            <TextField
                              name="address1"
                              defaultValue={values.address1}
                              inputProps={inputProps}
                              fullWidth
                              placeholder="123 Main St."
                              disabled={disabled}
                            />
                          </Box>
                          <Box width="50%" paddingLeft="5px">
                            <InputLabelBase
                              className={classes.inputLabelCustom}
                            >
                              Address 2
                            </InputLabelBase>
                            <TextField
                              name="address2"
                              defaultValue={values.address2}
                              inputProps={inputProps}
                              fullWidth
                              placeholder="Suite 123"
                              disabled={disabled}
                            />
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          paddingTop="4px"
                          justifyContent="space-between"
                          gap="0.5rem"
                        >
                          <Box width="33%">
                            <InputLabelBase
                              className={classes.inputLabelCustom}
                              required
                            >
                              City
                            </InputLabelBase>
                            <TextField
                              name="city"
                              defaultValue={values.city}
                              inputProps={inputProps}
                              placeholder="San Marco"
                              disabled={disabled}
                            />
                          </Box>
                          <Box width="33%">
                            <InputLabelBase
                              className={classes.inputLabelCustom}
                              required
                            >
                              State
                            </InputLabelBase>
                            <MuiSelect
                              name="state"
                              controllerProps={{ control: methods.control }}
                              defaultValue={values.state}
                              options={UsStatesFull}
                              fullWidth
                              fullHeight
                              className={classes.selectInputBase}
                              classes={{
                                select: classes.select,
                                disabled: classes.selectDisabled,
                              }}
                              disabled={disabled}
                            />
                          </Box>
                          <Box width="33%">
                            <InputLabelBase
                              className={classes.inputLabelCustom}
                              required
                            >
                              Zip Code
                            </InputLabelBase>
                            <TextField
                              name="zipCode"
                              defaultValue={values.zipCode}
                              inputProps={inputProps}
                              placeholder="92024"
                              disabled={disabled}
                            />
                          </Box>
                        </Box>
                      </div>
                    </div>
                  </Showable>
                </Grid>
                <Grid className={gridItem} item md={6}>
                  <div>
                    <InputLabelBase>Subsidiary/Franchisee</InputLabelBase>
                    <MuiSelect
                      name="isFranchise"
                      defaultValue={getIsFranchise(values.isFranchise)}
                      options={isFranchiseOptions}
                      fullWidth
                      className={classes.selectInputBase}
                      classes={{
                        select: classes.select,
                        disabled: classes.selectDisabled,
                      }}
                      disabled={disabled}
                    />
                  </div>

                  <Showable show={productType === ProductTypes.p100_pro}>
                    <div>
                      <InputLabelBase required={isGlobalFranchise === 'Yes'}>
                        If yes, does the Organization have Computer System
                        connectivity with the franchisor or any other franchise
                        locations?
                      </InputLabelBase>
                      <MuiSelect
                        name="franchiseSystemConnectivity"
                        defaultValue={values.franchiseSystemConnectivity}
                        options={[
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ]}
                        fullWidth
                        className={classes.selectInputBase}
                        classes={{
                          select: classes.select,
                          disabled: classes.selectDisabled,
                        }}
                        disabled={disabled}
                      />
                      <Showable
                        show={
                          !!methods.formState.errors.franchiseSystemConnectivity
                        }
                      >
                        <span style={{ color: '#fa8072' }}>
                          {methods.formState.errors?.franchiseSystemConnectivity
                            ?.message ?? ''}
                        </span>
                      </Showable>
                    </div>
                  </Showable>
                  <div>
                    <InputLabelBase>Primary Web Domain</InputLabelBase>
                    <DomainField
                      name="domainName"
                      defaultValue={values.domainName}
                      inputProps={inputProps}
                      fullWidth
                      placeholder="domain.com"
                      disabled={disabled}
                    />
                  </div>
                  <div>
                    <InputLabelBase>Secondary Web Domain(s)</InputLabelBase>
                    <DomainField
                      name="domains"
                      defaultValue={values.domains}
                      inputProps={inputProps}
                      fullWidth
                      isMulti
                      placeholder="domain.com, domain.net"
                      disabled={disabled}
                    />
                  </div>
                  <div>
                    <InputLabelBase>URL</InputLabelBase>
                    <TextField
                      name="url"
                      defaultValue={values.url}
                      inputProps={inputProps}
                      fullWidth
                      disabled={disabled}
                      isMulti
                    />
                  </div>
                  <div>
                    <InputLabelBase
                      className={classes.inputLabelCustom}
                      required
                    >
                      Entity Type
                    </InputLabelBase>
                    <MuiSelect
                      name="entityType"
                      controllerProps={{ control: methods.control }}
                      defaultValue={values.entityType}
                      options={entityTypeOptions}
                      fullWidth
                      className={classes.selectInputBase}
                      classes={{
                        select: classes.select,
                        disabled: classes.selectDisabled,
                      }}
                      disabled={disabled}
                    />
                  </div>

                  <Showable show={productType === ProductTypes.p100_pro}>
                    <div>
                      <InputLabelBase required>
                        Does the Organization collect, host, process, store,
                        transmit, use, share or maintain greater than 250,000
                        records?
                      </InputLabelBase>
                      <MuiSelect
                        name="collectingPersonalInformation"
                        controllerProps={{ control: methods.control }}
                        defaultValue={values.collectingPersonalInformation}
                        options={[
                          { value: 'yes', label: 'Yes' },
                          { value: 'no', label: 'No' },
                        ]}
                        fullWidth
                        className={classes.selectInputBase}
                        classes={{
                          select: classes.select,
                          disabled: classes.selectDisabled,
                        }}
                        disabled={disabled}
                      />
                      <Showable
                        show={
                          !!methods.formState.errors
                            .collectingPersonalInformation
                        }
                      >
                        <span style={{ color: '#fa8072' }}>
                          {methods.formState.errors
                            ?.collectingPersonalInformation?.message ?? ''}
                        </span>
                      </Showable>
                    </div>
                  </Showable>

                  <div>
                    <InputLabelBase required={collectPersonalInfo === 'yes'}>
                      {productType === ProductTypes.p100_pro
                        ? 'If yes, then please provide the estimated number of records:'
                        : 'Number of PII / PHI / PCI Records'}
                    </InputLabelBase>
                    <NumeralField
                      name="numberOfPersonalRecords"
                      defaultValue={values.numberOfPersonalRecords}
                      inputProps={inputProps}
                      fullWidth
                      disabled={disabled}
                    />
                  </div>

                  <div>
                    <InputLabelBase className={classes.inputLabelCustom}>
                      % of International Sales
                    </InputLabelBase>
                    <TextField
                      name="percentInternationalSales"
                      defaultValue={values.percentInternationalSales}
                      inputProps={inputProps}
                      type="number"
                      fullWidth
                      disabled={disabled}
                    />
                  </div>
                  <Showable show={productType !== ProductTypes.p100_pro}>
                    <div style={{ alignItems: 'flex-start' }}>
                      <div>
                        <Box display="flex">
                          <Box width="50%">
                            <InputLabelBase
                              className={classes.inputLabelCustom}
                              required
                            >
                              Address 1
                            </InputLabelBase>
                            <TextField
                              name="address1"
                              defaultValue={values.address1}
                              inputProps={inputProps}
                              fullWidth
                              placeholder="123 Main St."
                              disabled={disabled}
                            />
                          </Box>
                          <Box width="50%" paddingLeft="5px">
                            <InputLabelBase
                              className={classes.inputLabelCustom}
                            >
                              Address 2
                            </InputLabelBase>
                            <TextField
                              name="address2"
                              defaultValue={values.address2}
                              inputProps={inputProps}
                              fullWidth
                              placeholder="Suite 123"
                              disabled={disabled}
                            />
                          </Box>
                        </Box>
                        <Box display="flex" paddingTop="4px">
                          <Box maxWidth="50%">
                            <InputLabelBase
                              className={classes.inputLabelCustom}
                              required
                            >
                              City
                            </InputLabelBase>
                            <TextField
                              name="city"
                              defaultValue={values.city}
                              inputProps={inputProps}
                              placeholder="San Marco"
                              disabled={disabled}
                            />
                          </Box>
                          <Box marginRight="5px" marginLeft="5px">
                            <InputLabelBase
                              className={classes.inputLabelCustom}
                              required
                            >
                              State
                            </InputLabelBase>
                            <MuiSelect
                              name="state"
                              controllerProps={{ control: methods.control }}
                              defaultValue={values.state}
                              options={UsStatesFull}
                              fullWidth
                              fullHeight
                              className={classes.selectInputBase}
                              classes={{
                                select: classes.select,
                                disabled: classes.selectDisabled,
                              }}
                              disabled={disabled}
                            />
                          </Box>
                          <Box maxWidth="30%">
                            <InputLabelBase
                              className={classes.inputLabelCustom}
                              required
                            >
                              Zip Code
                            </InputLabelBase>
                            <TextField
                              name="zipCode"
                              defaultValue={values.zipCode}
                              inputProps={inputProps}
                              placeholder="92024"
                              disabled={disabled}
                            />
                          </Box>
                        </Box>
                      </div>
                    </div>
                  </Showable>
                  <div>
                    <InputLabelBase
                      className={classes.inputLabelCustom}
                      required
                    >
                      Business Phone
                    </InputLabelBase>
                    <PhoneField
                      name="phoneNumber"
                      defaultValue={values.phoneNumber}
                      inputProps={inputProps}
                      fullWidth
                      placeholder="(123) 456-7890"
                      disabled={disabled}
                    />
                  </div>

                  <Showable show={productType === ProductTypes.p100_pro}>
                    <div>
                      <InputLabelBase required style={{ paddingTop: 0 }}>
                        Has the Organization within the past twelve (12) months
                        completed or agreed to, or does it contemplate entering
                        into within the next twelve (12) months, a merger,
                        acquisition, consolidation, whether or not such
                        transactions were or will be completed?
                      </InputLabelBase>
                      <MuiSelect
                        name="changeInScope"
                        defaultValue={values.changeInScope}
                        options={[
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ]}
                        fullWidth
                        className={classes.selectInputBase}
                        classes={{
                          select: classes.select,
                          disabled: classes.selectDisabled,
                        }}
                        disabled={disabled}
                      />
                      <Showable show={!!methods.formState.errors.changeInScope}>
                        <span style={{ color: '#fa8072' }}>
                          {methods.formState.errors?.changeInScope?.message ??
                            ''}
                        </span>
                      </Showable>
                    </div>

                    <div>
                      <InputLabelBase required={chScope === 'yes'}>
                        If yes, please provide further details:
                      </InputLabelBase>
                      <TextField
                        name="changeInScopeDetail"
                        inputProps={inputProps}
                        fullWidth
                        placeholder="Details..."
                        disabled={disabled}
                      />
                    </div>
                  </Showable>
                </Grid>
              </Grid>
            </Card>
            <EditSection show={!readOnly} />
            {!_.isEmpty(methods.formState.errors) && (
              <Box textAlign="right" color={palette.common.tomato}>
                {Object.keys(methods.formState.errors)
                  .map(
                    (ekey) =>
                      _.get(methods.formState.errors, `[${ekey}].message`),
                    ''
                  )
                  .join(', ')}
              </Box>
            )}
            {error && (
              <Box textAlign="right" color={palette.common.tomato}>
                {error}
              </Box>
            )}
          </form>
        </FormProvider>
      );
    }
  )
);

export const useClasses = makeStyles(({ config, palette, spacing }) => ({
  gridItem: {
    '& label + *': {
      width: '100%',
      color: (props) => {
        if (props.from === 'tab' && props.live) {
          return palette.tabs.profile;
        }
        return palette.primary.contrastText;
      },
      fontSize: config.textSizes.normal,
    },
  },
  heading: {
    fontWeight: config.weights.bold,
    lineHeight: 1.38,
    marginTop: 0,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  editSection: {
    display: 'flex',

    '& > *': {
      marginLeft: spacing(1),
    },
  },

  divider: {
    borderBottom: `1px solid ${palette.primary.border}`,
  },

  labelWidth35: {
    '& > label': {
      width: '35%',
    },
  },

  percentageFieldContainer: {
    flexBasis: '20%',
    marginLeft: '0.4166666667rem',

    '& input': {
      textAlign: 'right',
    },
  },

  select: {
    border: 'none',
  },

  inputLabelCustom: {
    padding: '0 0 0.5rem 0',
  },

  selectInputBase: {
    background: palette.background.details,
    borderRadius: '5px',
  },

  parentBox: {
    border: `0.8px solid ${palette.text.secondary}`,
    borderRadius: 5,
    padding: '2rem',
  },

  selectDisabled: {
    backgroundColor: palette.background.disabled,
  },
}));

function onAccountUpdate(accountResponse, { params }, lastKnownPatch) {
  return new Promise((resolve, reject) => {
    let intervalCount = 0;

    const retry = setInterval(async () => {
      intervalCount += 1;

      try {
        const { data } = await fetchQuoteDetails(params.id);

        /**
         * 1. if the quote was not patched before, but is now, we're up-to-date
         * 2. if the quote was patched before, and it's most recent patch is after our last known patch, we're up-to-date
         * In either above case we stop retrying
         */
        if (
          (!lastKnownPatch && data.lastPatched) ||
          (lastKnownPatch && Moment(data.lastPatched).isAfter(lastKnownPatch))
        ) {
          clearInterval(retry);
          resolve(data);
        } else if (intervalCount === MAX_INTERVALS) {
          clearInterval(retry);
          reject(new Error('max retries reached'));
        }
      } catch (error) {
        if (intervalCount === MAX_INTERVALS) {
          clearInterval(retry);
          reject(new Error('max retries reached'));
        }
      }
    }, INTERVAL_DELAY);
  });
}

/**
 * Renders the primary and secondary Industry Code components
 * @param {String} name - type of Industry Code (naicsCode or secondNaicsCode)
 */
const NaicsCodeField = ({ name, ...props }) => {
  const classes = useNaicsCodeStyles();
  const autoCompleteClasses = useAutcompleteStyles();

  return (
    <Box className={classes.root}>
      <RegisteredNaicsCodeAutoComplete
        name={name}
        {...props}
        classes={autoCompleteClasses}
      />
    </Box>
  );
};

const useNaicsCodeStyles = makeStyles(() => ({
  root: {
    width: '80%',
  },
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));

const useAutcompleteStyles = makeStyles(() => ({
  input: {
    border: 0,
  },
}));

// schema
/* eslint-disable no-template-curly-in-string */
const schema = (extendedSchema) =>
  Yup.object().shape({
    name: Yup.string()
      .label('Name')
      .test(
        'alphabetical-chars',
        '${label} must contain at least one alphabetical character',
        (value) => {
          const matches = value.match(/[a-zA-Z]/);
          return matches?.length > 0;
        }
      ),
    revenue,
    noOfEmployeesAll: noOfEmployees,
    yearEstablished: Yup.number()
      .label('Year Established')
      .typeError('${label} is required')
      .lessThan(
        Moment().add(1, 'y').year(),
        '${label} must be less than or equal to current year'
      )
      .moreThan(1499, '${label} must be greater than or equal to 1500')
      .required(),

    ownershipType: Yup.string().label('Ownership Type').required(),

    dbaOrTradestyle: Yup.string()
      .label('DBA')
      .matches(/(^(?=.*[\w\d]).+)/, {
        message: '${label} should contain at least one alphabet or number',
        excludeEmptyString: true,
      }),

    naicsCode: Yup.number()
      .label('Industry Code')
      .transform((current, original) =>
        typeof original === 'object' && original !== null
          ? Number(original.value)
          : Number(original)
      )
      .min(10, 'Industry Code must be atleast 2 digits')
      .max(999999, 'Industry Code be less than 6 digits')
      .nullable()
      .required(),

    secondNaicsCode: Yup.number()
      .label('Secondary Industry Code')
      .when('primaryNaicsPercentage', {
        is: (value) => Number(value) === 100,
        then: Yup.number()
          .typeError(
            'Secondary Industry Code is not allowed when NiacsCode is 100%'
          )
          .nullable()
          .test({
            name: 'secondNaicsCode',
            message:
              'Secondary Industry Code is not allowed when NiacsCode is 100%',
            test: (value) => !value,
          }),
        otherwise: Yup.number()
          .transform((current, original) =>
            typeof original === 'object' && original !== null
              ? Number(original.value)
              : Number(original)
          )
          .min(10, 'Secondary Industry Code must be atleast 2 digits')
          .max(999999, 'Secondary Industry Code must be less than 6 digits')
          .nullable()
          .required(),
      }),

    ein: Yup.string().label('EIN Number'),

    primaryNaicsPercentage: Yup.number()
      .label('Industry Code percentage')
      .min(51, 'Primary Naics Percentage must be 51% or higher')
      .max(100, 'Primary Naics Percentage must be 100% or lower')
      .required(),

    percentInternationalSales: Yup.number()
      .label('% of International Sales')
      .transform((current, original) => {
        if (original === '') {
          return null;
        }
        return current;
      })
      .nullable()
      .min(0)
      .max(100),

    address1: Yup.string().label('Address').required(),

    city: Yup.string()
      .label('City')
      .required()
      .matches(/^[a-zA-Z\s-]+$/, 'Invalid City'),

    state: Yup.string().label('State').required(),

    zipCode: Yup.string()
      .label('Zip Code')
      .matches(/^\d{5}(-\d{4})?(?!-)$/, 'Invalid ZIP Code')
      .test('Is ZIP 00000', 'Invalid ZIP Code', (value) => value != '00000')
      .required(),

    phoneNumber: Yup.number()
      .label('Business Phone')
      .fromPhone()
      .phone()
      .required(),

    entityType: Yup.string().required().ensure().label('Entity Type'),

    numberOfPersonalRecords: Yup.number()
      .fromNumeral()
      .label('Number of records'),

    // isFranchise: Yup.string().required().label('Franchise'),

    ...extendedSchema,
  });
/* eslint-enable no-template-curly-in-string */

const determineSchema = (productType) => {
  if (productType !== ProductTypes.p100_pro) {
    return schema({});
  }
  const p100ProSchema = {
    nonUsRevenue: Yup.number()
      .label('Non US revenue')
      .fromCurrency()
      .required(),
    totalRevenue: Yup.number().label('Total Revenue').fromCurrency().required(),
    changeInScope: Yup.mixed()
      .transform((currentValue) => {
        if (currentValue.name) {
          return currentValue.value;
        }
        return currentValue;
      })
      .label('Change in scope')
      .required(),
    changeInScopeDetail: Yup.string().when('changeInScope', {
      is: (value) => {
        return value === 'yes';
      },
      then: Yup.string().label('Change in scope description').required(),
      otherwise: Yup.string(),
    }),
    franchiseSystemConnectivity: Yup.mixed()
      .transform((currentValue) => {
        if (currentValue.name) {
          return currentValue.value;
        }
        return currentValue;
      })
      .when('isFranchise', {
        is: (value) => {
          return Boolean(value) && value === 'Yes';
        },
        then: Yup.string().label('Franchise connectivity').required(),
        otherwise: Yup.string(),
      }),
    collectingPersonalInformation: Yup.mixed()
      .transform((currentValue) => {
        if (currentValue.name) {
          return currentValue.value;
        }
        return currentValue;
      })
      .label('Personal information collection')
      .required(),
    numberOfPersonalRecords: Yup.number()
      .min(250000)
      .when('collectingPersonalInformation', {
        is: (value) => {
          return typeof value !== 'undefined' && value === 'yes';
        },
        then: Yup.number().fromNumeral().label('Number of records').required(),
        otherwise: Yup.number().fromNumeral().label('Number of records'),
      }),
  };

  return schema(p100ProSchema);
};
