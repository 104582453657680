// These map to account types on the backend
const CARRIER = 'CARRIER';
const DIGITAL = 'DIGITAL';
const COWBELL = 'COWBELL';
const CUSTOMER = 'CUSTOMER';
const VENDOR = 'VENDOR';
const CLUSTER = 'CLUSTER';
const AGENCY = 'AGENCY';
const MSSP = 'MSSP';
export const UNKNOWN_PERSONA = 'UNKNOWN';
export const RE_INSURER = 'RE_INSURER';
export const PREMIUM_PROCESSOR = 'PREMIUM_PROCESSOR';

export const PERSONA_TYPES = {
  CARRIER,
  DIGITAL,
  COWBELL,
  CUSTOMER,
  VENDOR,
  CLUSTER,
  AGENCY,
  MSSP,
  RE_INSURER,
  PREMIUM_PROCESSOR,
} as const;

export type Persona = (typeof PERSONA_TYPES)[keyof typeof PERSONA_TYPES];
