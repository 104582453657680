import type { AxiosError } from 'axios';
import type { PublishableErrorOptions } from './publishable.error';
import { PublishableError } from './publishable.error';
import { manageAPIError } from '../../utils';

const defaults = {
  publish: true,
};

const FALLBACK_UI_MESSAGE = 'An issue occurred with this request';

/**
 * @name ApiError
 * @description create api specific errors to distinguish them from other error types
 * @example new ApiError(AxiosApiError)
 */
export class ApiError<
  T extends PublishableErrorOptions = typeof defaults
> extends PublishableError {
  readonly name = 'ApiError';

  readonly status: string | null = null;

  readonly statusCode: number | null = null;

  readonly uiMessage: string;

  /**
   * Axios properties
   */
  readonly code: AxiosError['code'] | string | null = null;

  readonly config: AxiosError['config'] | null = null;

  readonly request: AxiosError['request'] | null = null;

  readonly response: AxiosError['response'] | null = null;

  readonly toJSON: AxiosError['toJSON'] | null = null;

  readonly eventId = 'API_ERROR_EVENT';

  constructor(error: AxiosError, options?: T) {
    super(error, options || defaults);
    Object.setPrototypeOf(this, ApiError.prototype);

    /**
     * @description maintain the axios error shape for dependant, existing code
     */
    this.code = error.code;
    this.config = error.config;
    this.request = error.request;
    this.response = error.response;
    this.toJSON = error.toJSON;
    this.message = error.message;

    /**
     * @description set statuses for response
     */
    if (error.response) {
      this.status = error.code || null;
      this.statusCode = error.response.status;
    }

    this.uiMessage = manageAPIError(error, FALLBACK_UI_MESSAGE) as string;

    this.parseError(error);
    this.publishErrorEvent(this);
  }

  // eslint-disable-next-line class-methods-use-this
  private parseError(error: AxiosError) {
    if (!error.isAxiosError) {
      throw new Error(
        'ApiError: failed to parse "error", "error" is not a valid instance of "AxiosError"'
      );
    }
  }
}

export type ApiErrorWithResponse = ApiError & {
  response: NonNullable<AxiosError['response']>;
};

export function hasResponse(error: ApiError): error is ApiErrorWithResponse {
  return Boolean(error.response);
}
