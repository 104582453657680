import { InputLabelBase } from '../../../../../components/inputs/InputLabelBase';
import type { Question } from './securityQuestions.statics';
import {
  QuestionTypes,
  commonAnswerGroupStyles,
  commonQuestionGroupStyles,
  encryptionQuestionGroupStyles,
  mfaQuestionGroupStyles,
  planningQuestionGroupStyles,
  answers,
} from './securityQuestions.statics';

/* SCHEMAS */
export const primeTechBaseQuestions = [
  /* Training  */
  {
    questionHeading: '1. Training',
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0 }}
        htmlFor="training"
        {...inputLabelProps}
      >
        Is cybersecurity training required for all employees with Computer
        System access at least annually?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'training',
    required: true,
    answers,
  },
  /* MFA - vendor management */
  {
    questionHeading: '2. Multifactor Authentication',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="mfaVendorManagement"
        {...inputLabelProps}
      >
        Does the Organization have a vendor management program to ensure third
        parties' levels of security are commensurate with the Organization's
        cybersecurity standard?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'mfaVendorManagement',
    required: true,
    answers,
  },

  /*  Multifactor Authentication - mfaEmail*/
  {
    topLevelQuestion: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        className="tertia-text"
        style={{ paddingLeft: '1.5rem', paddingTop: '0.25rem' }}
        {...inputLabelProps}
      >
        Is MFA in place for the following?
      </InputLabelBase>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaEmail"
        {...inputLabelProps}
      >
        Email:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'mfaEmail',
    required: true,
    answers,
  },

  /* Multifactor Authentication - mfaRemoteSystemAccess */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaRemoteSystemAccess"
        {...inputLabelProps}
      >
        Remote Computer System:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaRemoteSystemAccess',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers,
  },

  /* Multifactor Authentication - mfaAdminAccounts */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaAdminAccounts"
        {...inputLabelProps}
      >
        Admin/Privileged Accounts:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaAdminAccounts',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers,
  },

  /* Multifactor Authentication - mfaCloud */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaCloud"
        {...inputLabelProps}
      >
        Cloud:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaCloud',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers,
  },

  /* Multifactor Authentication - mfaVendor */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="mfaVendor"
        {...inputLabelProps}
      >
        Vendor Access to Computer System:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'mfaVendor',
    questionGroupStyle: mfaQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers,
  },

  /* Multifactor Authentication - mfaComments */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="mfaComments"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        Comments:
      </InputLabelBase>
    ),
    questionGroupStyle: {
      paddingLeft: '1.5rem',
      paddingBottom: '0.5rem',
    },
    type: QuestionTypes.text,
    placeholder: 'MFA comments',
    name: 'mfaComments',
    show: {
      fields: [
        'mfaEmail',
        'mfaRemoteSystemAccess',
        'mfaAdminAccounts',
        'mfaCloud',
        'mfaVendor',
      ],
      shouldShow: (_answers: string[]) => {
        return _answers.filter((ans) => ans != null && ans !== 'YES').length;
      },
    },
  },

  /* Backups - backupFrequency */
  {
    questionHeading: '3. Backups',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0 }}
        htmlFor="backupFrequency"
        {...inputLabelProps}
      >
        How often does the Organization perform backups of business-critical
        data?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: {
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'backupFrequency',
    required: true,
    dynamic: true,
    answers: [
      { label: 'Daily or More Often', value: 'DAILY' },
      { label: 'Weekly', value: 'WEEKLY' },
      { label: 'Monthly', value: 'MONTHLY' },
      { label: 'Quarterly', value: 'QUARTERLY' },
      { label: 'Biannually', value: 'SIX_MONTHS' },
      { label: 'Never', value: 'NEVER' },
    ],
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            style={{ paddingTop: 0 }}
            htmlFor="backupTypes"
            {...inputLabelProps}
          >
            3a. Backups are (select all that apply):
          </InputLabelBase>
        ),
        type: QuestionTypes.multiSelect,
        questionGroupStyle: {
          paddingLeft: '1.5rem',
        },
        answerGroupStyle: commonAnswerGroupStyles,
        name: 'backupTypes',
        required: true,
        dynamic: true,
        comments: (ans: string[]) => {
          return ans != null && ans.includes('BACKUP_FREQUENCY_OTHER');
        },
        show: {
          fieldName: 'backupFrequency',
          isNot: 'NEVER',
        },
        answers: [
          {
            label: 'Segmented (either offline or air-gapped)',
            value: 'BACKUP_FREQUENCY_SEGMENTED',
          },
          {
            label: `In a cloud service that is secured by login credentials separate from the Computer System credentials and/or requires MFA`,
            value: `BACKUP_FREQUENCY_CLOUD`,
            tooltip:
              'In a cloud service that is secured by login credentials separate from the Computer System credentials and/or requires MFA',
          },
          {
            label: 'Tested at least annually',
            value: 'BACKUP_FREQUENCY_TESTED_ANNUALLY',
          },
          { label: 'Other (please specify)', value: 'BACKUP_FREQUENCY_OTHER' },
        ],
      },
    ],
  },

  /* Backups - patchingFrequency */
  {
    questionHeading: '4. Patching',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0 }}
        htmlFor="patchingFrequency"
        {...inputLabelProps}
      >
        How often does the Organization apply updates to Computer Systems and
        applications when a patch is released?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    name: 'patchingFrequency',
    questionGroupStyle: {
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    answers: [
      { label: 'Daily or More Often', value: 'DAILY' },
      { label: 'Weekly', value: 'WEEKLY' },
      { label: 'Monthly', value: 'MONTHLY' },
      { label: 'Quarterly', value: 'QUARTERLY' },

      { label: 'Biannually', value: 'SIX_MONTHS' },
      { label: 'Never', value: 'NEVER' },
    ],
  },

  /* Backups - vendorManagement */
  {
    questionHeading: '5. Vendors',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '65%' }}
        htmlFor="vendorManagement"
        {...inputLabelProps}
      >
        Does the Organization have a vendor management program to ensure third
        parties' levels of security are commensurate with the Organization's
        cybersecurity standard?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'vendorManagement',
    required: true,
    answers,
  },

  /* Encryption - encryptionAtRest */
  {
    questionHeading: '6. Encryption',
    topLevelQuestion: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        className="tertia-text"
        style={{ paddingTop: '0.5rem', paddingLeft: '1.5rem' }}
        {...inputLabelProps}
      >
        Is encryption of sensitive information in place for the following?
      </InputLabelBase>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="encryptionAtRestAnswer"
        {...inputLabelProps}
      >
        At Rest:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: encryptionQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'encryptionAtRestAnswer',
    required: true,
    answers,
  },

  /* Encryption - encryptionInTransit */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="encryptionInTransitAnswer"
        {...inputLabelProps}
      >
        In transit:
      </InputLabelBase>
    ),
    questionGroupStyle: encryptionQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'encryptionInTransitAnswer',
    required: true,
    answers,
  },

  /* Encryption - encryptionOnDevices */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="encryptionOnDevicesAnswer"
        {...inputLabelProps}
      >
        On mobile devices:
      </InputLabelBase>
    ),
    questionGroupStyle: encryptionQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'encryptionOnDevicesAnswer',
    required: true,
    answers,
  },

  /* Contingency Planning - planIncidentResponse */
  {
    questionHeading: '7. Contingency Planning',
    topLevelQuestion: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        className="tertia-text"
        style={{ paddingTop: '0.75rem', paddingLeft: '1.5rem' }}
        {...inputLabelProps}
      >
        Does the Organization have the following written plans, in place and
        tested, at least annually related to a potential interruption of their
        Computer Systems?
      </InputLabelBase>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="planIncidentResponseAnswer"
        {...inputLabelProps}
      >
        Incident Response:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: planningQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'planIncidentResponseAnswer',
    required: true,
    answers,
  },

  /* Contingency Planning - planDisasterRecovery */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="planDisasterRecoveryAnswer"
        {...inputLabelProps}
      >
        Disaster Recovery:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: planningQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'planDisasterRecoveryAnswer',
    required: true,
    answers,
  },

  /* Contingency Planning - planBusinessContinuity */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="planBusinessContinuityAnswer"
        {...inputLabelProps}
      >
        Business Continuity:
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    questionGroupStyle: planningQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'planBusinessContinuityAnswer',
    required: true,
    answers,
  },

  /* Contingency Planning - plansComments */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '30%' }}
        htmlFor="plansComments"
        {...inputLabelProps}
      >
        Comments:
      </InputLabelBase>
    ),
    questionGroupStyle: {
      paddingLeft: '1.5rem',
      paddingBottom: '0.5rem',
    },
    show: {
      fields: [
        'planIncidentResponseAnswer',
        'planDisasterRecoveryAnswer',
        'planBusinessContinuityAnswer',
      ],
      shouldShow: (_answers: string[]) => {
        return _answers.filter((ans) => ans != null && ans === 'NO').length;
      },
    },
    type: QuestionTypes.text,
    name: 'plansComments',
  },

  /* EOL Products */
  {
    questionHeading: '8. End-of-Life',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="eolProducts"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        Are any EOL products in use?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    required: true,
    name: 'eolProducts',
    dynamic: true,
    answers,
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="eolProducts-comments"
            style={{ paddingTop: 0 }}
            {...inputLabelProps}
          >
            8a. If yes, please comment on usage with supplemental control
            information:
          </InputLabelBase>
        ),
        questionGroupStyle: {
          paddingBottom: '0.5rem',
        },
        type: QuestionTypes.text,
        name: 'eolProducts-comments',
        show: {
          fieldName: 'eolProducts',
          is: 'YES',
        },
        placeholder:
          'Please comment on usage with supplemental control information',
      },
    ],
  },

  /* Endpoint Detection and Response */
  {
    questionHeading: '9. Endpoint Detection & Response',
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="edr"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        Is an EDR tool in place across the Organization's Computer Systems?
      </InputLabelBase>
    ),
    type: QuestionTypes.singleSelect,
    required: true,
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    name: 'edr',
    dynamic: true,
    answers,
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="edr-comments"
            style={{ paddingTop: 0 }}
            {...inputLabelProps}
          >
            9a. If yes, who is the vendor providing the EDR product?
          </InputLabelBase>
        ),

        type: QuestionTypes.text,
        placeholder: `Who is the vendor providing the EDR product?`,
        name: 'edr-comments',
        show: {
          fieldName: 'edr',
          is: 'YES',
        },
      },
    ],
  },

  /* Next generation Antivirus */
  {
    questionHeading: (
      <>
        10. Next Generation Antivirus <i>(Optional)</i>
      </>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="ngav"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        Is NGAV in place across the Organization's Computer Systems?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'ngav',
    dynamic: true,
    answers,
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="ngav-comments"
            style={{ paddingTop: 0 }}
            {...inputLabelProps}
          >
            10a. If yes, Who is the vendor providing the NGAV product?
          </InputLabelBase>
        ),
        type: QuestionTypes.text,
        placeholder: `Who is the vendor providing the NGAV product?`,
        name: 'ngav-comments',
        show: {
          fieldName: 'ngav',
          is: 'YES',
        },
      },
    ],
  },

  /* Email Filtering */
  {
    questionHeading: (
      <>
        11. Email Filtering <i>(Optional)</i>
      </>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="emailFiltering"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        Is an email filter in place across the Organization's Computer Systems?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'emailFiltering',
    dynamic: true,
    answers,
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="emailFiltering-comments"
            style={{ paddingTop: 0 }}
            {...inputLabelProps}
          >
            11a. If yes, who is the vendor providing the email filtering
            product:
          </InputLabelBase>
        ),
        type: QuestionTypes.text,
        placeholder: 'Who is the vendor providing the email filtering product?',
        name: 'emailFiltering-comments',
        show: {
          fieldName: 'emailFiltering',
          is: 'YES',
        },
      },
    ],
  },
] as Readonly<Question[]>;

export const primeTechCyberCrimeQuestions = [
  /* Cyber Crime - isVerifyingBankAccounts */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="isVerifyingBankAccountsAnswer"
        {...inputLabelProps}
      >
        1. Does the Organization verify vendor/supplier bank accounts before
        adding them to their accounts payable systems?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    type: QuestionTypes.singleSelect,
    name: 'isVerifyingBankAccountsAnswer',
    answers,
  },

  /* Cyber Crime - isAuthenticatingFundTransferRequests */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="isAuthenticatingFundTransferRequestsAnswer"
        {...inputLabelProps}
      >
        2. Is there an established procedure to verify changes to funds transfer
        instructions using a predetermined phone number of the vendor?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    type: QuestionTypes.singleSelect,
    name: 'isAuthenticatingFundTransferRequestsAnswer',
    answers,
  },

  /* Cyber Crime - isPreventingUnauthorizedWireTransfers */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="isPreventingUnauthorizedWireTransfersAnswer"
        {...inputLabelProps}
      >
        3. Does the Organization prevent unauthorized employees from initiating
        wire transfers?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    type: QuestionTypes.singleSelect,
    name: 'isPreventingUnauthorizedWireTransfersAnswer',
    answers,
  },

  /* Cyber Crime - hasDualAuthorization */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="hasDualAuthorizationControlsAnswer"
        {...inputLabelProps}
      >
        4. Does the Applicant have dual authorization controls in place which
        require all fund and wire transfers over 25,000 to be authorized and
        verified by at least two employees prior to its execution?
      </InputLabelBase>
    ),
    questionGroupStyle: {
      ...commonQuestionGroupStyles,
      paddingLeft: '1.5rem',
    },
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    required: true,
    name: 'hasDualAuthorizationControlsAnswer',
    answers,
  },
];

export const primeTechMediaQuestions = [
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="contentReviewProcedureAnswer"
        {...inputLabelProps}
      >
        1. Are Content review procedures conducted by a qualified attorney
        before information is published and/or disseminated to third parties by
        the organization?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    required: true,
    type: QuestionTypes.singleSelect,
    name: 'contentReviewProcedureAnswer',
    answers,
  },
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        style={{ paddingTop: 0, width: '70%' }}
        htmlFor="copyrightClearanceSearchAnswer"
        {...inputLabelProps}
      >
        2. Has the organization conducted copyright and trademark clearance
        Searches for all trade & service marks that are or have been used by the
        organization?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    required: true,
    name: 'copyrightClearanceSearchAnswer',
    comments: () => true,
    answers,
  },
] as unknown as Readonly<Question[]>;

export const primeTechPastActivitiesQuestions = [
  /* priorCoverage */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="priorCoverage"
        style={{ paddingTop: 0 }}
        {...inputLabelProps}
      >
        1. Does the organization currently carry an insurance policy that
        insures against a Tech E&O or Cyber Loss?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'priorCoverage',
    required: true,
    answers,
  },

  /* priorOrPendingClaimCircumstances */
  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="priorOrPendingClaimCircumstancesAnswer"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        2. Is the Organization or any person aware of any prior or pending
        circumstances that could lead to a claim under the proposed policy?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'priorOrPendingClaimCircumstancesAnswer',
    required: true,
    comments: (ans: string) => {
      return ans === 'YES';
    },
    answers,
  },

  /* */
  {
    topLevelQuestion: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        className="tertia-text"
        style={{ paddingTop: '0.25rem' }}
        {...inputLabelProps}
      >
        3. Has the applicant or any other person or organization proposed for
        this insurance:
      </InputLabelBase>
    ),
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="applicantComplaintsAllegations"
        style={{ paddingLeft: '1.5rem', paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        3a. Received any complaints or written demands, or been a subject of
        litigation or any governmental investigation, inquiry or other
        proceedings involving allegations of professional errors or omissions?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'applicantComplaintsAllegations',
    required: true,
    answers,
  },

  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="applicantComplaintsNetworkCompromised"
        style={{ paddingLeft: '1.5rem', paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        3b. Received any complaints or written demands or been a subject of
        litigation involving matters of privacy injury, breach of private
        information, network security, defamation, content infringement,
        identity theft, denial of service attacks, computer virus infections,
        theft of information, damage to third party networks or the ability of
        third parties to rely on the Applicant's network?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'applicantComplaintsNetworkCompromised',
    required: true,
    answers,
  },

  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="applicantAllegedViolation"
        style={{ paddingLeft: '1.5rem', paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        3c. Been the subject of any government action, investigation or other
        proceedings regarding any alleged violation of privacy law or
        regulation?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'applicantAllegedViolation',
    required: true,
    answers,
  },

  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="applicantNotifiedPrivacyBreaches"
        style={{ paddingLeft: '1.5rem', paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        3d. Notified customers, clients or any third party of any security
        breach or privacy breach?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'applicantNotifiedPrivacyBreaches',
    required: true,
    answers,
  },

  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="applicantReceivedExtortionThreat"
        style={{ paddingLeft: '1.5rem', paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        3e. Received any cyber extortion demand or threat?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'applicantReceivedExtortionThreat',
    required: true,
    answers,
  },

  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="applicantSustainedNetworkOutage"
        style={{ paddingLeft: '1.5rem', paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        3f. Sustained any unscheduled network outage or interruption for any
        reason?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'applicantSustainedNetworkOutage',
    required: true,
    answers,
  },

  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="applicantSustainedPropertyDamage"
        style={{ paddingLeft: '1.5rem', paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        3g. Sustained any property damage or business interruption losses as a
        result of a cyber-attack?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'applicantSustainedPropertyDamage',
    required: true,
    answers,
  },

  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="probableKnownActLeadingToClaim"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        4. Do you or any other person or organization proposed for this
        insurance have knowledge of any wrongful act, error, omission, security
        breach, privacy breach, privacy-related event or incident or allegations
        of breach of privacy that may give rise to a claim?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'probableKnownActLeadingToClaim',
    required: true,
    answers,
  },

  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="sustainedSystemOutage"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        5. In the past three (3) years, has any service provider with access to
        the Applicant's network or computer system(s) sustained an unscheduled
        network outage or interruption lasting longer than four (4) hours?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'sustainedSystemOutage',
    required: true,
    answers,
    dynamic: true,
    dynamicQuestions: [
      {
        label: ({ inputLabelProps = {} }) => (
          <InputLabelBase
            htmlFor="sustainedSystemOutageBusinessInterruption"
            style={{ paddingLeft: '1.5rem', paddingTop: 0, width: '60%' }}
            {...inputLabelProps}
          >
            5a. If yes, did the Applicant experience an interruption in business
            as a result of such outage or interruption?
          </InputLabelBase>
        ),
        type: QuestionTypes.singleSelect,
        questionGroupStyle: commonQuestionGroupStyles,
        answerGroupStyle: commonAnswerGroupStyles,
        answers,
        name: 'sustainedSystemOutageBusinessInterruption',
        required: true,
        show: {
          fieldName: 'sustainedSystemOutage',
          is: 'YES',
        },
      },
    ],
  },

  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="probableKnownDamageLeadingToClaim"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        6. Does the Applicant or any other person or organization proposed for
        this insurance have knowledge of any situation(s), circumstance(s) or
        allegation(s) of bodily injury, property damage or personal and
        advertising injury that may give rise to a claim?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'probableKnownDamageLeadingToClaim',
    required: true,
    answers,
  },

  {
    label: ({ inputLabelProps = {} }) => (
      <InputLabelBase
        htmlFor="hasDamageClaimAgainstApplicant"
        style={{ paddingTop: 0, width: '60%' }}
        {...inputLabelProps}
      >
        7. In the past five (5) years, has any claim for bodily injury, property
        damage or personal and advertising injury ever been made against the
        Applicant or any other person or organization proposed for this
        insurance?
      </InputLabelBase>
    ),
    questionGroupStyle: commonQuestionGroupStyles,
    answerGroupStyle: commonAnswerGroupStyles,
    type: QuestionTypes.singleSelect,
    name: 'hasDamageClaimAgainstApplicant',
    required: true,
    answers,
  },
] as unknown as Readonly<Question[]>;
