import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// validations
import '../../_global/lib/validations/PhoneSchemaType';
// react
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// mui
import { DialogActions, DialogContent, Grid } from '@mui/material';
// compoenents
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import { withFormController } from '../../../components/hocs/forms';
import CbButton from '../../../components/Buttons/CbButton';
import { PhoneField as PhoneFieldBase } from '../../../components/inputs/PhoneField';
// platform helpers
import { delayedEvent } from '../../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../../reducers/UiSettingsReducers';
// actions
import { sendQuote } from '../../../api/apis';

const schema = Yup.object().shape({
  customerFirstName: Yup.string()
    .label("Policy Holder's First Name")
    .matches(/^[aA-zZ\s]+$/, 'Please enter only alphabets')
    .required(),
  customerLastName: Yup.string()
    .label("Policy Holder's Last Name")
    .matches(/^[aA-zZ\s]+$/, 'Please enter only alphabets')
    .required(),
  customerEmail: Yup.string().label('Policy Holders Email').email().required(),
  customerPhone: Yup.number()
    .label('Policy Holders Phone')
    .fromPhone()
    .phone()
    .required(),
});

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);

export const SendQuoteToPolicyHolder = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
  });

  const { isSubmitting } = methods.formState;

  const onSubmit = (formValues) => {
    const productType = _.get(data, 'product', '');

    sendQuote({ quoteId: data.id, productType, ...formValues })
      .then(() => {
        enqueueSnackbar('Quote was successfully sent', { variant: 'success' });
        delayedEvent('table-refetch', 1000, AGENCY_QUOTES_LISTING_STORE_ID);
        delayedEvent('quote:read');
        close();
      })
      .catch(() => {
        enqueueSnackbar('Failed to send quote', { variant: 'error' });
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item sm={6} style={{ paddingBottom: 0 }}>
                <TextField
                  name="customerFirstName"
                  label="Policy Holder First Name"
                  placeholder="Please enter policy holder first name"
                  required
                  fullWidth
                  defaultValue={data.customerFirstName}
                />
              </Grid>
              <Grid item sm={6} style={{ paddingBottom: 0 }}>
                <TextField
                  name="customerLastName"
                  label="Policy Holder Last Name"
                  placeholder="Please enter policy holder last name"
                  required
                  fullWidth
                  defaultValue={data.customerLastName}
                />
              </Grid>
              <Grid item sm={6} style={{ paddingTop: 0 }}>
                <TextField
                  name="customerEmail"
                  label="Policy Holder Email"
                  type="email"
                  placeholder="Please enter policy holder email"
                  required
                  fullWidth
                  defaultValue={data.customerEmail}
                />
              </Grid>
              <Grid item sm={6} style={{ paddingTop: 0 }}>
                <PhoneField
                  name="customerPhone"
                  label="Policy Holder Phone"
                  required
                  fullWidth
                  placeholder="(___)___-____"
                  defaultValue={data.customerPhone}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" type="submit" onClick={close}>
              Cancel
            </CbButton>
            <CbButton
              styleName="ctaButton"
              type="submit"
              disabled={isSubmitting}
            >
              Send to Policy Holder
            </CbButton>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  );
};
