import React from 'react';
import { useSelector } from 'react-redux';

import { personaAliases } from '../../console/_statics/persona-alias.statics';
import type { Persona } from '../../console/_statics/persona.statics';
import { PERSONA_TYPES } from '../../console/_statics/persona.statics';

import {
  selectPersona,
  selectLegacyPersona,
} from '../../reducers/persona.selectors';

type PersonaAlias = keyof typeof personaAliases;

export type UsePersonaReturn = ReturnType<typeof usePersona>;

export const usePersona = () => {
  const persona = useSelector(selectPersona) as Persona;
  const urlPersona = useSelector(selectLegacyPersona) as PersonaAlias;

  return React.useMemo(
    () => ({
      type: persona,
      personaHome: getHomePathByPersona(persona),
      basePath: getBasePathByPersona(persona),

      /**
       * @description indicates the current PERSONA we are logged into.
       */
      isCowbell: persona === PERSONA_TYPES.COWBELL,
      isAgency: persona === PERSONA_TYPES.AGENCY,
      isInsured: persona === PERSONA_TYPES.CUSTOMER,
      isDigital: persona === PERSONA_TYPES.DIGITAL,
      isMssp: persona === PERSONA_TYPES.MSSP,
      isCarrier: persona === PERSONA_TYPES.CARRIER,
      isCluster: persona === PERSONA_TYPES.CLUSTER,
      isVendor: persona === PERSONA_TYPES.VENDOR,
      isReinsurer: persona === PERSONA_TYPES.RE_INSURER,
      isPremiumProcessor: persona === PERSONA_TYPES.PREMIUM_PROCESSOR,

      /**
       * @name _legacy_persona
       * @description Legacy form of persona still used in some views
       * @deprecated
       */
      _legacy_persona: personaAliases[urlPersona],

      /**
       * @name persona
       * @description Alias of "type" but often conflicted in name during use
       * @deprecated
       */
      persona,
    }),
    [persona, urlPersona]
  );
};

function getBasePathByPersona(persona: Persona) {
  switch (persona) {
    case PERSONA_TYPES.COWBELL:
      return '/admin';
    case PERSONA_TYPES.AGENCY:
      return '/agency';
    case PERSONA_TYPES.CUSTOMER:
      return '/customer';
    case PERSONA_TYPES.DIGITAL:
      return '/da';
    case PERSONA_TYPES.MSSP:
      return '/mssp';
    case PERSONA_TYPES.CARRIER:
      return '/carrier';
    case PERSONA_TYPES.CLUSTER:
      return '/cluster';
    case PERSONA_TYPES.VENDOR:
      return '/vendor';
    case PERSONA_TYPES.RE_INSURER:
      return '/reinsurer';
    case PERSONA_TYPES.PREMIUM_PROCESSOR:
      return '/premiumprocessor';
    default:
      throw new Error(
        `Unsupported Persona "${persona}" in "getBasePathByPersona"`
      );
  }
}

function getHomePathByPersona(persona: Persona) {
  const base = getBasePathByPersona(persona);
  return `${base}/dashboard`;
}
