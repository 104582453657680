import React from 'react';

const PublicLogo = () => {
  return (
    <a href="https://cowbell.insure" target="_blank" rel="noreferrer">
      <img
        className="public-logo"
        src="logos/CowbellLogo2.svg"
        alt="Cowbell Cyber"
      />
    </a>
  );
};

export default PublicLogo;
