import React, { useState } from 'react';

import { Box, Button, Fade, Typography } from '@mui/material';

export const IncidentBanner = ({ show = false }) => {
  const [isDimissed, setIsDismissed] = useState(false);

  if (!show || isDimissed) {
    return null;
  }

  return (
    <Fade in>
      <Box
        alignItems="center"
        textAlign="center"
        position="fixed"
        width="100%"
        padding="1rem 8rem"
        sx={(theme) => ({
          top: 0,
          backgroundColor: theme.palette.primary.yellow1,
          zIndex: 9999,
          color: 'white',
        })}
      >
        <Typography variant="body2" sx={{ color: '#643a02' }}>
          We are investigating an issue with our email service, which caused
          some emails to be delayed and some emails to duplicate.
        </Typography>
        <Typography variant="body2" sx={{ color: '#643a02' }}>
          We will have a resolution soon.
        </Typography>
        <br />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Button
            size="small"
            variant="text"
            style={{
              color: '#643a02',
              textDecoration: 'underline',
              marginRight: '.9rem',
            }}
            href="https://status.cowbellcyber.ai/"
            target="_blank"
          >
            Check Status
          </Button>
          <Button
            size="small"
            variant="text"
            style={{ color: '#643a02', textDecoration: 'underline' }}
            onClick={() => {
              setIsDismissed(true);
            }}
          >
            Dismiss Message
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};
