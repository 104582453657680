import { getCowbellTranslations } from '../../i18n/translations';

const { t } = getCowbellTranslations();

export default {
  networkSecurityCF: {
    label: 'Network Security',
    description: `Measures the strength of the ${t(
      'organization'
    ).toLowerCase()}'s network infrastructure and whether security best practices are deployed (encryption, secure protocols, patching frequency, and threat mitigation). This factor also checks for vulnerabilities, malware, misconfigurations and other weaknesses.`,
  },
  cloudSecurityCF: {
    label: 'Cloud Security',
    description: `Measures the strength of an ${t(
      'organization'
    ).toLowerCase()}'s cloud security based on its security practices and footprint on commonly used public clouds and cloud storage (i.e. AWS, Azure, GCP, Box). This factor incorporates configuration for security best practices such as the use of multi-factor authentication.`,
  },
  endpointSecurityCF: {
    label: 'Endpoint Security',
    description:
      'Measure of endpoints preparedness (servers, mobile devices, IoT endpoints) towards cyberattacks. This factor incorporates the number of endpoints as well as the level of security hygiene applied to them – patching cadence and presence of vulnerabilities or malware.',
  },
  darkIntelligenceCF: {
    label: 'Dark Intelligence',
    description: `Measure of an ${t(
      'organization'
    ).toLowerCase()}'s exposure to the darknet, taking into account the type and volume of data exposed and its value for criminal activity (examples: stolen credentials, PII).`,
  },
  fundsTransferCF: {
    label: 'Funds Transfer',
    description:
      'This factor tracks risk markers related to hacking of email and phishing that commonly leads to nefarious activities such as fraudulent funds transfer.',
  },
  cyberExtortionCF: {
    label: 'Cyber Extortion',
    description: `Measure of an ${t(
      'organization'
    ).toLowerCase()}'s potential exposure to extortion-related attacks such as ransomware. This factor shares some data sources with network security and endpoint security, presence of malware on the network, patching cadence, use of encryption and more.`,
  },
  complianceCF: {
    label: 'Compliance',
    description: `Measures an ${t(
      'organization'
    ).toLowerCase()}'s level of compliance to security standards such as CIS (Center of Internet Security) benchmarks, NIST CSF (Cyber Security Framework), CSC-20 (Critical Security Controls), HIPAA, PCI, EU GDPR and CCPA.`,
  },
  insiderThreatCF: {
    label: 'Insider Threat',
    description: `Measures an ${t(
      'organization'
    ).toLowerCase()}'s susceptibility to insider threats. This factor is compiled from social media platforms and other sources that might expose attrition rate, negative opinion, or detrimental intent towards the ${t(
      'organization'
    )} by past and current employees and stakeholders.`,
  },
  companyAggregateCF: {
    label: 'Company Aggregate',
    description:
      'Weighted average of Cowbell Factors for the account. A company with a score of 85 represents less risk than one with a score of 64. The aggregate Cowbell Factor is a good metric to benchmark a company against peers.',
  },
  industryAggregateCF: {
    label: 'Industry Aggregate',
    description:
      'Measures an industry overall cyber risk factor. This is calculated from Cowbell’s risk pool for the specific industry. An industry with a score of 80 represents less risk than one with a score of 56.',
  },
  portfolioAggregateCF: {
    label: 'Portfolio Aggregate',
    description:
      "Measures an industry overall cyber risk factor. This is calculated from the portfolio's risk pool for the specific industry. An industry with a score of 80 represents less risk than one with a score of 56.",
  },
  supplyChainCF: {
    label: 'Supply Chain',
    description: `Measures an ${t(
      'organization'
    ).toLowerCase()}'s susceptibility to software supply chain incidents. This factor is compiled from technographic and firmographic data, web scraping information, and public exploits and vulnerability repositories.`,
  },
} as const;
