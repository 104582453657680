import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import { withStyles } from '@mui/styles';
import { withFormController } from '../../hocs/forms';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import SimpleTooltip from '../../SimpleTooltip';
import CheckBoxBase from '../../inputs/Checkbox';
import CBButton from '../../Buttons/CbButton';
import { submitSignupNewAccount } from '../../../api/SignupService';

const CheckBoxField = withFormController(CheckBoxBase);
const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  invitationCode: Yup.string().required('Invitation code is required'),
  organization: Yup.string().required('Organization name is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  password: Yup.string().required('Password is required'),
  producerNo: Yup.string().required('Producer number is required'),
});

const SignupEmailNew = ({ classes, data, ...props }) => {
  const defaultValues = {
    invitationCode: '',
    organization: '',
    firstName: '',
    lastName: '',
    password: '',
    agreement: false,
    producerNo: '',
    accountType: props.location ? props.location.query.accountType : props,
  };
  const { accountType } = props;
  const [generalNetwork, setGeneralNetwork] = React.useState('');

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    getValues,
    control,
    formState: { isSubmitting },
  } = methods;
  // form values when not editing
  const values = getValues();

  const onSubmit = (formData) => {
    const formParams = _.pick(formData, [
      'invitationCode',
      'password',
      'organization',
      'firstName',
      'lastName',
      'npn',
      'accountType',
    ]);
    return submitSignupNewAccount(formParams, props.token)
      .then(() => {
        success();
      })
      .catch((errorMsg) => {
        error(errorMsg);
      });
  };

  const success = () => {
    const { location } = props;

    const mappings = {
      COWBELL: { path: '/admin/dashboard' },
      AGENCY: { path: '/agency/dashboard' },
      CUSTOMER: { path: '/customer/dashboard' },
    };

    if (location?.state?.from?.pathname !== '/logout') {
      props.history.push(location.state.from);
    } else {
      props.history.push(mappings[accountType].path);
    }
  };

  const error = (error) => {
    if (error.response && error.response.status === 412) {
      setGeneralNetwork(error.response.data);
    } else {
      setGeneralNetwork('Service Unavailable. Please try again.');
    }
  };

  return (
    <section className={classes.signupContainer}>
      <div className={classes.welcome}>
        Welcome to Cowbell Cyber, please complete your Sign-Up
      </div>

      <p className={classes.text}>
        You need an invitation code to proceed with your sign-up process.
        <br />
        If you do not have one, please contact support@cowbellcyber.ai to get
        one.
      </p>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <section>
            <TextField
              error={_.get(methods.formState, 'errors.invitationCode.message')}
              label="Invitation Code"
              fullWidth
              required
              name="invitationCode"
              placeholder="Please insert the correct invitation Code"
            />
            <div className={classes.emptyBar} />
            <TextField
              error={_.get(methods.formState, 'errors.organization.message')}
              name="organization"
              label="Organization Name"
              fullWidth
              required
              placeholder="Please enter Organization Name"
            />
          </section>
          <p className={classes.sectionTitle}>1. Personal Information</p>
          <section>
            <Grid
              container
              justifyContent="left"
              className={classes.inputFields}
              spacing={4}
            >
              <Grid item md={6}>
                <TextField
                  name="firstName"
                  label="First Name"
                  fullWidth
                  required
                  placeholder="please enter your first name"
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  required
                  placeholder="please enter your last name"
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="left"
              className={classes.inputFields}
              spacing={4}
            >
              <Grid item md={6}>
                <TextField
                  required
                  name="password"
                  label="Create Password"
                  type="password"
                  fullWidth
                  placeholder="must be minimum 8 characters"
                />
              </Grid>

              {accountType !== 'CUSTOMER' && (
                <Grid item md={6}>
                  <SimpleTooltip
                    className={classes.tooltip}
                    title="The National Producer Number is a unique NAIC identifier assigned through the licensing application process or the NAIC reporting systems to individuals and business entities (including, but not limited to producers, adjusters, and navigators) engaged in insurance related activities regulated by a state insurance department. The NPN is used to track those individuals and business entities on a national basis."
                  >
                    <img
                      src="images/info-2.svg"
                      alt=""
                      className={classes.icon}
                    />
                  </SimpleTooltip>
                  <TextField
                    name="producerNo"
                    label="NPN #"
                    type="number"
                    fullWidth
                    required
                    placeholder="please enter your NPN #"
                  />
                </Grid>
              )}
            </Grid>

            <section
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CheckBoxField
                controllerProps={{ control }}
                name="agreement"
                label="I agree to the Terms of Service and Privacy Policy"
                defaultChecked={values.agreement}
              />
              <CBButton
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !values.agreement}
                styleName="ctaButton"
                buttonText="Create Account"
                size="large"
                style={{ marginTop: '1rem' }}
              />
            </section>
          </section>
        </form>
      </FormProvider>

      <Typography type="body1" align="center" style={{ marginTop: 15 }}>
        Have an account? <Link to="/login">Sign in</Link>
      </Typography>

      {generalNetwork && (
        <Typography
          type="body1"
          align="center"
          style={{ paddingTop: '10px', color: 'red' }}
        >
          {generalNetwork}
        </Typography>
      )}
    </section>
  );
};

const styles = ({ palette }) => {
  return {
    tooltip: {
      float: 'right',
      position: 'relative',
      zIndex: '10',
      marginBottom: '-2rem',
    },
    icon: {
      width: '1.25rem',
      height: '1.25rem',
      margin: '0.5rem 6.5rem 0 1rem',
    },
    inputFields: {
      justifyContent: 'space-between',
    },
    signupContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '40rem',
    },
    welcome: {
      width: 'inherit',
      fontSize: 21,
      lineHeight: 1.38,
      textAlign: 'center',
      color: palette.primary.main,
    },
    sectionTitle: {
      height: 21,
      fontSize: 21,
      lineHeight: 1.38,
      textAlign: 'left',
      marginBottom: '2rem !important',
      color: palette.primary.main,
    },
    form: {
      minWidth: '30rem',
    },
    fieldGroup: {
      display: 'flex',
      flexDirection: 'row',
    },
    text: {
      width: 400,
      height: 40,
      paddingTop: 12,
      fontSize: 12,
      lineHeight: 1.5,
      textAlign: 'center',
      color: palette.text.primary,
      margin: '1rem 0 2rem 0 !important',
    },
    emptyBar: {
      height: 15,
      border: 'none',
      borderBottom: `3px solid ${palette.primary.cyan}`,
      marginLeft: -100,
      marginRight: -100,
      marginBottom: '1rem',
    },
  };
};

export default withStyles(styles)(SignupEmailNew);
