import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getData } from '../utils/functional/fp';

import {
  getPolicyDetails,
  searchBORPolicies,
  searchPolicies,
} from '../api/policies.api';

export const policies = createQueryKeys('policies', {
  list: (filters: Record<string, unknown>) => ({
    queryKey: [{ filters }],
    queryFn: () => searchPolicies({ params: filters }).then(getData),
  }),

  details: (policyId: string, product: string) => ({
    queryKey: [{ policyId, product }],
    queryFn: () => getPolicyDetails(policyId, product).then(getData),
  }),

  listBOR: (filters: Record<string, unknown>) => ({
    queryKey: [{ filters }],
    queryFn: () => searchBORPolicies({ params: filters }).then(getData),
  }),
});
