import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { delayedEvent } from '../../utils/eventUtils';
import { generateExcessPolicy } from '../../inbox/QuotesService';
import CbButton from '../Buttons/CbButton';

export const GenerateExcessPolicy = ({ close, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const [error, setError] = useState('');
  const { id, policyNumber } = data;

  const onSubmit = () => {
    return generateExcessPolicy(id, {})
      .then(() => {
        enqueueSnackbar(
          `Quote ${policyNumber} submitted for policy generation`,
          { variant: 'success' }
        );
        delayedEvent('table-refetch', 500, 'adminBinders');
        close();
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong while submitting for policy generation.'
            )
          )
        );
      });
  };
  return (
    <FormProvider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div className="modal-title">
            Are you sure you want to submit the binder#{' '}
            <span className="modal-blue-text">{policyNumber}</span> to generate
            policy?
          </div>
          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close}>
            Cancel
          </CbButton>
          <CbButton
            loading={isSubmitting}
            styleName="ctaButton"
            buttonText="Generate Policy"
            type="submit"
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default GenerateExcessPolicy;
