import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Numeral from 'numeral';
import Moment from 'moment';

import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  IconButton,
  Grid as MuiGrid,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { Create as Pencil } from '@mui/icons-material';
import { SquareButton } from '../../../components/Buttons/SquareButton';
import { CoverageChart } from '../../../console/_global/charts/coverage.chart';
import DownloadIcon from '../../../_assets/svg/Download.svg';
import CowbellIcon from '../../../_assets/svg/Cowbell.svg';

import { calculateCoverageOptions } from '../../../console/_statics/coverage.statics';
import { toggleModalDiffered } from '../../../utils/storeUtils';
import { downloadQuoteProposal } from '../../../inbox/QuotesService';
import { downloadAWSDoc } from '../../../policies/PolicyService';
import {
  saveBlobFile,
  phoneFormatter,
  moneyFormat2Decimals,
  moneyFormat,
} from '../../../utils/appUtils';
import { delayedEvent } from '../../../utils/eventUtils';
import { withSubs } from '../../../console/_global/lib/withSubs';
import { withShowable } from '../../../console/_global/lib/withShowable';
import { _isOneOf } from '../../../utils/data.utils';

import {
  INDICATION_STATUS,
  READY_STATUS,
} from '../../../console/_statics/quote.statics';
import {
  FORMAT_DATE_AND_TIME,
  toUniversalUtcDate,
} from '../../../utils/date.utils';

const PencilIcon = withShowable(Pencil);

export const Coverages = withSubs(['quote:patching'])(
  ({
    parentClasses,
    account = {},
    quote = {},
    coverageData = {},
    isPatching = false,
    getCardRef,
  }) => {
    const classes = useClasses();
    const {
      palette: { common },
    } = useTheme();
    const { P_100 } = useSelector((state) => state.auth);
    const options = useMemo(
      () =>
        calculateCoverageOptions(coverageData, {
          coverageMapping: P_100,
          disableSocial: coverageData.socialEngEndorsement,
          disableRansom: coverageData.ransomPaymentEndorsement,
          socialEngEndorsement: coverageData.socialEngEndorsement,
          socialReason: !_.isEmpty(P_100) && P_100.SOCIAL_ENGINEERING.tooltip,
          disableWebsite: coverageData.websiteMediaContentLiabilityEndorsement,
        }),
      [coverageData, P_100]
    );

    const {
      businessIncomeCoverage,
      ransomPaymentLimit,
      socialEngLimit,
      socialEngDeductible,
      socialEngEndorsement,
      ransomPaymentEndorsement,
      excludedFromSE,
      websiteMediaContentLiabilityEndorsement,
      websiteMediaContentLiabilitySubLimit,
    } = coverageData;

    const handleDownloadProposal = () => {
      const { id, accountId, quoteNumber, companyName, created } = quote;

      downloadQuoteProposal(accountId, id, Moment(created).unix())
        .then((response) => downloadAWSDoc(response.data))
        .then((response) =>
          saveBlobFile(
            response,
            `${companyName}-${quoteNumber}-proposal`,
            '.pdf'
          )
        )
        .catch(console.error.bind(console));
    };

    return (
      <>
        <div className="flex--spaced">
          <h3 className="contrast-text">Coverages</h3>
          <div className="flex--spaced">
            <h3 className="contrast-text">{quote.quoteNumber}</h3>
            <SquareButton
              variant="contained"
              style={{ marginLeft: '1rem' }}
              onClick={handleDownloadProposal}
            >
              <DownloadIcon fill="#ffffff" style={{ width: '1.3rem' }} />
            </SquareButton>
          </div>
        </div>
        <Card
          className={parentClasses.card}
          style={{ position: 'relative' }}
          ref={getCardRef}
        >
          <div className="flex--spaced">
            Quote Effective{' '}
            <span>
              {toUniversalUtcDate(coverageData.effectiveDate, {
                format: FORMAT_DATE_AND_TIME,
              })}
            </span>
          </div>
          <div className="flex--spaced">
            Quote Expires{' '}
            <span>
              {toUniversalUtcDate(quote.expiresOn, {
                format: FORMAT_DATE_AND_TIME,
              })}
            </span>
          </div>
          <div className="flex--spaced">
            Quote Created{' '}
            <span>
              {toUniversalUtcDate(quote.created, {
                format: FORMAT_DATE_AND_TIME,
              })}
            </span>
          </div>
          <Box
            className="flex--spaced"
            style={{
              alignItems: 'flex-start',
              marginTop: '1.66rem',
              marginBottom: '2.5rem',
            }}
          >
            <div className="flex--spaced">
              Policy Holder Contact{' '}
              <IconButton
                size="small"
                style={{ marginLeft: '0.416rem' }}
                onClick={toggleModalDiffered(
                  'UpdatePolicyHolder',
                  {
                    accountId: quote.accountId,
                    agentFirstName: quote.agentFirstName,
                    agentLastName: quote.agentLastName,
                    policyContactFirstName: account.policyContactFirstName,
                    policyContactLastName: account.policyContactLastName,
                    policyContactEmail: account.policyContactEmail,
                    policyContactPhone: account.policyContactPhone,
                    onSuccess: function onSuccess() {
                      delayedEvent('account:refresh', 1000, {
                        accountId: quote.accountId,
                      });
                    },
                  },
                  {
                    title: `Update Policy Holder for ${quote.companyName}`,
                    icon: 'Users',
                    maxWidth: 'md',
                  }
                )}
              >
                <PencilIcon
                  show={_isOneOf(quote.agencyStatus, [
                    INDICATION_STATUS,
                    READY_STATUS,
                  ])}
                />
              </IconButton>
            </div>
            <div className="text-right">
              {account.policyContactFirstName}{' '}
              {account.policyContactLastName || '-'} <br />
              {account.policyContactEmail || '-'} <br />
              {account.policyContactPhone
                ? phoneFormatter(account.policyContactPhone)
                : '-'}
            </div>
          </Box>

          <h4 className="card-heading">Coverages</h4>
          <CoverageChart
            readOnly
            options={options}
            social={socialEngEndorsement}
            ransom={ransomPaymentEndorsement}
            exclude={excludedFromSE}
            website={websiteMediaContentLiabilityEndorsement}
            data={coverageData}
            renderSublimit={() => (
              <>
                <MuiGrid item sm={7}>
                  <span className={classes.indentedLabel}>
                    Selected Sublimit
                  </span>
                </MuiGrid>
                <MuiGrid item sm={5} className={classes.nestedFigure}>
                  {moneyFormat(businessIncomeCoverage)}
                </MuiGrid>
              </>
            )}
            renderRansomlimit={() => (
              <>
                {ransomPaymentEndorsement && (
                  <>
                    <MuiGrid item sm={7}>
                      <span className={classes.indentedLabel}>
                        Selected Sublimit
                      </span>
                    </MuiGrid>
                    <MuiGrid item sm={5} className={classes.nestedFigure}>
                      {moneyFormat(ransomPaymentLimit)}
                    </MuiGrid>
                  </>
                )}
              </>
            )}
            renderWebsiteLimit={(chartProps) => (
              <>
                {websiteMediaContentLiabilityEndorsement && (
                  <>
                    <MuiGrid
                      item
                      sm={7}
                      className={chartProps.classes.gridItem}
                    >
                      <span className={classes.indentedLabel}>
                        Selected Sublimit
                      </span>
                    </MuiGrid>
                    <MuiGrid
                      item
                      sm={5}
                      className={chartProps.classes.gridItem}
                      style={{ justifyContent: 'flex-end', paddingRight: 10 }}
                    >
                      {Numeral(websiteMediaContentLiabilitySubLimit).format(
                        '$0,000'
                      )}
                    </MuiGrid>
                  </>
                )}
              </>
            )}
            renderSocialLimit={() => (
              <>
                {socialEngEndorsement && (
                  <>
                    <MuiGrid item sm={7}>
                      <span className={classes.indentedLabel}>
                        Selected Sublimit
                      </span>
                    </MuiGrid>
                    <MuiGrid item sm={5} className={classes.nestedFigure}>
                      {moneyFormat(socialEngLimit)}
                    </MuiGrid>
                  </>
                )}
              </>
            )}
            renderSocialDeductible={() => (
              <>
                {socialEngEndorsement && (
                  <>
                    <MuiGrid item sm={7}>
                      <span className={classes.indentedLabel}>
                        Selected Deductible
                      </span>
                    </MuiGrid>
                    <MuiGrid item sm={5} className={classes.nestedFigure}>
                      {moneyFormat(socialEngDeductible)}
                    </MuiGrid>
                  </>
                )}
              </>
            )}
          />
          <Box className={classes.summary}>
            <MuiGrid container>
              <MuiGrid item md={12}>
                <div className="flex--spaced">
                  Aggregate Limit: <span className="oswald">$4,000,000</span>
                </div>
                <div className="flex--spaced">
                  Deductible:<span className="oswald">$1,000</span>
                </div>
              </MuiGrid>
              <MuiGrid item md={12}>
                <div className="flex--spaced">
                  Waiting Period: <span className="oswald">12 Hours</span>
                </div>
                <div className="flex--spaced">
                  Retro Active Period:
                  <span className="oswald">Full Prior Acts</span>
                </div>
              </MuiGrid>
            </MuiGrid>
            <div className={`${classes.premium} flex--spaced`}>
              Total Premium
              <span className="oswald">
                {moneyFormat2Decimals(quote.totalPremium)}
              </span>
            </div>
          </Box>

          <Backdrop className={classes.backdrop} open={isPatching}>
            <CircularProgress color="inherit" thickness="2" size="25rem" />
            <div className={classes.logo}>
              <CowbellIcon
                id="policy-loader-icon"
                fill1={common.cowbellBlue}
                fill2={common.cowbellGreen2}
                width="8.3333333333rem"
              />
              <div>Updating Quote...</div>
            </div>
          </Backdrop>
        </Card>
      </>
    );
  }
);

const useClasses = makeStyles(({ config, palette, ...theme }) => ({
  backdrop: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    color: palette.common.cowbellBlue,
    backgroundColor: 'rgba(0, 0, 0, .75)',
  },

  indentedLabel: {
    paddingLeft: '3.4rem',
    color: palette.text.text8,
  },

  summary: {
    marginTop: '.5rem',
    color:
      palette.mode === 'dark' ? palette.common.white : palette.text.secondary,
    fontSize: config.textSizes.tertia,
  },

  premium: {
    marginTop: '1rem',
    color: palette.common.cowbellBlue,
    fontSize: '1.5rem',
  },

  heading: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: palette.primary.contrast,
    fontSize: config.textSizes.canon,
  },

  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: palette.common.cowbellBlue,
    fontSize: config.textSizes.paragon,
  },

  nestedFigure: {
    justifyContent: 'flex-end',
    paddingRight: 10,
    textAlign: 'right',
  },
}));
