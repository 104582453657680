import { Api } from '../config/axiosConfig';
import { ServiceHandlerFactory } from '../utils/serviceUtils';
import {
  policySearchV2Mapping,
  policyServiceMappings,
} from './policyServiceMappings';
import { quoteServiceMappings } from '../inbox/quoteServiceMappings';
import { ProductTypes } from '../types';
import { applyQueryConfig, ServiceHandler } from '../utils';
import { standardQuerySerializer } from '../api';
import {
  getPrimeXPaymentDetails,
  getPrimeXPolicyDetailsByView,
} from '../api/policies.api';

export const fetchPreviousPolicyMainData = ServiceHandlerFactory({
  axios: {
    url: `/api/policy/v1/P250/prevpolicy/:policyId`,
    method: 'get',
  },
});

export const fetchOriginalPolicyData = ServiceHandlerFactory({
  axios: {
    url: 'api/policy/v1/:product/endorsement/original/:policyNumber',
    method: 'get',
  },
});

export const fetchPolicies = ServiceHandlerFactory({
  axios: { url: '/api/policy/v1/search' },
  isCancelable: true,
  paramSchema: policyServiceMappings,
});

export const fetchEndorsementPolicies = ServiceHandlerFactory({
  axios: { url: '/api/policy/v1/search/endorsements' },
  isCancelable: true,
  paramSchema: policyServiceMappings,
});

export const fetchBORPolicies = ServiceHandlerFactory({
  axios: { url: '/api/policy/v1/search/bor-policies' },
  isCancelable: true,
  paramSchema: policyServiceMappings,
});

export const fetchPolicyPremium = ServiceHandlerFactory({
  axios: { url: 'api/policy/v1/search/premium/' },
  isCancelable: true,
  paramSchema: policyServiceMappings,
});

export const fetchAllPolicyPremium = ServiceHandlerFactory({
  axios: { url: 'api/policy/v1/search/all-premium/' },
  isCancelable: true,
  paramSchema: policyServiceMappings,
});

export const fetchPolicyCaps = ServiceHandlerFactory({
  axios: { url: 'api/policy/v1/search/caps/p100' },
  paramSchema: policyServiceMappings,
});

export const fetchPolicyLimit = ServiceHandlerFactory({
  axios: { url: 'api/policy/v1/search/limit/' },
  paramSchema: policyServiceMappings,
});

export const fetchEndorsementPolicyPremiumStats = ServiceHandlerFactory({
  axios: { url: 'api/policy/v1/search/endorsement-premium' },
  isCancelable: true,
  paramSchema: policyServiceMappings,
});

/**
 * New location src/api/policies.api.ts
 * @deprecated
 */
export const fetchLatestPolicyData = ServiceHandlerFactory({
  axios: {
    url: 'api/policy/v1/:product/endorsement/latest/:policyNumber',
    method: 'get',
  },
});

export const sendPolicyCertificateEmail = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/certificate/:productType',
    method: 'post',
  },
});

export const createRenewalOptout = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/optout/:policyId',
    method: 'post',
  },
});

export const updateRenewalOptout = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/optout/:policyId',
    method: 'put',
  },
});

export const getRenewalOptoutReasons = ServiceHandlerFactory({
  axios: { url: '/api/policy/v1/reasons' },
  isCancelable: true,
  paramSchema: policyServiceMappings,
});

export const updatePolicyNonPremiumData = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/nonpremium/:policyId',
    method: 'put',
  },
});

export const updatePolicyEffEndDate = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/nonpremium/:policyId/date',
    method: 'put',
  },
});

export const fetchAdditonalInsured = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/ai/:policyId',
  },
});

export const fetchPolicyStatuses = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/statuses',
  },
});

/**
 * New location src/apis/policies.api.ts
 * @deprecated
 */
export function fetchPolicyDetails(policyId) {
  return Api.get(`/api/policy/v1/${policyId}`);
}

export function fetchPolicyEndorsementDetails(policyNum) {
  return Api.get(`api/policy/v1/endorsement/latest/${policyNum}`);
}

/**
 * New location src/apis/policies.api.ts
 * @deprecated
 */
export function fetch250PolicyDetails(policyId) {
  return Api.get(`/api/policy/v1/P250/${policyId}`);
}

export function fetch250PolicyPageDetails(policyId) {
  return Api.get(`/api/policy/v1/P250/details/${policyId}`);
}

/**
 * New location src/apis/policies.api.ts
 * @deprecated
 */
export function fetchPrimecloudDetails(policyId) {
  return Api.get(`/api/policy/v1/primecloud/details/${policyId}`);
}

/**
 * New location src/apis/policies.api.ts
 * @deprecated
 */
export function getPolicyDetails(policyId, product, params) {
  if (product === ProductTypes.p100) {
    return fetchPolicyDetails(policyId);
  }
  if (product === ProductTypes.p250) {
    return fetch250PolicyPageDetails(policyId);
  }
  if (product === ProductTypes.primecloud) {
    return fetchPrimecloudDetails(policyId);
  }
  return getPrimeXPolicyDetailsByView(product, policyId, { params });
}

export function regeneratePolicyDocs({ id, product }) {
  if (product === ProductTypes.p100) {
    return Api.get(`/api/policy/v1/document/${id}`);
  }
  return Api.put(`/api/policy/v1/policyDoc/${id}`);
}

export function getPolicyDetailsWithFirmo(policyId, product) {
  if (product === ProductTypes.p100) {
    return fetchPolicyDetails(policyId);
  }
  if (product === ProductTypes.p250) {
    return fetch250PolicyDetails(policyId);
  }
  if (product === ProductTypes.primecloud) {
    return fetchPrimecloudDetails(policyId);
  }
  return getPrimeXPaymentDetails(product, policyId);
}

export const unReadPolicy = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/search/read',
  },
  paramSchema: policyServiceMappings,
});

export function deletePolicy(policyNumber) {
  return Api.delete(`/api/policy/v1/${policyNumber}`);
}

export function downloadPolicyDocuments(
  accountId,
  policyId,
  created,
  isEndorsement,
  product,
  policyNumber
) {
  return Api.get(
    `/api/docs/v1/policy/${accountId}/${policyId}?createdDate=${created}&isEndorsement=${isEndorsement}&product=${product}&policyNumber=${policyNumber}`
  );
}
export function downloadPolicyCertificate(accountId, policyId, created) {
  return Api.get(
    `/api/docs/v1/policy/${accountId}/certificate/${policyId}?createdDate=${created}`
  );
}
export function downloadReceipt(accountId, policyId, created, isEndorsement) {
  return Api.get(
    `/api/docs/v1/receipt?accountId=${accountId}&policyId=${policyId}&createdDate=${created}&isEndorsement=${isEndorsement}`
  );
}
export function downloadPaymentPlan(
  accountId,
  policyId,
  created,
  isEndorsement
) {
  return Api.get(
    `/api/billing/v1/finance?accountId=${accountId}&policyId=${policyId}&createdDate=${created}&isEndorsement=${isEndorsement}`
  );
}
export function voidPolicy({ policyId, productType }, details) {
  return Api.put(`/api/policy/v1/${productType}/void/${policyId}`, details);
}

export function cancelP100Policy(policyId, details) {
  return Api.put(`/api/policy/v1/cancel/${policyId}`, details);
}

export const cancelPrimeXPolicy = ServiceHandlerFactory({
  axios: {
    url: `/api/policy/v1/:productType/cancel/:policyId`,
    method: 'put',
  },
});

export const cancelP250Policy = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/P250/cancel/:id',
    method: 'put',
  },
});

export const cancelP250ExcessPolicy = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/P250/excess/cancel/:id',
    method: 'put',
  },
});

export function downloadInvoice(
  accountId,
  policyId,
  created,
  latest,
  isEndorsement
) {
  return Api.get(
    `/api/docs/v1/invoice?accountId=${accountId}&policyId=${policyId}&createdDate=${created}&latest=${latest}&isEndorsement=${isEndorsement}`
  );
}

export function downloadAWSDoc(url) {
  return Api.get(url, { responseType: 'blob' });
}

export function changeAgencyBilled(policyId, product) {
  return Api.put(
    `api/policy/v1/toggle/agencyBilled?policyId=${policyId}&product=${product}`
  );
}
export function togglePaymentReminders(policyId) {
  return Api.put(` api/billing/v1/toggle/invoiceReminder?policyId=${policyId}`);
}
export function markCheckInMail(policyId) {
  return Api.put(`api/policy/v1/description/${policyId}`);
}

export const changeBOR = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/bor/:policyId',
    method: 'post',
  },
});
export const expediteBOR = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/update/BOR/:id',
    method: 'put',
  },
});

export const rescindBOR = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/bor/:policyId',
    method: 'put',
  },
});

export const getBinderPolicyStats = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/search/bound-premium',
  },
  isCancelable: true,
  paramSchema: quoteServiceMappings,
});

const fetchP250MidtermEndorsementsList = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/P250/midterm/list/:policyNumber',
  },
  isCancelable: true,
});

const fetchP100MidtermEndorsementsList = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/midterm/list/:policyNumber',
  },
  isCancelable: true,
});

const fetchPxMidtermEndorsementsList = ServiceHandler({
  url: '/api/policy/v1/:productType/midterm/list/:policynumber',
});

export const fetchMidtermEndorsementsList = ({ policyNumber, productType }) => {
  if (productType === ProductTypes.p100)
    return fetchP100MidtermEndorsementsList({ policyNumber });
  if (productType === ProductTypes.p250)
    return fetchP250MidtermEndorsementsList({ policyNumber });

  return fetchPxMidtermEndorsementsList(productType, policyNumber);
};

export const getPolicyAmountPaid = ServiceHandlerFactory({
  axios: {
    url: '/api/billing/v1/amountPaid/:policyId',
  },
});

export const putP250FlatCancel = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/P250/flatcancel/:policyId',
    method: 'put',
  },
});

export const generateLossRuns = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/lossRuns',
    method: 'post',
  },
});

export const getLossRuns = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/policy/:accountId/loss-runs/:policyId',
  },
  isCancelable: true,
});
export const getAllCancellationReasons = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/noc/reason',
  },
});

export const erpDuration = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/P250/erp',
    method: 'post',
  },
});

export const getErpPremium = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/P250/erp/premium/:policyId',
  },
});

export const muteRenewalReminders = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/:policyId/mute-renewal-reminders/:product',
    method: 'post',
  },
});

export const checkProductEligibilityForQuoting = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/product-eligibility',
    method: 'post',
  },
});

const reinstateCancelledP100Policy = ServiceHandler({
  url: '/api/policy/v1/undo-cancelled-policy/:policyId',
  method: 'PUT',
});

const reinstateCancelledP250Policy = ServiceHandler({
  url: '/api/policy/v1/P250/undo-cancelled-policy/:policyId',
  method: 'PUT',
});

export const reinstateCancelledPolicy = (policy) => {
  const { product: productType, id } = policy;
  switch (productType) {
    case ProductTypes.p100:
      return reinstateCancelledP100Policy(id);
    case ProductTypes.p250:
      return reinstateCancelledP250Policy(id);
    default:
  }
};

export const searchPoliciesV2 = ServiceHandler({
  url: '/api/policy/v1/search/dashboard',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(policySearchV2Mapping, params)),
});
