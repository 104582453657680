/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@mui/lab/Autocomplete';

// lodash
import _ from 'lodash';

// components
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { ButtonBase, InputBase, Checkbox, Popper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { InputLabelBase } from '../InputLabelBase';
import { getOptionLabel } from './AutoCompleteBase';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(({ palette, ...theme }) => ({
  button: {
    display: 'block',
    width: '100%',
    // padding: '0.91666666rem',
    padding: 11,
    paddingRight: '2.916666666rem',
    overflow: 'hidden',
    backgroundColor: palette.background.active,
    // border: '1px solid #ffffff',
    borderRadius: 5,
    color: palette.primary.contrastText,
    fontSize: '1rem',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:disabled': {
      background: palette.background.disabled,
    },

    '& span': {
      width: '100%',
    },

    '& svg': {
      position: 'absolute',
      top: '50%',
      right: '0.91666666rem',
      transform: 'translateY(-50%)',
    },
  },

  tag: {
    marginTop: 3,
    height: 20,
    padding: '.15em 4px',
    fontWeight: 600,
    lineHeight: '15px',
    borderRadius: 2,
  },

  popper: {
    border: '1px solid #707070',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: '1rem',
    backgroundColor: palette.background.default,
  },

  header: {
    padding: '8px 10px',
    fontWeight: 600,
  },

  inputBase: {
    padding: 10,
    width: '100%',

    '& input': {
      // borderRadius: 4,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  },
  paper: {
    border: 0,
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: '1rem',
  },
  option: {
    minHeight: 'auto',
    alignItems: 'center',
    // alignItems: 'flex-start',
    padding: 8,
    color: palette.text.primary,

    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: palette.action.hover,
    },
  },

  autoCompleteRoot: {
    borderBottom: '1px solid #707070',
  },

  popperDisablePortal: {
    position: 'relative',
  },

  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
}));

export function MultiSelectBase({
  buttonEmptyText = 'Select',
  defaultValue = [],
  disabled,
  error,
  fullWidth = true,
  label,
  labelProps = { indent: true },
  minWidth = 100,
  maxWidth = 200,
  name,
  options = [],
  onChange,
  required,
  buttonBaseProps = {},
  ...props
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(defaultValue || []);
  const _value = Array.isArray(value) ? value : [value];

  const [pendingValue, setPendingValue] = React.useState([]);

  React.useEffect(() => {
    if (defaultValue && Array.isArray(defaultValue)) {
      const hydratedValues = _.intersectionWith(
        options,
        defaultValue,
        (a, b) => {
          return a.value === b;
        }
      );

      setValue(hydratedValues);
    }

    if (defaultValue == null) {
      setValue([]);
      setPendingValue([]);
    }
  }, [options, defaultValue]);

  const handleClick = (event) => {
    if (Array.isArray(value)) {
      setPendingValue(value);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }

    setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }

    setAnchorEl(null);
  };

  const handleChange = React.useCallback((event, newValue) => {
    setPendingValue(newValue);

    if (typeof onChange === 'function') {
      onChange(newValue);
    }
    // eslint-disable-next-line
  }, []);

  const renderInput = React.useCallback((params) => {
    return (
      <InputBase
        ref={params.InputProps.ref}
        inputProps={params.inputProps}
        autoFocus
        className={classes.inputBase}
      />
    );
    // eslint-disable-next-line
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'multi-select-base' : undefined;

  return (
    <>
      <InputLabelBase
        htmlFor={name}
        required={required}
        show={Boolean(label)}
        {...labelProps}
      >
        {label}
      </InputLabelBase>
      <div
        className={classes.root}
        style={{
          minWidth,
          maxWidth: fullWidth ? '100%' : maxWidth,
        }}
      >
        <ButtonBase
          disabled={disabled}
          disableRipple
          className={classes.button}
          aria-describedby={id}
          onClick={handleClick}
          data-qa={`MULTISELECT_INPUT:${name}`}
          title={_value.map((option) => option.name).join(', ')}
          {...buttonBaseProps}
        >
          {_value.map((option) => option.label).join(', ')}
          {_value.length ? '' : buttonEmptyText}
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ButtonBase>
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
        style={{ zIndex: 9999 }}
      >
        <Autocomplete
          id={id}
          open
          onClose={handleClose}
          multiple
          classes={{
            root: classes.autoCompleteRoot,
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue}
          onChange={handleChange}
          disableCloseOnSelect
          disablePortal
          renderTags={noop}
          noOptionsText="No options"
          renderOption={renderOption}
          options={options}
          getOptionLabel={getOptionLabel}
          renderInput={renderInput}
          {...props}
        />
      </Popper>
    </>
  );
}

function noop() {
  return null;
}

function renderOption(optionProps, option, { selected }) {
  return (
    <li {...optionProps}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        checked={selected}
        color="primary"
      />
      {option.label}
    </li>
  );
}
