import moment from 'moment';
import { stringsToBools } from '../../../utils/data.utils';

export const saveClaimPayloadMapper = ({
  state,
  name: { value, label, ...reportedBy },
  lossDate,
  ...data
}) => {
  const modified = stringsToBools(data);

  return {
    ...modified,
    ...reportedBy,
    name: label,
    state,
    lossDate: lossDate ? moment(lossDate).format('YYYY-MM-DD') : undefined,
  };
};
