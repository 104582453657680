import { useMemo } from 'react';
import { useFeature } from '@growthbook/growthbook-react';
import type { Languages } from '../../i18n/i18n.language-config';
import { languages } from '../../i18n/i18n.language-config';
import { FEATURE_FLAG_KEYS } from '../../config/growthbook';

export const usePlatformLanguage = (): Languages => {
  const ukEnvironment = useFeature(FEATURE_FLAG_KEYS.UK_ENVIRONMENT);
  return useMemo(() => {
    const url = new URL(window.location.href);

    if (url.hostname.endsWith('.co.uk') || ukEnvironment.on) {
      return languages['en-GB'];
    }

    return languages['en-US'];
  }, [ukEnvironment.on]);
};
