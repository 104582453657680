import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as Yup from 'yup';
import Highlight from 'react-highlighter';
import { useSnackbar } from 'notistack';
// react
import React, { useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// mui
import { DialogActions, DialogContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import { withFormController } from '../../../components/hocs/forms';
import CbButton from '../../../components/Buttons/CbButton';
import { PhoneField as PhoneFieldBase } from '../../../components/inputs';
import { RegisteredTypeAhead } from '../../../components/inputs/autocomplete/index';
// actions
import { sendQuote } from '../../../api/apis';
import { searchRetailAgents } from '../_services/accounts.service';
// platform helpers
import { delayedEvent } from '../../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../../reducers/UiSettingsReducers';
// validations
import '../../_global/lib/validations/PhoneSchemaType';

const schema = Yup.object().shape({
  agencyName: Yup.string().label('Agency Name').required(),
  agentFirstName: Yup.string().label('First Name').required(),
  agentLastName: Yup.string().label('Last Name').required(),
  agentEmail: Yup.string().label('Email').email().required(),
  agentPhone: Yup.number().label('Phone').fromPhone().phone().required(),
});

const TextField = withFormController(TextFieldBase);
const PhoneField = withFormController(PhoneFieldBase);

export const SendQuoteToRetailAgent = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [wraId, setwraId] = useState(null);
  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
  });

  methods.watch('agencyName');

  const values = methods.getValues();

  const onSubmit = (formValues) => {
    setSaving(true);
    const productType = _.get(data, 'product', '');
    sendQuote({ quoteId: data.id, productType, ...formValues, wraId })
      .then(() => {
        setSaving(false);
        delayedEvent('table-refetch', 500, AGENCY_QUOTES_LISTING_STORE_ID);
        delayedEvent('quote:read');
        enqueueSnackbar('Quote request(s) sent successfully!', {
          variant: 'success',
        });
        close();
      })
      .catch(() => {
        enqueueSnackbar('Failed to send quote', { variant: 'error' });
        setSaving(false);
      });
  };

  const onAgencyChange = useCallback(
    (selectedOption) => {
      if (typeof selectedOption === 'string') {
        methods.setValue('agencyName', selectedOption);
      } else {
        const {
          retailAgencyName,
          email,
          phone,
          firstName,
          lastName,
          wholesaleId,
        } = selectedOption;

        methods.setValue('agencyName', retailAgencyName);
        methods.setValue('agentFirstName', firstName);
        methods.setValue('agentLastName', lastName);
        methods.setValue('agentEmail', email);
        methods.setValue('agentPhone', phone);
        setwraId(wholesaleId);
      }
    },
    [methods]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item md={6} style={{ paddingBottom: 0 }}>
              <RegisteredTypeAhead
                freeSolo
                fullWidth
                label="Agency Name"
                name="agencyName"
                required
                onFetch={fetchAgencies}
                error={_.get(methods.formState, 'errors.agencyName.message')}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.retailAgencyName
                }
                renderOption={(optionProps, option) => {
                  return (
                    <li {...optionProps}>
                      <AgencyOption option={option} value={values.agencyName} />
                    </li>
                  );
                }}
                onChange={onAgencyChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6} style={{ paddingBottom: 0 }}>
              <TextField
                name="agentFirstName"
                label="Agent First Name"
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6} style={{ paddingBottom: 0 }}>
              <TextField
                name="agentLastName"
                label="Agent Last Name"
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6} style={{ paddingTop: 0 }}>
              <TextField
                name="agentEmail"
                label="Agent Email"
                type="email"
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6} style={{ paddingTop: 0 }}>
              <PhoneField
                name="agentPhone"
                label="Agent Phone"
                required
                fullWidth
                placeholder="(___) ___-____"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close}>
            Cancel
          </CbButton>
          <CbButton styleName="ctaButton" type="submit" disabled={saving}>
            Send Quote
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const fetchAgencies = (request, callback) => {
  searchRetailAgents({ search: `*${request.input}*` })
    .then((response) => callback(_.get(response, 'data.content', [])))
    .catch(console.error.bind(console));
};

const AgencyOption = ({ option, value }) => {
  const classes = useOptionStyles();

  return (
    <div>
      <h4 className={classes.optionTitle}>
        <Highlight search={value}>{option.retailAgencyName}</Highlight>
      </h4>
      <span className={classes.optionText}>
        <Highlight search={value}>
          {option.firstName} {option.lastName} | {option.phone}
        </Highlight>
      </span>
      <span className={classes.optionText}>
        <Highlight search={value}>{option.email}</Highlight>
      </span>
    </div>
  );
};

const useOptionStyles = makeStyles(({ config, palette }) => ({
  optionTitle: {
    color: palette.primary.contrastText,
    fontSize: config.textSizes.tertia,
    fontWeight: 400,
    margin: 0,
  },

  optionText: {
    display: 'block',
    fontSize: config.textSizes.normal,
  },
}));
