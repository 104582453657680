import React from 'react';
import clsx from 'classnames';
import { useSnackbar, SnackbarContent } from 'notistack';

// components
import {
  Box,
  Card,
  CardActions,
  IconButton,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { green } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Show } from '../Show';

interface PaperSnackbarProps {
  children: React.ReactNode;
  variant?: Variant;
  id?: string;
  message: React.ReactNode;
  onClose?: () => void;
}

export type Variant = 'error' | 'warning' | 'info' | 'success';

export const PaperSnackbar = React.forwardRef<
  HTMLDivElement,
  PaperSnackbarProps
>(({ children, variant = 'error', ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const classes = useStyles();
  const actionClasses = useVariantClasses(classes.actionRoot, variant);
  const actionIconClasses = useVariantClasses(classes.actionIconRoot, variant);
  const TitleIcon = getNotificationIconForVariant(variant);

  function handleDismiss() {
    props.onClose?.();
    closeSnackbar(props.id);
  }

  return (
    <SnackbarContent ref={ref}>
      <Card className={classes.cardRoot}>
        <CardActions className={actionClasses}>
          <Box className={classes.actionLeft}>
            <TitleIcon />
            <Title variant="subtitle2">{props.message}</Title>
          </Box>

          <IconButton onClick={handleDismiss} size="small">
            <CloseIcon className={actionIconClasses} />
          </IconButton>
        </CardActions>

        <Show when={children != null}>
          <Paper className={classes.paperRoot}>{children}</Paper>
        </Show>
      </Card>
    </SnackbarContent>
  );
});

const Title = styled(Typography)`
  font-size: 1rem;
  font-weight: bold;
  line-height: unset;
`;

const useStyles = makeStyles(({ palette }) => ({
  actionRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.83rem',
  },

  actionLeft: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },

  actionIconRoot: {
    background: 'transparent',
  },

  paperRoot: {
    padding: '1rem',
    overflow: 'auto',
    background: palette.background.tableRowHover,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    color: palette.primary.contrastText,
    fontSize: '1rem',
  },

  cardRoot: {
    minWidth: 300,
    maxWidth: 600,
    maxHeight: 400,
    margin: '0 auto',
    background: 'transparent',
  },
}));

const useVariantStyles = makeStyles(({ palette }) => ({
  error: {
    background: palette.error.main,
    color: palette.error.contrastText,
  },

  warning: {
    background: palette.common.yellow,
    color: palette.common.white,
  },

  info: {
    background: palette.common.cowbellBlue,
    color: palette.common.white,
  },

  success: {
    background: green[600],
    color: palette.common.white,
  },
}));

const useVariantClasses = (className: string, variant: Variant) => {
  const classes = useVariantStyles();

  return clsx(className, {
    [classes.error]: variant === 'error',
    [classes.warning]: variant === 'warning',
    [classes.info]: variant === 'info',
    [classes.success]: variant === 'success',
  });
};

function getNotificationIconForVariant(type: Variant) {
  switch (type) {
    case 'error':
      return ErrorOutlineIcon;
    case 'warning':
      return WarningAmberIcon;
    case 'info':
      return HelpOutlineIcon;
    case 'success':
      return CheckCircleOutlineIcon;
    default:
      throw new Error(`Unsupported notification type: ${type}`);
  }
}
