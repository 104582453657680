import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getAllNoteTypes, getNotes } from '../api';
import { getData } from '../utils/functional/fp';
import { getNoteById } from '../console/_tabs/notesTab/notes.service';

export const notes = createQueryKeys('notes', {
  detail: (noteId: string) => ({
    queryKey: ['note-details', noteId],
    queryFn: () => getNoteById({ noteId }).then(getData),
  }),

  list: (filters: Record<string, unknown>) => ({
    queryKey: [{ filters }],
    queryFn: () => getNotes({ params: filters }).then(getData),
  }),

  types: () => ({
    queryKey: ['note-types'],
    queryFn: () => getAllNoteTypes().then(getData),
  }),
});
