import * as Ramda from 'ramda';
import { type Result, Ok, Err } from '@thames/monads';

// Generic utils to utilized for a declarative, functional programming style

/**
 * Guards the passed function from being invoked with a nullish argument.
 *
 * @param {function} fn - The function to be protected.
 * @return {function} A new function which, when invoked with a non-nullish argument, returns the invokation of `fn` with said argument.
 *
 * @example
 *
 *     foo(identityFn)('hello') => 'hello'
 *     foo(identityFn)(undefined) => undefined
 */
export function guard<T extends (arg: any) => any>(fn: T) {
  return function (
    arg: Parameters<T>[0] | null | undefined
  ): ReturnType<T> | null | undefined {
    return arg != null ? fn(arg) : arg;
  };
}

/**
 * Useful for debugging compositions. Prints a log with the provided tag and the recieved value `x` and then returns `x`.
 *
 * @param {string} tag - A string identifier for the produced log
 * @return {any} The provided argument, unchanged.
 *
 * @example
 *
 *     trace('After Sum')(3) => 3
 *     Console: 'Traced | After Sum: 3'
 *
 */
export function trace<T>(tag: string) {
  return function $trace(x: T) {
    // eslint-disable-next-line no-console
    console.log(`Traced | ${tag}:`, x);
    return x;
  };
}

export const getData = Ramda.prop('data');

export function transformToOption<T = unknown>(
  getLabel?: (data: T) => string,
  getValue?: (data: T) => string | number
) {
  const _getLabel = getLabel != null ? getLabel : stringIdentity;
  const _getValue = getValue != null ? getValue : stringIdentity;

  return (data: T) => ({
    label: _getLabel(data).split('_').join(' '),
    value: _getValue(data),
    meta: data,
  });
}

const stringIdentity = Ramda.compose(String, Ramda.identity);

export const isNilOrEmpty = (arg: unknown) =>
  Ramda.isNil(arg) || Ramda.isEmpty(arg);

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const getIndex = (
  values: string[],
  value: string
): Result<number, string> => {
  const index = values.indexOf(value);

  switch (index) {
    case -1:
      return Err('Value not found');
    default:
      return Ok(index);
  }
};
