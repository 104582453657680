import _ from 'lodash';

import type { GenericJsonApiSpecResponse } from '../../types';
import { ProductTypes } from '../../types';
import type { Quote } from '../../types/quotes/quote.entity';

import { pull250AccountQuestions } from '../../utils';
import { utcISO } from '../../utils/date.utils';
import type { GetPrimeXQuoteDetailsResponse } from '../quotes.api.types';
import { getRegionByProduct } from '../../i18n/utils';

export function transformP250QuoteDtoToQuoteEntity(
  body: any
): GenericJsonApiSpecResponse<Quote> {
  const { data } = body;

  const baseQuoteFields = _.pick(data, [
    'id',
    'quoteNumber',
    'companyName',
    'agencyStatus',
    'isRenewal',
    'expiresOn',
    'accountId',
    'premium',
    'totalPremium',
    'deductible',
    'aggLimit',
    'isSpecimenAvailable',
  ]);
  const baseAgencyFields = _.pick(data, ['agencyId', 'agencyName']);
  const baseAgentFields = _.pick(data, ['agentEmail', 'agentName']);

  return {
    data: {
      ...baseQuoteFields,
      product: ProductTypes.p250,
      effectiveDate: utcISO(data.initial250RequestData.effectiveDate),
      endDate: utcISO(data.initial250RequestData.endDate),
      agency: baseAgencyFields,
      agent: baseAgentFields,
      endorsements: data.quoteResponse.endorsements,
      securityQuestions: pull250AccountQuestions(data.firmographicData),
      coverages: data.initial250RequestData.coverages,
    },
  };
}

interface TransformQuoteDetailsResponseParams {
  data: GetPrimeXQuoteDetailsResponse;
}

export const transformQuoteDetailsResponse = ({
  data,
}: TransformQuoteDetailsResponseParams) => {
  const language = getRegionByProduct(data.quote.product);

  return {
    ...data,
    ...data.quote,
    limit: data.quote.aggLimit,
    firmographicData: {
      ...data.firmographicData,
      language,
    },
    language,
  };
};
