import i18n from 'i18next';
import * as Ramda from 'ramda';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import type { HttpBackendOptions } from 'i18next-http-backend';
import Backend from 'i18next-http-backend';
import en_US_account from '../locales/account/en-US.json';
import en_US_agency from '../locales/agency/en-US.json';
import en_US_agent from '../locales/agent/en-US.json';
import en_US_common from '../locales/common/en-US.json';
import en_US_policy from '../locales/policy/en-US.json';
import en_US_coverage from '../locales/coverage/en-US.json';
import en_US_organization from '../locales/organization/en-US.json';
import en_US_carrier from '../locales/carrier/en-US.json';
import en_US_submission from '../locales/submission/en-US.json';
import en_US_quote from '../locales/quote/en-US.json';
import en_GB_account from '../locales/account/en-GB.json';
import en_GB_agency from '../locales/agency/en-GB.json';
import en_GB_agent from '../locales/agent/en-GB.json';
import en_GB_common from '../locales/common/en-GB.json';
import en_GB_policy from '../locales/policy/en-GB.json';
import en_GB_coverage from '../locales/coverage/en-GB.json';
import en_GB_organization from '../locales/organization/en-GB.json';
import en_GB_carrier from '../locales/carrier/en-GB.json';
import en_GB_submission from '../locales/submission/en-GB.json';
import en_GB_quote from '../locales/quote/en-GB.json';

export const defaultNS = 'common';
export const ns = [
  'account',
  'agency',
  'agent',
  'carrier',
  'common',
  'coverage',
  'organization',
  'policy',
  'quote',
  'submission',
] as const;
export type NS = (typeof ns)[number];

const debug = !['production', 'test'].some(Ramda.equals(process.env.NODE_ENV));
const isTest = process.env.NODE_ENV == 'test';
const isLocalDevelopment = window.location.hostname.includes('localhost');
const includeLocalLocales = isTest || isLocalDevelopment;

const isStageEnvironment =
  window.location.hostname.includes('morecowbell') ||
  window.location.hostname.includes('cowshavebells');
const isProdEnvironment = window.location.hostname.includes('cowbellcyber');

export const resources = {
  'en-US': {
    account: en_US_account,
    agency: en_US_agency,
    agent: en_US_agent,
    carrier: en_US_carrier,
    common: en_US_common,
    coverage: en_US_coverage,
    organization: en_US_organization,
    policy: en_US_policy,
    quote: en_US_quote,
    submission: en_US_submission,
  },
  'en-GB': {
    account: en_GB_account,
    agency: en_GB_agency,
    agent: en_GB_agent,
    carrier: en_GB_carrier,
    common: en_GB_common,
    coverage: en_GB_coverage,
    organization: en_GB_organization,
    policy: en_GB_policy,
    quote: en_GB_quote,
    submission: en_GB_submission,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    returnNull: false,
    debug,
    load: 'currentOnly',
    fallbackLng: 'en-US',
    ns,
    defaultNS,
    ...(includeLocalLocales && { resources }),
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
      ],
      caches: ['cookie'],
      cookieOptions: { path: '/', sameSite: 'strict' },
      cookieMinutes: 1,
    },
    backend: {
      loadPath: (lng, nameSpace) => {
        let baseUrl;
        if (isStageEnvironment) {
          baseUrl = 'https://locales.morecowbell.ai';
        } else if (isProdEnvironment) {
          baseUrl = 'https://locales.cowbellcyber.ai';
        } else {
          baseUrl = 'https://locales-dev.meetmoo.ai';
        }
        return `${baseUrl}/${nameSpace}/${lng}.json`;
      },
      crossDomain: true,
    },
  });

export default i18n;
