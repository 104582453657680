import { InternalApiAxiosInstance } from '../../config/axios/internal.api.config';
import { ServiceHandler } from '../../utils';
import type { PasswordConstraintsDto } from './auth.dto';

export const login = ServiceHandler(
  {
    url: '/api/auth/v1/login',
    method: 'post',
  },
  InternalApiAxiosInstance
);

export const getPasswordConstraints = ServiceHandler<
  unknown,
  PasswordConstraintsDto[]
>(
  {
    url: '/api/auth/v1/password-constraints',
  },
  InternalApiAxiosInstance
);

export const getAccessToken = ServiceHandler({
  url: '/api/auth/v1/access',
  method: 'POST',
});

export type GetLatestDeploymentTimeResp = {
  latestDeploymentTime: string;
};

export const getLatestDeploymentTime = ServiceHandler<
  unknown,
  GetLatestDeploymentTimeResp
>({
  url: '/latest-deployment-time',
  method: 'GET',
});
