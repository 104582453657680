import React from 'react';
import type { TooltipProps } from '@mui/material';
import { Tooltip, Zoom as MuiZoom } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'classnames';

type Props = {
  className?: string;
  naked?: boolean;
  show?: boolean;
} & TooltipProps;

const SimpleTooltip: React.FC<Props> = ({
  children,
  className,
  placement = 'top',
  TransitionComponent = MuiZoom,
  show = true,
  naked,
  ...props
}) => {
  const classes = useTooltipStyles();
  const rootClassName = clsx(className, 'expand-icon', {
    [classes.root]: !naked,
  });

  if (!show) {
    return <div className={className}>{children}</div>;
  }

  return (
    <Tooltip
      placement={placement}
      TransitionComponent={TransitionComponent}
      className={rootClassName}
      style={{ marginLeft: '0.2rem' }}
      {...props}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default SimpleTooltip;

const useTooltipStyles = makeStyles({
  root: {
    cursor: 'auto',
    minWidth: '1rem',
    lineHeight: '1.2rem',
  },
});
