import React from 'react';

// components
import type {
  TypographyProps,
  FormControlProps,
  FormHelperTextProps,
} from '@mui/material';
import {
  FormControl,
  FormHelperText as MuiFormHelperText,
  Input as MuiInput,
  InputLabel as MuiInputLabel,
  Typography,
} from '@mui/material';
import { styled, makeStyles } from '@mui/styles';

import { withShowable } from '../../../../console/_global/lib/withShowable';

type Props = React.ComponentProps<typeof Input> & {
  name?: string;
  label?: string;
  helperText?: string;
  descriptionText?: string;
  DescriptionTextProps?: TypographyProps;
  FormControlProps?: FormControlProps;
  FormHelperTextProps?: FormHelperTextProps;
  errorMessage?: string;
};

const TextFieldBase = React.forwardRef(
  (
    {
      name,
      label,
      required,
      descriptionText,
      errorMessage,
      DescriptionTextProps = {},
      FormControlProps = {},
      FormHelperTextProps = {},
      helperText,
      ...props
    }: Props,
    ref
  ): JSX.Element => {
    const inputLabelClasses = useInputLabelClasses();
    return (
      <FormControl variant="standard" fullWidth {...FormControlProps}>
        <InputLabel
          show={Boolean(label)}
          htmlFor={name}
          required={required}
          disabled={props.disabled}
          classes={inputLabelClasses}
        >
          {label}
        </InputLabel>
        <DescriptionText
          className="text-field-description-text normal-text"
          show={Boolean(descriptionText)}
          {...DescriptionTextProps}
        >
          {descriptionText}
        </DescriptionText>
        <Input ref={ref} name={name} id={name} {...props} />
        <FormErrorText show={Boolean(errorMessage)}>
          {errorMessage}
        </FormErrorText>
      </FormControl>
    );
  }
);

const Input = styled(MuiInput)(({ theme, styles }: any) => ({
  minHeight: 25,

  '&.Mui-disabled': {
    opacity: 0.5,
  },

  '& > input': {
    minHeight: 25,
    padding: '0.5rem 0.6666666667rem',
    borderRadius: 3,
  },

  'label + &': {
    marginTop: '0.366667rem',
  },

  '.text-field-description-text + &': {
    marginTop: '0',
  },

  '& > input:disabled': {
    backgroundColor: theme.palette.background.active,
  },

  ...styles,
}));

export const useInputLabelClasses = makeStyles((theme) => ({
  root: {
    position: 'relative',
    top: 'initial',
    left: 'initial',
    transform: 'initial',
    color: theme.palette.primary.contrastText,
    fontSize: theme.config.textSizes.normal,
  },
  asterisk: {
    color: theme.palette.error.main,
  },
}));

const StyledDescriptionText = styled(Typography)({
  'label + &': {
    marginTop: '0',
  },
});

const InputLabel = withShowable(MuiInputLabel);
const FormErrorText = withShowable(
  styled(MuiFormHelperText)(() => ({
    margin: 0,
  }))
);
const FormHelperText = withShowable(
  styled(FormErrorText)(({ theme }) => ({
    color: theme.palette.primary.main,
  }))
);
const DescriptionText = withShowable(StyledDescriptionText);

export default TextFieldBase;
