import React from 'react';
import { I18nextProvider as BaseProvider } from 'react-i18next';
import type { WithChildrenProps } from '../types/components/common';

import i18n from '../i18n/i18n';

function I18nextProvider({ children }: WithChildrenProps) {
  return (
    <React.Suspense fallback={<div />}>
      <BaseProvider i18n={i18n}>{children}</BaseProvider>
    </React.Suspense>
  );
}

export default I18nextProvider;
