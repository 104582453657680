import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getQuoteDetails, searchQuotes } from '../api';
import { getData } from '../utils/functional/fp';

export const quotes = createQueryKeys('quotes', {
  list: (filters: Record<string, unknown>) => ({
    queryKey: [{ filters }],
    queryFn: () => searchQuotes({ params: filters }).then(getData),
  }),

  detail: (quoteId: string, product: string) => ({
    queryKey: [{ quoteId, product }],
    queryFn: () => getQuoteDetails(quoteId, product).then(getData),
  }),
});
