import * as Sentry from '@sentry/react';
import CowbellErrorPage from './CowbellErrorPage';

export const GlobalErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={(sentryProps) => <CowbellErrorPage {...sentryProps} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
