// https://github.com/Cowbell-Cyber/fir-login/blob/master/src/main/java/com/cowbell/server/login/entities/CowEnvironment.java

const QA_ENV_ID = 'cowbellqa';
const DEV_ENV_ID = 'confluent';
const STAGING_ENV_ID = 'confluentstaging';
const PROD_ENV_ID = 'prod';
const DEV_SPACE = 'dev';

export const ENVIRONMENTS = {
  QA_ENV_ID,
  DEV_ENV_ID,
  STAGING_ENV_ID,
  PROD_ENV_ID,
  DEV_SPACE,
} as const;

export type Environment = (typeof ENVIRONMENTS)[keyof typeof ENVIRONMENTS];

// GET: /api/auth/v1/env
export type EnvDto = {
  prod: boolean;
  envName: Environment;
};
