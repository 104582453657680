import type { InternalAxiosRequestConfig } from 'axios';
import Axios from 'axios';

import { retrieveAuthTokens } from '../../utils/next/auth.utils';
import { ApiError } from '../../_lib/errors';

export const InternalApiAxiosInstance = Axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

InternalApiAxiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config.headers = config.headers || {};

    const tokens = retrieveAuthTokens();
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }

    /*
     * removes new behavior of axios where multipart FormData request bodies are automatically serialized to JSON
     * https://github.com/axios/axios/issues/5556#issuecomment-1434668134
     */
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = undefined;
    }

    return config;
  }
);

InternalApiAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(new ApiError(error));
  }
);
