import _ from 'lodash';
import React from 'react';
import { withSnackbar } from 'notistack';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

// componets
import { Box, Backdrop, CircularProgress, DialogContent } from '@mui/material';

import {
  resetAgencyData,
  resetAgent,
} from '../../../_reducers/prime100.reducer';

import { CartList } from './CartList';
import { Button } from '../../../../components/Buttons/Button';

// services
import {
  deleteListedQuote,
  getTempQuotesByAccount,
  create250Quotes,
} from '../../../../api/apis';
import { useQuoteCreationFunctions } from '../../../../accounts/accountUtils';
import { usePersona } from '../../../../components/hooks/usePersona';
import {
  AGENCY_DASHBOARD_LISTING_TABS,
  useAgencyDashboardFeature,
} from '../../../dashboard/agency/agency-dashboard.statics';
import { PaperSnackbar } from '../../../../components/snackbars';
import { AGENCY_DASHBOARD_BASE_PATH } from '../../../dashboard/agency/constants';
import { PubSub } from '../../../../utils/eventUtils';

export const RequestQuoteSimple250Cart = compose(
  withSnackbar,
  withRouter
)(({ enqueueSnackbar, data, close, history }) => {
  const agencyDashboardFeature = useAgencyDashboardFeature();
  const persona = usePersona();

  const { account, setCartTotal, customPresetsDictionary } = data;
  const [quotes, setQuotes] = React.useState([]);
  const [loading, setLoading] = React.useState();
  const [sending, setSending] = React.useState();

  const {
    detemineAgentOnQuoteCreation,
    determineRetailAgentForFlorida,
    getAgencyId,
    determineTeamIdOfAgent,
  } = useQuoteCreationFunctions(data.account);

  const storeDispatch = useDispatch();

  const handleRemoveQuote = React.useCallback(
    (id) => {
      return () => {
        const agencyId = getAgencyId();
        deleteListedQuote(id, agencyId)
          .then(() => {
            const nextQuotes = [...quotes];

            _.remove(nextQuotes, (quote) => quote.id === id);

            setCartTotal(nextQuotes.length);
            setQuotes(nextQuotes);
          })
          .catch(() =>
            enqueueSnackbar('Failed to remove quote, please try again', {
              variant: 'error',
            })
          );
      };
    },
    // eslint-disable-next-line
    [quotes]
  );

  const handleQuoteNow = React.useCallback(() => {
    setSending(true);

    const agentInfo = detemineAgentOnQuoteCreation();
    const retailFloridaAgentInfo = determineRetailAgentForFlorida();

    const { agentFirstName, agentLastName, agentEmail, agentPhone } = agentInfo;
    const {
      retailAgentFirstName,
      retailAgentLastName,
      retailAgentLicenseNumber,
    } = retailFloridaAgentInfo;

    create250Quotes(
      {},
      {
        quoteIds: quotes.map((q) => q.id),
        accountId: data.account.id,
        agencyId: getAgencyId(),
        agentFirstName,
        agentLastName,
        agentEmail,
        agentPhone,
        retailAgentFirstName,
        retailAgentLastName,
        retailAgentLicenseNumber,
        teamIds: determineTeamIdOfAgent(),
      }
    )
      .then(() => {
        setCartTotal(0);
        setSending(false);
        enqueueSnackbar('Quotes successfully sent', { variant: 'success' });
        storeDispatch(resetAgent());
        storeDispatch(resetAgencyData());
        close();
        setTimeout(() => {
          if (persona.isCowbell) {
            history.push(`${persona.basePath}/quotes`);
          } else if (persona.isAgency && agencyDashboardFeature.on) {
            history.push(
              `${AGENCY_DASHBOARD_BASE_PATH}?tab=${AGENCY_DASHBOARD_LISTING_TABS.QUOTES}&prefilterAccountId=${account.id}`
            );
          } else {
            history.push(`${persona.basePath}/inbox`);
          }
        }, 2 * 1000);

        setTimeout(() => {
          PubSub.publish('quote-refresh:show', true);
        }, 10 * 1000);
      })
      .catch((error) => {
        setSending(false);

        enqueueSnackbar('Failed to send quotes', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          content: (key, message) => {
            if (error.response.data.errors) {
              return (
                <PaperSnackbar key={key} message={message}>
                  <strong>
                    The quotes could not be requested for the following
                    reason(s):
                  </strong>
                  <ul>
                    {error.response.data.errors.map((error, index) => (
                      <li key={index}>{error.detail}</li>
                    ))}
                  </ul>
                </PaperSnackbar>
              );
            }

            return null;
          },
        });
      });
    // eslint-disable-next-line
  }, [quotes, persona]);

  React.useEffect(() => {
    const agencyId = getAgencyId();
    setLoading(true);

    getTempQuotesByAccount({ accountId: account.id, agencyId })
      .then((resp) => {
        const nextQuotes = resp.data.content.map((quote) => {
          const presetId = quote.calculatorGeneratedData.presets;

          return {
            ...quote,
            presetName: ['BASIC', 'POPULAR', 'ELITE'].includes(presetId)
              ? presetId
              : customPresetsDictionary[presetId],
          };
        });

        setQuotes(nextQuotes);
        setLoading(false);
        setCartTotal(resp.data.content.length);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Failed to get cart', { variant: 'error' });
        setLoading(false);
      });

    // eslint-disable-next-line
  }, []);

  return (
    <DialogContent>
      <Backdrop open={loading} style={{ zIndex: 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {quotes.length ? (
        <>
          <CartList data={quotes} handleRemoveQuote={handleRemoveQuote} />
          <Box className="flex--spaced">
            <Box>
              {quotes.length >= 5 ? (
                <div>
                  You have reached 5 request limit, please delete one to add
                  another variation!
                </div>
              ) : null}
              <div className="blue-text">
                {quotes.length}
                {quotes.length === 1 ? '\tRequest' : '\tRequests'}
              </div>
            </Box>
            <Button
              fixed
              stylename="primary"
              disabled={sending}
              onClick={handleQuoteNow}
              size="lg"
            >
              Request Above Quotes
            </Button>
          </Box>
        </>
      ) : (
        <Box
          className="primer-text contrast-text"
          height="12rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          No quotes have been added to the cart yet.
        </Box>
      )}
    </DialogContent>
  );
});
