import React from 'react';
import * as Ramda from 'ramda';
import { useDispatch } from 'react-redux';
import { useFeature } from '@growthbook/growthbook-react';
import { useTranslation } from 'react-i18next';

import './config/appConfig';

import GlobalStyles from './GlobalStyles';
import { RouteResolver } from './RouteResolver';
import { PubSub } from './utils/eventUtils';
import VersionLayer from './workers/VersionLayer';

import { fetchConsoleVersion } from './api/apis';
import { setUIVersion } from './reducers/UiSettingsReducers';

import { getData } from './utils/functional/fp';
import { FEATURE_FLAG_KEYS } from './config/growthbook';

import MaintenanceModeView from './public/MaintenanceModeView';
import useIsMaintenanceModeActive from './components/hooks/useIsMaintenanceModeActive';

const { REACT_APP_VERSION } = import.meta.env;

const App = () => {
  useInit();
  useListeners();

  // load translations at top of React component tree
  useTranslation();

  const isMaintenanceModeActive = useIsMaintenanceModeActive();

  if (isMaintenanceModeActive) {
    return (
      <>
        <GlobalStyles />
        <MaintenanceModeView />
      </>
    );
  }

  return (
    <>
      <GlobalStyles />
      <RouteResolver />
    </>
  );
};

export default App;

function useListeners() {
  React.useEffect(() => {
    const serverDownEvent = PubSub.subscribe('server-down', () => {
      window.location.href = '/serverdown';
    });

    return () => {
      serverDownEvent.remove();
    };
    // eslint-disable-next-line
  }, []);
}

function useInit() {
  const dispatch = useDispatch();
  const cloudflareFeature = useFeature(FEATURE_FLAG_KEYS.CLOUDFLARE);

  React.useEffect(() => {
    const dispatchUIVersionChange = Ramda.compose(dispatch, setUIVersion);

    // set the app's current version within Redux
    if (cloudflareFeature.on) {
      dispatchUIVersionChange({ v: REACT_APP_VERSION });
    } else {
      fetchConsoleVersion().then(getData).then(dispatchUIVersionChange).catch();
    }

    VersionLayer.init();
    // eslint-disable-next-line
  }, []);
}
