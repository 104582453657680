import type { Languages } from '../i18n.language-config';
import { languages } from '../i18n.language-config';

const gbpFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const currencyFormatter = {
  gbp: gbpFormatter,
  usd: usdFormatter,
};

type Options = {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  placeholder?: string;
};

const numberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const NO_DECIMAL_FORMAT = {
  minimumFractionDigits: 0,
};

const currencyFormatterByLanguage = {
  [languages['en-GB']]: gbpFormatter,
  [languages['en-US']]: usdFormatter,
};

export const deriveCurrencyFormat = (
  language: Languages,
  input: number | string | undefined | null,
  options?: Options
) => {
  if (input == null || Number.isNaN(input)) {
    return options?.placeholder || '-';
  }

  const _options = { ...numberFormatOptions, ...options };
  const currencyFormatterOptions = (
    currencyFormatterByLanguage[
      language as keyof typeof currencyFormatterByLanguage
    ] || usdFormatter
  ).resolvedOptions();

  return new Intl.NumberFormat(language, {
    ...currencyFormatterOptions,
    ..._options,
  }).format(Number(input));
};
