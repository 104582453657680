import React, { useState, forwardRef, useCallback } from 'react';

import * as classnames from 'classnames';
import {
  Collapse,
  Paper,
  Typography,
  Card,
  CardActions,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSnackbar, SnackbarContent } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    width: '100%',
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  typography: {
    // fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    display: 'flex',
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: 16,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

export const DeveloperSnackbar = forwardRef<
  HTMLDivElement,
  {
    id: string | number;
    message: string | React.ReactNode;
    config?: any;
    response?: any;
  }
>((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const details = React.useMemo(() => {
    const urlParts = props.config ? props.config?.url.split('?') : [];

    return {
      method: props.config?.method?.toUpperCase(),
      requestUrl: urlParts[0],
      queryParams: props.config?.params,
      responseData: props.response?.data,
    };
  }, [props.config, props.response]);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Typography className={classnames(classes.typography, 'normal-text')}>
            {props.message}
          </Typography>
          <div className={classes.icons}>
            <IconButton
              aria-label="Show more"
              className={classnames(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton
              className={classes.expand}
              onClick={handleDismiss}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse}>
            <Typography className="normal-text" gutterBottom>
              {JSON.stringify(details.responseData)}
            </Typography>

            <Typography className="normal-text" gutterBottom>
              {details.method} {details.requestUrl}
            </Typography>

            <Typography className="normal-text">Query Parameters</Typography>
            <code>
              <pre>{JSON.stringify(details.queryParams, null, 2)}</pre>
            </code>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});
