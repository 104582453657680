import type * as Yup from 'yup';

export type Nullable<T> = T | (null | undefined);
export type valueOf<T> = T[keyof T];

export function isObject(val: unknown): val is object {
  return typeof val === 'object';
}

export interface YupFormValidationSchema {
  [x: string]: Yup.Schema<unknown>;
}

export const yupTypeCastTransform = (value: unknown) =>
  Number.isNaN(value) || value === null || value === undefined ? 0 : value;

export const YUP_REQUIRED_FIELD_ERROR_MSG = '${label} is a required field';

export function isErrorWithStatus(error: unknown): error is { status: number } {
  return typeof error === 'object' && error !== null && 'status' in error;
}
