import _ from 'lodash';
import {
  Prime100CoverageGroups,
  Prime100Coverages,
} from '../workflows/quotes/prime-100/types';
import { BoostRaterVersionResponse } from '../workflows/quotes/prime-100/api/types';

const baseStates = ['NY', 'WA'];
export const calculateCoverageOptions = (
  {
    businessIncomeCoverage,
    ransomPaymentLimit,
    hardwareReplCostEndorsement,
    hardwareReplCostSubLimit,
    telecomsFraudEndorsement,
    telecomsFraudSubLimit,
    postBreachRemediationEndorsement,
    postBreachRemediationSubLimit,
    computerFraudEndorsement,
    websiteMediaContentLiabilityEndorsement,
    websiteMediaContentLiabilitySubLimit,
    limit,
    socialEngLimit,
    additionalInsureds,
    isTriaExcluded,
  },
  {
    onChange = null,
    register,
    onClick = null,
    disableSocial,
    socialEngEndorsement,
    socialReason,
    disableWebsite,
    coverageList = [],
    coverageMapping,
    state,
    boostVersion,
  } = {}
) => [
  {
    id: 'SECURITY_BREACH_EXPENSE',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.SECURITY_BREACH_EXPENSE.name,
    defaultChecked: true,
    percentage: 1,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(coverageMapping.SECURITY_BREACH_EXPENSE.ordinal),
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.SECURITY_BREACH_EXPENSE.tooltip,
    mandatory: true,
    groupName: 'First Party Coverages',
  },
  {
    id: 'RESTORATION_OF_ELECTRONIC_DATA',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.RESTORATION_OF_ELECTRONIC_DATA.name,
    defaultChecked: true,
    percentage: 1,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(
        coverageMapping.RESTORATION_OF_ELECTRONIC_DATA.ordinal
      ),
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.RESTORATION_OF_ELECTRONIC_DATA.tooltip,
    mandatory: true,
    groupName: 'First Party Coverages',
  },
  {
    id: 'PUBLIC_RELATIONS_EXPENSE',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.PUBLIC_RELATIONS_EXPENSE.name,
    defaultChecked: true,
    percentage: baseStates.includes(state) ? 50000 / limit : 0.05,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(coverageMapping.PUBLIC_RELATIONS_EXPENSE.ordinal),
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.PUBLIC_RELATIONS_EXPENSE.tooltip,
    mandatory: true,
    groupName: 'First Party Coverages',
  },
  {
    id: 'EXTORTION_THREATS_AND_RANSOM_PAYMENT',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.EXTORTION_THREATS_AND_RANSOM_PAYMENT.name,
    defaultChecked: true,
    percentage: ransomPaymentLimit / limit,
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.EXTORTION_THREATS_AND_RANSOM_PAYMENT.tooltip,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(
        coverageMapping.EXTORTION_THREATS_AND_RANSOM_PAYMENT.ordinal
      ),
    mandatory: true,
    groupName: 'First Party Coverages',
  },
  {
    id: 'ransomLimit',
    groupName: 'First Party Coverages',
  },
  {
    id: 'extortionThreatsLimitDetail',
    groupName: 'First Party Coverages',
  },
  {
    id: 'BI_EXTRA_EXPENSE',
    label:
      !_.isEmpty(coverageMapping) &&
      boostVersion === BoostRaterVersionResponse.V4
        ? 'Business Income and Extra Expense'
        : 'Business Income, Contingent Business Income & Extra Expense',
    defaultChecked: true,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(coverageMapping.BI_EXTRA_EXPENSE.ordinal),
    percentage: businessIncomeCoverage / limit,
    tooltip:
      !_.isEmpty(coverageMapping) && coverageMapping.BI_EXTRA_EXPENSE.tooltip,
    mandatory: true,
    groupName: 'First Party Coverages',
  },
  {
    id: 'sublimit',
    groupName: 'First Party Coverages',
  },
  {
    id: 'COMPUTER_FUNDS_TRANSFER_FRAUD',
    name: 'computerFraudEndorsement',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.COMPUTER_FUNDS_TRANSFER_FRAUD.name,
    defaultChecked: !!computerFraudEndorsement,
    percentage: !computerFraudEndorsement ? 0 : 1,
    onChange,
    checked: !!computerFraudEndorsement,
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.COMPUTER_FUNDS_TRANSFER_FRAUD.ordinal
      ),
    register,
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.COMPUTER_FUNDS_TRANSFER_FRAUD.tooltip,
    mandatory: false,
    groupName: 'First Party Coverages',
  },
  {
    id: 'SOCIAL_ENGINEERING',
    label:
      !_.isEmpty(coverageMapping) && coverageMapping.SOCIAL_ENGINEERING.name,
    checked: socialEngEndorsement,
    defaultChecked: socialEngEndorsement,
    percentage: socialEngLimit / limit,
    onChange,
    onClick,
    modalName: 'SocialEngg',
    register,
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.SOCIAL_ENGINEERING.ordinal),
    showRedTooltip:
      disableSocial ||
      (!_.isEmpty(coverageMapping) &&
        !coverageList.includes(coverageMapping.SOCIAL_ENGINEERING.ordinal)),
    errorTooltip: disableSocial ? socialReason : 'State Not Supported',
    tooltip:
      !_.isEmpty(coverageMapping) && coverageMapping.SOCIAL_ENGINEERING.tooltip,
    mandatory: false,
    groupName: 'First Party Coverages',
  },
  {
    id: 'socialLimit',
    groupName: 'First Party Coverages',
  },
  {
    id: 'socialDeductible',
    groupName: 'First Party Coverages',
  },
  {
    id: 'PCI_FINES_AND_PENALTIES',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.PCI_FINES_AND_PENALTIES.name,
    defaultChecked: true,
    percentage: 1,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(coverageMapping.PCI_FINES_AND_PENALTIES.ordinal),
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.PCI_FINES_AND_PENALTIES.tooltip,
    mandatory: true,
    groupName: 'Third Party Coverages',
  },
  {
    id: 'REGULATOR_DEFENSE_AND_PENALTIES',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.REGULATOR_DEFENSE_AND_PENALTIES.name,
    defaultChecked: true,
    percentage: 1,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(
        coverageMapping.REGULATOR_DEFENSE_AND_PENALTIES.ordinal
      ),
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.REGULATOR_DEFENSE_AND_PENALTIES.tooltip,
    mandatory: true,
    groupName: 'Third Party Coverages',
  },
  {
    id: 'SECURITY_BREACH_LIABILITY',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.SECURITY_BREACH_LIABILITY.name,
    defaultChecked: true,
    percentage: 1,
    disabled:
      !_.isEmpty(coverageMapping) &&
      coverageList.includes(coverageMapping.SECURITY_BREACH_LIABILITY.ordinal),
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.SECURITY_BREACH_LIABILITY.tooltip,
    mandatory: true,
    groupName: 'Third Party Coverages',
  },
  {
    id: 'WEBSITE_MEDIA_CONTENT_LIABILITY',
    name: 'websiteMediaContentLiabilityEndorsement',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.WEBSITE_MEDIA_CONTENT_LIABILITY.name,
    defaultChecked: websiteMediaContentLiabilityEndorsement,
    percentage: websiteMediaContentLiabilitySubLimit / limit,
    onChange,
    register,
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.WEBSITE_MEDIA_CONTENT_LIABILITY.ordinal
      ),

    checked: websiteMediaContentLiabilityEndorsement,
    showRedTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.WEBSITE_MEDIA_CONTENT_LIABILITY.ordinal
      ),
    errorTooltip: disableWebsite ? 'Not Available For Industry / State!' : '',
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.WEBSITE_MEDIA_CONTENT_LIABILITY.tooltip,
    mandatory: false,
    groupName: 'Third Party Coverages',
  },

  {
    id: 'websiteLimit',
    groupName: 'Third Party Coverages',
  },
  {
    id: 'HARDWARE_REPLACEMENT_COSTS',
    name: 'hardwareReplCostEndorsement',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.HARDWARE_REPLACEMENT_COSTS.name,
    defaultChecked: hardwareReplCostEndorsement,
    percentage: !hardwareReplCostEndorsement
      ? 0
      : hardwareReplCostSubLimit / limit,
    onChange,
    register,
    showRedTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.HARDWARE_REPLACEMENT_COSTS.ordinal
      ),
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.HARDWARE_REPLACEMENT_COSTS.ordinal
      ),
    checked: hardwareReplCostEndorsement,
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.HARDWARE_REPLACEMENT_COSTS.tooltip,
    errorTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(
        coverageMapping.HARDWARE_REPLACEMENT_COSTS.ordinal
      ) &&
      'Not Available For Industry / State!',
    mandatory: false,
    groupName: 'Endorsements',
  },

  {
    id: 'TELECOM_FRAUD',
    name: 'telecomsFraudEndorsement',
    label: !_.isEmpty(coverageMapping) && coverageMapping.TELECOM_FRAUD.name,
    defaultChecked: telecomsFraudEndorsement,
    percentage: !telecomsFraudEndorsement ? 0 : telecomsFraudSubLimit / limit,
    onChange,
    register,
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.TELECOM_FRAUD.ordinal),
    checked: telecomsFraudEndorsement,
    showRedTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.TELECOM_FRAUD.ordinal),
    tooltip:
      !_.isEmpty(coverageMapping) && coverageMapping.TELECOM_FRAUD.tooltip,
    errorTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.TELECOM_FRAUD.ordinal) &&
      'Not Available For Industry / State!',
    mandatory: false,
    groupName: 'Endorsements',
  },
  {
    id: 'POST_BREACH_REMEDIATION',
    name: 'postBreachRemediationEndorsement',
    label:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.POST_BREACH_REMEDIATION.name,
    defaultChecked: postBreachRemediationEndorsement,
    percentage: !postBreachRemediationEndorsement
      ? 0
      : postBreachRemediationSubLimit / limit,
    onChange,
    register,
    showRedTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.POST_BREACH_REMEDIATION.ordinal),
    disabled:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.POST_BREACH_REMEDIATION.ordinal),
    checked: postBreachRemediationEndorsement,
    tooltip:
      !_.isEmpty(coverageMapping) &&
      coverageMapping.POST_BREACH_REMEDIATION.tooltip,
    errorTooltip:
      !_.isEmpty(coverageMapping) &&
      !coverageList.includes(coverageMapping.POST_BREACH_REMEDIATION.ordinal) &&
      'Not Available For Industry / State!',
    mandatory: false,
    groupName: 'Endorsements',
  },
  {
    id: 'ADDITIONAL_INSURED',
    name: 'additionalInsureds',
    label: 'Additional Insured',
    register,
    onChange,
    checked: additionalInsureds,
    disabled: false,
    mandatory: false,
    groupName: 'Endorsements',
  },
  {
    id: Prime100Coverages.TRIA,
    name: 'isTriaExcluded',
    label: !_.isEmpty(coverageMapping) && coverageMapping.TRIA.name,
    checked: !isTriaExcluded,
    mandatory: boostVersion === BoostRaterVersionResponse.V4,
    groupName: Prime100CoverageGroups.TRIA,
  },
];

export const waitingPeriods = {
  6: '6 Hours',
  8: '8 Hours',
  12: '12 Hours',
  24: '24 Hours',
};

export const retroActivePeriods = {
  1: '1 Year',
  2: '2 Years',
  3: 'Full Prior Acts',
};
