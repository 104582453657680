import React from 'react';

import type { Input } from '@mui/material';
import TextFieldBase from './text-fields/base/TextFieldBase';
import type { Languages } from '../../i18n/i18n.language-config';
import { usePlatformLanguage } from '../../utils/hooks/usePlatformLanguage';
import { CleaveBase } from './CleaveBase';

type CleaveInputProps = { inputRef?: unknown } & React.ComponentProps<
  typeof CleaveBase
>;

const CleaveInput = ({
  inputRef,
  options = {},
  ...props
}: CleaveInputProps) => {
  return (
    <CleaveBase
      ref={inputRef}
      options={{
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        rawValueTrimPrefix: true,
        numeralDecimalScale: 2,
        ...options,
      }}
      {...props}
    />
  );
};

export interface LanguageCurrencyFieldProps
  extends React.ComponentProps<typeof TextFieldBase> {
  language?: Languages;
}

export default function LanguageCurrencyField(
  props: LanguageCurrencyFieldProps
) {
  const region = usePlatformLanguage();

  const language = props.language ?? region;

  const LanguageCleaveInput = React.useCallback(
    (inputProps: React.ComponentProps<typeof Input>) => (
      <CleaveInput
        options={{ prefix: getCurrencySign(language) }}
        {...inputProps}
      />
    ),
    [language]
  );

  return <TextFieldBase inputComponent={LanguageCleaveInput} {...props} />;
}

const getCurrencySign = (language: Languages) => {
  if (language === 'en-GB') {
    return '£';
  }

  return '$';
};
