import type { Prime100QuoteDetailsResponse } from '../../quotes/prime-100/api/types';
import type useGetRenewalDependencies from '../hooks/useGetRenewalDependencies';
import type useRenewQuoteSteps from '../hooks/useRenewQuoteSteps';

export enum RenewalSteps {
  VERIFY_ACCOUNT_INFO = 'verify',
  RENEWAL_SUMMARY = 'summary',
  CUSTOM_QUOTE = 'custom-quote',
}

export type RenewFlowDependencies = ReturnType<
  typeof useGetRenewalDependencies
>['data'];

export type RenewQuoteStepProps = {
  renewSteps: ReturnType<typeof useRenewQuoteSteps>;
} & RenewFlowDependencies;

export type RenewalQuoteDetails = Prime100QuoteDetailsResponse;
