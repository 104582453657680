import React, { Component } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';
import {
  Grid as MuiGrid,
  DialogContent,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { compose } from 'redux';
import { TextField } from '../components/inputs';
import {
  updateAccountContact,
  fetchAccountDetailsById,
} from './AccountService';
import CbButton from '../components/Buttons/CbButton';
import withRouter from '../components/hocs/withRouter';
import { PubSub } from '../utils/eventUtils';

import { useAuth } from '../components/hooks/useAuth';
import { withActor } from '../components/hocs/withActor';
import { manageAPIError } from '../utils';
import { withToggleModal } from '../components/hocs/withToggleModal';

const schema = Yup.object().shape({
  contactFirstName: Yup.string().required(
    'Please enter Policy Holder First Name.'
  ),
  contactLastName: Yup.string().required(
    'Please enter Policy Holder Last Name.'
  ),
  contactEmail: Yup.string()
    .email('Please enter a valid Email Address')
    .required('Please enter Email Address'),
  contactPhone: Yup.string().required(
    'Please enter a valid Policy Holder Phone Number.'
  ),
});

const styles = ({ palette }) => {
  return {
    marginRight: {
      marginRight: 45,
    },

    addUser: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 35,
    },
    field: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 20,
      width: '50%',
    },
    radioGroup: {
      height: 40,
      maxHeight: 40,
      minWidth: 150,
      marginTop: 5,
      paddingRight: 15,
      border: `1px solid ${palette.common.almostWhite}`,
      borderRadius: 5,
    },
  };
};

class AddContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
        addUserToSystem: '',
        agentFirstName: '',
        agentLastName: '',
        agentEmail: '',
        agentPhone: '',
      },
      errors: {
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
      },
      formError: false,
      errorMessage: '',
      redirect: false,
      toSendData: {},
    };

    this.handleAddContactAsUser = this.handleAddContactAsUser.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
  }

  componentDidMount() {
    const { data, auth, actor } = this.props;
    const { fields } = this.state;

    const isCowbellJumpedIntoAgency =
      auth.subscriberType === 'COWBELL' && auth.accountType === 'AGENCY';

    // eslint-disable-next-line no-unused-expressions
    fields.contactFirstName = _.get(data, 'policyContactFirstName') || '';
    fields.contactLastName = _.get(data, 'policyContactLastName') || '';
    fields.contactEmail = _.get(data, 'policyContactEmail') || '';
    fields.contactPhone = _.get(data, 'policyContactPhone') || '';
    fields.addUserToSystem = _.get(data, 'addUserToSystem') || false;

    const accountId =
      _.get(data, 'id', _.get(data, 'data.id', '')) ||
      _.get(data.account, 'id', _.get(data.account, 'data.account.id', ''));
    this.setState({ accountId, ...fields });

    if (isCowbellJumpedIntoAgency) {
      const { agentFirstName, agentLastName, agentEmail, agentPhone } = data;

      if (_.isUndefined(agentFirstName)) {
        const { firstName, lastName, email, phone } = actor;

        this.setState({
          agentFirstName: firstName,
          agentLastName: lastName,
          agentEmail: email,
          agentPhone: phone,
        });
      } else {
        this.setState({
          agentFirstName,
          agentLastName,
          agentEmail,
          agentPhone,
        });
      }
    } else {
      const { firstName, lastName, email, phone } = actor;
      const { agentFirstName, agentLastName, agentEmail, agentPhone } =
        this.props.data;

      this.setState({
        agentFirstName: firstName ?? agentFirstName,
        agentLastName: lastName ?? agentLastName,
        agentEmail: email ?? agentEmail,
        agentPhone: phone ?? agentPhone,
      });
    }

    this.subscription = PubSub.subscribe('foot-button-one', (eventData) => {
      if (eventData) {
        this.handleSubmit();
      }
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  validateForm() {
    const { fields } = this.state;
    const errors = {};

    try {
      schema.validateSync(fields, { abortEarly: false });

      return true;
    } catch ({ inner }) {
      inner.forEach(({ path, message }) => {
        errors[path] = message;
      });

      this.setState({ errors });
      return false;
    }
  }

  handleAddContactAsUser(event) {
    this.setState({ addUserToSystem: event.target.checked });
  }

  handleChange = (name) => (event) => {
    const { errors, fields } = this.state;
    fields[name] = event.target.value || '';
    errors[name] = '';

    this.setState({ errors, [name]: event.target.value });
  };

  handleSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);

    const { pathname } = this.props.location;
    const isCowbellUser = pathname.startsWith('/admin');

    const formError = !this.validateForm(this.state);
    if (formError) {
      const errorMessage = '';
      PubSub.publish('loading', false);
      PubSub.publish('disabled', false);
      this.setState({ formError, errorMessage });
      return;
    }

    const contact = {
      accountId: this.state.accountId,
      agentFirstName: this.state.agentFirstName,
      agentLastName: this.state.agentLastName,
      agentEmail: this.state.agentEmail,
      agentPhone: this.state.agentPhone,
      contactFirstName: this.state.contactFirstName,
      contactLastName: this.state.contactLastName,
      contactEmail: this.state.contactEmail,
      contactPhone: this.state.contactPhone,
      addUserToSystem: this.state.addUserToSystem,
    };

    if (isCowbellUser) {
      contact.agencyId = this.props.data.agencyId;
      contact.accountId = this.props.data.accountId;
    }

    updateAccountContact(contact)
      .then((response) => {
        const { data } = this.props;
        if (data?.nextModal) {
          const id = _.get(data, 'data.id', _.get(data, 'account.id', ''));
          fetchAccountDetailsById(id).then((resp) => {
            this.setState({ toSendData: resp.data });
            this.setState({ redirect: true });
          });
        } else {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          this.props.toggleModal.direct('AddContact', false);
          PubSub.publish('contact-added', {
            id: this.state.accountId,
            contact: true,
          });
          setTimeout(() => {
            PubSub.publish('org:contact', {
              accountId: this.state.accountId,
              customerName: response.data.customerName,
            });
            PubSub.publish('org:email', {
              accountId: this.state.accountId,
              customerEmail: response.data.customerEmail,
            });
            PubSub.publish('org:phone', {
              accountId: this.state.accountId,
              customerPhone: response.data.customerPhoneNumber,
            });
          }, 500);
          this.props.enqueueSnackbar(
            data.isUpdate
              ? 'Contact Updated Successfully!'
              : 'Contact Added Successfully!',
            {
              variant: 'success',
            }
          );
        }
        if (typeof this.props.data.onRefetch === 'function') {
          setTimeout(() => this.props.data.onRefetch(), 1500);
        }
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.setState({
          formError: true,
          errorMessage: manageAPIError(error),
        });
      });
  };

  // so hacky but it is the only way without rewriting
  handleSkip() {
    const id = _.get(
      this.props.data,
      'data.id',
      _.get(this.props.data, 'account.id', '')
    );

    fetchAccountDetailsById(id).then((resp) => {
      this.setState({ toSendData: resp.data });
      this.setState({ redirect: true });
    });
  }

  render() {
    const { classes, data } = this.props;
    const { contactFirstName, contactLastName, contactEmail, contactPhone } =
      this.state.fields;

    if (this.state.redirect) {
      const productType = data.data
        ? _.get(data, 'data.productType')
        : _.get(data, 'account.productType');
      const accountId = data.data
        ? _.get(data, 'data.id')
        : _.get(data, 'account.id');

      PubSub.publish('loading', false);
      PubSub.publish('disabled', false);
      this.props.toggleModal.direct('AddContact', false);

      return (
        <Redirect
          to={{
            pathname: `/agency/requestQuote/${productType}/${accountId}/no-quote/new-quote`,
          }}
        />
      );
    }

    return (
      <DialogContent>
        <form id="submit-form">
          <MuiGrid container spacing={6}>
            <MuiGrid item md={6}>
              <TextField
                type="text"
                required
                id="contactFirstName"
                label="Policy Holder First Name"
                variant="outlined"
                value={contactFirstName}
                placeholder="Please enter policy holder first name"
                onChange={this.handleChange('contactFirstName')}
              />
              <FormHelperText className="api-text-error">
                {this.state.errors.contactFirstName}
              </FormHelperText>
            </MuiGrid>

            <MuiGrid item md={6}>
              <TextField
                id="contactLastName"
                label="Policy Holder Last Name"
                margin="none"
                required
                value={contactLastName}
                onChange={this.handleChange('contactLastName')}
                placeholder="Please enter policy holder last name"
              />
              <FormHelperText className="api-text-error">
                {this.state.errors.contactLastName}
              </FormHelperText>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container spacing={6}>
            <MuiGrid item md={6}>
              <TextField
                id="contactEmail"
                required
                label="Policy Holder Email"
                margin="none"
                value={contactEmail}
                onChange={this.handleChange('contactEmail')}
                placeholder="Please enter policy holder email address"
              />
              <FormHelperText className="api-text-error">
                {this.state.errors.contactEmail}
              </FormHelperText>
            </MuiGrid>

            <MuiGrid item md={6}>
              <TextField
                id="contactPhone"
                required
                label="Policy Holder Phone"
                type="tel"
                format="(###) ###-####"
                mask="_"
                value={contactPhone}
                onChange={this.handleChange('contactPhone')}
                placeholder="(___)___-____"
              />
              <FormHelperText className="api-text-error">
                {this.state.errors.contactPhone}
              </FormHelperText>
            </MuiGrid>
          </MuiGrid>

          {!data.hideInvite && (
            <div className={classes.addUser}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.addUserToSystem}
                    onChange={this.handleAddContactAsUser}
                    inputProps={{ required: false }}
                    color="secondary"
                  />
                }
                label="Invite Policy Holder to the Cowbell Cyber Platform"
              />

              {this.state.addUserToSystem && (
                <FormHelperText
                  style={{ fontStyle: 'italic', color: '#2180e2' }}
                >
                  Note: Select this only if you have communicated with the
                  Customer
                </FormHelperText>
              )}
              <FormHelperText className="api-text-error">
                {this.state.errorMessage}
              </FormHelperText>
            </div>
          )}
        </form>
        {data.showSkip && (
          <CbButton
            styleName="ctaButton"
            size="medium"
            onClick={this.handleSkip}
            styles={{ position: 'absolute', bottom: 20, left: 20 }}
          >
            Skip for now
          </CbButton>
        )}
      </DialogContent>
    );
  }
}

const withAuth = (Comp) =>
  function HOC({ ...props }) {
    const auth = useAuth();

    return <Comp {...props} auth={auth} />;
  };

export default compose(
  withToggleModal,
  withAuth,
  withActor,
  withSnackbar,
  withRouter,
  withStyles(styles)
)(AddContact);
