import { SendQuoteToRetailAgent } from '../../console/agencies/inbox/SendQuoteToRetailAgent';
import { SoftAssignAgency } from './SoftAssignAgency';
import { HardAssignAgency } from './HardAssignAgency';
import AddContact from '../../accounts/AddContact';
import DeleteQuote from '../../inbox/DeleteQuote';
import AccountsCreateEdit from './AccountsCreateEdit';
import AccountsDeleteConfirm from './AccountsDeleteConfirm';
import { AgencyCreateProfile } from './AgencyCreatePro';
import BlackListCreate from './BlackListCreate';
import BlackListDeleteConfirm from './BlackListDeleteConfirm';
import { UsersCreateEdit } from './UsersCreateEdit';
import UsersDeleteConfirm from './UsersDeleteConfirm';
import RequestToBind from '../../inbox/bind/RequestToBind';
import NaicsCodeDeleteConfirm from './NaicsCodeDeleteConfirm';
import MfaEnabled from './MfaEnabled';
import { GeneratePolicy } from './generatePolicy';
import { GenerateExcessPolicy } from './GenerateExcessPolicy';
import SMSEnabled from './SMSEnabled';
import AdminAccountsDeleteConfirm from './AdminAccountsDeleteConfirm';
import RefreshFirmographic from './RefreshFirmographic';
import OnDemandScan from './onDemandScanModal';
import SetMyAgentConfirm from './SetMyAgentConfirm';
import RemoveMyAgent from './RemoveMyAgent';
import { ResetMfaConfirm } from './ResetMfaConfirm';
import DeleteInvalidConfirm from './DeleteInvalidConfirm';
import DeleteExpiredConfirm from './DeleteExpiredConfirm';
import { AgencyLicenseCreateEdit } from './AgencyLicenseCreateEdit';
import { AgentLicenseCreateEdit } from './AgentLicenseCreateEdit';
import { AgencyLicenseDeleteConfirm } from './AgencyLicenseDeleteConfirm';
import { AgentLicenseDeleteConfirm } from './AgentLicenseDeleteConfirm';
import { TeamDeleteConfirm } from './TeamDeleteConfirm';
import NoDomainConfirmation from '../main/settings/orgProfile/NoDomainConfirmation';
import { ClaimCreateEdit } from '../../console/customers/claims/modals/ClaimCreateEdit';
import { ClaimDeleteConfirm } from '../../console/customers/claims/modals/ClaimDeleteConfirm';
import WebHookCreate from '../../admin/WebHookCreate';
import WebHookDeleteConfirm from '../../admin/WebHookDeleteConfirm';
import WebHookEdit from '../../admin/WebHookEdit';
import MSConnectorConfigContainer from '../../policyholders/connectors/MSConnectorConfigContainer';
import BulkUpload from '../../accounts/bulkUpload/BulkUpload';
import DeleteTag from '../../console/agencies/manage/tags/DeleteTag';
import ApplyChangeTags from './ApplyChangeTags';
import { SocialEngg } from './SocialEngg';
import SocialEnggReasons from './SocialEnggReasons';
import DownloadStatements from '../../agencies/commissions/DownloadStatements';
import KeyGenerated from '../../admin/apiKeys/KeyGenerated';
import RegenKey from '../../admin/apiKeys/RegenKey';
import DeleteKey from '../../admin/apiKeys/DeleteKey';
import DaCreateEdit from '../../admin/digital/DaCreateEdit';
import DaDeleteConfirm from '../../admin/digital/DaDeleteConfirm';
import StateDeleteConfirm from './StateDeleteConfirm';
import { SendQuoteToPolicyHolder } from '../../console/agencies/inbox/SendQuoteToPolicyHolder';
import SecurityCodeSent from './SecurityCodeSent';
import { UpdatePolicyHolder } from '../../public/quote/confirm/UpdatePolicyHolder';
import { UploadProfilePicture } from './UploadProfilePicture';
import SendToAccounting from '../../console/customers/policies/SendToAccounting';
import ACHWireTransfer from '../../console/customers/policies/ACHWireTransfer';
import SendAccountingConfirmation from '../../console/customers/policies/SendAccountingConfirmation';
import { UploadAgencyLogoSmall } from './UploadAgencyLogoSmall';
import { UploadAgencyLogoMedium } from './UploadAgencyLogoMedium';
import InstallmentPlan from '../../console/customers/policies/InstallmentPlan';
import { MarkCheckInMailConfirm } from './MarkCheckInMailConfirm';
import EditBrokerFee from './EditBrokerFee';
import EditMgaFee from './EditMgaFee';
import { SecurityQuestions } from './SecurityQuestions';
import { SecurityQuestionsV2 } from './SecurityQuestionsV2';
import { SecurityQuestionsReasons } from './SecurityQuestionsReasons';
import PrivacyConsent from './PrivacyConsent';
import SecurityStudioTokenForm from '../../policyholders/connectors/SecurityStudioTokenForm';
import { RequestQuote250Cart } from '../../console/agencies/quotes/250/RequestQuote250Cart';
import { RequestQuoteSimple250Cart } from '../../console/agencies/quotes/250/RequestQuoteSimple250Cart';
import { SecurityQuestionsUnansweredAlert } from './SecurityQuestionsUnansweredAlert';
import { ClosePremiumFinancedTransaction } from './ClosePremiumFinancedTransaction';
import { CreatePremiumFinancing } from './CreatePremiumFinancing';
import { UWUpdateQuoteConfirmation } from '../../console/agencies/quotes/250/underwriter/UWUpdateQuoteConfirmation';
import { UWDeclineQuote } from '../../console/agencies/quotes/250/underwriter/UWDeclineQuote';
import { UWUpdateRiskConfirmation } from '../../console/agencies/quotes/250/underwriter/UWUpdateRiskConfirmation';
import { UWCancelConfirmation } from '../../console/agencies/quotes/250/underwriter/UWCancelConfirmation';
import { UWResetConfirmation } from '../../console/agencies/quotes/250/underwriter/UWResetConfirmation';
import { UWReleaseQuoteConfirmation } from '../../console/agencies/quotes/250/underwriter/UWReleaseQuoteConfirmation';
import { UpdateTransaction } from './UpdateTransaction';
import { AddAccounts } from './AddAccounts';
import { AgencyUpdateAgent } from '../../accounts/agentUpdate/AgencyUpdateAgent';
import { AddAgencyToAggregator } from './AddAgencyToAggregator';
import { AddAgencyToCluster } from './AddAgencyToCluster';
import { DeleteBankAccountConfirmation } from '../../console/agencies/manage/profile/DeleteBankAccountConfirmation';
import { InvalidateQuotes } from './InvalidateQuotes';
import { InvalidateQuotesConfirm } from './InvalidateQuotesConfirm';
import { ApplyPayment } from './ApplyPayment';
import QualysForm from '../../policyholders/connectors/QualysForm';
import { AddAccountMSSP } from './AddAccountMSSP';
import { SendPaymentReminder } from './SendPaymentReminder';
import { SubmitForUWReview } from './SubmitForUWReview';
import { UWEventExclusionModal } from '../../console/agencies/quotes/250/underwriter/UWEventExclusionModal';
import { UWIrpmModal } from '../../console/agencies/quotes/250/underwriter/UWIRPMModal';
import { AdminToggleAgencyPayments } from './AdminToggleAgencyPayments';
import { CopyApplicationLink } from './CopyApplicationLink';
import { LostQuoteConfirm } from './LostQuoteConfirm';
import { NeedBy } from './NeedBy';
import { DeclineAccountConfirm } from './DeclineAccountConfirm';
import { AccountSyncLeads } from './AccountSyncLeads';
import certificateEmailConfirm from './certificateEmailConfirm';
import { DownloadEmailCertificate } from './DownloadEmailCertificate';
import { AgencyUploadDocumentModal } from '../fileUploader/AgencyUploadDocumentModal';
import { DeleteDocument } from '../fileUploader/useDeleteDocument';
import WizerTokenForm from '../../policyholders/connectors/WizerTokenForm';
import VideoPlayerModal from './videoPlayerModal';
import { UWSwitchingSurplus } from '../../console/agencies/quotes/250/underwriter/UWSwitchingSurplus';
import { NameInsuredAttestation } from '../../console/customers/quotes/NameInsuredAttestation';
import { FeedbackModal } from './feedback';
import FeedbackConfirm from './feedbackConfirm';
import RedirectRequote from './redirectRequote';
import { UWSwitchingTabs } from '../../console/agencies/quotes/250/underwriter/UWSwitchingTabs';
import { DeleteSurplusLicense } from '../../console/agencies/manage/licenses/DeleteSurplusLicense';
import { AddSurplusLicenses } from '../../console/agencies/manage/licenses/AddSurplusLicense';

const Modals = {
  OnDemandScan: {
    component: OnDemandScan,
  },
  FeedbackConfirm: {
    component: FeedbackConfirm,
  },
  FeedbackModal: {
    component: FeedbackModal,
  },
  RedirectRequote: {
    component: RedirectRequote,
  },
  DownloadEmailCertificate: {
    component: DownloadEmailCertificate,
  },
  WizerTokenForm: {
    component: WizerTokenForm,
    sticky: true,
  },
  UWSwitchingSurplus: {
    component: UWSwitchingSurplus,
    sticky: true,
  },
  UWSwitchingTabs: {
    component: UWSwitchingTabs,
    sticky: true,
  },
  DeleteDocument: {
    component: DeleteDocument,
    sticky: true,
  },
  AgencyUploadDocumentModal: {
    component: AgencyUploadDocumentModal,
    sticky: true,
  },
  UWIrpmModal: {
    component: UWIrpmModal,
    sticky: true,
  },
  UWEventExclusionModal: {
    component: UWEventExclusionModal,
    sticky: true,
  },
  QualysForm: {
    component: QualysForm,
    sticky: true,
  },
  DeleteBankAccountConfirmation: {
    component: DeleteBankAccountConfirmation,
    sticky: true,
  },
  UWReleaseQuoteConfirmation: {
    component: UWReleaseQuoteConfirmation,
    sticky: true,
  },
  UWResetConfirmation: {
    component: UWResetConfirmation,
    sticky: true,
  },
  UWCancelConfirmation: {
    component: UWCancelConfirmation,
    sticky: true,
  },
  UWUpdateRiskConfirmation: {
    component: UWUpdateRiskConfirmation,
    sticky: true,
  },
  UWDeclineQuote: {
    component: UWDeclineQuote,
    sticky: true,
  },
  UWUpdateQuoteConfirmation: {
    component: UWUpdateQuoteConfirmation,
    sticky: true,
  },
  SecurityStudioTokenForm: {
    component: SecurityStudioTokenForm,
  },
  EditBrokerFee: {
    component: EditBrokerFee,
    sticky: true,
  },
  PrivacyConsent: {
    component: PrivacyConsent,
    sticky: true,
  },
  InstallmentPlan: {
    component: InstallmentPlan,
    sticky: true,
  },
  SendAccountingConfirmation: {
    component: SendAccountingConfirmation,
    sticky: true,
  },
  ACHWireTransfer: {
    component: ACHWireTransfer,
    sticky: true,
  },
  SendToAccounting: {
    component: SendToAccounting,
    sticky: true,
  },
  DeleteKey: {
    component: DeleteKey,
    sticky: true,
  },
  RegenKey: {
    component: RegenKey,
    sticky: true,
  },
  KeyGenerated: {
    component: KeyGenerated,
    sticky: true,
  },
  DownloadStatements: {
    component: DownloadStatements,
    sticky: true,
  },
  ApplyChangeTags: {
    component: ApplyChangeTags,
    sticky: true,
  },
  DeleteTag: {
    component: DeleteTag,
    sticky: true,
  },
  BulkUpload: {
    component: BulkUpload,
    sticky: true,
  },
  WebHookCreate: {
    component: WebHookCreate,
    sticky: true,
  },
  WebHookEdit: {
    component: WebHookEdit,
    sticky: true,
  },
  WebHookDeleteConfirm: {
    component: WebHookDeleteConfirm,
    sticky: true,
  },
  AccountsCreateEdit: {
    component: AccountsCreateEdit,
    sticky: false,
  },
  AccountsDeleteConfirm: {
    component: AccountsDeleteConfirm,
    sticky: false,
  },
  BlackListCreate: {
    component: BlackListCreate,
    sticky: false,
  },
  BlackListDeleteConfirm: {
    component: BlackListDeleteConfirm,
    sticky: false,
  },
  NaicsCodeDeleteConfirm: {
    component: NaicsCodeDeleteConfirm,
    sticky: false,
  },
  StateDeleteConfirm: {
    component: StateDeleteConfirm,
    sticky: false,
  },
  UsersCreateEdit: {
    component: UsersCreateEdit,
    sticky: false,
  },
  VideoPlayerModal: {
    component: VideoPlayerModal,
    sticky: false,
  },
  UsersDeleteConfirm: {
    component: UsersDeleteConfirm,
    sticky: false,
  },
  certificateEmailConfirm: {
    component: certificateEmailConfirm,
    sticky: false,
  },
  SendQuoteToRetailAgent: {
    component: SendQuoteToRetailAgent,
    sticky: false,
  },
  SendQuoteToPolicyHolder: {
    component: SendQuoteToPolicyHolder,
    sticky: false,
  },
  RequestToBind: {
    component: RequestToBind,
    sticky: false,
  },
  DeleteQuote: {
    component: DeleteQuote,
    sticky: false,
  },
  AddContact: {
    component: AddContact,
    sticky: false,
  },
  UpdatePolicyHolder: {
    component: UpdatePolicyHolder,
    sticky: false,
  },
  SoftAssignAgency: {
    component: SoftAssignAgency,
    sticky: false,
  },
  HardAssignAgency: {
    component: HardAssignAgency,
    sticky: false,
  },
  AgencyUpdateAgent: {
    component: AgencyUpdateAgent,
    sticky: false,
  },
  AddAgencyToAggregator: {
    component: AddAgencyToAggregator,
    sticky: true,
  },
  AddAgencyToCluster: {
    component: AddAgencyToCluster,
    sticky: true,
  },
  MfaEnabled: {
    component: MfaEnabled,
    sticky: true,
  },

  SMSEnabled: {
    component: SMSEnabled,
    sticky: true,
  },

  AdminAccountsDeleteConfirm: {
    component: AdminAccountsDeleteConfirm,
    sticky: true,
  },
  RefreshFirmographic: {
    component: RefreshFirmographic,
    sticky: true,
  },
  SetMyAgentConfirm: {
    component: SetMyAgentConfirm,
    sticky: true,
  },
  RemoveMyAgent: {
    component: RemoveMyAgent,
    sticky: true,
  },
  ResetMfaConfirm: {
    component: ResetMfaConfirm,
    sticky: true,
  },
  DeleteInvalidConfirm: {
    component: DeleteInvalidConfirm,
    sticky: true,
  },
  DeleteExpiredConfirm: {
    component: DeleteExpiredConfirm,
    sticky: true,
  },
  AgencyLicenseCreateEdit: {
    component: AgencyLicenseCreateEdit,
    sticky: false,
  },
  AgentLicenseCreateEdit: {
    component: AgentLicenseCreateEdit,
    sticky: false,
  },
  AgentLicenseDeleteConfirm: {
    component: AgentLicenseDeleteConfirm,
    sticky: false,
  },
  MarkCheckInMailConfirm: {
    component: MarkCheckInMailConfirm,
    sticky: false,
  },
  AgencyLicenseDeleteConfirm: {
    component: AgencyLicenseDeleteConfirm,
    sticky: false,
  },
  DaDeleteConfirm: {
    component: DaDeleteConfirm,
    sticky: false,
  },
  DaCreateEdit: {
    component: DaCreateEdit,
    sticky: false,
  },
  TeamDeleteConfirm: {
    component: TeamDeleteConfirm,
    sticky: false,
  },
  NoDomainConfirmation: {
    component: NoDomainConfirmation,
    sticky: true,
  },
  ClaimCreateEdit: {
    component: ClaimCreateEdit,
    sticky: true,
  },
  ClaimDeleteConfirm: {
    component: ClaimDeleteConfirm,
    sticky: true,
  },
  MSConnectorConfigContainer: {
    component: MSConnectorConfigContainer,
    sticky: true,
  },
  SocialEngg: {
    component: SocialEngg,
    sticky: true,
  },
  SocialEnggReasons: {
    component: SocialEnggReasons,
    sticky: true,
  },
  SecurityCodeSent: {
    component: SecurityCodeSent,
    sticky: true,
  },
  UploadAgencyLogoSmall: {
    component: UploadAgencyLogoSmall,
    sticky: false,
  },
  UploadAgencyLogoMedium: {
    component: UploadAgencyLogoMedium,
    sticky: false,
  },
  UploadProfilePicture: {
    component: UploadProfilePicture,
    sticky: false,
  },
  SecurityQuestions: {
    component: SecurityQuestions,
  },
  SecurityQuestionsV2: {
    component: SecurityQuestionsV2,
  },
  SecurityQuestionsReasons: {
    component: SecurityQuestionsReasons,
  },
  RequestQuote250Cart: {
    component: RequestQuote250Cart,
  },
  RequestQuoteSimple250Cart: {
    component: RequestQuoteSimple250Cart,
  },
  SecurityQuestionsUnansweredAlert: {
    component: SecurityQuestionsUnansweredAlert,
  },
  ClosePremiumFinancedTransaction: {
    component: ClosePremiumFinancedTransaction,
  },
  CreatePremiumFinancing: {
    component: CreatePremiumFinancing,
  },
  UpdateTransaction: {
    component: UpdateTransaction,
  },
  AddAccounts: {
    component: AddAccounts,
  },
  InvalidateQuotes: {
    component: InvalidateQuotes,
  },
  InvalidateQuotesConfirm: {
    component: InvalidateQuotesConfirm,
  },
  ApplyPayment: {
    component: ApplyPayment,
  },
  EditMgaFee: {
    component: EditMgaFee,
  },
  AddAccountMSSP: {
    component: AddAccountMSSP,
  },
  AgencyCreateProfile: {
    component: AgencyCreateProfile,
  },
  SendPaymentReminder: {
    component: SendPaymentReminder,
  },
  SubmitForUWReview: {
    component: SubmitForUWReview,
  },
  GeneratePolicy: {
    component: GeneratePolicy,
  },
  GenerateExcessPolicy: {
    component: GenerateExcessPolicy,
  },
  AdminToggleAgencyPayments: {
    component: AdminToggleAgencyPayments,
  },
  CopyApplicationLink: {
    component: CopyApplicationLink,
  },
  LostQuoteConfirm: {
    component: LostQuoteConfirm,
  },
  NeedBy: {
    component: NeedBy,
  },
  DeclineAccountConfirm: {
    component: DeclineAccountConfirm,
  },
  AccountSyncLeads: {
    component: AccountSyncLeads,
  },
  NameInsuredAttestation: {
    component: NameInsuredAttestation,
  },
  DeleteSurplusLicense: {
    component: DeleteSurplusLicense,
  },
  AddSurplusLicenses: {
    component: AddSurplusLicenses,
  },
};

export const mappedModals = Object.keys(Modals).reduce(
  (acc, key) => ({
    ...acc,
    [key]: false,
  }),
  {}
);

export { default as Modal } from './GenericModal';
export default Modals;
