import type { NotificationPosition, NotificationType } from '../types';
import type { Variant } from '../../../components/snackbars/PaperSnackbar';

export function getPaperSnackbarVariant(type: NotificationType): Variant {
  return type;
}

export const DEFAULT_NOTI_POSITION =
  'bottom-right' satisfies NotificationPosition;

export function getSnackbarAnchorOriginFromPosition(
  position: NotificationPosition
) {
  const [vertical, horizontal] = position.split('-') as [
    'top' | 'bottom',
    'left' | 'center' | 'right'
  ];

  return {
    vertical,
    horizontal,
  };
}
