import React from 'react';
import moment from 'moment/moment';
import { useSnackbar } from 'notistack';

// components
import {
  Box,
  DialogActions,
  DialogContent,
  Radio,
  FormControlLabel,
  RadioGroup,
} from '@mui/material';

import { compose } from 'redux';

import { CertificateHolderForm } from './certificateHolder';
import { saveBlobFile } from '../../utils/appUtils';
import {
  downloadAWSDoc,
  downloadPolicyCertificate,
  fetchAdditonalInsured,
  sendPolicyCertificateEmail,
} from '../../policies/PolicyService';
import { withShowable } from '../../console/_global/lib/withShowable';
import CBButtonBase from '../Buttons/CbButton';

import { withToggleModal } from '../hocs/withToggleModal';
import { useToggleModal } from '../../utils/modal.utils';
import { useAPIErrorHandler } from '../hooks/useAPIErrorHandler';
import { Show } from '../Show';

const CBButton = withShowable(CBButtonBase);

export const DownloadEmailCertificate = compose(withToggleModal)(
  ({ data, close }) => {
    const { enqueueSnackbar } = useSnackbar();
    const handleAPIError = useAPIErrorHandler();
    const toggleModal = useToggleModal();

    const [additionalInsuredList, setAdditionalInsuredList] = React.useState(
      []
    );

    const [additionalInsuredId, setAdditonalInsuredID] = React.useState('');

    const downloadCertificate = () => {
      const { accountId, policyNumber } = data;
      const policyId = data.id;
      const name = data.companyName;
      const created = moment(data.created).unix();

      downloadPolicyCertificate(accountId, policyId, created)
        .then((response) => {
          const certificateURL = response.data;
          return downloadAWSDoc(certificateURL);
        })
        .then((resp) => {
          saveBlobFile(
            resp,
            `${name}-${policyNumber}-Cowbell-Policy-Certificate`,
            '.pdf'
          );

          close();
          enqueueSnackbar('Policy certificate successfully generated', {
            variant: 'success',
            autoHideDuration: 2000,
          });
        })
        .catch(
          handleAPIError(
            'An error occurred finding the document. Please try again'
          )
        );
    };

    const emailAdditionalInsured = () => {
      const payload = {
        docType: 'Additional_Insured',
        additionalInsuredId,
        policyId: data.id,
      };

      return sendPolicyCertificateEmail({ productType: data.product }, payload)
        .then(() => {
          toggleModal.direct('certificateEmailConfirm', data, {
            title: 'Generate Policy Certificate',
            footerButtons: [
              {
                buttonText: 'Okay',
                action: 'cancel',
                showLoading: true,
                showDisabled: true,
              },
            ],
          });
        })
        .catch(
          handleAPIError(
            'Unable to request sending of policy certificate email.'
          )
        );
    };

    React.useEffect(() => {
      fetchAdditonalInsured({ policyId: data.id })
        .then((response) => {
          if (response.data !== '') {
            setAdditionalInsuredList(response.data);
          }
        })
        .catch(handleAPIError('Unable to fetch additional insured info.'));
      // eslint-disable-next-line
    }, []);

    const [option, setOption] = React.useState('downloadCertificate');
    // eslint-disable-next-line no-restricted-globals
    const additionalInsuredSelected = !isNaN(option);

    const handleChange = (event) => {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(event.target.value)) {
        setAdditonalInsuredID(event.target.value);
      }

      setOption(event.target.value);
    };

    return (
      <>
        <DialogContent>
          <RadioGroup
            aria-label="actions"
            name="certificate-action"
            value={option}
            onChange={handleChange}
          >
            <FormControlLabel
              label="Download Certificate of Insurance"
              value="downloadCertificate"
              control={<Radio color="secondary" />}
              size="large"
              sx={formControlLabelStyles}
            />

            <>
              {additionalInsuredList.map((insured) => (
                <FormControlLabel
                  key={insured.businessName}
                  label={`ADDITONAL INSURED ${insured.businessName} (${insured.address1}, ${insured.city}, ${insured.state}, ${insured.zipCode})`}
                  value={`${insured.id}`}
                  control={<Radio color="secondary" />}
                  sx={formControlLabelStyles}
                />
              ))}
            </>

            <FormControlLabel
              label="Certificate Holder"
              value="Certificate_Holder"
              control={<Radio color="secondary" />}
              sx={formControlLabelStyles}
            />
          </RadioGroup>

          <Box sx={{ padding: '0 2rem' }}>
            <CertificateHolderForm
              data={data}
              docType={option}
              show={option === 'Certificate_Holder'}
            />
          </Box>

          <Show when={option == 'Certificate_Holder'}>
            <Box
              component="p"
              className="tertia-text contrast-text"
              style={{ textAlign: 'center', fontStyle: 'italic' }}
            >
              Note: By choosing this option, you will not be adding the
              certificate holder as an additional insured on the policy.
            </Box>
          </Show>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <CBButton size="medium" styleName="cancel" onClick={close}>
            Cancel
          </CBButton>

          <CBButton
            show={option === 'downloadCertificate'}
            styleName="ctaButton"
            size="medium"
            onClick={downloadCertificate}
          >
            Download
          </CBButton>

          <CBButton
            show={additionalInsuredSelected}
            styleName="ctaButton"
            size="medium"
            onClick={emailAdditionalInsured}
          >
            Email me
          </CBButton>

          <CBButton
            type="submit"
            form="certificateHolderForm"
            show={option === 'Certificate_Holder'}
            styleName="ctaButton"
            size="medium"
          >
            Email me
          </CBButton>
        </DialogActions>
      </>
    );
  }
);

export default DownloadEmailCertificate;

const formControlLabelStyles = {
  '& .MuiFormControlLabel-label': { fontSize: '1.2rem' },
};
