import { useFeature } from '@growthbook/growthbook-react';
import { FEATURE_FLAG_KEYS } from '../../config/growthbook';
import { getPlatformRegion } from '../../utils';
import { languages } from '../../i18n/i18n.language-config';

type UseIsMaintenanceModeActive = () => boolean;

const useIsMaintenanceModeActive: UseIsMaintenanceModeActive = () => {
  const appRegion = getPlatformRegion();
  const isAppRegionUS = appRegion === languages['en-US'];
  const isAppRegionUK = appRegion === languages['en-GB'];

  // general (affects all regions)
  const maintenanceModeFeature = useFeature(FEATURE_FLAG_KEYS.MAINTENANCE_MODE);

  // region-specific
  const maintenanceModeUSFeature = useFeature(
    FEATURE_FLAG_KEYS.MAINTENANCE_MODE_US
  );
  const maintenanceModeUKFeature = useFeature(
    FEATURE_FLAG_KEYS.MAINTENANCE_MODE_UK
  );

  return (
    maintenanceModeFeature.on ||
    (isAppRegionUS && maintenanceModeUSFeature.on) ||
    (isAppRegionUK && maintenanceModeUKFeature.on)
  );
};

export default useIsMaintenanceModeActive;
