import React, { Component } from 'react';
import { withSnackbar } from 'notistack';

import { compose } from 'redux';
import { DialogContent, FormHelperText } from '@mui/material';
import { PubSub } from '../../utils/eventUtils';
import { onDemandScan } from '../../accounts/AccountService';

import { withQueryClient } from '../hocs/withQueryClient';
import { withToggleModal } from '../hocs/withToggleModal';
import { manageAPIError } from '../../utils';
import { queries } from '../../queries';

class OnDemandScan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountName: '',
      accountId: '',
      error: '',
    };
  }

  componentDidMount() {
    const {
      props: { data },
    } = this;
    this.setState({
      accountName: data.name,
      accountId: data.id,
    });

    this.subscription = PubSub.subscribe('foot-button-one', (subData) => {
      if (subData) {
        this.handleSubmit();
      }
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  handleSubmit = () => {
    const { queryClient } = this.props;

    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);

    onDemandScan(this.state.accountId)
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.props.toggleModal.direct('OnDemandScan', false);
        this.props.enqueueSnackbar('Data Scanned Successfully!', {
          variant: 'success',
        });

        queryClient.invalidateQueries(queries.accounts.list._def);
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.setState({ error: manageAPIError(error) });
      });
  };

  render() {
    return (
      <DialogContent>
        <div className="modal-title">
          Are you sure you want to do a On-Demand scan data for
          <span className="modal-blue-text"> {this.state.accountName}</span>?
        </div>
        <FormHelperText className="api-text-error">
          {this.state.error}
        </FormHelperText>
      </DialogContent>
    );
  }
}

OnDemandScan.defaultProps = {
  data: {},
};

export default compose(
  withSnackbar,
  withQueryClient,
  withToggleModal
)(OnDemandScan);
