import { useTranslation } from 'react-i18next';
import type { ComponentType } from 'react';
import type { NS } from '../i18n';
import i18n from '../i18n';

export const useCowbellTranslations = (...nameSpace: Array<NS>) => {
  const param = nameSpace?.length > 0 ? nameSpace : (['common'] as const);
  const nameSpaces = [...param] as const;
  return useTranslation(nameSpaces);
};

export const withCowbellTranslations = <P extends object>(
  Component: ComponentType<P>
) => {
  return (props: P) => {
    const cowbellTranslations = useTranslation();

    return <Component {...props} {...cowbellTranslations} />;
  };
};

export const getCowbellTranslations = () => ({
  t: i18n.t,
  i18n,
});
