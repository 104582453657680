import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// services
// mui
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
// utils
import { delayedEvent } from '../../utils/eventUtils';
// components
import CbButton from '../Buttons/CbButton';
import { iconMappings } from '../../console/_global/navigation/IconsMappings';
import { deleteAgentLicense } from '../../console/agencies/_services/licenses.service';

export const AgentLicenseDeleteConfirm = ({ data: { id, number }, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = () => {
    deleteAgentLicense({ licenseId: id })
      .then(() => {
        close();
        enqueueSnackbar(`License ${number} Removed Successfully!`, {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, 'agent_license');
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Cannot add license at this time.Please try again later.'
            )
          )
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <div
          className="modal-title"
          style={{ fontWeight: 'normal', paddingTop: '4.58rem' }}
        >
          Are you sure you want to remove the following license{' '}
          <span className="modal-blue-text">{number}</span>?
        </div>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <CbButton buttonText="Cancel" styleName="cancel" onClick={close} />
        <CbButton
          buttonText="Delete License"
          styleName="ctaButton"
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </DialogActions>
    </form>
  );
};

export const AgentLicenseDeleteConfirmConfig = {
  AgentLicenseDeleteConfirm: {
    component: AgentLicenseDeleteConfirm,
    config: {
      maxWidth: 'sm',
      fullWidth: true,
      PaperProps: { style: { overflow: 'visible' } },
      icon: iconMappings.Delete.iconImport,
    },
  },
};
