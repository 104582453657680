import LogRocket from 'logrocket';
import { ENVIRONMENTS } from '../../types/common/env.dto';
import { getEnvironment } from '../../api/AuthService';

export async function initializeLogRocket() {
  try {
    const envResp = await getEnvironment();
    if (envResp.data?.envName == ENVIRONMENTS.STAGING_ENV_ID) {
      LogRocket.init('i0bntq/cowbell');
    }
  } catch (err) {
    console.error('LogRocket failed to initialize');
    console.error(err);
  }
}
