import { Api } from '../config/axiosConfig';
import {
  formatFinalParams,
  ServiceHandlerFactory,
} from '../utils/serviceUtils';
import { fetchUserParamsMapping } from '../mappings/services/userServiceMapping';
import { ServiceHandler } from '../utils';

export function fetchCurrentUser() {
  return Api.get('/api/auth/v1/me');
}

export const getMe = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/me',
  },
});

export const disableEnableUserPrivileges = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/users/enable/:email?enable=:toggle&isSecurityConcern=:isSecurityConcern',
    method: 'PUT',
  },
});

export function RequestDeleteUser(userId) {
  return Api.delete(`/api/auth/v1/users/${userId}`);
}

//  user releated apis
export function fetchUsers(params) {
  const finalParams = formatFinalParams(fetchUserParamsMapping, params);
  if (Number.isNaN(finalParams.page)) finalParams.page = 0;
  if (Number.isNaN(finalParams.size)) finalParams.size = 25;
  return Api.get('/api/auth/v1/users', {
    params: finalParams,
  });
}

//  user releated api
export function fetchUsersForLookup() {
  const params = {
    page: 0,
    size: 1000,
  };
  return Api.get('/api/auth/v1/users', {
    params,
  });
}

export const getAllUsers = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/users/all',
  },
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'accountType':
          case 'name':
            if (orderBy && order) {
              return `account.${orderBy},${order}`;
            }
            break;
          case 'created':
          case 'modified':
          case 'lastLogin':
          case 'primaryContact':
          case 'mfaType':
          case 'npn':
          case 'firstName':
          case 'lastName':
          case 'email':
          case 'role.name':
            if (orderBy && order) {
              return `${orderBy},${order}`;
            }
            break;
          default:
            if (orderBy && order) {
              return `${orderBy}.keyword,${order}`;
            }
            return null;
        }
      },
    },
  ],
});

export const getAllAgencyTeams = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/teams/agencies',
  },
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'account':
            if (orderBy && order) {
              return `${orderBy}.name,${order}`;
            }
            break;
          case 'modified':
            return null;
          default:
            if (orderBy && order) {
              return `${orderBy},${order}`;
            }
            return null;
        }
      },
    },
  ],
});

/**
 * @deprecated
 * @replacedBy "getUsers" in /src/api/users/users.api.ts
 */
export const getUsers = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/users',
  },
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'created':
          case 'modified':
          case 'lastLogin':
          case 'primaryContact':
          case 'mfaType':
          case 'npn':
          case 'firstName':
          case 'lastName':
          case 'email':
          case 'slackHandle':
          case 'role.name':
          case 'passwordResetDate':
          case 'enabled':
            if (orderBy && order) {
              return `${orderBy},${order}`;
            }
            break;
          default:
            if (orderBy && order) {
              return `${orderBy}.keyword,${order}`;
            }
            return null;
        }
      },
    },
  ],
});

export function countUndefinedRoles() {
  return Api.get(`/api/auth/v1/users/unassigned`);
}

export function requestUser(userId) {
  return Api.get(`/api/auth/v1/users/${userId}`);
}

export function createUser(user) {
  return Api.post('/api/auth/v1/users', user);
}

export function updateUser(userId, user) {
  return Api.put(`/api/auth/v1/users/${userId}`, user);
}

export const updateAgencyUser = ServiceHandler({
  url: '/api/auth/v1/users/:userId',
  method: 'PUT',
});

export function fetchRoles(accountType) {
  return Api.get(`/api/auth/v1/roles/accounttype/${accountType}`);
}
export function fetchTeams() {
  return Api.get(`/api/auth/v1/teams/all`);
}
export function fetchTams() {
  return Api.get(`/api/auth/v1/users/tams`);
}
export const fetchTamsV2 = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/users/tams',
  },
});

export function fetchBds() {
  return Api.get(`/api/auth/v1/users/bds`);
}

export const fetchBdsV2 = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/users/bds',
  },
});

export function assignUser(userId, accountIds) {
  return Api.put(`/api/auth/v1/users/assign/${userId}`, accountIds);
}

export function resendInvite(userDetails) {
  return Api.post(`/api/auth/v1/resendUserInvitationEmail `, userDetails);
}

export function addExternalAccounts(userEmail, payload) {
  return Api.put(`/api/auth/v1/users/add-accounts/${userEmail}`, payload);
}

export function getUserAccountsById(userEmail) {
  return Api.get(`/api/auth/v1/users/get-accounts/${userEmail}`);
}

export function getMyAccounts() {
  return Api.get('/api/auth/v1/users/my-accounts');
}

export function getAccountTempToken(accountId) {
  return Api.post(`/api/auth/v1/accounts/${accountId}/myToken`);
}

export const getAgents = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/users/fellow-users',
  },
});

export const getAgentsInAdmin = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/users/agency',
  },
});

export const getCowbellAccountDetails = ServiceHandlerFactory({
  axios: {
    url: `/api/auth/v1`,
  },
});

export const getAccountExecutives = ServiceHandlerFactory({
  axios: {
    url: `/api/auth/v1/users/aes?size=100`,
  },
});

export const getAllUnderWriters = ServiceHandlerFactory({
  axios: {
    url: `/api/auth/v1/users/uw?size=100`,
  },
});

export const getAllPlatformAgents = ServiceHandlerFactory({
  axios: {
    url: ` /api/auth/v1/users/agents`,
  },
});

export const getAllAgentAgencies = ServiceHandlerFactory({
  axios: {
    url: `/api/auth/v1/users/children?`,
  },
  paramSchema: [{ path: 'username' }],
});
