import _ from 'lodash';
import {
  FORMAT_DATE_AND_TIME,
  toUniversalDate,
  toUniversalUtcDate,
} from './date.utils';

export const prepFileContent = (data, columns) => {
  const tableDataString = mapTableDataWithDataMap(data, columns).join('\n');
  const headerDataString = columns
    .filter(
      (col) =>
        (!!col.label || !!col.title) &&
        (col.show === undefined || col.show) &&
        !col.ommitInCsv
    )
    .map((obj) => obj.label || obj.title)
    .join(',');
  return [headerDataString, '\n', tableDataString];
};
export const mapTableDataWithDataMap = (data, columns) => {
  return data.map((row) =>
    columns
      .filter(
        (col) =>
          (!!col.label || !!col.title) &&
          (col.show === undefined || col.show) &&
          !col.ommitInCsv
      )
      .map((column) => {
        let content = _.get(row, column.name);
        if (typeof column.csv === 'function') {
          content = column.csv(row);
        }

        if (!content) {
          return '';
        }

        // enclose cell value with double quotes and escape any double quotes in the value
        return `"${content.toString().replace('"', '""')}"`;
      })
      .join(',')
  );
};
export const startDownload = ({ data, columns, filename }) => {
  return new Promise((resolve) => {
    const fileContentArray = prepFileContent(data, columns);
    const csvFile = new Blob(fileContentArray, { type: 'text/csv' });
    resolve({ csvFile, filename });
  });
};
export const handleDownload = ({ csvFile, filename }) => {
  // Download link
  const downloadLink = document.createElement('a');
  // Make sure that the link is not displayed
  downloadLink.style.display = 'none';
  // File name
  downloadLink.download = filename;
  // We have to create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);
  // Add the link to your DOM
  document.body.appendChild(downloadLink);
  // Lanzamos
  downloadLink.click();
};
export const getShortDateString = (date) => {
  return toUniversalDate(date, { placeholder: '' });
};
export const getShortDateTimeFromUtc = (date) => {
  return toUniversalUtcDate(date, { placeholder: '' });
};
export const getDateString = (date) => {
  return toUniversalUtcDate(date, {
    format: FORMAT_DATE_AND_TIME,
    placeholder: '',
  });
};
