import type { BasePolicyDto } from '../../../../../types/policy/policy.dto';
import type { P100QuoteDetailsDto } from '../../../../../types/quotes/details/p100-quote-details.dto';
import type {
  AdditionalInsureds,
  GlobalPropertyKeys,
  Prime100Coverages,
  SocialEngQuestions,
} from '../types';
import type { Prime100CoverageKeys } from './constants';

export interface EligibleCoverageValuesResponse {
  hardwareReplCostEndorsement: {
    isAvailable: boolean;
    default: number;
    limits: number[];
  };
  websiteMediaContentLiabilityEndorsement: {
    isAvailable: boolean;
    default: number;
    limits: number[];
  };
  computerFundsTransfer: {
    isAvailable: boolean;
    default: number;
  };
  retroactivePeriod: {
    default: number;
    values: number[];
  };
  deductible: {
    default: number;
    values: number[];
  };
  waitingPeriod: {
    default: number;
    values: number[];
  };
  limit: {
    default: number;
    values: number[];
  };
  businessIncomeCoverage: {
    default: number;
    values: number[];
  };
  socialEngEndorsement: {
    isAvailable: boolean;
    deductibles: number[];
    limitDefault: number;
    deductibleDefault: number;
    limits: number[];
  };
  extortionThreatsAndRansomPayment: {
    isAvailable: boolean;
    default: number;
    limits: number[];
  };
  telecomsFraudEndorsement: {
    isAvailable: boolean;
    default: number;
    limits: number[];
  };
  postBreachRemediationEndorsement: {
    isAvailable: boolean;
    default: number;
    limits: number[];
  };
}

export type TransformedEligibleCoverageValuesResponse = {
  [K in Prime100CoverageKeys | GlobalPropertyKeys]: {
    default: number;
    values: number[];
  };
};

export interface SocialEngineeringResponse {
  isAuthenticatingFundTransferRequests: boolean;
  reason: string[];
  isVerifyingBankAccounts: boolean;
  isPreventingUnauthorizedWireTransfers: boolean;
  excludedFromSE: boolean;
}

/* Start Cart API types */
export interface QuoteCartListResponse {
  content: CartItem[];
  totalElements: number;
}

export type CartItem = {
  accountId: string;
  agencyId: string;
  id: string;
  quoteNumber: string;
  calculatorGeneratedData: CalculatorGeneratedData;
};

type CalculatorGeneratedData = {
  agencyId: string;
  accountId: string;
  policyId: string | null;
  limit: string;
  deductible: string;
  waitingPeriod: number;
  retroactivePeriod: number;
  premium: string;
  coverageExclusion: string | null;
  businessIncomeCoverage: number;
  computerFraudEndorsement: boolean;
  effectiveDate: string;
  endDate: string;
  socialEngEndorsement: boolean;
  socialEngLimit: number;
  socialEngDeductible: number;
  ransomPaymentEndorsement: boolean;
  ransomPaymentLimit: number;
  hardwareReplCostEndorsement: boolean;
  hardwareReplCostSubLimit: number;
  telecomsFraudEndorsement: boolean;
  telecomsFraudSubLimit: number;
  postBreachRemediationEndorsement: boolean;
  postBreachRemediationSubLimit: number;
  websiteMediaContentLiabilityEndorsement: boolean;
  websiteMediaContentLiabilitySubLimit: number;
  additionalInsureds: string[];
  extortionThreatsLimitDetail: string;
  pciFinesAndPenaltiesLimit: number;
  regulatorDefenceAndPenaltiesLimit: number;
};
/* End Cart Api types */

export interface BoostRaterVersionPayload {
  effectiveDate: string;
  state: string;
  isRenewal: boolean;
}

export enum BoostRaterVersionResponse {
  V4 = 'V4',
  V5 = 'V5',
}

export type GetPrime100CoveragesResponse = Record<
  Prime100Coverages,
  CoverageDetails
>;

export type CoverageDetails = {
  name: string;
  tooltip: string;
  errorMessage: string;
  ordinal: number;
};

export interface CalculateP100PricingRequest {
  naicsCode: number;
  revenue: number;
  numberOfEmployees: number;
  companyType: string;
  state: string;
  zipCode: string;
  aggregateLimit: number;
  deductible: number;
  waitingPeriod: number;
  businessIncomeCoverage: number;
  socialEngLimit?: number;
  socialEngDeductible?: number;
  ransomPaymentLimit?: number;
  postBreachRemediationSubLimit?: number;
  hardwareReplCostSubLimit?: number;
  telecomsFraudSubLimit?: number;
  excludedFromSE?: boolean;
  computerFraudEndorsement?: boolean;
  socialEngEndorsement?: boolean;
  postBreachRemediationEndorsement?: boolean;
  hardwareReplCostEndorsement?: boolean;
  telecomsFraudEndorsement?: boolean;
  questionEncryption: boolean;
  questionLeadership: boolean;
  questionCloud: boolean;
  questionTraining: boolean;
  isVerifyingBankAccounts?: boolean;
  isAuthenticatingFundTransferRequests?: boolean;
  isPreventingUnauthorizedWireTransfers?: boolean;
  websiteMediaContentLiabilityEndorsement?: boolean;
  extortionThreatsAndRansomPayment?: boolean;
  websiteMediaContentLiabilitySubLimit?: number;
  effectiveDate: string;
  endDate?: string;
  claimHistory: number;
  yearEstablished: number;
  additionalInsureds?: AdditionalInsureds[];
  retroactivePeriod: number;
  additionalBrokerFee?: number;
  isRenewal?: boolean;
  policyId?: string;
}

export interface CalculateP100PricingResponse {
  additionalBrokerFee: number;
  brokerFee: number;
  carrierPrefix: string;
  premium: number;
  totalPremium: number;
}

export interface UpdateSocialEngineeringPayload {
  accountId: string;
  isAuthenticatingFundTransferRequests: boolean;
  isPreventingUnauthorizedWireTransfers: boolean;
  isVerifyingBankAccounts: boolean;
}

export interface UpdateSocialEngineeringResponse {
  socialEngineering: boolean;
  reason?: SocialEngQuestions[];
}

export interface AddQuoteToCartRequest {
  agencyId?: string; // when called from Cowbell persona
  accountId: string;
  policyId?: string;
  limit: number;
  deductible: number;
  waitingPeriod: number;
  retroactivePeriod: number;
  premium: number;
  coverageExclusion?: string;
  businessIncomeCoverage: number;
  computerFraudEndorsement?: boolean;
  effectiveDate: string;
  endDate: string;
  socialEngEndorsement?: boolean;
  socialEngLimit?: number;
  socialEngDeductible?: number;
  ransomPaymentEndorsement?: boolean;
  ransomPaymentLimit?: number;
  hardwareReplCostEndorsement?: boolean;
  hardwareReplCostSubLimit?: number;
  telecomsFraudEndorsement?: boolean;
  telecomsFraudSubLimit?: number;
  postBreachRemediationEndorsement?: boolean;
  postBreachRemediationSubLimit?: number;
  websiteMediaContentLiabilityEndorsement?: boolean;
  websiteMediaContentLiabilitySubLimit?: number;
  additionalInsureds?: AdditionalInsureds[];
  extortionThreatsLimitDetail?: string;
  pciFinesAndPenaltiesLimit?: number;
  regulatorDefenceAndPenaltiesLimit?: number;
}

export interface AddQuoteToCartResponse {
  id: string;
  message: string;
}

export interface BulkRequestQuotesRequest {
  accountId: string;
  agencyId?: string;
  agentEmail: string;
  agentFirstName: string;
  agentLastName: string;
  agentPhone: string;
  quoteIds: string[];
  teamIds?: string[];
}

export interface Prime100QuoteDetailsResponse extends P100QuoteDetailsDto {
  coverages: {
    name: Prime100Coverages;
    isSelected: boolean;
    limit: string | number;
    deductible: string | number;
    payload?: any;
  }[];
}

export type EditPrime100QuoteRequest = AddQuoteToCartRequest;

export type Prime100PolicyDetailsResponse = BasePolicyDto;

export interface RequestQuoteRequest extends AddQuoteToCartRequest {
  teamIds: string[];
}
