import { NO_DECIMAL_FORMAT, deriveCurrencyFormat } from '../i18n/currencies';
import { getPlatformRegion } from '../utils';
import type { AgencyLevelListingTableRow, RevenueBand } from './accounts.api';

export const transformAgencyLevelResponse = (data: any) => {
  const region = getPlatformRegion();
  const content = data.data.content.map((row: AgencyLevelListingTableRow) => {
    const ui_revenueBands = row.qualifiers.map((revenueBand: RevenueBand) => ({
      ...revenueBand,
      minRevenue: deriveCurrencyFormat(
        region,
        revenueBand.minRevenue,
        NO_DECIMAL_FORMAT
      ),
      maxRevenue: deriveCurrencyFormat(
        region,
        revenueBand.maxRevenue,
        NO_DECIMAL_FORMAT
      ),
    }));

    return {
      ...row,
      ui_revenueBands,
    };
  });
  return {
    ...data.data,
    content,
  };
};
