import React from 'react';
import type { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup as RadioGroupBase,
} from '@mui/material';
import { InputLabelBase } from '../../../../../../components/inputs/InputLabelBase';
import Showable from '../../../../../../components/Showable';
import type {
  DynamicQuestion,
  Question,
} from '../../securityQuestionsSchema/securityQuestions.statics';
import { QuestionTypes } from '../../securityQuestionsSchema/securityQuestions.statics';
import { withFormController } from '../../../../../../components/hocs/forms';
import { TextAreaBase } from '../../../../../../components/inputs/TextAreaBase';

const TextArea = withFormController(TextAreaBase);
const RadioGroup = withFormController(RadioGroupBase);

//BASE INPUTS

interface SingleSelectProps {
  question: Question;
  isReadOnly: boolean;
}

export const SingleSelect = ({ question, isReadOnly }: SingleSelectProps) => {
  const { watch, formState } = useFormContext();
  const formValue = watch(question.name);

  const answers = watch(question?.show?.fields || []);
  const shouldShow = question?.show?.shouldShow?.(answers);

  if (question?.show && !shouldShow) {
    return null;
  }

  return (
    <>
      <Showable show={Boolean(question.questionHeading)}>
        <strong className="tertia-prime-text contrast-text">
          {question.questionHeading}
        </strong>
      </Showable>

      <Showable show={Boolean(question.topLevelQuestion)}>
        {question.topLevelQuestion?.({})}
      </Showable>

      <FormControl
        variant="standard"
        component="fieldset"
        style={{ display: 'block' }}
      >
        <div style={question.questionGroupStyle} className="tertia-text">
          {question.label({
            inputLabelProps: {
              required: question.required,
            },
          })}

          <RadioGroup name={question.name} style={question.answerGroupStyle}>
            {question.answers.map((answer, index) => (
              <FormControlLabel
                key={index}
                disabled={isReadOnly}
                control={<Radio color="secondary" />}
                label={answer.label}
                value={`${answer.value}`}
              />
            ))}
          </RadioGroup>
        </div>
      </FormControl>

      <Showable show={!!formState.errors[question.name]}>
        <FormHelperText
          style={{ marginTop: -10, ...question.questionGroupStyle }}
        >
          {(formState.errors?.[question.name]?.message as string) ?? ''}
        </FormHelperText>
      </Showable>

      <DynamicQuestions question={question} isReadOnly={isReadOnly} />

      <CommentsField
        question={question}
        formValue={formValue}
        isReadOnly={isReadOnly}
      />
    </>
  );
};

interface MultiSelectProps {
  question: Question;
  isReadOnly: boolean;
}

export const MultiSelect = ({ question, isReadOnly }: MultiSelectProps) => {
  const { watch, formState, control } = useFormContext();
  const formValue = watch(question.name);

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    if (checked) {
      field.onChange([...field.value, event.target.value]);
    } else {
      field.onChange(
        field.value.filter((value: any) => value !== event.target.value)
      );
    }
  };

  const answers = watch(question?.show?.fields || []);
  const shouldShow = question?.show?.shouldShow?.(answers);

  if (question?.show && !shouldShow) {
    return null;
  }

  return (
    <>
      <Showable show={Boolean(question.questionHeading)}>
        <strong className="tertia-prime-text contrast-text">
          {question.questionHeading}
        </strong>
      </Showable>

      <Showable show={Boolean(question.topLevelQuestion)}>
        {question.topLevelQuestion?.({})}
      </Showable>

      <div style={question.questionGroupStyle} className="tertia-text">
        <FormControl component="fieldset" variant="standard">
          {question.label({
            inputLabelProps: {
              required: question.required,
            },
          })}
        </FormControl>
        <FormGroup>
          <Controller
            name={question.name}
            control={control}
            render={({ field }) => (
              <>
                {question.answers.map(
                  (
                    answer: {
                      label: string;
                      value: number | string | boolean;
                      tooltip?: string;
                    },
                    index: number
                  ) => (
                    <FormControlLabel
                      key={index}
                      label={answer.label}
                      control={
                        <Checkbox
                          value={answer.value}
                          disabled={isReadOnly}
                          checked={field.value?.some(
                            (existingValue: string) =>
                              existingValue === answer.value
                          )}
                          onChange={(event, checked) =>
                            handleCheckbox(event, checked, field)
                          }
                          color="secondary"
                        />
                      }
                    />
                  )
                )}
              </>
            )}
          />
        </FormGroup>

        <Showable show={!!formState.errors[question.name]}>
          <FormHelperText
            style={{ marginTop: -10, ...question.questionGroupStyle }}
          >
            {(formState.errors?.[question.name]?.message as string) ?? ''}
          </FormHelperText>
        </Showable>

        <CommentsField
          question={question}
          formValue={formValue}
          isReadOnly={isReadOnly}
        />
      </div>
    </>
  );
};

interface TextInputProps {
  question: Question;
  isReadOnly: boolean;
}

export const TextInput = ({ question, isReadOnly }: TextInputProps) => {
  const { watch, formState } = useFormContext();
  const formValue = watch(question.name);

  const answers = watch(question?.show?.fields || []);
  const shouldShow = question?.show?.shouldShow?.(answers);

  if (question?.show && !shouldShow) {
    return null;
  }

  return (
    <>
      <Showable show={Boolean(question.questionHeading)}>
        <strong className="tertia-prime-text contrast-text">
          {question.questionHeading}
        </strong>
      </Showable>

      <Showable show={Boolean(question.topLevelQuestion)}>
        {question.topLevelQuestion?.({})}
      </Showable>

      <div style={question.questionGroupStyle} className="tertia-text">
        {question.label({
          inputLabelProps: {
            required: question.required,
          },
        })}

        <TextArea
          name={question.name}
          placeholder={question.placeholder}
          minRows={3}
          fullWidth
          blueBackground={false}
          disabled={isReadOnly}
          style={question.answerGroupStyle}
        />
      </div>

      <Showable show={!!formState.errors[question.name]}>
        <FormHelperText>
          {(formState.errors?.[question.name]?.message as string) ?? ''}
        </FormHelperText>
      </Showable>

      <DynamicQuestions question={question} isReadOnly={isReadOnly} />

      <CommentsField
        question={question}
        formValue={formValue}
        isReadOnly={isReadOnly}
      />
    </>
  );
};

//DYNAMIC INPUTS

interface DynamicSingleSelectProps {
  question: DynamicQuestion;
  isReadOnly: boolean;
}

const DynamicSingleSelect = ({
  question,
  isReadOnly,
}: DynamicSingleSelectProps) => {
  const { watch, formState } = useFormContext();
  const formValue = watch(question.show.fieldName);
  const dynamicField = watch(question.name);

  const customEvaluation = question.show.is
    ? formValue === question.show.is
    : formValue !== question.show.isNot;

  if (typeof formValue !== 'undefined' && customEvaluation) {
    return (
      <Fade in>
        <div>
          <FormControl
            variant="standard"
            component="fieldset"
            style={{ display: 'block' }}
          >
            <div style={question.questionGroupStyle} className="tertia-text">
              {question.label({
                inputLabelProps: {
                  required: question.required,
                },
              })}

              <RadioGroup
                name={question.name}
                style={question.answerGroupStyle}
              >
                {question.answers.map((answer, index) => (
                  <FormControlLabel
                    key={index}
                    disabled={isReadOnly}
                    control={<Radio color="secondary" />}
                    label={answer.label}
                    value={`${answer.value}`}
                  />
                ))}
              </RadioGroup>
            </div>
          </FormControl>
          <div>
            <Showable show={!!formState.errors[question.name]}>
              <FormHelperText
                style={{ marginTop: -10, ...question.questionGroupStyle }}
              >
                {(formState.errors?.[question.name]?.message as string) ?? ''}
              </FormHelperText>
            </Showable>

            <CommentsField
              question={question}
              formValue={dynamicField}
              isReadOnly={isReadOnly}
            />
          </div>
        </div>
      </Fade>
    );
  }
  return null;
};

interface DynamicTextFieldProps {
  question: DynamicQuestion;
  isReadOnly: boolean;
}

const DynamicTextField = ({ question, isReadOnly }: DynamicTextFieldProps) => {
  const { watch, formState } = useFormContext();
  const formValue = watch(question?.show?.fieldName);
  const dynamicField = watch(question.name);

  const customEvaluation = question.show.is
    ? formValue === question.show.is
    : formValue !== question.show.isNot;

  if (typeof formValue !== 'undefined' && customEvaluation) {
    return (
      <Fade in>
        <div style={question.questionGroupStyle} className="tertia-text">
          {question.label({
            inputLabelProps: {
              required: question.required,
            },
          })}

          <TextArea
            name={question.name}
            placeholder={question.placeholder}
            minRows={3}
            style={question.answerGroupStyle}
            fullWidth
            blueBackground={false}
            disabled={isReadOnly}
          />

          <Showable show={!!formState.errors[question.name]}>
            <FormHelperText>
              {(formState.errors?.[question.name]?.message as string) ?? ''}
            </FormHelperText>
          </Showable>

          <CommentsField
            question={question}
            formValue={dynamicField}
            isReadOnly={isReadOnly}
          />
        </div>
      </Fade>
    );
  }

  return null;
};

interface DynamicMultiSelectProps {
  question: DynamicQuestion;
  isReadOnly: boolean;
}

const DynamicMultiSelect = ({
  question,
  isReadOnly,
}: DynamicMultiSelectProps) => {
  const { watch, formState, control } = useFormContext();
  const formValue = watch(question?.show?.fieldName);
  const dynamicField = watch(question.name);

  const customEvaluation = question.show.is
    ? formValue === question.show.is
    : formValue !== question.show.isNot;

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    if (checked) {
      field.onChange([...field.value, event.target.value]);
    } else {
      field.onChange(
        field.value.filter((value: any) => value !== event.target.value)
      );
    }
  };

  if (typeof formValue !== 'undefined' && customEvaluation) {
    return (
      <Fade in>
        <div style={question.questionGroupStyle} className="tertia-text">
          <FormControl component="fieldset" variant="standard">
            {question.label({
              inputLabelProps: {
                required: question.required,
              },
            })}
          </FormControl>
          <FormGroup>
            <Controller
              name={question.name}
              control={control}
              render={({ field }) => (
                <>
                  {question.answers.map(
                    (
                      answer: {
                        label: string;
                        value: number | string | boolean;
                        tooltip?: string;
                      },
                      index: number
                    ) => (
                      <FormControlLabel
                        key={index}
                        label={answer.label}
                        control={
                          <Checkbox
                            value={answer.value}
                            name={question.name}
                            disabled={isReadOnly}
                            checked={(field.value || []).some(
                              (existingValue: string) =>
                                existingValue === answer.value
                            )}
                            onChange={(event, checked) =>
                              handleCheckbox(event, checked, field)
                            }
                            color="secondary"
                          />
                        }
                      />
                    )
                  )}
                </>
              )}
            />
          </FormGroup>

          <Showable show={!!formState.errors[question.name]}>
            <FormHelperText
              style={{ marginTop: -10, ...question.questionGroupStyle }}
            >
              {(formState.errors?.[question.name]?.message as string) ?? ''}
            </FormHelperText>
          </Showable>

          <CommentsField
            question={question}
            formValue={dynamicField}
            isReadOnly={isReadOnly}
          />
        </div>
      </Fade>
    );
  }

  return null;
};

interface DynamicQuestionsProps {
  question: Question;
  isReadOnly: boolean;
}

const DynamicQuestions = ({ question, isReadOnly }: DynamicQuestionsProps) => {
  return (
    <Showable show={question.dynamic}>
      {question.dynamicQuestions?.map(
        (dynamicQuestion: DynamicQuestion, index: number) => {
          return (
            <React.Fragment key={index}>
              <Showable
                show={dynamicQuestion.type === QuestionTypes.singleSelect}
              >
                <DynamicSingleSelect
                  question={dynamicQuestion}
                  isReadOnly={isReadOnly}
                />
              </Showable>
              <Showable
                show={dynamicQuestion.type === QuestionTypes.multiSelect}
              >
                <DynamicMultiSelect
                  question={dynamicQuestion}
                  isReadOnly={isReadOnly}
                />
              </Showable>
              <Showable show={dynamicQuestion.type === QuestionTypes.text}>
                <DynamicTextField
                  question={dynamicQuestion}
                  isReadOnly={isReadOnly}
                />
              </Showable>
            </React.Fragment>
          );
        }
      )}
    </Showable>
  );
};

interface CommentsFieldProps {
  question: Question | DynamicQuestion;
  formValue: string | boolean | undefined;
  isReadOnly: boolean;
}

const CommentsField = ({
  question,
  formValue,
  isReadOnly,
}: CommentsFieldProps) => {
  return (
    <Showable
      show={
        !!question?.comments?.(formValue) && typeof formValue !== 'undefined'
      }
    >
      <Fade in>
        <div className="tertia-prime-text" style={{ paddingBottom: '0.5rem' }}>
          <InputLabelBase style={{ paddingTop: 0 }}>Comments:</InputLabelBase>

          <TextArea
            name={`${question.name}-comments`}
            minRows={3}
            fullWidth
            blueBackground={false}
            disabled={isReadOnly}
          />
        </div>
      </Fade>
    </Showable>
  );
};
