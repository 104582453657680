import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import { withStyles } from '@mui/styles';

class RadioButtons extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  handleChange = (event) => {
    const { disabled } = this.props;
    if (!disabled) {
      this.props.checkError(true);
      this.setState({ value: getVal(event.target.value) });
      this.props.infunc(event.target.value);
    }
  };

  render() {
    const { props, state } = this;
    const { value } = state;
    if (!value) {
      return '';
    }

    const radios = props.values.map((radio, idx) => {
      return (
        <FormControlLabel
          key={radio.value}
          value={radio.value}
          control={<Radio color="primary" inputProps={{ required: true }} />}
          label={radio.label}
          labelPlacement="end"
          disabled={props.disabled}
          className={`${
            idx === 0 ? props.classes.leftButton : props.classes.rightButton
          }`}
        />
      );
    });

    return (
      <FormControl
        variant="standard"
        required
        component="fieldset"
        className={props.classContainer}
      >
        <RadioGroup
          aria-label="position"
          name={props.name}
          value={value.value}
          onClick={this.handleChange}
          required
          row
          className={props.classes.radioGroupContainer}
        >
          {radios}
        </RadioGroup>
      </FormControl>
    );
  }
}

const getVal = (val) => {
  if (val === 'true') {
    return { value: true, label: 'Yes' };
  }
  return { value: false, label: 'No' };
};

RadioButtons.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.object,
  values: PropTypes.array,
  infunc: PropTypes.func,
  checkError: PropTypes.func,
  classContainer: PropTypes.string,
  name: PropTypes.string,
};

RadioButtons.defaultProps = {
  disabled: false,
  value: null,
  values: [],
  infunc: () => {},
  checkError: () => {},
  classContainer: '',
  name: 'position',
};

const styles = {
  radioGroupContainer: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftButton: {
    paddingRight: '1rem',
    margin: '-1px 0 -1px -1px',
    borderTop: '1px solid transparent',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  rightButton: {
    paddingRight: '1rem',
    margin: '-1px 0 -1px -1px',
    borderTop: '1px solid transparent',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
};

export default withStyles(styles)(RadioButtons);
