import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getAccountDetails, searchAccounts } from '../api';
import { getData } from '../utils/functional/fp';

export const accounts = createQueryKeys('accounts', {
  detail: (accountId: string) => ({
    queryKey: ['account-details', accountId],
    queryFn: () => getAccountDetails(accountId).then(getData),
  }),

  detailWithParams: (accountId: string, params: Record<string, any>) => ({
    queryKey: ['account-details', accountId, params],
    queryFn: () => getAccountDetails(accountId, { params }).then(getData),
  }),

  list: (filters: Record<string, unknown>) => ({
    queryKey: [{ filters }],
    queryFn: () => searchAccounts({ params: filters }).then(getData),
  }),
});
