/* eslint-disable no-shadow */
import React, { useState } from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
// mui
import { DialogActions, DialogContent, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';

// components
// utils
import { useForm } from 'react-hook-form';
import CbButton from '../Buttons/CbButton';

import { PubSub } from '../../utils/eventUtils';
import RadioButtons from '../inputs/RadioButtons';
import { updateSocialEngg } from '../../accounts/AccountService';
import { useToggleModal } from '../../utils/modal.utils';

const styles = ({ palette }) => {
  return {
    radioGroup: {
      marginTop: 5,
      border: `1px solid ${palette.common.almostWhite}`,
      borderRadius: 5,
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 20,
    },
    labels: {
      textAlign: 'left',
      maxWidth: 550,
      marginRight: 30,
    },
  };
};
const radioOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const SocialEngg = withStyles(styles)(({ close, classes, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const {
    isAuthenticatingFundTransferRequests,
    isVerifyingBankAccounts,
    isPreventingUnauthorizedWireTransfers,
  } = data;
  const {
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      isAuthenticatingFundTransferRequests: isAuthenticatingFundTransferRequests
        ? { value: true, label: 'Yes' }
        : {
            value: _.isUndefined(isAuthenticatingFundTransferRequests)
              ? ''
              : false,
            label: 'No',
          },
      isVerifyingBankAccounts: isVerifyingBankAccounts
        ? { value: true, label: 'Yes' }
        : {
            value: _.isUndefined(isVerifyingBankAccounts) ? '' : false,
            label: 'No',
          },
      isPreventingUnauthorizedWireTransfers:
        isPreventingUnauthorizedWireTransfers
          ? { value: true, label: 'Yes' }
          : {
              value: _.isUndefined(isPreventingUnauthorizedWireTransfers)
                ? ''
                : false,
              label: 'No',
            },
    },
  });

  const [error, setError] = useState('');

  const values = getValues();

  const onSubmit = (formData) => {
    const {
      isAuthenticatingFundTransferRequests,
      isVerifyingBankAccounts,
      isPreventingUnauthorizedWireTransfers,
    } = formData;

    const payload = {
      accountId: data.accountId,
      isAuthenticatingFundTransferRequests:
        isAuthenticatingFundTransferRequests.value,
      isPreventingUnauthorizedWireTransfers:
        isPreventingUnauthorizedWireTransfers.value,
      isVerifyingBankAccounts: isVerifyingBankAccounts.value,
    };

    return updateSocialEngg({}, payload)
      .then((resp) => {
        PubSub.publish('set-social', resp.data.socialEngineering);
        if (resp.data.socialEngineering) {
          enqueueSnackbar('Social Engineering Endorsement Added', {
            variant: 'success',
          });
          toggleModal.direct('SocialEngg', false);
        } else {
          toggleModal.direct(
            'SocialEnggReasons',
            { ...resp.data },
            {
              title: 'Social Engineering Unavailable',
              icon: 'notVerified',
              footerButtons: [{ buttonText: 'Close', action: 'cancel' }],
            }
          );
        }
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong.Please try again.'
            )
          )
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
      <DialogContent>
        <div className="modal-title">
          <div className={classes.flexBox}>
            <span className={classes.labels}>
              Does the policyholder or their employees verify vendor/supplier
              bank accounts before adding to their accounts payable systems?
            </span>
            <RadioButtons
              required
              classContainer={classes.radioGroup}
              value={values.isVerifyingBankAccounts}
              values={radioOptions}
              infunc={(val) => {
                const value = val === 'true';
                if (value) {
                  setValue('isVerifyingBankAccounts', { value, label: 'Yes' });
                } else {
                  setValue('isVerifyingBankAccounts', { value, label: 'No' });
                }
              }}
              name="isVerifyingBankAccounts"
              ref={register('isVerifyingBankAccounts', { required: true })}
            />
          </div>
          <div className={classes.flexBox}>
            <span className={classes.labels}>
              Does the policy holder or their employees authenticate funds
              transfer requests (e.g.by calling a customer to verify the request
              at a predetermined phone number)?
            </span>
            <RadioButtons
              required
              classContainer={classes.radioGroup}
              value={values.isAuthenticatingFundTransferRequests}
              values={radioOptions}
              infunc={(val) => {
                const value = val === 'true';
                if (value) {
                  setValue('isAuthenticatingFundTransferRequests', {
                    value,
                    label: 'Yes',
                  });
                } else {
                  setValue('isAuthenticatingFundTransferRequests', {
                    value,
                    label: 'No',
                  });
                }
              }}
              name="isAuthenticatingFundTransferRequests"
              ref={register('isAuthenticatingFundTransferRequests', {
                required: true,
              })}
            />
          </div>
          <div className={classes.flexBox} style={{ paddingBottom: 0 }}>
            <span className={classes.labels}>
              Does the policy holder or their employees prevent unauthorized
              employees from initiating wire transfers?
            </span>
            <RadioButtons
              required
              classContainer={classes.radioGroup}
              value={values.isPreventingUnauthorizedWireTransfers}
              values={radioOptions}
              infunc={(val) => {
                const value = val === 'true';
                if (value) {
                  setValue('isPreventingUnauthorizedWireTransfers', {
                    value,
                    label: 'Yes',
                  });
                } else {
                  setValue('isPreventingUnauthorizedWireTransfers', {
                    value,
                    label: 'No',
                  });
                }
              }}
              name="isPreventingUnauthorizedWireTransfers"
              ref={register('isPreventingUnauthorizedWireTransfers', {
                required: true,
              })}
            />
          </div>
        </div>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" action={close}>
          Cancel
        </CbButton>
        <CbButton
          loading={isSubmitting}
          disabled={isSubmitting}
          styleName="ctaButton"
          buttonText="Save"
          type="submit"
        />
      </DialogActions>
    </form>
  );
});
