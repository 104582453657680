import React from 'react';
import { Typography, styled } from '@mui/material';
import Showable from '../../../../../../components/Showable';
import type { Question } from '../../securityQuestionsSchema/securityQuestions.statics';
import { QuestionTypes } from '../../securityQuestionsSchema/securityQuestions.statics';

import { MultiSelect, SingleSelect, TextInput } from './SecurityQuestionInputs';

interface SecurityAssessmentProps {
  isEditable?: boolean;

  sectionTitle?: string;
  questions: Readonly<Question[]>;
}

const SecurityAssessmentView = ({
  sectionTitle,
  isEditable = true,
  questions,
}: SecurityAssessmentProps) => {
  return (
    <>
      <Showable show={Boolean(sectionTitle)}>
        <FirmoAndSecurityHeader sx={{ marginBottom: '1rem' }}>
          {sectionTitle}
        </FirmoAndSecurityHeader>
      </Showable>

      {questions.map((question, index) => {
        return (
          <React.Fragment key={index}>
            <Showable
              show={
                question.type === QuestionTypes.singleSelect ||
                question.type === QuestionTypes.boolAnswer
              }
            >
              <SingleSelect question={question} isReadOnly={!isEditable} />
            </Showable>
            <Showable show={question.type === QuestionTypes.multiSelect}>
              <MultiSelect question={question} isReadOnly={!isEditable} />
            </Showable>

            <Showable show={question.type === QuestionTypes.text}>
              <TextInput question={question} isReadOnly={!isEditable} />
            </Showable>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default SecurityAssessmentView;

export const FirmoAndSecurityHeader = styled(Typography)(
  ({ theme }: { theme: any }) => {
    return {
      fontSize: '1.6rem',
      textTransform: 'uppercase',
      color: theme.palette.text.secondary,
      borderBottom: `1px solid ${theme.palette.text.secondary}`,
      width: '100%',
    };
  }
);
