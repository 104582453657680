import _ from 'lodash';

import Color from 'color';
import { blueGrey } from '@mui/material/colors';

import { adaptV4Theme, createTheme } from '@mui/material';
import _config from './_base/_config';

import _base from './_base/_base';
import _baseOverrides from './_base/_base.overrides';
import _baseGenerics from './_base/_base.generics';

// prop overrides
import MuiCardProps from './_props/MuiCard.props';

const { colors } = _config;
const primaryColor = '#6ac52a';

const themebase = {
  config: _config,
  images: {
    wizerTraining: {
      logo: 'images/wizeLogo.png',
    },
  },
  palette: {
    mode: 'dark',
    common: colors,

    primary: {
      light: colors.lightCyan,
      cowbellBlue: colors.cowbellBlue,
      main: colors.white,
      dark: colors.aquaMarine,
      contrastText: colors.white,
      greyCard: '#262626',
      border: '#707070',
      oldBorder: '#c9cdd9',
      title: colors.cowbellLight,
      green: colors.good,
      mainYellow: colors.yellow,
      yellow: colors.elevated,
      red: colors.critical,
      modalBorder: colors.paleGrey1,
      border1: '#91949d',
      border2: colors.cowbellLight,
      border3: colors.darkGreySoft,
      border4: colors.cowbellAccent,
      border5: colors.faintBrown,
      border6: colors.darkGrey,
      border7: colors.green3,
      yellow1: colors.yellow1,
      green1: colors.green1,
      aquaMarine1: colors.aquaMarine1,
      purple: colors.purple,
      brown: colors.brown,
      grey: colors.greyWhite,
      cyan: colors.cyan,
      disableButton: colors.white,
      loadingIcon: colors.cowbellBlue,
      favoriteIcon: colors.favoriteOrange,
    },
    tabs: {
      profile: colors.lightBlue,
      question: colors.cowbellBlue,
    },
    secondary: {
      light: blueGrey[400],
      main: blueGrey[500],
      dark: blueGrey[600],
      contrastText: colors.white,
    },
    active: {
      menu: colors.white,
    },
    success: {
      main: colors.paleGreen,
      contrastText: colors.white,
    },
    checkbox: {
      secondary: colors.cowbellAccent,
    },
    breadcrumbs: {
      text: colors.grey4,
    },

    error: {
      light: colors.paleTomato,
      main: colors.error,
      dark: colors.ripeTomato,
      contrastText: colors.white,
      errorText: colors.paleTomato,
    },

    warning: {
      light: colors.lightCanery,
      main: colors.canery,
      dark: colors.darkCanery,
      contrastText: colors.darkGrey,
    },
    icon: {
      primary: colors.darkGreySoft,
      secondary: colors.almostWhite,
    },

    text: {
      primary: colors.darkGreySoft,
      secondary: colors.almostWhite,
      title: colors.greyWhite,
      hover: colors.darkGreyActive,
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: '',
      icon: colors.almostWhite,
      link: colors.white,
      link1: colors.cowbellLight1,
      placeholder: colors.almostWhite,
      highlighter: colors.darkGrey,
      asterisk: colors.orangeRed,
      text3: colors.cowbellAccent,
      text4: colors.cowbellGreen2,
      text5: '#fa8072',
      text6: colors.almostWhite,
      message: colors.cowbellLight,
      white1: colors.white1,
      text7: colors.grey2,
      text8: colors.white,
      text9: colors.textRed,
      text10: colors.blue3,
      text11: colors.faintBrown,
      contrastText: colors.white,
      text12: colors.cowbellBlue,
      text13: colors.white,
      text14: colors.white,
      text15: colors.darkGreySoft,
      text16: colors.greyWhite,
      highlightedtext: colors.lightBlue,
      text17: colors.white3,
    },
    email: {
      primary: colors.blue1,
    },

    background: {
      default: colors.darkGrey,
      paper: colors.darkGreyLighter,
      autocompleteOptionFocus: '#343c53',
      modal: colors.darkGreyActive,
      mfa: colors.cowbellCyan,
      lighter: colors.darkGreyLighter,
      active: colors.darkGreyActive,
      public: colors.almostBlack,
      tertiary: '#39474e',
      highlighter: Color(primaryColor).lighten(0.75).string(),
      button: colors.cowbellLight,
      tableHeader: colors.blueGrey,
      white: colors.darkGreyActive,
      yellow: colors.yellow,
      darkerBlue: colors.darkBlue,
      paleBlue: colors.paleBlue,
      powderBlue: colors.powderBlue,
      blue5: colors.blue5,
      tableRowHover: colors.darkGreyActive,
      tableRowDisabled: colors.disabledRow,
      tableRow: colors.darkGreyLighter,
      tableBody: colors.darkGrey,
      green: colors.cowbellAccent,
      green1: colors.green3,
      green4: colors.green4,
      green5: colors.green4,
      actualGreen5: colors.green5,
      lightGreen: colors.lightGreen,
      grey3: colors.grey3,
      grey11: colors.grey11,
      red: colors.textRed,
      dark: colors.black1,
      white1: colors.white,
      activeIcon: colors.white,
      usersBox: colors.darkGreyActive,
      darkGrey1: colors.darkGrey1,
      darkGrey2: colors.darkGrey4,
      disabled: colors.grey8,
      greyBlack: colors.greyBlack,
      darkBlack: colors.darkblack,
      greyBlackLight: colors.greyBlackLight,
      customTable: colors.darkGrey1,
      lightNavy: colors.lightNavy,
      payment: colors.darkGreyActive,
      accountLookup: colors.darkGreyLighter,
      connectorHover: colors.darkGreyActive,
      connectorQuickView: colors.black1,
      details: colors.darkGreyActive,
      iframe: colors.blueWhiteHard,
      fileUploader: colors.blue4,
      oddRow: colors.darkGrey,
      salesInboxAccordion: colors.blue5,
      darkB: colors.dark,
      radioButtonSelected: colors.cowbellBlue,
      radioButton: colors.darkblack,
      uniqueRow: colors.darkGreyActive,
      buttonGroup: colors.darkGrey,
      contrastText: colors.white,
    },

    drawer: {
      background: colors.black1,
      rowsOdd: colors.darkGrey,
      rowsEven: colors.black1,
    },

    tables: {
      selectedRow: blueGrey[800],
      shimmer: 'rgba(255, 255, 255, .15)',
      productRow: colors.tableRowDark,
      billedRow: colors.florescentGreen,
      endorsement: colors.blue5,
    },

    notification: {
      list: colors.darkGreyLighter,
      background: colors.brownGrey,
      border: colors.almostWhite,
      header: colors.darkGrey,
      lowerText: colors.lightGrey,
      hoverHeader: colors.darkGreyActive,
      hoverBody: colors.darkGreyActive1,
      containerBorder: colors.greyBorder,
      clear: colors.white1,
      hover: colors.darkGreyActive,
    },
    ticker: {
      barcolor: colors.lightGrey3,
    },

    graphs: {
      bar: {
        primary: colors.cowbellLight,
      },
      pie: {
        primaryBlue: colors.cowbellLight,
        secondaryBlue: colors.powderBlue,
        primaryGreen: colors.cowbellAccent,
        secondaryGreen: colors.cowbellGreen,
      },
      line: colors.paleGrey1,
    },

    calendar: {
      headerText: colors.white,
      borderColor: 'rgba(255, 255, 255, 0.7)',
      presetBackground: colors.darkGrey,
      presetHoverBackground: primaryColor,
      presetHoverText: colors.white,

      native: {
        selectionColor: primaryColor,
        weekdayColor: colors.lightAquaMarine,
        headerColor: primaryColor,
        todayColor: colors.lightAquaMarine,
        accentColor: colors.lightAquaMarine,
        floatingNav: {
          background: colors.lightGrey,
          color: colors.darkGreySoft,
          chevron: 'transparent',
        },
      },
    },

    select: {
      disabled: 'rgba(255, 255, 255, 0.5)',
    },

    components: {
      globalHeader: {
        background: colors.darkGreyLighter,
        contrastText: colors.white,
        iconColor: colors.darkGreySoft,
        paper: colors.darkGreyActive,
      },

      sidebar: {
        background: colors.darkGrey,
      },
    },
    badge: {
      icon: colors.badgeRed,
    },
    border: {
      menuItem: colors.white,
      icon: colors.white,
      grey: colors.darkGreyActive,
      acceptFileUploader: colors.blue4,
      soft: colors.softWhite,
    },
    snackBar: {
      primary: colors.snackGreen,
    },
    button: {
      text: colors.white,
      text2: colors.white,
      border: colors.white,
      background: colors.grey7,
      background2: 'unset',
      disabledBorder: colors.grey5,
      disabledText: colors.grey5,
      disabledBackground: colors.grey8,
      disabledBackground1: 'unset',
    },

    connectors: {
      legend1: colors.blue2,
      legend2: colors.green2,
      legend3: colors.pink1,
      legend4: colors.powderBlue,
    },
    apiKeys: {
      row: colors.darkGrey1,
    },

    flags: {
      icon: '#E6E6E6',
      program: '#506F98',
      api: '#B2864F',
      unverified: '#7F67A6',
      openClaim: '#A55D55',
      closedClaim: '#658C5B',
      raterX: '#CE6E9E',
      raterXIcon: '#E6E6E6',
    },
  },
  jsonPalette: {
    base00: colors.darkGreyActive, // background
    base01: 'red',
    base02: colors.darkGreySoft,
    base03: colors.darkGreySoft,
    base04: colors.darkGreySoft,
    base05: '#444',
    base06: '#444',
    base07: colors.darkGreySoft,
    base08: '#444',
    base09: colors.darkGreySoft,
    base0A: colors.darkGreySoft,
    base0B: colors.darkGreySoft,
    base0C: colors.darkGreySoft,
    base0D: colors.darkGreySoft,
    base0E: colors.darkGreySoft,
    base0F: colors.darkGreySoft,
  },
  connectors: {
    icons: {
      'Cowbell Cyber': 'Cowbell_Logo_white.svg',
      'Microsoft Secure Score': 'Microsoft365B.svg',
      'GCP Security Command Center': 'GoogleCloudB.svg',
      'AWS Security Hub': 'AWSB.svg',
      AWS: 'AWSB.svg',
      'Secureworks ': 'Secureworks_Black.svg',
      'Security Studio': 'securityStudio_dark.svg',
      'Qualys Vulnerability Management': 'Qualys.svg',
      'Qualys Policy Compliance': 'Qualys.svg',
      'Qualys Container Security': 'Qualys.svg',
      'Wizer Cyber Training': 'WizerSmall_DarkLogo.svg',
      'Safeguard Cyber': 'SafeGuard_Small_DarkTheme.svg',
      'Secureworks Taegis VDR': 'secureworks_icon.png',
      'Google Workspace': 'GoogleWorkspace.svg',
      CLOUDFLARE: 'connectors/Cloudflare.svg',
      'SOPHOS ENDPOINT': 'connectors/Sophos.svg',
    },
  },
  agencyDash: {
    icon: '#b1c2de',
    interactable: '#90aede',
    background: {
      default: '#000',
      tableRow: '#2e2e2e',
      divider: '#121212',
      contrast: '#383838',
      menu: '#383838',
      paper: '#121212',
      button: '#212121',
      header: colors.darkGreySoft,
      hover: '#4c4d50',
      tooltip: '#cedaf0',
    },
    border: {
      default: '#3a474e',
      divider: '#b1c2de',
    },
    tabs: {
      selected: '#b1c2de',
      default: '#2e2e2e',
      hover: '#b1c2de',
    },
    text: {
      link: '#b1c2de',
      hyperlink: '#829CC6',
      error: '#ff897d',
      warning: '#ffe082',
      secondary: '#95a2ad',
      tab: '#383f4d',
      primary: '#e6e6e6',
      tooltip: '#000',
    },
    buttons: {
      startNewQuote: '#7cbf86',
      renewPolicy: '#307abf',
      bindQuote: '#8d7cbf',
      viewCommissions: '#bf7ca8',
    },
    statsBar: {
      expiringQuotes: '#ffaba3',
      expiringBinders: '#9a7d9f',
      policiesUpForRenewal: '#a4adbf',
    },
    group: {
      border: '#373737',
      background: '#1B1B1B',
    },
  },
};

const themeoverrides = {
  MuiFormControlLabel: {
    label: {
      color: `${themebase.palette.text.text8}`,
      fontSize: _config.textSizes.normal,
      fontFamily: _config.fonts.stackSans,
    },
    root: {
      marginRight: '1rem',
    },
  },

  MuiTabs: {
    indicator: {
      backgroundColor: themebase.palette.primary.main,
    },
  },

  MuiInputBase: {
    input: {
      color: colors.white,
      WebkitTextFillColor: colors.white,
      // border: 'inherit',

      '&:-webkit-autofill': {
        // WebkitBoxShadow: `0 0 0 100px ${themebase.palette.background.active} inset`,
        WebkitTextFillColor: colors.dark1,
      },
    },
  },

  MuiPaper: {
    elevation1: {
      boxShadow: 'none',
    },
    elevation6: {
      boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.32)',
    },
  },

  MuiToggleButton: {
    root: {
      color: themebase.palette.text.primary,

      '&.Mui-selected': {
        backgroundColor: themebase.palette.background.active,
        color: themebase.palette.primary.contrastText,
      },

      '&.Mui-selected:hover': {
        backgroundColor: `${themebase.palette.background.active} !important`,
      },
    },
  },
};

export const darkThemebaseV4 = {
  ..._.merge({}, _base, themebase),
  overrides: _.merge(
    {},
    _baseOverrides({
      themebase,
      primaryText: themebase.palette.text.primary,
      borderColor: themebase.palette.background.paper,
      tableHeaderBackground: themebase.palette.background.tableHeader,

      inputBaseStyles: {
        '&:focus': {
          background: themebase.palette.common.darkBlue,
        },
      },
    }),
    themeoverrides
  ),
  props: {
    ...MuiCardProps,
  },
  globals: _baseGenerics, // global styling for resuable components
  typography: {
    htmlFontSize: 12,
    fontFamily: [
      'Roboto',
      'Helvetica, Arial',
      '"Arial Unicode"',
      '"Lucida Sans Unicode"',
      'sans-serif',
    ].join(','),
  },
};

export default createTheme(adaptV4Theme(darkThemebaseV4));
