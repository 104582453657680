import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getAllMessages, getMessageByMsgId } from '../api/cmail.api';

export const cmail = createQueryKeys('cmail', {
  detail: (msgId: string) => ({
    queryKey: ['message-details', msgId],
    queryFn: () =>
      getMessageByMsgId({ params: { msgId } }).then((resp) => {
        return resp.data;
      }),
  }),

  list: (filters: Record<string, unknown>) => ({
    queryKey: [{ filters }],
    queryFn: () => getAllMessages({ params: filters }),
  }),
});
