import AT from '../actions/ActionTypes';
import {
  fetchAccountDetailsById,
  getProfileScore,
  verifyOrgProfileGroupById,
} from './AccountService';

import { publishDelayedEvent } from '../utils/eventUtils';
import { getPaymentProcessor } from '../console/customers/_services/BillingService';

export const fetchAccountSucceeded = (resp) => {
  return {
    type: AT.FETCH_ACCOUNT_SUCCEEDED,
    payload: {
      account: resp.data, // if policyHolder does not dispatch change it to (resp.data || resp);
    },
  };
};

export const fetchAccountById = (accountId) => {
  return (dispatch) => {
    return fetchAccountDetailsById(accountId).then((resp) => {
      dispatch({
        type: AT.GET_PROFILE_SCORE_SUCCEEDED,
        payload: resp.data.profileScore,
      });
      dispatch(fetchAccountSucceeded(resp.data));
    });
  };
};

export const getCurrentProfileScore = (successCallback) => {
  return (dispatch) => {
    return getProfileScore().then((resp) => {
      dispatch({
        type: AT.GET_PROFILE_SCORE_SUCCEEDED,
        payload: resp.data.profileScore,
      });

      if (successCallback) {
        successCallback(parseInt(resp.data.profileScore));
      }
    });
  };
};

export const verifyProfileGroupById = (accountId, groupId, flag, callback) => {
  return (dispatch) => {
    return verifyOrgProfileGroupById(groupId, flag).then(() => {
      publishDelayedEvent('turnOnVerify');

      if (callback) {
        callback();
      }

      setTimeout(() => {
        fetchAccountDetailsById(accountId).then((accountDetailsResponse) => {
          dispatch({
            type: AT.GET_PROFILE_SCORE_SUCCEEDED,
            payload: accountDetailsResponse.data.profileScore,
          });
          dispatch(fetchAccountSucceeded(accountDetailsResponse));
        });
      }, 3000);
    });
  };
};

export const getPaymentProcessorValue = () => {
  return (dispatch) => {
    return getPaymentProcessor().then((resp) => {
      dispatch({
        type: AT.GET_PAYMENT_PROCESSOR,
        payload: resp.data,
      });
    });
  };
};

export const updateExcessDetails = (details) => {
  return {
    type: AT.UPDATE_EXCESS_DETAILS,
    payload: details,
  };
};
