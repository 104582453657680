import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useState } from 'react';
import Moment from 'moment';
import { useSnackbar } from 'notistack';
// mui
// components
// utils
import { DialogActions, Grid as MuiGrid, FormHelperText } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { withFormController } from '../hocs/forms';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { SimpleSelect } from '../inputs/selects/SimpleSelect';
import {
  createAgencyLicense,
  updateAgencyLicense,
} from '../../console/agencies/_services/licenses.service';
import { delayedEvent, PubSub } from '../../utils/eventUtils';

import { UsStatesFull } from '../../utils/USState';

import CBButton from '../Buttons/CbButton';
import { useToggleModal } from '../../utils/modal.utils';

const Select = withFormController(SimpleSelect);
const TextField = withFormController(TextFieldBase);

const today = Moment().format('YYYY-MM-DD');

const schema = Yup.object().shape({
  npn: Yup.string().test(
    'len',
    'NPN must be less than or equal to 10 digits',
    (val) => val.length <= 10
  ),
  number: Yup.string()
    .required('License # is required')
    .matches(
      /^[0-9aA-zZ\s]+$/,
      'Only alphabets and numbers are allowed for this field '
    ),
  state: Yup.string().required('State field is required'),
  expirationDate: Yup.date().required('Date is required'),
});

export const AgencyLicenseCreateEdit = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const editing = Boolean(data.id);
  const [backendError, setBackendError] = useState('');
  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      activeResidentState: Boolean(data.activeResidentState),
      npn: data.npn || '',
      number: data.number || '',
      state: data.state || '',
      expirationDate: data
        ? Moment(data.expirationDate).format('YYYY-MM-DD')
        : '',
    },
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting, isDirty },
  } = methods;
  function onSubmit({ ...formData }) {
    if (editing) {
      const func = updateAgencyLicense;
      return func(
        {},
        {
          ...formData,
          id: data.id,
          agencyName: data.agency.name,
        }
      )
        .then(() => {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          enqueueSnackbar(`License ${formData.number} Updated successfully!`, {
            variant: 'success',
          });
          PubSub.publish('table-refetch', 500, 'agency_licenses');
          makeRefreshCall();
          toggleModal.direct('LicenseCreateEdit', false);
        })
        .catch((error) => {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          setBackendError(error.response.data);
        });
    }

    const createFunc = createAgencyLicense;
    return createFunc(
      {},
      {
        ...formData,
        id: data.id,
        agencyName: data.agency.name,
      }
    )
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        enqueueSnackbar(`License ${formData.number} successfully created`, {
          variant: 'success',
        });
        PubSub.publish('table-refetch', 500, 'agency_licenses');
        makeRefreshCall();
        toggleModal.direct('LicenseCreateEdit', false);
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        setBackendError(error.response.data);
      });
  }

  const handleCancel = useCallback(() => {
    toggleModal.direct('AgencyLicenseCreateEdit', false);
    // eslint-disable-next-line
  }, []);
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MuiGrid container spacing={6} style={{ justifyContent: 'center' }}>
          <MuiGrid item md={5}>
            <Select
              required
              label="State"
              name="state"
              options={UsStatesFull}
              style={{ paddingTop: '-405px' }}
            />
          </MuiGrid>
          <MuiGrid item md={5} style={{ marginTop: '1rem' }}>
            <TextField required name="npn" label="NPN" type="number" />
          </MuiGrid>
        </MuiGrid>
        <MuiGrid container spacing={6} style={{ justifyContent: 'center' }}>
          <MuiGrid item md={5}>
            <TextField name="number" label="Licensed #" required />
          </MuiGrid>
          <MuiGrid item md={5}>
            <TextField
              name="expirationDate"
              label="Expiration Date"
              required
              type="date"
              InputProps={{ inputProps: { min: today } }}
            />
          </MuiGrid>
        </MuiGrid>
        <FormHelperText className="api-text-error">
          {backendError}
        </FormHelperText>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <CBButton size="medium" styleName="cancel" onClick={handleCancel}>
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            styleName="ctaButton"
            size="medium"
            loading={isSubmitting}
            disabled={isSubmitting || !isDirty}
          >
            Save Changes
          </CBButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'agency_licenses');
}
