import { useSelector } from 'react-redux';

import _ from 'lodash';

import { getAgencyDetails } from '../agencies/AgencyService';
import { useAuth } from '../components/hooks/useAuth';
import { ProductTypes } from '../types';
import { UsStatesObjects } from '../utils/USState';
import { usePersona } from '../components/hooks/usePersona';
import { useActor } from '../components/hooks/useActor';
import { QUOTE_FLOWS } from '../console/agencies/quotes/PrimeX/constants';
import { RenewalSteps } from '../console/workflows/renew-quote/types';

export const claimYearsMap = {
  0: 'Never',
  1: 'within last 12 months',
  2: 'within last 2 years',
  3: 'within last 3 years',
  4: 'within last 4 years',
  5: 'within 5 years or more',
};

export const claimYears = Object.keys(claimYearsMap).map((key) => ({
  label: claimYearsMap[key],
  value: Number(key),
}));

export const getYearsOfPastClaims = (val) => {
  return claimYears.find((yr) => yr.value === val);
};

export const frequency = [
  { label: 'Weekly or more', value: 'WEEKLY' },
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Quarterly', value: 'QUARTERLY' },
  { label: 'Every 6 Months', value: 'SIX_MONTHS' },
  { label: 'Never', value: 'NEVER' },
];

export const getFrequency = (val) => {
  return frequency.find((yr) => yr.value === val);
};

export const orgMap = [
  { id: 'dunsNumber', label: 'Duns Number' },
  { id: 'domainName', label: 'Primary Web Domain' },
  { id: 'domains', label: 'Secondary Web Domain(s)' },
  { id: 'url', label: 'Url' },
  { id: 'yearEstablished', label: 'Year Established' },
  { id: 'entityType', label: 'Entity Type' },
  { id: 'isFranchise', label: 'Subsidiary/Franchisee' },
  { id: 'secondaryIndustry', label: 'Industry Class' },
  { id: 'ownershipType', label: 'Ownership Type' },
  { id: 'noOfEmployeesAll', label: 'Number of Employees' },
];

export const orgContact = [
  { id: 'businessAddress', label: 'Business Address' },
  { id: 'phoneNumber', label: 'Business Phone' },
];

export const policyholderContact = [
  {
    id: ['contactFirstName', 'contactLastName'],
    label: 'Current Contact Person',
  },
  { id: 'contactEmail', label: 'Current Contact Email' },
  { id: 'contactPhone', label: 'Current Mobile Number' },
];

export const securityAssessment = [
  { id: 'pastCyberIncident', label: 'Had any past Cyber incidents(s)?' },
  { id: 'securityQuestions', label: 'Security Questions' },
  {
    id: 'numberOfPersonalRecords',
    label: 'Number of PII / PHI / PCI Records?',
  },
  { id: 'percentInternationalSales', label: '% of International Sales?' },
];

export const determineIsAccountEligibleForQuote = (data, isVerifiedByAgent) => {
  if (!data) {
    return { disable: true, error: 'No data present' };
  }
  const { status, profileScore } = data;

  const incompleteProfile = profileScore < 4;

  const openStatus =
    status === 'Open' || status === 'Quoted' || status === 'Active Policy';

  const shouldQuotingBeDisabledForAccountError =
    incompleteProfile || !openStatus || !isVerifiedByAgent;

  let error = '';

  if (shouldQuotingBeDisabledForAccountError) {
    if (!openStatus) {
      error = data.errorMessage || 'Account is not eligible to Request Quote';
    } else if (incompleteProfile) {
      error = 'Account needs more information to enable Request Quote';
    }
  }

  return { shouldQuotingBeDisabledForAccountError, error };
};

export const useQuoteCreationFunctions = (accountInfo) => {
  const { isCowbellUserJumped } = useAuth();

  const persona = usePersona();
  const actor = useActor();

  const agencyData = useSelector(({ prime100 }) => prime100.agency);
  const agentDetails = useSelector(
    ({ prime100 }) => prime100.florida250license
  );

  const getAgencyId = () => {
    if (persona.isCowbell) {
      return agencyData.agencyId;
    }

    if (persona.isAgency) {
      return undefined;
    }
  };

  /**
   * @name detemineAgentOnQuoteCreation
   * @description The purpose is to find out who the agent is on quote creation
   */
  const detemineAgentOnQuoteCreation = () => {
    const { agentFirstName, agentLastName, agentEmail, agentPhone } =
      agencyData;
    let { firstName, lastName, email, phone } = actor;

    if (persona.isCowbell) {
      firstName = agentFirstName;
      lastName = agentLastName;
      email = agentEmail;
      phone = agentPhone;
    } else if (isCowbellUserJumped) {
      if (_.isUndefined(accountInfo.agentEmail)) {
        getAgencyDetails().then((resp) => {
          const agency = resp.data;
          if (!_.isUndefined(agency.contactEmail)) {
            firstName = agency.contactFirstName;
            lastName = agency.contactLastName;
            email = agency.contactEmail;
            phone = agency.contactPhone;
          }
        });
      } else {
        firstName = accountInfo.agentFirstName;
        lastName = accountInfo.agentLastName;
        email = accountInfo.agentEmail;
        phone = accountInfo.agentPhone;
      }
    }

    return {
      agentFirstName: firstName,
      agentLastName: lastName,
      agentEmail: email,
      agentPhone: phone,
    };
  };

  const determineRetailAgentForFlorida = () => {
    const {
      retailAgentFirstName,
      retailAgentLastName,
      retailAgentLicenseNumber,
    } = agentDetails;

    return {
      retailAgentFirstName,
      retailAgentLastName,
      retailAgentLicenseNumber,
    };
  };

  const determineTeamIdOfAgent = () => {
    if (persona.isCowbell) {
      return _.get(agencyData, 'teamIds', []);
    }
    if (persona.isAgency) {
      return _.get(accountInfo, 'agencies.teamIds', []);
    }
  };

  const getAgencyInfoFromCowbell = () => {
    if (persona.isCowbell) {
      return { ...agencyData };
    }
  };

  const detemineAgentOnAttestation = () => {
    const nextAgent = {
      agentFirstName: actor.firstName,
      agentLastName: actor.lastName,
      agentEmail: actor.email,
      agentPhone: actor.phone,
    };

    if (persona.isCowbell) {
      return {
        ...nextAgent,
        agencyId: agencyData.agencyId,
        agencyName: agencyData.agencyName,
      };
    }

    if (persona.isAgency) {
      return {
        ...nextAgent,
        agencyId: actor.account.id,
        agencyName: actor.account.name,
      };
    }
  };

  return {
    detemineAgentOnQuoteCreation,
    determineRetailAgentForFlorida,
    getAgencyId,
    determineTeamIdOfAgent,
    getAgencyInfoFromCowbell,
    detemineAgentOnAttestation,
  };
};

export const getAgentAttestLabelText = (isVerifiedByAgent, verifiedDate) => {
  return isVerifiedByAgent ? (
    <>
      You attested on {verifiedDate} that the information presented is complete,
      accurate, and a true representation of this business.
    </>
  ) : (
    <>
      I attest that the information presented is a complete, true and accurate
      representation on this business and understand that any fraudulent intent
      is subject to criminal and civil penalties.
    </>
  );
};

export const pullAttestationDataFromAccount = (account) => {
  const array = [];
  const domains = _.pick(account, 'domains');
  let finalDomains = '';
  if (!_.isEmpty(domains)) {
    domains.domains.map((v) => array.push(v.domain));
    finalDomains = _.join(array, ',');
  }

  return {
    ..._.pick(account, [
      'address1',
      'address2',
      'changeInScope',
      'changeInScopeOther',
      'city',
      'claimHistory',
      'country',
      'dunsNumber',
      'domainName',
      'entityType',
      'isSecurityOfficer',
      'isSecurityTraining',
      'useEncryption',
      'useCloudStorage',
      'isFranchise',
      'percentInternationalSales',
      'phoneNumber',
      'p250Details',
      'name',
      'naicsCode',
      'noOfEmployeesAll',
      'numberOfPersonalRecords',
      'ownershipType',
      'revenue',
      'state',
      'url',
      'yearEstablished',
      'zipCode',
      'incidentResponsePlan',
      'p100ProQuestions',
    ]),
    domains: finalDomains,
    accountId: account.id,
  };
};

export const constructAgencyInfo = (data) => {
  const { firstName, lastName, email, phone, id, account } = data;
  return {
    agentFirstName: firstName,
    agentLastName: lastName,
    agentEmail: email,
    agentPhone: phone,
    agencyId: id,
    agencyName: account?.name,
  };
};

export const determineAttestationButtonText = (account, product) => {
  if (
    account.state === UsStatesObjects.FL.value &&
    !account.surplusOnly &&
    product === ProductTypes.p250
  ) {
    return 'Next';
  }
  return 'Request Quote';
};

export const determineRenewalRoute = (data, feature = false) => {
  const { productType, policyId, accountId } = data;
  if (!policyId) return null;

  if (productType === ProductTypes.p100) {
    if (feature) {
      return `/agency/quotes/${productType}/${accountId}/${QUOTE_FLOWS.RENEW_QUOTE}/${RenewalSteps.VERIFY_ACCOUNT_INFO}`;
    }

    return `/agency/accounts/${accountId}/renew/accountDetails?product=${ProductTypes.p100}&policyId=${policyId}`;
  }
  if (productType === ProductTypes.p250) {
    return `/agency/policies/${policyId}/account/${accountId}/${ProductTypes.p250}/verify`;
  }
};
