import { PureComponent } from 'react';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import { DialogContent, FormHelperText } from '@mui/material';

import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { formatAsPhone } from '../../utils';

import { assignAccount } from '../../accounts/AccountService';
import { PubSub } from '../../utils/eventUtils';
import { getAgentInfo } from '../../agencies/AgencyService';
import { withActor } from '../hocs/withActor';
import { withToggleModal } from '../hocs/withToggleModal';
import { getCowbellTranslations } from '../../i18n/translations';

const { t } = getCowbellTranslations();

class SetMyAgentConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      error: '',
      agentData: {},
    };
  }

  componentDidMount() {
    this.subscription = PubSub.subscribe('foot-button-one', (eventData) => {
      if (eventData) {
        this.handleSubmit();
      }
    });

    if (this.props.data.getAgent) {
      getAgentInfo(this.props.data.agencyId)
        .then((resp) => {
          this.setState({ agentData: resp.data });
        })
        .catch(console.error.bind(console));
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  render() {
    const { props } = this;
    const { classes } = props;
    const { error } = this.state;
    const {
      name,
      contactEmail,
      address1,
      city,
      state,
      zipCode,
      contactPhone,
      contactName,
      agentName,
      agentEmail,
      agentPhone,
    } = props.data;

    let header = '';
    let footer = '';

    header = (
      <div className={`${classes.header}`}>
        Do you want to make <span className={classes.blueText}>{name}</span>,{' '}
        <span className={classes.greenText}>
          your {t('agent').toLowerCase()}
        </span>
        ?
      </div>
    );
    footer = `If you make ${name} your ${t(
      'agent'
    ).toLowerCase()}, any active quote(s) from other agencies will become invalid.`;

    const agencyInfo = ['Name:', 'Address:', ''];

    const agencyInfoValues = [
      name,
      address1 || '',
      address1 ? city + state + zipCode : '',
    ];

    const agentInfo = ['Name:', 'Email:', 'Contact:'];
    const agentInfoValues = [
      contactName || agentName || this.state.agentData.agentName,
      contactEmail || agentEmail || this.state.agentData.agentEmail,
      formatAsPhone(contactPhone) ||
        formatAsPhone(agentPhone) ||
        formatAsPhone(this.state.agentData.agentPhone),
    ];

    return (
      <DialogContent>
        <form
          className="modal-title"
          style={{ width: 600 }}
          onSubmit={this.approveQuoteHandler}
          id="submit-form"
        >
          {header}
          <div
            className={classes.wrapper}
            style={{ textAlign: 'initial', paddingBottom: 10 }}
          >
            <div>
              <span style={{ fontWeight: 600 }}>
                {t('agency').toUpperCase()} DETAILS
              </span>
              <div className={classes.statsLabel} style={{ marginRight: 50 }}>
                <div className={classes.dataWrapper}>
                  {agencyInfo.map((factor) => {
                    return <div key={factor}>{factor}</div>;
                  })}
                </div>
                <div className={classes.statsValue}>
                  {agencyInfoValues.map((factor) => {
                    return <div key={factor}>{factor}</div>;
                  })}
                </div>
              </div>
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>
                {t('agent').toUpperCase()} DETAILS
              </span>

              <div className={classes.statsLabel}>
                <div className={classes.dataWrapper}>
                  {agentInfo.map((factor) => {
                    return <div key={factor}>{factor}</div>;
                  })}
                </div>
                <div className={classes.statsValue}>
                  {agentInfoValues.map((factor) => {
                    return <div key={factor}>{factor}</div>;
                  })}
                </div>
              </div>
            </div>
          </div>
          <FormHelperText className="api-text-error">{error}</FormHelperText>
          <span
            className="modal-blue-text"
            style={{ fontWeight: 600, textAlign: 'center' }}
          >
            {footer}
          </span>
        </form>
      </DialogContent>
    );
  }

  handleSubmit() {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);

    const { props } = this;
    const { actor } = props;

    const {
      agencyId,
      linking,
      name,
      contactPhone,
      contactFirstName,
      contactEmail,
      contactLastName,
      contactName,
    } = props.data;

    const { utils } = props.data;
    const { agentFirstName, agentLastName, agentEmail, agentPhone, agentName } =
      this.state.agentData;

    const payload = {
      agencyId,
      agencyName: name,
      customerFirstName: actor.firstName,
      customerLastName: actor.lastName,
      customerEmail: actor.email,
      customerPhoneNumber: actor.phone,
      agentFirstName: contactFirstName || agentFirstName,
      agentLastName: contactLastName || agentLastName,
      agentEmail: contactEmail || agentEmail,
      agentPhoneNumber: contactPhone || agentPhone,
      agentName: contactName || agentName,
      link: linking,
    };
    assignAccount(actor.account.id, payload)
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.props.toggleModal.direct('SetMyAgentConfirm', false);
        this.props.enqueueSnackbar(
          `${t('agent')} ${name} has been Assigned Successfully!`,
          { variant: 'success' }
        );
        utils.setMyAgent({
          ...props.data,
          ...payload,
          ...this.state.agentData,
          id: agencyId,
        });
        utils.setInterestedAgencies(
          utils.interestedAgencies.filter((agency) => agency.name !== name)
        );
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.setState({
          error: _.get(
            error,
            'response.data',
            'There was an error with your request'
          ),
        });
      });
  }
}

SetMyAgentConfirm.defaultProps = {
  data: {},
};

const styles = ({ palette, ...theme }) => {
  return {
    header: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.13,
      textAlign: 'center',
      marginBottom: 30,
    },
    card: {
      maxWidth: '100%',
      minheight: '25vh',
      textAlign: 'center',
      backgroundColor: 'inherit',
    },
    cardContent: {
      padding: theme.spacing(5),
      color: palette.text.secondary,
    },
    footer: {
      padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    },
    title: {
      fontFamily: 'Titillium Web',
      fontSize: '15px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: palette.text.link,
      marginTop: 50,
    },
    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginBottom: 20,
    },
    blueText: {
      color: palette.common.cowbellLight,
    },
    greenText: {
      color: palette.common.cowbellGreen2,
    },
    textCenter: {
      textAlign: 'center',
      marginBottom: 20,
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dataWrapper: {
      marginRight: '10px',
      textAlign: 'left',
      fontWeight: 300,
      display: 'flex',
      flexDirection: 'column',
    },
    statsValue: {
      textAlignLast: 'left',
      display: 'flex',
      flexDirection: 'column',
    },
    statsLabel: {
      display: 'flex',
    },
  };
};

export default compose(
  withToggleModal,
  withSnackbar,
  withActor,
  withStyles(styles)
)(SetMyAgentConfirm);
