export const ACCOUNT_CMAIL = 'ACCOUNT_CMAIL';
export const ACCOUNT_SPOTLIGHTS = 'ACCOUNT_SPOTLIGHTS';
export const ACTIVITY = 'ACTIVITY';
export const AGENCY_ACCOUNTS = 'AGENCY_ACCOUNTS';
export const AGENCY_ENDORSEMENTS = 'AGENCY_ENDORSEMENTS';
export const AGENCY_TEAM_LOCATION_MAPPING = 'AGENCY_TEAM_LOCATION_MAPPING';
export const AGENCY_LEVELS = 'AGENCY_LEVELS';
export const CARRIER_RISK_PROFILE = 'CARRIER_RISK_PROFILE';
export const CARRIER_USERS_TABLE_ID = 'CARRIER_USERS_TABLE_ID';
export const COWBELL_AGENCY_USER_MIGRATIONS = 'COWBELL_AGENCY_USER_MIGRATIONS';
export const COWBELL_ACCOUNTS = 'COWBELL_ACCOUNTS';
export const COWBELL_AGENCY_PROGRAMS = 'COWBELL_AGENCY_PROGRAMS';
export const COWBELL_BINDERS = 'COWBELL_BINDERS';
export const COWBELL_ENDORSEMENTS = 'COWBELL_ENDORSEMENTS';
export const COWBELL_NOTES = 'COWBELL_NOTES';
export const COWBELL_POLICY = 'COWBELL_POLICY';
export const COWBELL_QUOTES = 'COWBELL_QUOTES';
export const COWBELL_RISK_ENG = 'COWBELL_RISK_ENG';
export const COWBELL_SPOTLIGHTS = 'COWBELL_SPOTLIGHTS';
export const COWBELL_SUBMISSIONS_WORKSPACE = 'COWBELL_SUBMISSIONS_WORKSPACE';
export const LOSS_RUNS_HISTORY = 'LOSS_RUNS_HISTORY';
export const PREMIUM_PROCESSORS_TABLE_ID = 'PREMIUM_PROCESSORS';
export const REINSURERS_TABLE_ID = 'REINSURERS';
