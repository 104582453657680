import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import MultiSelect from './MultiSelect';

export const ScrollableMultiSelect = ({
  api,
  apiParams,
  options,
  totalPages,
  reduceFunc,
  supportSelectAll = false,
  toggleAllHandler,
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState(options);
  const [loading, setLoading] = useState(false);
  const key = _.keys(apiParams);
  const value = _.values(apiParams);

  useEffect(() => {
    setData(options);
    // eslint-disable-next-line
  }, [options]);

  const scroll = () => {
    setPage(page + 1);
    if (page + 1 < totalPages) {
      api({ page: page + 1, [key]: value })
        .then((resp) => {
          const result = reduceFunc(resp.data?.content);
          setData((currData) => _.concat(currData, result));
        })
        .catch(console.error.bind(console));
    }
  };

  const reset = () => {
    setPage(0);
  };

  const handleInputChange = useCallback(
    _.debounce((newValue) => {
      setLoading(true);

      api({ before: null, searchValue: newValue, [key]: value })
        .then((resp) => {
          const result = reduceFunc(resp.data.content);
          setData(result);
        })
        .catch(console.error.bind(console))
        .finally(() => setLoading(false));
    }, 1000),
    []
  );

  return (
    <MultiSelect
      supportSelectAll={supportSelectAll}
      toggleAllHandler={toggleAllHandler}
      onMenuScrollToBottom={scroll}
      onMenuScrollToTop={reset}
      options={data}
      isLoading={loading}
      inputChange={handleInputChange}
      noOptionsMessage={() => 'No Results.'}
      loadingMessage={() => 'Loading'}
      {...props}
    />
  );
};
