import {
  COUNTRIES,
  getIndustryCodePath,
  getIndustryPrimaryDescriptionPath,
  getIndustrySecondaryDescriptionPath,
} from '../../i18n/i18n.language-config';
import type { AccountDto } from '../../types';
import type { FirmographicDataDto } from '../../types/firmo/firmographic-data.dto';
import type { GetPrimeXPolicyDetails } from '../policies.api.types';
import type { GetPrimeXQuoteDetailsResponse } from '../quotes.api.types';

export const serializeAccountIndustryCode = ({
  data,
}: {
  data: AccountDto;
}) => {
  const industryCodePath = getIndustryCodePath(data.country);
  const primaryIndustryDescPath = getIndustryPrimaryDescriptionPath(
    data.country
  );
  const secondaryIndustryDescPath = getIndustrySecondaryDescriptionPath(
    data.country
  );

  return {
    ...data,
    industryCode: data[industryCodePath],
    ui_primaryIndustryDescription: data[primaryIndustryDescPath] ?? '',
    ui_secondaryIndustryDescription: data[secondaryIndustryDescPath] ?? '',
  };
};

export const serializeLegacyIndustryCode = ({
  data,
}: {
  data: { firmographicData: FirmographicDataDto };
}) => {
  const industryCodePath = getIndustryCodePath(
    data?.firmographicData?.country || COUNTRIES.US
  );
  const primaryIndustryDescPath = getIndustryPrimaryDescriptionPath(
    data?.firmographicData?.country || COUNTRIES.US
  );
  const secondaryIndustryDescPath = getIndustrySecondaryDescriptionPath(
    data?.firmographicData?.country || COUNTRIES.US
  );

  return {
    data: {
      ...data,
      firmographicData: {
        ...data.firmographicData,
        industryCode: data.firmographicData?.[industryCodePath],
        ui_primaryIndustryDescription:
          data.firmographicData?.[primaryIndustryDescPath] ?? '',
        ui_secondaryIndustryDescription:
          data.firmographicData?.[secondaryIndustryDescPath] ?? '',
      },
    },
  };
};

export const serializePxQuoteIndustryCode = ({
  data,
}: {
  data: GetPrimeXPolicyDetails & GetPrimeXQuoteDetailsResponse;
}) => {
  const industryCodePath = getIndustryCodePath(data.firmographicData?.country);

  const primaryIndustryDescPath = getIndustryPrimaryDescriptionPath(
    data.firmographicData?.country
  );
  const secondaryIndustryDescPath = getIndustrySecondaryDescriptionPath(
    data.firmographicData?.country
  );

  return {
    data: {
      ...data,
      firmographicData: {
        ...data.firmographicData,
        industryCode: data.firmographicData?.[industryCodePath],
        ui_primaryIndustryDescription:
          data.firmographicData?.[primaryIndustryDescPath] ?? '',
        ui_secondaryIndustryDescription:
          data.firmographicData?.[secondaryIndustryDescPath] ?? '',
      },
    },
  };
};
