import React from 'react';
import Moment from 'moment';

// components
import { Box, ThemeProvider, Typography } from '@mui/material';
import { styled } from '@mui/system';

import LogoHeader from './LogoHeader';
import CbButton from '../Buttons/CbButton';
import darkTheme from '../../themes/dark.theme';
import { getIsUsPlatform } from '../../utils';
import { usePlatformLanguage } from '../../utils/hooks/usePlatformLanguage';
import type { Languages } from '../../i18n/i18n.language-config';
import { languages } from '../../i18n/i18n.language-config';
import { legal } from '../../i18n/constants';

interface Props {
  children: React.ReactNode;
  MarketingSlot?: React.ReactElement | null;
}

function NextPublicWrapper({ children, MarketingSlot = null }: Props) {
  const region = usePlatformLanguage();
  const isUkRegion = !getIsUsPlatform(region);

  return (
    <ThemeProvider theme={darkTheme}>
      <Background>
        <LoginContainer>
          <ContentBackground>
            <ContentContainer>
              <Box maxWidth="45%">
                <LogoHeader />
              </Box>
              <Box pt={3}>{children}</Box>
            </ContentContainer>
            <MarketingContainer>
              {MarketingSlot ? (
                React.cloneElement(MarketingSlot)
              ) : (
                <MarketingStylesContainer>
                  <img alt="person-working" src="/images/LoginBackground.png" />
                  <MarketingMessage>
                    <Typography className="canon-text" component="h3">
                      Adaptive Cyber Insurance
                    </Typography>
                    <Typography
                      className="tertia-text"
                      style={{ marginBottom: '1rem' }}
                    >
                      {isUkRegion ? (
                        <>
                          Adaptive Cyber Insurance Cowbell utilises technology
                          and data to offer small and medium-sized enterprises
                          advanced warning of cyber risk and customised cyber
                          insurance that can adapt to the threats of today and
                          tomorrow.
                        </>
                      ) : (
                        <>
                          Cowbell harnesses technology and data to provide SMEs
                          with advanced warning of cyber risk and customized
                          cyber insurance adaptable to the threats of today and
                          tomorrow.
                        </>
                      )}
                    </Typography>
                    <MarketingAction
                      stylename="primary"
                      fixed
                      href={LEARN_MORE_LINK[region]}
                      target="_blank"
                    >
                      Learn more
                    </MarketingAction>
                  </MarketingMessage>
                </MarketingStylesContainer>
              )}
            </MarketingContainer>
          </ContentBackground>
        </LoginContainer>
        <Footer region={region} />
      </Background>
    </ThemeProvider>
  );
}

export default NextPublicWrapper;

const LEARN_MORE_LINK = {
  [languages['en-US']]: 'https://cowbell.insure',
  [languages['en-GB']]: 'https://cowbell.insure/uk',
};

const Background = styled(Box)(({ theme }: { theme: any }) => {
  return {
    width: '100%',
    background: '#525461',
    overflow: 'hidden',
    color: theme.palette.text.contrastText,
  };
});

const Footer = ({ region }: { region: Languages }) => {
  return (
    <span
      style={{
        position: 'absolute',
        left: '50%',
        bottom: 5,
        transform: 'translateX(-50%)',
        textAlign: 'center',
        fontSize: '0.83rem',
      }}
    >
      <a
        href="https://cowbell.insure/terms/"
        rel="noopener noreferrer"
        target="_blank"
        style={{ color: 'inherit' }}
      >
        Terms of Service
      </a>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <a
        href="https://cowbell.insure/privacy/"
        rel="noopener noreferrer"
        target="_blank"
        style={{ color: 'inherit' }}
      >
        Privacy Policy
      </a>
      <span style={{ marginLeft: 5 }}>
        {`© ${Moment().year()} Cowbell Cyber Inc. All Rights Reserved, `}
        {legal[region]}
      </span>
    </span>
  );
};

const LoginContainer = styled('div')(() => {
  return {
    display: 'flex',
    width: '100vw',
    maxWidth: '1440px',
    height: '100vh',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#525461',
    color: '#fff',
  };
});

const ContentBackground = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '75%',
  height: '80%',
  maxHeight: '980px',
  justifyContent: 'start',
  position: 'relative',
  background: '#26262E',
  borderRadius: 8,

  [theme.breakpoints.up('md')]: {
    width: '90%',
    height: '90%',
  },

  [theme.breakpoints.up('lg')]: {
    width: '90%',
    maxHeight: '840px',
  },
}));

const ContentContainer = styled('div')({
  display: 'flex',
  width: '50%',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '2rem 8rem 2rem 2rem',
});

const MarketingContainer = styled('div')({
  display: 'flex',
  width: '50%',
  position: 'relative',

  '&:before': {
    content: '""',
    width: '50vw',
    height: 'calc(100% + 40px)',
    position: 'absolute',
    top: -20,
    left: 0,
    backgroundImage: 'linear-gradient(90deg, rgba(0, 0, 0, 0.3), transparent)',
  },
});

const MarketingStylesContainer = styled('div')({
  display: 'flex',
  padding: '2rem',
  alignItems: 'flex-end',
  position: 'relative',

  '& > img': {
    width: 'calc(100% + 50px)',
    maxWidth: 'unset',
    position: 'absolute',
    top: '50%',
    left: -25,
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
});

const MarketingMessage = styled('div')({
  position: 'relative',
  zIndex: 1,
  textAlign: 'right',
});

const MarketingAction = styled(CbButton)(({ theme }: any) => ({
  background: 'transparent',
  color: '#fff',
  border: `1px solid ${theme.config.colors.cowbellBlue}`,
  transition: 'all 0.2s',
  '&:hover': {
    background: theme.config.colors.cowbellBlue,
    border: `1px solid transparent`,
  },
}));
