/* eslint-disable no-unused-vars */
import React, { useRef, useState, useContext } from 'react';

import { IconButton, Popover } from '@mui/material';
import { withStyles } from '@mui/styles';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import { TableContext } from '../context.table';
import { ColumnSorter } from './column-sorter.table';

const styles = ({ palette }) => ({
  '@global': {
    '.table-ab-icon': {
      cursor: 'pointer',
      width: '1.25rem',
    },
  },

  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 14,
  },

  popoverPaper: {
    transform: 'none !important',
  },

  section: {
    paddingBottom: 14,
    borderBottom: `1px solid ${palette.primary.oldBorder}`,

    '&:last-child': {
      paddingBottom: 0,
      borderBottom: 0,
    },
  },
});

export const ColumnCustomizer = withStyles(styles)(
  ({ classes, popoverProps, render }) => {
    const ctx = useContext(TableContext);
    const $trigger = useRef(popoverProps.anchorEl || null);
    const { onClick, ...popover } = usePopoverState(popoverProps, $trigger);

    return (
      <>
        <IconButton ref={$trigger} color="primary" onClick={onClick}>
          <ViewColumnIcon />
        </IconButton>

        <Popover
          {...popover}
          style={{ marginTop: 10 }}
          classes={{ paper: classes.popoverPaper }}
        >
          {typeof render === 'function' ? (
            render({ classes, ...ctx })
          ) : (
            <div className={classes.popoverContent}>
              <div className={classes.section}>
                <ColumnSorter />
              </div>
            </div>
          )}
        </Popover>
      </>
    );
  }
);

const usePopoverState = (defaults, $ref) => {
  const [popover, setPopover] = useState(defaults);

  function togglePopover() {
    setPopover({
      ...popover,
      open: !popover.open,
      anchorEl: $ref.current,
    });
  }

  return {
    onClick: togglePopover,
    onClose: togglePopover,
    anchorEl: $ref,
    ...popover,
  };
};

ColumnCustomizer.displayName = 'ColumnCustomizer';

ColumnCustomizer.defaultProps = {
  popoverProps: {
    anchorEl: null,
    open: false,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
};
