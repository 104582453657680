import type { AxiosError } from 'axios';
import _ from 'lodash';
import type { Nullable } from '../types/common/utility';

export class ApiError extends Error {
  meta = {};

  developers = {};

  constructor(...args: any[]) {
    super(...args);
    const [message, options = {}] = args;
    const { meta, developers } = options;

    this.meta = meta;
    this.developers = developers;

    return this;
  }
}

export function isApiError(error: any) {
  return error instanceof ApiError;
}

const GENERIC_SERVER_STATUS_CODES = Object.freeze([500, 502, 503, 504]);
export function manageAPIError(
  error: AxiosError | undefined,
  fallbackMessage: Nullable<string> = 'An error has occurred. Please try again later.'
) {
  const isGenericServerError =
    error?.response &&
    GENERIC_SERVER_STATUS_CODES.includes(error.response.status);

  const phoneRelatedErrorMessage = getPhoneAPIError(error);

  const errorDetails = getErrorDetails(error);

  if (isGenericServerError) {
    return 'Our servers seem to be having trouble. Please try again later.';
  }

  if (phoneRelatedErrorMessage) {
    return phoneRelatedErrorMessage;
  }

  if (errorDetails) {
    return errorDetails;
  }

  const receivedErrorMessage = getAPIError(error);
  return receivedErrorMessage
    ? (receivedErrorMessage as string)
    : fallbackMessage;
}

function getAPIError(error: AxiosError | undefined) {
  const errorMessagePath =
    typeof error?.response?.data === 'string' ? 'data' : 'data.message';

  return _.get(error?.response, errorMessagePath) as string | undefined;
}

const INVALID_PHONE_REGEX = /\[.*?: /;

const getPhoneAPIError = (error: AxiosError | undefined) => {
  if (isErrorWithDetail(error)) {
    return `${error?.response?.data.detail}`
      ?.replace(INVALID_PHONE_REGEX, '')
      ?.replace(/]/g, '');
  }

  return null;
};

function isErrorWithDetail(
  error: AxiosError<any> | undefined
): error is AxiosError<{ detail: string }> {
  return Boolean(error?.response?.data?.detail);
}

function getErrorDetails(error: AxiosError<any> | undefined) {
  if (!Array.isArray(error?.response?.data?.errors)) {
    return null;
  }

  return error?.response?.data?.errors.map((err: any) => err.detail).join(', ');
}
