import * as Yup from 'yup';
import Moment from 'moment';
import '../../console/_global/lib/validations/CurrencySchemaType';
import { YUP_REQUIRED_FIELD_ERROR_MSG } from '../../types/common/utility';

export const name = Yup.string().label('Name').required();

export const noOfEmployees = Yup.number()
  .required()
  .label('Number of Employees')
  .min(1)
  .round('floor')
  .typeError(YUP_REQUIRED_FIELD_ERROR_MSG);

export const revenue = Yup.number()
  .label('Projected Revenue')
  .fromCurrency()
  .min(1)
  .max(
    1000000000000,
    '${label} must be less than or equal to 1 Trillion Dollars.'
  )
  .typeError(YUP_REQUIRED_FIELD_ERROR_MSG)
  .required();

export const noOfEmployeesAll = noOfEmployees;

export const yearEstablished = Yup.number()
  .label('Year Established')
  .typeError('${label} is required')
  .lessThan(
    Moment().add(1, 'y').year(),
    '${label} must be less than or equal to current year'
  )
  .moreThan(1499, '${label} must be greater than or equal to 1500')
  .required();

export const ownershipType = Yup.string().label('Ownership Type').required();

export const naicsCode = Yup.number()
  .label('Industry Code')
  .transform((current, original) =>
    typeof original === 'object' && original !== null
      ? Number(original.value)
      : Number(original)
  )
  .min(10, 'Industry Code must be atleast 2 digits')
  .max(999999, 'Industry Code be less than 6 digits')
  .nullable()
  .required();

export const secondNaicsCode = Yup.number()
  .label('Secondary Industry Code')
  .when('primaryNaicsPercentage', {
    is: (value) => Number(value) === 100,
    then: Yup.number()
      .typeError(
        'Secondary Industry Code is not allowed when NiacsCode is 100%'
      )
      .nullable()
      .test({
        name: 'secondNaicsCode',
        message:
          'Secondary Industry Code is not allowed when NiacsCode is 100%',
        test: (value) => !value,
      }),
    otherwise: Yup.number()
      .transform((current, original) =>
        typeof original === 'object' && original !== null
          ? Number(original.value)
          : Number(original)
      )
      .min(10, 'Secondary Industry Code must be atleast 2 digits')
      .max(999999, 'Secondary Industry Code must be less than 6 digits')
      .nullable()
      .required(),
  });

export const primaryNaicsPercentage = Yup.number()
  .label('Industry Code percentage')
  .min(51, 'Primary Naics Percentage must be 51% or higher')
  .max(100, 'Primary Naics Percentage must be 100% or lower')
  .required();

export const address1 = Yup.string().label('Address').required();

export const city = Yup.string()
  .label('City')
  .required()
  .matches(/^[a-zA-Z\s-]+$/, 'Invalid City');

export const state = Yup.string().label('State').required();

export const zipCode = Yup.string()
  .label('Zip Code')
  .matches(/^\d{5}(-\d{4})?(?!-)$/, 'Invalid ZIP Code')
  .test('Is ZIP 00000', 'Invalid ZIP Code', (value) => value !== '00000')
  .required();

export const phoneNumber = Yup.number()
  .label('Business Phone')
  .fromPhone()
  .phone()
  .required();

export const entityType = Yup.string().required().ensure().label('Entity Type');

export const hostname = Yup.string().required().label('Hostname');

export const additionalBrokerFee = Yup.number()
  .label('Additional Broker Fee')
  .fromCurrency()
  .required()
  .min(0, 'Additional Broker fee cannot be less than $0')
  .max(10000, 'Additional Broker fee must be less than or equal to $10,000')
  .typeError('Additional Broker Fee must be a number');
