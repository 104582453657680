import React from 'react';
import Moment from 'moment';

import { Box, Button, Fade, Link, Typography } from '@mui/material';

import { LOCAL_STORAGE_KEYS } from '../local-storage.consts';
import { useDelay } from './hooks/useDelay';

export const CookieConsent = () => {
  const [isShown, setIsShown] = React.useState(false);

  const { hasUserAnsweredCookieConsent, setAreCookiesEnabled } =
    useCookieConsent();
  useDelay(1500, handleTimeout);

  if (isShown === false || hasUserAnsweredCookieConsent) {
    return null;
  }

  function handleTimeout() {
    setIsShown(true);
  }

  const handleAccept = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.COOKIES_ENABLED, 'true');
    setLastConsentedDateInLocalStorage();
    setAreCookiesEnabled('true');
  };

  const handleDeny = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.COOKIES_ENABLED, 'false');
    setLastConsentedDateInLocalStorage();
    setAreCookiesEnabled('false');
  };

  const setLastConsentedDateInLocalStorage = () => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.COOKIES_LAST_CONSENTED,
      new Date().toISOString()
    );
  };

  return (
    <Fade in>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="fixed"
        width="100%"
        padding="1rem"
        sx={(theme) => ({
          bottom: 0,
          backgroundColor: theme.palette.primary.cowbellBlue,
          zIndex: 1000,
          color: 'white',
        })}
      >
        <Typography variant="body2">
          We use cookies to improve your experience on our platform.
          <Link
            href="https://cowbell.insure/privacy/"
            target="_blank"
            sx={{
              color: 'white',
              textDecoration: 'underline',
              marginLeft: '5px',
            }}
          >
            Privacy Policy
          </Link>
        </Typography>
        <Box display="flex" gap="1.5rem">
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.2)',
              fontWeight: 'bold',
            }}
            onClick={handleDeny}
          >
            Deny
          </Button>
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.2)',
              fontWeight: 'bold',
            }}
            onClick={handleAccept}
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

const useCookieConsent = () => {
  const [areCookiesEnabled, setAreCookiesEnabled] = React.useState<
    string | null
  >(() => localStorage.getItem(LOCAL_STORAGE_KEYS.COOKIES_ENABLED));

  React.useEffect(() => {
    if (areCookiesEnabled === null || areCookiesEnabled === 'false') {
      return;
    }

    const cookiesLastConstentedDate = localStorage.getItem(
      LOCAL_STORAGE_KEYS.COOKIES_LAST_CONSENTED
    );

    const sixMonthsFromLastConsent = Moment(cookiesLastConstentedDate).add(
      6,
      'months'
    );

    const shouldShowCookieConsentAgain = Moment().isSameOrAfter(
      sixMonthsFromLastConsent
    );

    if (shouldShowCookieConsentAgain) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.COOKIES_ENABLED);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.COOKIES_LAST_CONSENTED);
      setAreCookiesEnabled(null);
    }
  }, [areCookiesEnabled, setAreCookiesEnabled]);

  return {
    hasUserAnsweredCookieConsent: areCookiesEnabled !== null,
    setAreCookiesEnabled,
  };
};
