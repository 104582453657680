import React from 'react';
// ui
// components
import { FormProvider, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from '@mui/material';
import CBButton from '../../../../../components/Buttons/CbButton';
import { update250UwQuote } from '../../../../../api/p250.service';
import { PubSub } from '../../../../../utils/eventUtils';
import { useAPIErrorHandler } from '../../../../../components/hooks/useAPIErrorHandler';

export const UWUpdateQuoteConfirmation = ({ data, close }) => {
  const handleAPIError = useAPIErrorHandler();
  const { classes, actionName, quoteStatus } = data;
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    const { payload, enqueueSnackbar, history, redirect } = data;
    return update250UwQuote({}, payload)
      .then(() => {
        enqueueSnackbar('Quote updated', { variant: 'success' });
        close();
        PubSub.publish('quoteUpdated', payload.revenueFactor);
        if (redirect) {
          setTimeout(() => {
            history.push('/admin/inbox');
          }, 2000);
        }
      })
      .catch((e) => {
        handleAPIError('Failed to update quote.')(e);
        close();
      });
  };

  const renderContent = () => {
    const mappings = {
      'UPDATE TERMS': {
        line1: 'Are you sure you want to update the terms? This will',
        line2: `update the quote and the status will remain 'Ready'.`,
      },
      UPDATE: {
        line1: `Are you sure you want to update the quote? This will`,
        line2: `update the quote and the status will remain ${
          quoteStatus === 'INDICATION' ? 'Indication' : 'Referral'
        }.`,
      },
    };
    return (
      <p className={classes.description}>
        {mappings[actionName].line1}
        <br />
        {mappings[actionName].line2}
      </p>
    );
  };

  return (
    <FormProvider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>{renderContent()}</DialogContent>
        <DialogActions>
          <CBButton action={close} styleName="cancel" buttonText="Cancel" />
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="Save"
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};
