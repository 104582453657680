import React from 'react';
import QRCode from 'react-qr-code';

import {
  DialogActions,
  DialogContent,
  FormHelperText,
  CircularProgress,
  Box,
  styled,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, FormProvider } from 'react-hook-form';

import ReactCodeInput from 'react-code-input';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { getQRCode, verifyQR } from '../../api/AuthService';

import { InputLabelBase } from '../inputs/InputLabelBase';
import CbButton from '../Buttons/CbButton';
import { withFormController } from '../hocs/forms';

import { useMeQuery } from '../hooks/useMeQuery';
import { useActor } from '../hooks/useActor';
import { usePersona } from '../hooks/usePersona';
import { useToggleModal } from '../../utils/modal.utils';
import { Show } from '../Show';
import { getData } from '../../utils/functional/fp';

import useNotifications from '../../_lib/notifications/useNotifications';
import { manageAPIError } from '../../utils';

const CodeInput = withFormController(ReactCodeInput);

export default ({ close }) => {
  const notifications = useNotifications();

  const toggleModal = useToggleModal();

  const queryClient = useQueryClient();
  const history = useHistory();
  const actor = useActor();
  const persona = usePersona();

  const [QRCodeData, setQRCodeData] = React.useState(null);
  const [apiError, setApiError] = React.useState();

  const classes = useClasses();
  const {
    formState: { isSubmitting },
    handleSubmit,
    ...methods
  } = useForm();

  const handleAPIError = React.useCallback((error) => {
    const errorMessage = manageAPIError(
      error,
      'An error has occurred fetching the QR Code. Please try again after some time.'
    );

    setApiError(errorMessage);
  }, []);

  React.useEffect(() => {
    getQRCode('GOOGLE')
      .then(getData)
      .then((data) => {
        setQRCodeData(data);
      })
      .catch(handleAPIError);
  }, [handleAPIError]);

  const onSubmit = (formValues) => {
    return verifyQR({
      mfaType: 'GOOGLE',
      mfa1: formValues.firstCode,
      mfa2: formValues.secondCode,
    })
      .then(() => {
        notifications.show({ type: 'success', title: 'MFA Enabled!' });

        toggleModal.direct('MfaEnabled', false);
        queryClient.invalidateQueries([useMeQuery.QUERY_KEY]);

        if (actor.isInsured && persona.isInsured) {
          setTimeout(() => {
            history.push('/customer/policies');
          }, 2000);
        }
      })
      .catch(handleAPIError);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          className="modal-title"
          style={{ textAlign: 'inherit', paddingBottom: '12px' }}
        >
          <p className={classes.margins}>
            Step 1:{' '}
            <span className="modal-blue-text">
              Download and open an authenticator app on your phone.
            </span>
          </p>
          <p className={classes.margins}>
            Step 2:{' '}
            <span className="modal-blue-text">
              Scan the QR code below or enter manually, keep time based on.
            </span>
          </p>
          <p className={classes.margins}>
            Step 3:{' '}
            <span className="modal-blue-text">
              Enter the first 6-digit code, and the second 6-digit code.
            </span>
          </p>

          <div className={classes.container}>
            <QRCodeContainer>
              <Show
                when={QRCodeData != null}
                fallback={
                  <div className={classes.qrPlaceholder}>
                    {!apiError ? (
                      <Box sx={{ position: 'relative', width: 'fit-content' }}>
                        <span className={classes.loadingText}>Loading</span>

                        <CircularProgress
                          classes={{
                            root: `${classes.loadingIconRoot}`,
                            circle: `${classes.circles}`,
                          }}
                        />
                      </Box>
                    ) : (
                      <Typography
                        variant="subtitle"
                        color={(theme) => theme.palette.common.grey3}
                      >
                        Uh oh...
                      </Typography>
                    )}
                  </div>
                }
              >
                <QRCode value={QRCodeData} size={160} />
              </Show>
            </QRCodeContainer>

            <div className={classes.code}>
              <div className={classes.code}>
                <InputLabelBase
                  required
                  style={{ padding: '0 1rem', fontSize: '1rem' }}
                >
                  Enter First Code
                </InputLabelBase>
                <CodeInput
                  name="firstCode"
                  type="text"
                  autoFocus
                  className={classes.in}
                  fields={6}
                />
              </div>
              <div className={classes.code}>
                <InputLabelBase
                  required
                  style={{ padding: '0 1rem', fontSize: '1rem' }}
                >
                  Enter Second Code
                </InputLabelBase>
                <CodeInput
                  name="secondCode"
                  type="text"
                  autoFocus={false}
                  className={classes.in}
                  fields={6}
                />
              </div>
            </div>
          </div>

          <FormHelperText sx={{ marginTop: '18px' }} className="api-text-error">
            {apiError}
          </FormHelperText>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" action={close}>
            Cancel
          </CbButton>
          <CbButton
            loading={isSubmitting}
            styleName="ctaButton"
            buttonText="Enable MFA"
            type="submit"
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const QRCodeContainer = styled(Box)`
  background: white;
  padding: 8px;
  padding-bottom: 0;
  border-radius: 8px;

  min-width: 176px;
  min-height: 176px;
`;

const useClasses = makeStyles(({ config, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: '3rem',
  },
  code: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  qrPlaceholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    color: 'black',

    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(30px, 44px)',
  },
  loadingIconRoot: {
    width: '9.16rem !important',
    height: '9.16rem !important',
  },
  circles: {
    '&.MuiCircularProgress-circle': {
      strokeWidth: 1,
      color: palette.common.darkBlue,
    },
  },
  in: {
    '& input': {
      borderRadius: 5,
      border: `solid 1px ${config.colors.almostWhite}`,
      background: palette.common.darkBlue,
      marginLeft: 5,
      color: config.colors.white,
      fontSize: config.textSizes.paragon,
      textAlign: 'center',
      width: 36,
      height: 42,
    },
  },
  margins: {
    margin: 0,
  },
}));
