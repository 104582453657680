import React from 'react';

import { uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';

import type { ShowNotification } from './types';
import {
  DEFAULT_NOTI_POSITION,
  getPaperSnackbarVariant,
  getSnackbarAnchorOriginFromPosition,
} from './useNotifications/helpers';

import { PaperSnackbar } from '../../components/snackbars';

export const useNotifications = () => {
  const snackbarMethods = useSnackbar();

  const showNotification = React.useCallback<ShowNotification>(
    (options) => {
      const _position = options.position ?? DEFAULT_NOTI_POSITION;
      const snackbarAnchorOrigin =
        getSnackbarAnchorOriginFromPosition(_position);

      const variant = getPaperSnackbarVariant(options.type);

      const snackbarId = uniqueId();
      snackbarMethods.enqueueSnackbar(snackbarId, {
        content: (key) => (
          <PaperSnackbar
            key={key}
            id={key as string}
            message={options.title}
            variant={variant}
          >
            {options.body}
          </PaperSnackbar>
        ),
        id: snackbarId,

        anchorOrigin: snackbarAnchorOrigin,
      });
    },
    [snackbarMethods]
  );

  return React.useMemo(
    () => ({
      show: showNotification,
    }),
    [showNotification]
  );
};

export default useNotifications;
