import React, { useMemo } from 'react';
import {
  GrowthBook,
  GrowthBookProvider as BaseProvider,
} from '@growthbook/growthbook-react';

import { ENVIRONMENTS } from '../types/common/env.dto';
import { useEnvQuery } from '../queries/useEnvQuery';
import type { WithChildrenProps } from '../types/components/common';

function GrowthBookProvider({ children }: WithChildrenProps) {
  const { isLoading, data: envData } = useEnvQuery();

  const growthBook = useMemo(() => {
    if (isLoading || !envData?.envName) {
      return;
    }

    const gb = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: growthBookClientKeyMap[envData.envName],
      enableDevMode: true,
      subscribeToChanges: true,
      trackingCallback: (experiment, result) => {
        // eslint-disable-next-line no-console
        console.log('Viewed Experiment', {
          experimentId: experiment.key,
          variationId: result.key,
        });
      },
    });
    gb.loadFeatures();
    return gb;
  }, [isLoading, envData?.envName]);

  if (isLoading) {
    return null;
  }

  return <BaseProvider growthbook={growthBook}>{children}</BaseProvider>;
}

export default GrowthBookProvider;

const growthBookClientKeyMap = {
  [ENVIRONMENTS.DEV_ENV_ID]: 'sdk-LJmIwiWSM4G03M',
  [ENVIRONMENTS.DEV_SPACE]: 'sdk-LJmIwiWSM4G03M',
  [ENVIRONMENTS.QA_ENV_ID]: 'sdk-p1WUNKNDcuRwBJ9x',
  [ENVIRONMENTS.STAGING_ENV_ID]: 'sdk-vlzDam0Ar4ert0e',
  [ENVIRONMENTS.PROD_ENV_ID]: 'sdk-joFoSxzM3ZT7q9ME',
} as const;
