import _ from 'lodash';
import * as Yup from 'yup';
import React, { useState } from 'react';

// mui
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import ToggleButton from '@mui/lab/ToggleButton';
// helpers
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reasons, accountReasons } from '../../../../_statics/decline.statics';
import { _isOneOf } from '../../../../../utils/data.utils';
// components
import CBButton from '../../../../../components/Buttons/CbButton';
import { withFormController } from '../../../../../components/hocs/forms';
import { TextFieldBase } from '../../../../../components/inputs/TextFieldBase';
import { InputLabelBase } from '../../../../../components/inputs/InputLabelBase';
import { withShowable } from '../../../../_global/lib/withShowable';
import { RegisteredToggleButtonGroup } from '../../../../../components/Buttons/registered/v7/RegistedToggleButtonGroup';
// actions
import { decline250Quote } from '../../../../../api/p250.service';
import { declineAccount } from '../../../_services/accounts.service';
import { ProductTypes } from '../../../../../types';

const TextField = withFormController(TextFieldBase);
const ShowGrid = withShowable(MuiGrid);

const schema = Yup.object().shape({
  reasonSummary: Yup.string().ensure().required().label('Declaration Reason'),
  reason: Yup.string().required().label('Reason Description'),
  reasonFillIn: Yup.string().when(['reasonSummary'], {
    is: (reasonSummary) => _isOneOf(reasonSummary, ['Fill-in option']),
    then: Yup.string().ensure().required().label('Fill-In text'),
    otherwise: Yup.string(),
  }),
  accountReasonSummary: Yup.string()
    .label('Declaration Reason')
    .when(['accountDecline'], {
      is: (accountReasonSummary) => accountReasonSummary,
      then: Yup.string().required().ensure(),
      otherwise: Yup.string(),
    }),
  accountReason: Yup.string()
    .label('Reason Description')
    .when(['accountDecline'], {
      is: (accountReason) => accountReason,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
  accountReasonFillIn: Yup.string().when(
    ['accountReasonSummary', 'accountDecline'],
    {
      is: (accountReasonSummary, accountDecline) =>
        _isOneOf(accountReasonSummary, ['Fill-in option']) && accountDecline,
      then: Yup.string().ensure().required().label('Fill-In text'),
      otherwise: Yup.string(),
    }
  ),
});

export const UWDeclineQuote = ({ data, close }) => {
  const { classes } = data;
  const [showFillIn, setShowFillIn] = useState(false);
  const [accountShowFillIn, setAccountShowFillIn] = useState(false);

  const customClasses = useClasses();

  const defaultValues = {
    reason: '',
    reasonSummary: '',
    reasonFillIn: '',
    accountDecline: false,
    accountReason: '',
    accountReasonSummary: '',
    accountReasonFillIn: '',
  };

  const { handleSubmit, getValues, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    formState: { isSubmitting, errors },
  } = methods;

  const values = getValues();

  const handleReasonType = (event) => {
    const { name, value } = event.target;
    methods.setValue(name, value);
    if (value === reasons[6].value) {
      setShowFillIn(true);
      values.reasonFillIn = '';
    } else {
      setShowFillIn(false);
      values.reasonFillIn = value;
    }
  };

  const handleAccountReasonType = (event) => {
    const { name, value } = event.target;
    methods.setValue(name, value);
    if (value === accountReasons[4].value) {
      setAccountShowFillIn(true);
      values.accountReasonFillIn = '';
    } else {
      setAccountShowFillIn(false);
      values.accountReasonFillIn = value;
    }
  };

  const renderOptions = ({ label, value }) => {
    return (
      <MuiMenuItem className={classes.menuItem} key={label} value={value}>
        {label}
      </MuiMenuItem>
    );
  };

  const onSubmit = (formData) => {
    const reasonSummary = showFillIn
      ? formData.reasonFillIn
      : formData.reasonSummary;
    // schema.validate({ reason: formData.reason });

    if (formData.accountDecline) {
      const accountReasonSummary = accountShowFillIn
        ? formData.accountReasonFillIn
        : formData.accountReasonSummary;
      // schema.validate({ reason: formData.accountReason, accountReasonSummary });
    }

    const { quoteId, isNotificationEnabled, enqueueSnackbar, history } = data;

    if (formData.accountDecline) {
      const accountPayload = {
        productType: ProductTypes.p250,
        reason: formData.accountReason,
        reasonSummary: formData.accountReasonSummary,
      };

      declineAccount({ id: data.accountId }, accountPayload)
        .then(() => {
          enqueueSnackbar('Account Declined Successfully!', {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar('Failed to decline account', { variant: 'error' });
        });
    }

    decline250Quote(
      {},
      {
        quoteId,
        isNotificationEnabled,
        reason: formData.reason,
        reasonSummary,
      }
    )
      .then(() => {
        enqueueSnackbar('Quote declined', { variant: 'success' });
        close();
        setTimeout(() => {
          history.push(`/admin/inbox`);
        }, 2000);
      })
      .catch(() => {
        enqueueSnackbar('Failed to decline quote', { variant: 'error' });
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ textAlign: 'left', padding: '2rem 5rem' }}>
          <div className={customClasses.header}>
            <p>Are you sure you want to decline the quote?</p>
            <p> Please provide a reason for the declination below.</p>
          </div>

          <MuiGrid style={{ paddingBottom: '1rem' }}>
            <InputLabelBase required className={customClasses.label}>
              Reason for Declination
            </InputLabelBase>

            <MuiSelect
              variant="standard"
              {...methods.register('reasonSummary')}
              name="reasonSummary"
              required
              onChange={handleReasonType}
              values={values.reasonSummary}
              classes={{
                select: customClasses.select,
                icon: customClasses.selectIcon,
              }}
              placeholder="please select a reason"
              error={
                !values.reasonSummary && _.get(errors, 'reasonSummary.message')
              }
            >
              {reasons.map(renderOptions)}
            </MuiSelect>
            {!values.reasonSummary && (
              <FormHelperText className={customClasses.error}>
                {_.get(errors, 'reasonSummary.message')}
              </FormHelperText>
            )}
          </MuiGrid>

          <ShowGrid show={showFillIn}>
            <TextField name="reasonFillIn" required />
          </ShowGrid>

          <MuiGrid>
            <TextField
              label="Declination Description"
              name="reason"
              multiline
              required
              maxRows={6}
              minRows={6}
            />
          </MuiGrid>

          <MuiGrid>
            <div style={{ textAlign: 'center' }}>
              <InputLabelBase className={customClasses.accountLabel}>
                Are you also declining the entire account?
              </InputLabelBase>
              <RegisteredToggleButtonGroup name="accountDecline" exclusive>
                <ToggleButton value className={customClasses.toggleButton}>
                  <div>Yes</div>
                </ToggleButton>
                <ToggleButton
                  value={false}
                  className={customClasses.toggleButton}
                >
                  <div>No</div>
                </ToggleButton>
              </RegisteredToggleButtonGroup>
            </div>
          </MuiGrid>

          {values.accountDecline && (
            <>
              <MuiGrid style={{ paddingBottom: '1rem' }}>
                <InputLabelBase required className={customClasses.label}>
                  Reason for Account Declination
                </InputLabelBase>

                <MuiSelect
                  {...methods.register('accountReasonSummary')}
                  variant="standard"
                  name="accountReasonSummary"
                  required
                  onChange={handleAccountReasonType}
                  values={values.accountReasonSummary}
                  classes={{
                    select: customClasses.select,
                    icon: customClasses.selectIcon,
                  }}
                  placeholder="please select a reason"
                  error={
                    !values.accountReasonSummary &&
                    _.get(errors, 'accountReasonSummary.message')
                  }
                >
                  {accountReasons.map(renderOptions)}
                </MuiSelect>
                {!values.accountReasonSummary && (
                  <FormHelperText className={customClasses.error}>
                    {_.get(errors, 'accountReasonSummary.message')}
                  </FormHelperText>
                )}
              </MuiGrid>

              <ShowGrid show={accountShowFillIn}>
                <TextField name="accountReasonFillIn" required />
              </ShowGrid>

              <MuiGrid>
                <TextField
                  label="Account Declination Description"
                  name="accountReason"
                  multiline
                  required
                  maxRows={6}
                  minRows={6}
                />
              </MuiGrid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <CBButton action={close} styleName="cancel" buttonText="Cancel" />
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="Decline"
            customClass={customClasses.declineButton}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const useClasses = makeStyles(({ palette, config }) => ({
  declineButton: {
    border: 'none',
    backgroundColor: palette.background.red,
  },
  header: {
    fontSize: config.textSizes.primer,
    color: palette.primary.contrastText,
    '& p': {
      margin: 0,
    },
  },
  select: {
    justifyContent: 'left',
    '&:focus': {
      borderRadius: 5,
    },
  },
  selectIcon: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  label: {
    padding: '1.5rem 0 0.5rem 1rem',
    fontSize: config.textSizes.normal,
  },
  toggleButton: {
    padding: '0.5rem 4rem',
  },
  accountLabel: {
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: config.textSizes.primer,
  },
  error: {
    color: config.colors.textRed,
    textAlign: 'left',
    marginTop: 0,
  },
}));

export const UWDeclineQuoteConfig = {
  UWDeclineQuote: {
    component: UWDeclineQuote,
    config: {
      fullWidth: false,
      maxWidth: 'md',
      title: 'Reason to Decline',
      scroll: 'body',
    },
  },
};
