import { ExceptionBus } from './exception.bus';

export interface PublishableErrorOptions {
  publish: boolean;
}

const defaults: PublishableErrorOptions = {
  publish: true,
};

/**
 * @name PublishableError
 * @description create an exception that is published with pubsub
 * @example new PublishableError(BadRequestException)
 */
export abstract class PublishableError extends Error {
  eventId: string | null = null;

  private _options: PublishableErrorOptions = {
    publish: true,
  };

  constructor(error: any, options: PublishableErrorOptions) {
    super();
    Object.setPrototypeOf(this, PublishableError.prototype);

    this._options = { ...defaults, ...options };
    this._parseError(error);

    return this;
  }

  private _parseError(error: any) {
    this.eventId = error.eventId;
  }

  public publishErrorEvent(error: any) {
    if (this._options.publish) {
      if (!this.eventId) {
        throw new Error('Failed to publish error no "eventId" was provided');
      }

      ExceptionBus.publish(this.eventId, error);
    }
  }
}
