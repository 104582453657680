import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// helpers
import _ from 'lodash';

// material ui
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Divider,
  Grid as MuiGrid,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// platform helpers
import { applyPayment } from '../../console/admin/pay/PaymentService';
import { getBankAccountDetails } from '../../agencies/commissions/CommissionService';
import { delayedEvent } from '../../utils/eventUtils';

// components
import CbButton from '../Buttons/CbButton';
import { TextFieldBase } from '../inputs/TextFieldBase';

import { withFormController } from '../hocs/forms';
import LanguageCurrencyFieldBase from '../inputs/LanguageCurrencyField';

const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);
const TextField = withFormController(TextFieldBase);

const styles = ({ config }) => {
  return {
    divider: {
      marginBottom: '1.25rem',
    },
    label: {
      padding: '0 0 0.25rem 0.83rem',
      fontSize: config.textSizes.normal,
    },
  };
};

const validationSchema = Yup.object().shape({
  commissionsAmount: Yup.number()
    .label('Commissions Amount')
    .fromCurrency()
    .min(1)
    .typeError('${label} is a required field')
    .required(),

  description: Yup.string().label('Description').min(1).required(),
});

export const ApplyPayment = withStyles(styles)(({ classes, close, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');
  const [agencyToken, setAgencyToken] = useState();
  const [disabled, setDisabled] = useState(true);

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      agencyId: data.agencyId,
      commissionsAmount: data.amountDue,
      description: data.description,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    getBankAccountDetails({ agencyId: data.agencyId })
      .then((resp) => {
        const token = _.get(resp, 'data.token');
        setAgencyToken(token);
        setDisabled(!token);
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(error.response, 'data', 'Server Error')
          )
        );
      });
    // eslint-disable-next-line
  }, []);

  const onSubmit = (formData) => {
    const payload = {
      ...formData,
      agencyId: data.agencyId,
      token: agencyToken,
    };

    return applyPayment({}, payload)
      .then(() => {
        close();
        enqueueSnackbar('Payment Applied Successfully.', {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, 'outstandingbalance');
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong.Try again later.'
            )
          )
        );
      });
  };

  const { formState } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          className="modal-title"
          style={{ textAlign: 'left', margin: '-2rem 0' }}
        >
          <MuiGrid>
            <MuiGrid container spacing={6}>
              <MuiGrid item md={8}>
                <LanguageCurrencyField
                  name="commissionsAmount"
                  label="Commissions Amount"
                  required
                  fullWidth
                />
              </MuiGrid>
            </MuiGrid>
            <Divider className={classes.divider} />

            <MuiGrid item md={12}>
              <TextField
                type="text"
                name="description"
                label="Description"
                fullWidth
                multiline
                minRows={2}
                required
              />
            </MuiGrid>
          </MuiGrid>
          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText="Make Payment"
            loading={formState.isSubmitting}
            disabled={formState.isSubmitting || disabled}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
});

export default ApplyPayment;
