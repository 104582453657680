import _ from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
// mui
import { FormHelperText, DialogContent, DialogActions } from '@mui/material';
// services
import { assignAgency, fetchAgencies } from '../../api/apis';
// platform helpers

import CbButton from '../Buttons/CbButton';
import { reduceForAgencies } from '../../console/admin/users/userUtils';

// components
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/forms';
import { ScrollableMultiSelect } from '../Selects/MultiSelect/ScrollableMultiSelect';

// constants
import { useCowbellTranslations } from '../../i18n/translations';
import { queries } from '../../queries';

const TextField = withFormController(TextFieldBase);

export const SoftAssignAgency = ({ data, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = React.useState('');
  const [agencies, setAgencies] = React.useState([]);
  const [totalPages, setTotal] = React.useState(1);
  const { t } = useCowbellTranslations();

  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    formState: { isSubmitting },
    ...methods
  } = useForm({
    defaultValues: {
      accountName: data.name,
      agencySelected: '',
    },
  });

  const queryClient = useQueryClient();

  const values = getValues();

  React.useEffect(() => {
    fetchAgencies()
      // eslint-disable-next-line no-shadow
      .then((values) => {
        const result = reduceForAgencies(_.get(values, 'data.content'));
        setAgencies(result);
        setTotal(_.get(values, 'data.totalPages'));
      })
      .catch(() => {
        setError(
          `Not able to assign ${t(
            'agency'
          ).toLowerCase()} at this time. Please try again later.`
        );
      });
    // eslint-disable-next-line
  }, []);

  const handleSelect = (name, selectedOption) => {
    const option = _.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];
    setValue(name, option);
  };

  const onSubmit = ({ agencySelected }) => {
    const agency = agencySelected[0];

    const payload = {
      agencyId: _.get(agency, 'value') || '',
      agencyName: _.get(agency, 'label') || '',
      agentFirstName: _.get(agency, 'agentFirstName') || '',
      agentLastName: _.get(agency, 'agentLastName') || '',
      agentEmail: _.get(agency, 'agentEmail') || '',
      agentPhoneNumber: _.get(agency, 'agentPhoneNumber') || '',
    };

    return assignAgency(data.id, payload)
      .then(() => {
        enqueueSnackbar(
          `${t('agency')}\t${_.get(agency, 'label')}\tAssigned Successfully!`,
          { variant: 'success' }
        );

        close();
        queryClient.invalidateQueries(queries.accounts.list._def);
      })
      .catch((e) => {
        const errorData = _.get(
          e,
          'response.data',
          'Not able to assign agency at this time.Please try again later.'
        );
        setError(errorData);
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField name="accountName" label="Account Name" readOnly />
          <ScrollableMultiSelect
            {...register('agencySelected')}
            name="agencySelected"
            label={t('agencies')}
            buttonPlaceholder={`Assign ${t('agencies')}`}
            options={agencies}
            onChange={handleSelect}
            values={values.agencySelected}
            isMulti={false}
            api={(q) => fetchAgencies({ ...q, size: 100 })}
            totalPages={totalPages}
            reduceFunc={reduceForAgencies}
          />
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
          <CbButton
            type="submit"
            styleName="ctaButton"
            buttonText={`Assign ${t('agency')}`}
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default SoftAssignAgency;
