import _ from 'lodash';
import React from 'react';

import Numeral from 'numeral';
import * as Ramda from 'ramda';

import {
  ACTIVE_STATUS,
  BOUND_STATUS,
  IN_FORCE_STATUS,
  ISSUED_STATUS,
  CANCELLED_STATUS,
  CANCEL_TRANSACTION,
  CANCEL_REQUESTED_STATUS,
  CANCELLED_PENDING_STATUS,
  CANCELLED_IN_REINSTATEMENT_PERIOD,
  EXPIRED_STATUS,
  RENEWED_STATUS,
} from '../console/_statics/policy.statics';

import { _isOneOf } from './data.utils';
import {
  getOneIncKey,
  getOneIncKeyByPolicyNumber,
} from '../console/customers/_services/BillingService';
import { truncateString } from './text.utils';
import { getPlatformRegion } from '.';
import { NO_DECIMAL_FORMAT, deriveCurrencyFormat } from '../i18n/currencies';

/**
 * get localhost, staging or prod
 */
export function getEnvironment() {
  const { hostname } = window.location;

  if (hostname.includes('meetmoo.ai') || hostname.includes('localhost')) {
    return 'dev';
  }

  if (hostname.includes('morecowbell.ai')) {
    return 'staging';
  }
  if (hostname.includes('.cowbellcyber.ai')) {
    return 'prod';
  }
  return '';
}

const region = getPlatformRegion();
const stripSpecialChars = (input) => {
  if (typeof input === 'string') {
    return input.replace(/[^0-9.-]/g, '');
  }
  return input;
};

export function moneyFormat2Decimals(input, language = region) {
  return deriveCurrencyFormat(language, stripSpecialChars(input));
}

export function moneyFormat(input, language = region) {
  return deriveCurrencyFormat(
    language,
    stripSpecialChars(input),
    NO_DECIMAL_FORMAT
  );
}

export function numberFormat(input) {
  return Numeral(input).format('0,0');
}

export function numberFormat2Decimals(input) {
  return Numeral(input).format('0,0.00');
}

export function percentageFormat(input) {
  return Numeral(input).format('0%');
}

function percentageFormat4Decimals(input) {
  return Numeral(input).format('0.[0000]%');
}

const convertPercentageToDecimal = Ramda.compose(
  percentageFormat4Decimals,
  Ramda.divide(Ramda.__, 100)
);

export const formatPercentageField = (fieldName) =>
  Ramda.compose(
    Ramda.ifElse(Boolean, convertPercentageToDecimal, Ramda.always('-')),
    Ramda.prop(fieldName)
  );

export function hasUrl(searchText) {
  return window.location.pathname.indexOf(searchText) >= 0;
}

export function getUrlHost() {
  const host = window.location.hostname;
  if (host.indexOf('localhost') > -1) {
    return '.meetmoo.ai';
  }

  return `.${window.location.hostname.split('.').slice(1).join('.')}`;
}

export function summarizeValidQuotesStats(
  source,
  key,
  includeInvalidQuotes = false
) {
  const premiumBuckets = _.get(source, key, []);

  const reducer = (accumulator, currentObj) => {
    let count = 0;
    let premium = 0;

    if (
      includeInvalidQuotes ||
      !['INVALID', 'DRAFT', 'TEMP'].includes(currentObj.key)
    ) {
      count += _.get(currentObj, 'doc_count', 0);
      premium += _.get(currentObj, 'GWP.value', 0);
    }

    accumulator.count += count;
    accumulator.premium += premium;
    return accumulator;
  };

  return premiumBuckets.reduce(reducer, { count: 0, premium: 0 });
}

export function summarizeValidPolicyStats(source, key) {
  const premiumBuckets = _.get(source, key, []);

  return premiumBuckets.reduce(
    (acc, bucket) => {
      if (
        _isOneOf(bucket.key, [
          IN_FORCE_STATUS,
          ISSUED_STATUS,
          CANCEL_TRANSACTION,
          CANCEL_REQUESTED_STATUS,
          CANCELLED_PENDING_STATUS,
          CANCELLED_IN_REINSTATEMENT_PERIOD,
          EXPIRED_STATUS,
          RENEWED_STATUS,
          CANCELLED_STATUS,
          BOUND_STATUS,
        ])
      ) {
        return {
          ...acc,
          count: _isOneOf(bucket.key, [
            IN_FORCE_STATUS,
            ISSUED_STATUS,
            CANCEL_REQUESTED_STATUS,
            CANCELLED_PENDING_STATUS,
            CANCELLED_IN_REINSTATEMENT_PERIOD,
          ])
            ? (acc.count += _.get(bucket, 'doc_count', 0))
            : acc.count,
          expiredCount:
            bucket.key === EXPIRED_STATUS
              ? (acc.expiredCount += _.get(bucket, 'doc_count', 0))
              : acc.expiredCount,
          renewedCount:
            bucket.key === RENEWED_STATUS
              ? (acc.renewedCount += _.get(bucket, 'doc_count', 0))
              : acc.renewedCount,
          cancelledCount:
            bucket.key === CANCELLED_STATUS
              ? (acc.cancelledCount += _.get(bucket, 'doc_count', 0))
              : acc.cancelledCount,
          premium: _isOneOf(bucket.key, [
            IN_FORCE_STATUS,
            ISSUED_STATUS,
            CANCELLED_STATUS,
            CANCEL_TRANSACTION,
            CANCEL_REQUESTED_STATUS,
            CANCELLED_PENDING_STATUS,
            CANCELLED_IN_REINSTATEMENT_PERIOD,
          ])
            ? (acc.premium += _.get(bucket, 'GWP.value', 0))
            : acc.premium,
          binders:
            bucket.key === BOUND_STATUS
              ? (acc.binders += _.get(bucket, 'doc_count', 0))
              : acc.binders,
          boundPremium:
            bucket.key === BOUND_STATUS
              ? (acc.boundPremium += _.get(bucket, 'GWP.value', 0))
              : acc.boundPremium,
        };
      }
      return acc;
    },
    {
      count: 0,
      premium: 0,
      cancelledCount: 0,
      renewedCount: 0,
      expiredCount: 0,
      binders: 0,
      boundPremium: 0,
    }
  );
}

export function summarizeValidPolicyLimits(source, key) {
  const premiumBuckets = _.get(source, key, []);

  return premiumBuckets.reduce(
    (acc, bucket) => {
      return _isOneOf(bucket.key, [
        IN_FORCE_STATUS,
        BOUND_STATUS,
        ISSUED_STATUS,
        ACTIVE_STATUS,
      ])
        ? {
            ...acc,
            count: (acc.count += _.get(bucket, 'doc_count', 0)),
            premium: (acc.premium += _.get(bucket, 'Limit.value', 0)),
          }
        : acc;
    },
    {
      count: 0,
      premium: 0,
    }
  );
}

export function saveBlobFile(response, name = '', ext) {
  let type = response.headers['content-type'];
  let nextExt = ext;

  if (response.status === 400) {
    // change the file type to .xml to be able to read the error message
    type = 'application/xml';
    nextExt = '.xml';
  }

  const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.download = name.replace(/[,.\s]/g, '') + nextExt;
  link.click();
}

export const commonOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];
export const getYesNoLabel = (bool) => (bool && 'Yes') || 'No';
export const assessmentsMap = {
  claimHistory: {
    label: (
      <span>
        Had any <strong>past Claim(s)?</strong>
      </span>
    ),
    options: [
      { label: 'Never', value: 0 },
      { label: 'within last 12 months', value: 1 },
      { label: 'within last 2 years', value: 2 },
      { label: 'within last 3 years', value: 3 },
      { label: 'within last 4 years', value: 4 },
      { label: 'within 5 years or more', value: 5 },
    ],
  },
  isSecurityOfficer: {
    label: (
      <span>
        Does the policyholder agree to be the Designated{' '}
        <strong>Information Security Contact?</strong>
      </span>
    ),
    options: commonOptions,
  },
  isSecurityTraining: {
    label: (
      <span>
        Does policyholder provide mandatory{' '}
        <strong>information security training</strong> to all employees at least
        annually? If not, are they willing to implement it during the policy
        period?
      </span>
    ),
    options: commonOptions,
  },
  useEncryption: {
    label: (
      <span>
        Does the policyholder <strong>encrypt</strong> emails, mobile and
        computing devices containing <strong>sensitive information</strong>{' '}
        (e.g., PII, PHI, PCI) sent to external parties?
      </span>
    ),
    options: commonOptions,
  },
  useCloudStorage: {
    label: (
      <span>
        Does the policyholder have <strong>sensitive information</strong> stored{' '}
        <strong>on the cloud</strong>?
      </span>
    ),
    options: commonOptions,
  },
};

export const policyHolderAssessmentsMap = {
  claimHistory: {
    label: 'Had any past Claim(s)?',
    options: [
      { label: 'Never', value: 0 },
      { label: 'Within last 12 months', value: 1 },
      { label: 'Within last 2 years', value: 2 },
      { label: 'Within last 3 years', value: 3 },
      { label: 'Within last 4 years', value: 4 },
      { label: 'Within 5 years or more', value: 5 },
    ],
  },
  isSecurityOfficer: {
    label:
      'Does your organization have a Designated Information Security Contact?',
    options: commonOptions,
  },
  isSecurityTraining: {
    label:
      'Does your organization provide mandatory information security training to all employees at least annually? If not, are you willing to implement it during the policy period?',
    options: commonOptions,
  },
  useEncryption: {
    label:
      'Does your organization encrypt emails, mobile and computing devices containing sensitive information (e.g., PII, PHI, PCI) sent to external parties?',
    options: commonOptions,
  },
  useCloudStorage: {
    label:
      'Does your organization have sensitive information stored on the cloud?',
    options: commonOptions,
  },
};

export const claimTextMapping = {
  0: 'Never',
  1: 'Within last 12 months',
  2: 'Within last 2 years',
  3: 'Within last 3 years',
  4: 'Within last 4 years',
  5: 'Within 5 years or more',
};

/**
 * @name phoneFormatter
 * @deprecated use formatAsPhone instead
 */
export const phoneFormatter = (phone, defaultValue) => {
  if (phone) {
    const temp = `${phone.replace(/[^0-9]/g, '')}`;
    if (temp.length === 11) {
      return `${temp.substring(0, 1)} (${temp.substring(
        1,
        4
      )}) ${temp.substring(4, 7)}-${temp.substring(7)}`;
    }
    return `(${temp.substring(0, 3)}) ${temp.substring(3, 6)}-${temp.substring(
      6
    )}`;
  }
  return defaultValue;
};

/**
 * @deprecated
 */
export const loadScript = async (namespace, script, callback) => {
  if (window[namespace]) {
    return;
  }

  if (document.getElementById(namespace)) {
    return;
  }

  let $script = document.createElement('script');
  $script.async = false;
  $script.src = script;
  $script.id = namespace;

  if (typeof callback === 'function') {
    $script = callback($script);
  }

  document.getElementById('app-body').appendChild($script);
};

export const getScript = (src, options = {}) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(options.id)) {
      return;
    }

    const $script = document.createElement('script');

    const keys = Object.keys(options);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const value = options[key];
      $script[key] = value;
    }

    $script.src = src;
    $script.onload = resolve;
    $script.onError = reject;

    document.getElementById('app-body').appendChild($script);
  });
};

export const loadNonScript = async (elem, namespace, url, callback) => {
  if (document.getElementById(namespace)) {
    return;
  }

  let $elem = document.createElement(elem);
  $elem.async = true;
  $elem.href = url;
  $elem.id = namespace;

  if (typeof callback === 'function') {
    $elem = callback($elem);
  }

  document.getElementsByTagName('head')[0].appendChild($elem);
};

export const laodJQuery = () => {
  loadScript(
    'jQuery',
    'https://code.jquery.com/jquery-3.6.0.min.js',
    ($script) => {
      $script.integrity = 'sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=';
      $script.crossOrigin = 'anonymous';
      return $script;
    }
  );
};

export const useJQuery = (callback) => {
  const [loadingState, setLoadingState] = React.useState({
    isLoaded: typeof window.jQuery !== 'undefined',
  });

  React.useEffect(() => {
    getScript('/js/vendor/jquery-3.6.0.min.js', {
      id: 'jquery3.6.0',
    })
      .then(() => {
        /* confirm jquery was loaded and initialized */
        const interval = setInterval(() => {
          if (typeof window.jQuery !== 'undefined') {
            setLoadingState({ isLoaded: true });

            if (typeof callback === 'function') {
              /* called once when we confirm jquery was loaded */
              callback(null);
            }

            clearInterval(interval);
          }
        }, 500);
      })
      .catch((error) => {
        setLoadingState({ isLoaded: false, error });

        if (typeof callback === 'function') {
          /* called once when we failed to load jquery */
          callback(error);
        }
      });
    // eslint-disable-next-line
  }, []);

  return loadingState;
};

export function useScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = React.useState(src ? 'loading' : 'idle');
  React.useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', 'loading');
        // Add script to document body
        document.body.appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error'
          );
        };
        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };
      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );
  return status;
}

export const loadOneIncByPolicyNumber = (policyNumber) => {
  return getOneIncKeyByPolicyNumber({ policyNumber })
    .then((res) => {
      loadScript(
        'oneinc',
        `${res.data.url}/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js`
      );
      return res;
    })
    .catch(console.error);
};

export const loadOneInc = () => {
  return getOneIncKey()
    .then(({ data }) => {
      loadScript(
        'oneinc',
        `${data.url}/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js`
      );
      return data;
    })
    .catch(console.error);
};

export const YesNoOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
];
export const getBoolValueText = (value) => (value ? 'Yes' : 'No');
export const getIsRenewal = (value) => (value ? 'Renewal' : 'New');

export const RenewalOptions = [
  { label: 'Renewal', value: '1' },
  { label: 'New', value: '0' },
];

export function getRenewalText(isRenewal) {
  return isRenewal ? 'Renewal' : 'New';
}

/**
 * Triggers a setTimeout for the given time and returns a promise afterwards.
 * @param {Number} [ms] - Number of milliseconds for the timeout
 * @param {Boolean} [shouldThrow] - If true, a promise reject will be returned
 */
export async function sleep(ms = 1000, shouldThrow = false) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (shouldThrow) {
        reject(new Error('forced failure from "sleep"'));
      } else {
        resolve('');
      }
    }, ms);
  });
}

export const radioButtonOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

/**
 * Safely gets the error message of a cowbell BE network response.
 * @param {!import('axios').AxiosError} [error] - The axios error thrown
 * @param {Number} [maxLength] - The maximum length of the returned message
 * @param {string} [fallbackMessage] - The message to fallback onto if BE didn't provide one
 * @return {!string} The retrieved message only if it exists. Otherwise, the fallback messsage.
 */
export function errorMessageGetter(
  error,
  maxLength = 30,
  fallbackMessage = ''
) {
  if (typeof error.response?.data === 'string') {
    return truncateString(error.response.data, maxLength);
  }

  if (error.response?.data?.message) {
    return truncateString(error.response.data.message, maxLength);
  }

  return fallbackMessage;
}

/**
 * Description: Randomly produces a boolean
 * @param {number} chanceOfTrue - The desired probablity of the value `true`
 * @returns {boolean} The randomly produced boolean
 */
export function randomBoolean(chanceOfTrue) {
  const randomNum = Math.random() * 100;
  if (randomNum > chanceOfTrue) {
    return false;
  }

  return true;
}
