export enum RequestQuoteSteps {
  VERIFY_ACCOUNT_INFO = 'verify',
  PRODUCT_SELECTOR = 'product-selector',
  SECURITY_QUESTIONS = 'questions',
  STATE_LICENSE = 'state-license',
  REQUEST_QUOTE = 'quote',
  ADDITIONAL_INFO = 'additional-info',
  CUSTOM_QUOTE = 'custom-quote',
  VERIFY_SUBMISSION_INFO = 'verify-submission',
  SUBMISSIONS_PRODUCT_SELECTOR = 'submissions-product-selector',
  SUBMISSIONS_ADDITIONAL_INFO = 'submissions-additional-info',
  SUBMISSIONS_SECURITY_QUESTIONS = 'submissions-questions',
  SUBMISSIONS_REQUEST_QUOTE = 'submissions-quote',
  SUBMISSIONS_CUSTOM_QUOTE = 'submissions-custom-quote',
}

export const maxNumberQuotesInCart = 5 as const;

export const QUOTE_FLOWS = Object.freeze({
  REQUEST_QUOTE: 'requestQuote',
  EDIT_QUOTE: 'edit-quote',
  RENEW_QUOTE: 'renew-quote',
});

export type QuoteFlows = (typeof QUOTE_FLOWS)[keyof typeof QUOTE_FLOWS];

export const UNKNOWN_PRODUCT = 'UNKNOWN';
export type UnknownProduct = typeof UNKNOWN_PRODUCT;
