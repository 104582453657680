import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import SimpleTooltip from '../SimpleTooltip';
import TootTipIcon from '../../_assets/svg/Tooltip.svg';
import { CustomCheckbox } from './CustomCheckbox';

interface ToolTipCheckBoxProps {
  showRedTooltip: boolean;
  errorTooltip: string;
  mandatory: boolean;
}

export const TooltipCheckbox = ({
  showRedTooltip,
  errorTooltip,
  ...props
}: ToolTipCheckBoxProps) => {
  const checkboxStyles = useLegendClasses();
  const checkboxClasses = classnames({
    mandatory: props.mandatory,
  });

  return (
    <>
      <CustomCheckbox
        {...props}
        className={checkboxClasses}
        classes={checkboxStyles}
      />

      {showRedTooltip && (
        <SimpleTooltip title={errorTooltip} style={{ marginLeft: '-0.8rem' }}>
          <TootTipIcon fill="#f53d00" />
        </SimpleTooltip>
      )}
    </>
  );
};

const useLegendClasses = makeStyles(({ palette }) => ({
  root: {
    color: palette.text.primary,
  },

  checked: {
    color: `${palette.common.cowbellGreen2} !important`,

    '& + span': {
      color: `${palette.common.cowbellGreen2} !important`,
    },

    '&.mandatory': {
      color: `${palette.common.cowbellBlue} !important`,
    },

    '&.mandatory + span': {
      color: `${palette.common.cowbellBlue} !important`,
    },
    '&:hover': {
      background: 'none !important',
    },
  },
}));

export default TooltipCheckbox;
