import { createAction } from 'redux-actions';

// action types
export const REHYDRATE_FROM_PERSONA = 'REHYDRATE_FROM_PERSONA';
export const TOGGLE_FEATURE_FLAGS = 'TOGGLE_FEATURE_FLAGS';
export const PLATFORM_LANGUAGE_UPDATED = 'PLATFORM_LANGUAGE_UPDATED';
export const TOGGLE_UK_ENV = 'TOGGLE_UK_ENV';
export const TOGGLE_DYNAMIC_FORMS_ALL_PRODUCTS =
  'TOGGLE_DYNAMIC_FORMS_ALL_PRODUCTS';
export const TOGGLE_SUBMISSIONS_VIEW = 'TOGGLE_SUBMISSIONS_VIEW';

// actions
export const reyhdrateFromPersona = createAction(REHYDRATE_FROM_PERSONA);
export const toggleFeatureFlags = createAction(TOGGLE_FEATURE_FLAGS);
export const setPlatformLanguage = createAction(PLATFORM_LANGUAGE_UPDATED);
export const toggleUKEnvFlag = createAction(TOGGLE_UK_ENV);
export const toggleDynamicFormsAllProductsFlag = createAction(
  TOGGLE_DYNAMIC_FORMS_ALL_PRODUCTS
);
export const toggleSubmissionsView = createAction(TOGGLE_SUBMISSIONS_VIEW);
