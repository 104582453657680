import Moment from 'moment';
import { commonDateParamMappings } from '../api/common/mappings';
import { trimAndAddAsterisk } from '../utility/QueryUtility';

const commonAccountMappings = [{ path: 'page' }, { path: 'size' }];

export const accountServiceMappings = [
  ...commonAccountMappings,
  {
    path: 'from',
    paramKey: 'after',
    transform: ({ from, to }) => {
      if (!from) {
        return null;
      }

      return from === to
        ? Moment.unix(from).startOf('day').toISOString()
        : Moment.unix(from).toISOString();
    },
  },
  {
    path: 'to',
    paramKey: 'before',
    transform: ({ to, from }) => {
      if (!to) {
        return null;
      }

      return from === to
        ? Moment.unix(to).endOf('day').toISOString()
        : Moment.unix(to).toISOString();
    },
  },
  // {
  //   path: 'pageIndex',
  //   paramKey: 'page',
  //   transform: ({ pageIndex }) => {
  //     return isNaN(pageIndex) ? 0 : pageIndex;
  //   },
  // },
  {
    path: 'tag',
  },
  { path: 'isRiskMgmtAccount' },
  {
    path: 'state',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'profileScore':
        case 'revenue':
        case 'quickQuotePremium':
        case 'naicsCode':
        case 'noOfEmployeesAll':
        case 'created':
        case 'modified':
        case 'effectiveDate':
        case 'otherPolicyRenewalDate':
        case 'policyId':
        case 'naics':
        case 'isOpenForRenewal':
        case 'riskMgmtDetails.riskMgmtPriority.value':
        case 'riskMgmtDetails.riskMgmtDeadline':
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          break;
        case 'timestamp':
          if (orderBy && order) {
            return `created,${order}`;
          }
          break;
        default:
          if (orderBy && order) {
            return `${orderBy}.keyword,${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search, searchValue }) => {
      if (searchValue) {
        return searchValue;
      }
      return search ? trimAndAddAsterisk(search) : null;
    },
  },
  { path: 'fav' },

  // Service Mappings for search fields
  {
    path: 'name',
  },
  {
    path: 'description',
  },
  {
    path: 'domain',
  },
  {
    path: 'location',
  },
  { path: 'naicsCode' },
  { path: 'state' },
  { path: 'status' },
  { path: 'teamIds' },
  { path: 'isAgency' },
  { path: 'agentEmail' },
  { path: 'country' },
  { path: 'f', paramKey: 'filter' },
];

export const accountSearchV2Mappings = [
  ...commonDateParamMappings,
  ...commonAccountMappings,
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }
      return null;
    },
  },
  { path: 'search' },
  { path: 'accountId' },
  { path: 'f', paramKey: 'filter' },
  { path: 'teamIds' },
  { path: 'allAgencyResults' },
  { path: 'quoteFilter' },
  { path: 'binderFilter' },
  { path: 'policyFilter' },
];

export const accountAndNadSearchMappings = [
  ...commonAccountMappings,
  { path: 'search' },
  { path: 'state' },
  { path: 'country' },
];
