import type { JsonApiSpecResponse } from '../../types';

export type PageableResponse<T = any> = {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
};

type ElasticSearchHit = {
  _id: string;
  _index: string;
  _score: number;
  _source: any;
  _type?: any;
};

export function serializePageableResponse(
  data: PageableResponse
): JsonApiSpecResponse {
  return {
    data: data.content,
    meta: {
      total: data.totalElements,
      size: data.size,
      start: data.number,
    },
  };
}

interface ElasticPageableTokenResponse {
  hits: ElasticSearchHit[];
  nextToken: string;
  prevToken: string;
  totalHits: number;
}

export function serializeElasticPageableTokenResponse(
  data: ElasticPageableTokenResponse
): JsonApiSpecResponse {
  return {
    data: data.hits,
    meta: {
      total: data.totalHits,
      nextToken: data.nextToken,
      prevToken: data.prevToken,
    },
  };
}

export const standardElasticSerializers = [
  JSON.parse,
  serializeElasticPageableTokenResponse,
];
