import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { sendPaymentReminder } from '../../accounts/AccountService';
import CbButton from '../Buttons/CbButton';

export const SendPaymentReminder = ({ close, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const [error, setError] = useState('');
  const { id, companyName } = data;

  const onSubmit = () => {
    sendPaymentReminder(id)
      .then(() => {
        enqueueSnackbar(`Payment Reminder sent to ${companyName}.`, {
          variant: 'success',
        });
        close();
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong while sending payment reminder.'
            )
          )
        );
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <div className="modal-title">
          Are you sure you want to send payment reminder to{' '}
          <span className="modal-blue-text">{companyName}</span> ?
        </div>
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          loading={isSubmitting}
          styleName="ctaButton"
          buttonText="Send Reminder"
          type="submit"
        />
      </DialogActions>
    </form>
  );
};

export default SendPaymentReminder;
