import _ from 'lodash';
import React from 'react';
import {
  Box,
  Card as MuiCard,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { useFormContext } from 'react-hook-form';

import { withFormController } from '../../../../components/hocs/forms';
import { TextFieldBase } from '../../../../components/inputs/TextFieldBase';
import { InputLabelBase as InputLabel } from '../../../../components/inputs/InputLabelBase';
import { SimpleSelect } from '../../../../components/inputs';

import { useCardStyles } from './ClaimModalStyles';
import { UsStatesFull } from '../../../../utils/USState';

const TextField = withFormController(TextFieldBase);
const ControlledSelect = withFormController(SimpleSelect);

export const CreateClaimLocationLoss = withStyles(({ palette }) => ({
  heading: {
    marginTop: 0,
    marginBottom: 25,
    color: palette.common.cowbellBlue,
    textAlign: 'center',
  },
}))(({ classes }) => {
  const cardStyles = useCardStyles();

  const { control, getValues, register, onSelect, formState } =
    useFormContext();

  const formValues = getValues();

  return (
    <MuiCard classes={cardStyles}>
      <h2 className={classes.heading}>Location And Loss Information</h2>
      <MuiGrid container spacing={1}>
        <MuiGrid item md={6}>
          <InputLabel inline htmlFor="organizationName" required>
            Organization Name:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6}>
          {_.get(formValues, 'companyName', '-')}
        </MuiGrid>

        <MuiGrid item md={6}>
          <InputLabel inline htmlFor="address" required>
            Loss Location Address:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6}>
          <TextField name="address" controllerProps={{ control }} required />
        </MuiGrid>

        <MuiGrid item md={6}>
          <InputLabel inline htmlFor="city" required>
            Loss Location city, state, zip:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6}>
          <TextField
            name="city"
            controllerProps={{ control }}
            required
            style={{ marginRight: 7 }}
            placeholder="City"
          />
          <ControlledSelect
            name="state"
            isMulti={false}
            options={UsStatesFull}
            error={_.get(formState.errors, 'state.value.message')}
          />

          <Box mt={1}>
            <TextField
              name="zipCode"
              controllerProps={{ control }}
              required
              style={{ marginLeft: 7 }}
              placeholder="Zip code"
            />
          </Box>
        </MuiGrid>

        <MuiGrid item md={6}>
          <InputLabel inline htmlFor="lossDescription" required>
            Loss Description:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6}>
          <TextField
            name="lossDescription"
            controllerProps={{ control }}
            minRows="4"
            required
            fullWidth
            multiline
          />
        </MuiGrid>

        <MuiGrid item md={6}>
          <InputLabel inline htmlFor="lossDate">
            Loss Date:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6}>
          <TextField
            name="lossDate"
            controllerProps={{ control }}
            type="date"
          />
        </MuiGrid>

        <MuiGrid item md={6}>
          <InputLabel inline htmlFor="impactedDevicesOs">
            Operating System of Impacted Device(s):
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6}>
          <TextField
            name="impactedDevicesOs"
            controllerProps={{ control }}
            fullWidth
          />
        </MuiGrid>

        <MuiGrid item md={6}>
          <InputLabel inline htmlFor="impactedDevicesType">
            Type of Impacted Device (model, version):
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6}>
          <TextField
            name="impactedDevicesType"
            controllerProps={{ control }}
            fullWidth
          />
        </MuiGrid>

        <MuiGrid item md={6}>
          <InputLabel inline htmlFor="incidentNoticed">
            How was the incident noticed (inbox, browser, other):
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6}>
          <TextField
            name="incidentNoticed"
            controllerProps={{ control }}
            fullWidth
          />
        </MuiGrid>

        <MuiGrid item md={6}>
          <InputLabel inline htmlFor="lossInformationExplanation">
            If other, please explain:
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6}>
          <TextField
            name="lossInformationExplanation"
            controllerProps={{ control }}
            fullWidth
            multiline
            minRows="4"
          />
        </MuiGrid>

        <MuiGrid item md={6}>
          <InputLabel inline htmlFor="isAuthoritiesInvolved" required>
            Authorities involved?
          </InputLabel>
        </MuiGrid>
        <MuiGrid item md={6}>
          <MuiSelect
            variant="standard"
            name="isAuthoritiesInvolved"
            {...register('isAuthoritiesInvolved')}
            onChange={onSelect}
            defaultValue={getValues().isAuthoritiesInvolved}
            error={_.get(
              formState.error,
              'isAuthoritiesInvolved.value.message'
            )}
          >
            {claimContactOptions.map(({ label, value }) => (
              <MuiMenuItem key={label} value={value}>
                {label}
              </MuiMenuItem>
            ))}
          </MuiSelect>
        </MuiGrid>

        {formValues.isAuthoritiesInvolved === 'yes' && (
          <>
            <MuiGrid item md={6}>
              <InputLabel inline htmlFor="authorityName" required>
                Authorities Name:
              </InputLabel>
            </MuiGrid>
            <MuiGrid item md={6}>
              <TextField
                name="authorityName"
                required
                controllerProps={{ control }}
              />
            </MuiGrid>

            <MuiGrid item md={6}>
              <InputLabel inline htmlFor="authorityPhoneNumber" required>
                Phone Number:
              </InputLabel>
            </MuiGrid>
            <MuiGrid item md={6}>
              <TextField
                name="authorityPhoneNumber"
                required
                controllerProps={{ control }}
              />
            </MuiGrid>

            <MuiGrid item md={6}>
              <InputLabel inline htmlFor="isReportFiled" required>
                Report filed?:
              </InputLabel>
            </MuiGrid>
            <MuiGrid item md={6}>
              <MuiSelect
                variant="standard"
                name="isReportFiled"
                {...register('isReportFiled')}
                onChange={onSelect}
                defaultValue={formValues.isReportFiled || 'no'}
              >
                {claimContactOptions.map(({ label, value }) => (
                  <MuiMenuItem key={label} value={value}>
                    {label}
                  </MuiMenuItem>
                ))}
              </MuiSelect>
            </MuiGrid>

            {formValues.isReportFiled === 'yes' && (
              <>
                <MuiGrid item md={6}>
                  <InputLabel inline htmlFor="reportNumber" required>
                    Report Number?:
                  </InputLabel>
                </MuiGrid>
                <MuiGrid item md={6}>
                  <TextField
                    name="reportNumber"
                    required
                    controllerProps={{ control }}
                  />
                </MuiGrid>
              </>
            )}
          </>
        )}
      </MuiGrid>
    </MuiCard>
  );
});

const claimContactOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
