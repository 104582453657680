import type { TypographyProps } from '@mui/material';
import { Box, Typography, styled } from '@mui/material';
import { getPlatformRegion, truncateString } from '../../utils';
import useNotifications from '../../_lib/notifications/useNotifications';
import { getSupportEmailByRegion } from '../../i18n/utils';

type CowbellErrorPageProps = {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError(): void;
};

const CowbellErrorPage = (props: CowbellErrorPageProps) => {
  return (
    <Container>
      <Box
        p={1}
        mt={26}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <CowbellLogo />

        <Box textAlign="center" mt={3}>
          <Typography fontSize="1.5rem">
            An unexpected error occurred.
          </Typography>
          <Typography style={{ opacity: 0.5, marginTop: '0.75rem' }}>
            Sorry about that. Please try refreshing and contact us if the
            problem persists.
          </Typography>

          <ErrorLinksModule {...props} />
        </Box>
      </Box>
    </Container>
  );
};

export default CowbellErrorPage;

const CowbellLogo = () => (
  <img src="logos/CowbellLogo2.svg" alt="cowbell-logo" style={{ height: 60 }} />
);

const ErrorLinksModule = (
  props: React.ComponentProps<typeof CowbellErrorPage>
) => {
  const notifications = useNotifications();
  const email = getSupportEmailByRegion(getPlatformRegion());
  const onRefresh = () => window.location.reload();
  const onCopyError = () => {
    const issueLink = `${BASE_SENTRY_ISSUE_URL}&query=${props.eventId}`;
    const errorMessage = `Error: ${truncateString(
      props.error.message,
      60
    )}\n${issueLink}`;
    navigator.clipboard.writeText(errorMessage);

    notifications.show({
      title: 'Copied error details',
      type: 'success',
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="space-evenly"
      width="75%"
      margin="0 auto"
      mt="1rem"
    >
      <StyledLink onClick={onCopyError}>Copy Error Details</StyledLink>
      <Typography variant="caption" style={{ opacity: 0.5 }}>
        -
      </Typography>
      <StyledLink variant="caption" onClick={onRefresh}>
        Refresh Page
      </StyledLink>
      <Typography variant="caption" style={{ opacity: 0.5 }}>
        -
      </Typography>
      <a
        href={`mailto:${email}`}
        style={{ textDecoration: 'none', color: '#000' }}
      >
        <StyledLink variant="caption">Contact Support</StyledLink>
      </a>
    </Box>
  );
};

const Container = styled(Box)(() => ({
  background: '#f5f5f5',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
}));

const StyledLink = styled((props: TypographyProps) => (
  <Typography variant="caption" {...props} />
))(() => ({
  cursor: 'pointer',
  textDecoration: 'none',
  transition: 'opacity 0.3s ease',
  opacity: 0.5,
  '&:hover': {
    opacity: 1,
  },
}));

const SENTRY_ORG = 'cowbellcyber';
const PROJECT_ID = `5471177`;
const BASE_SENTRY_ISSUE_URL = `https://sentry.io/organizations/${SENTRY_ORG}/issues/?project=${PROJECT_ID}`;
